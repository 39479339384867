import React, { FC, createRef, forwardRef } from "react";
import {
  ToggleSwitchComponentProps,
} from "../../interface";
import { SwitchComponent as SyncfusionSwitchComponent, SwitchModel } from "@syncfusion/ej2-react-buttons";

import { defaultDropdownButtonProps } from "../../constants/globalVariable";
import { createUseStyles } from "react-jss";
import { getToggleSwitchComponentStyle } from "../../constants/globalFunctions";

const ToggleSwitchComponent: FC<ToggleSwitchComponentProps & SwitchModel> = forwardRef((props: ToggleSwitchComponentProps & SwitchModel, ref: any) => {
  /******************* 
@Purpose : Switch Component
@Parameter : ToggleSwitchComponentProps
@Author : Techture
 *******************/

  const useStyles = createUseStyles({
    toggleSwitchGradientButton: getToggleSwitchComponentStyle(
      props.dragOffColor as string,
      props.borderColor as string,
      props.dragOnColor as string,
      props.gradientColor1 as string,
      props.gradientColor2 as string,
      props.bgColor as string
    ),
  });

  const classes = useStyles() as any;
  const toggleRef = ref || createRef(null)


  return (
    <>
      <div className="flex items-center gap-1">
        {props.label && <label htmlFor={props.id}> {props.label} </label>}
        <div className="flex border-div" >
          <SyncfusionSwitchComponent
            ref={toggleRef}
            {...props}
            cssClass={classes.toggleSwitchGradientButton}
          /> 
        </div>
      </div>
    </>
  );
});
ToggleSwitchComponent.defaultProps = defaultDropdownButtonProps;
export default ToggleSwitchComponent;
