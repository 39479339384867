import { useEffect, useState } from "react";
import IconComponent from "@CubeComponents/icons";
import ImageEditorModal from "@Components/UserProfileComponents/ImageEditorModal";
import { useSelector } from "react-redux";

function ImageEditor({
  imageData = "",
  defaultImageData,
  isEditable = true,
  onDelete,
  onSubmitFunction,
  heading = "Add Project Image",
}) {
  const layout = useSelector((state) => state.layout);

  const [imgSrc, setImgSrc] = useState(imageData);
  const [tempImgSrc, setTempImgSrc] = useState(imageData);
  const [showOverlay, setShowOverlay] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  useEffect(() => {
    setImgSrc(
      imageData ||
        defaultImageData ||
        process.env.REACT_APP_IMAGES_PUBLIC_URL + layout.PROJECT_LOGO,
    );
    setTempImgSrc(imageData);
  }, [imageData, layout]);

  const handleModalOpen = () => {
    setOpenEditModal(!openEditModal);
  };

  const handleImgSaveClick = (base64EncodedImg) => {
    onSubmitFunction(base64EncodedImg);
    setImgSrc(base64EncodedImg);
  };

  const handleEditClick = () => {
    handleModalOpen();
  };

  const handleHover = () => {
    setShowOverlay(true);
  };

  const handleBlur = () => {
    setShowOverlay(false);
  };

  return (
    <>
      <div
        className="relative flex"
        onMouseEnter={handleHover}
        onMouseLeave={handleBlur}
      >
        {isEditable && showOverlay && (
          <div className="absolute flex flex-row h-full w-full items-center cursor-pointer">
            <div className="h-full w-full relative" onClick={handleEditClick}>
              <div className="flex h-full w-full bg-white opacity-40 hover:opacity-80" />
              <IconComponent
                name="edit"
                className="h-5 opacity-1 absolute inset-0 m-auto z-0"
              />
            </div>

            {tempImgSrc && (
              <div className="h-full w-full relative" onClick={onDelete}>
                <div className="flex h-full w-full bg-white opacity-40 hover:opacity-80" />
                <IconComponent
                  name="delete"
                  className="h-5 opacity-1 absolute inset-0 m-auto"
                />
              </div>
            )}
          </div>
        )}
        <img src={imgSrc} alt="logo" width="100%" />
      </div>
      {openEditModal && (
        <ImageEditorModal
          closeModal={handleModalOpen}
          onSubmitFunction={handleImgSaveClick}
          heading={heading}
          existingImg={imageData}
        />
      )}
    </>
  );
}

export default ImageEditor;
