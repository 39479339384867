import { getUrlForImage } from "@CubeComponents/constants/globalFunctions";
import axiosWrapper from "../services/axiosWrapper";
import { CONSTANTS } from "@CubeComponents/constants/globalVariable";
import { formCategories } from "../components/PortalSetings/Configuration/FormsAndFields/constants";

export function setSettingContent(data) {
  return {
    type: "SET_SETTING_CONTENT",
    data,
  };
}

export function setPortalProfiles(data) {
  return {
    type: "SET_PORTAL_PROFILES_LIST",
    data,
  };
}

export function setEntityList(data) {
  return {
    type: "SET_ENTITY_LIST",
    data,
  };
}

export function setCategoryList(data) {
  return {
    type: "SET_CATEGORY_LIST",
    data,
  };
}
export function setStatusData(data) {
  return {
    type: "SET_STATUS_LIST",
    data,
  };
}

export function setPortalUserPermission(data) {
  return {
    type: "SET_PORTAL_PERMISSION__LIST",
    data,
  };
}

export function getPortalUserList(data) {
  return {
    type: "PORTAL_USERS",
    data,
  };
}

export function getPortalUserCount(data) {
  return {
    type: "PORTAL_USERS_COUNT",
    data,
  };
}

export function setPortalStatusData(data) {
  return {
    type: "SET_PORTAL_STATUS_LIST",
    data,
  };
}

export function setPortalData(data) {
  return {
    type: "SET_PORTAL_DATA",
    data,
  };
}

export function mergePortalData(data) {
  return {
    type: "MERGE_PORTAL_DATA",
    data,
  };
}

export function setPortalEntityList(data) {
  return {
    type: "SET_PORTAL_ENTITY_LIST",
    data,
  };
}
export function setPortalCategoryList(data) {
  return {
    type: "SET_PORTAL_CATEGORY_LIST",
    data,
  };
}

export function getDsrData(data) {
  return {
    type: "GET_DSR_DATA",
    data,
  };
}

export function getPortalSetting(data) {
  return {
    type: "PORTAL_DASHBOARD_PERMISSION",
    data,
  };
}

export async function customModuleController(data) {
  const responseData = await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/portal/customModuleController`,
    data,
  );
  return responseData;
}

export function getPortalData() {
  return async (dispatch) => {
    const response = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/portal/getPortalData`,
      {
        value: {
          where: [
            {
              isComplex: false,
              field: "frontendUrl",
              operator: "equal",
              value: window.location.host,
            },
          ],
        },
      },
    );
    const portalData = response.data.result[0];

    dispatch(
      setPortalData({
        ...portalData,
        logoUrl: getUrlForImage({ id: portalData?._id }, CONSTANTS.PORTAL),
      }),
    );
  };
}

export function getPortalUsedSeats(data) {
  return async (dispatch) => {
    const response = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/portal/getPortalUsedSeats`,
      data,
    );

    const mergedData = {
      usedProSeats: 0,
      usedLiteSeats: 0,
    };

    response.data.result.forEach((data) => {
      if (data.roleName == "PORTAL_GUEST") {
        mergedData.usedLiteSeats = mergedData.usedLiteSeats + data.count;
      } else {
        mergedData.usedProSeats = mergedData.usedProSeats + data.count;
      }
    });

    dispatch(mergePortalData(mergedData));
  };
}

// updateProjectRolePermission
export function changePortalRolePermission(data) {
  return (dispatch) => {
    axiosWrapper
      .post(
        `${process.env.REACT_APP_CUBE_BACKEND}/portal/changePortalRolePermission`,
        data,
      )
      .then((response) => {
        if (response.data.status) {
          // console.log()
        }
      })
      .catch((err) => console.log("Error in changePortalRolePermission ", err));
  };
}

export function addStatusToPortal(data) {
  return (dispatch) => {
    axiosWrapper
      .post(
        `${process.env.REACT_APP_CUBE_BACKEND}/portal/savePortalStatus/`,
        data,
      )
      .then((response) => {});
  };
}

export function getPortalProfiles(data) {
  return async (dispatch) => {
    const responseData = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/portal/getPortalProfiles`,
    );
    if (responseData.status) {
      dispatch(setPortalProfiles(responseData.data.result));
    }
  };
}

export function getPortalUserDetails(data) {
  return (dispatch) => {
    axiosWrapper
      .post(`${process.env.REACT_APP_CUBE_BACKEND}/portal/getPortalUsers`, data)
      .then((response) => {
        if (response && response.status) {
          dispatch(getPortalUserList(response.data.result));
          dispatch(getPortalUserCount(response.data.count));
        }
      });
  };
}

export function getPortalUserPermission() {
  return (dispatch) => {
    axiosWrapper
      .get(
        `${process.env.REACT_APP_CUBE_BACKEND}/portal/getUserPortalPermissions`,
      )
      .then((response) => {
        if (response.data.status) {
          dispatch(setPortalUserPermission(response.data.data));
        }
      });
  };
}

export function addUserToPortal(data) {
  return (dispatch) => {
    axiosWrapper
      .post(
        `${process.env.REACT_APP_CUBE_BACKEND}/portal/addUserToPortal`,
        data,
      )
      .then((response) => {
        if (response.data.status) {
          dispatch(setPortalUserPermission(response.data.data));
        }
      });
  };
}

export function changePortalRole(data) {
  return (dispatch) => {
    axiosWrapper
      .post(
        `${process.env.REACT_APP_CUBE_BACKEND}/portal/changeUserPortalProfile`,
        data,
      )
      .then((response) => {
        if (response.data.status) {
          dispatch(setPortalUserPermission(response.data.data));
        }
      });
  };
}

export function getPortalStatusData(data) {
  return (dispatch) => {
    axiosWrapper
      .put(`${process.env.REACT_APP_CUBE_BACKEND}/portal/getPortalStatus`, data)
      .then((response) => {
        if (response.data.status) {
          dispatch(setPortalStatusData(response.data.data));
        }
      });
  };
}

export function getAllPortalEntity() {
  return (dispatch) => {
    axiosWrapper
      .get(`${process.env.REACT_APP_CUBE_BACKEND}/portal/getPortalEntityList`)
      .then((response) => {
        if (response.data.status) {
          dispatch(setPortalEntityList(response.data.data));
        }
      });
  };
}

export function getAllPortalCategories() {
  return (dispatch) => {
    axiosWrapper
      .get(
        `${process.env.REACT_APP_CUBE_BACKEND}/resourceStatus/getResourceStatusCategory`,
      )
      .then((response) => {
        if (response.data.status) {
          dispatch(setPortalCategoryList(response.data.data));
        }
      });
  };
}

export function fetchDSRData(data) {
  return async (dispatch) => {
    axiosWrapper
      .post(`${process.env.REACT_APP_CUBE_BACKEND}/dsr/getDsrData`, data)
      .then((res) => {
        if (res.data.status) {
          dispatch(getDsrData(res.data.result));
        }
      });
  };
}

export const saveAddEditCostDb = async (data) => {
  const response = await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/dsr/saveDsrData`,
    data,
  );
  return response;
};

export const deleteCostDb = async (data) => {
  const response = await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/dsr/deleteDsrData`,
    data,
  );
  return response;
};

export const updateGroupsData = async (data) => {
  const response = await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/groups/updateGroupsData`,
    data,
  );
  return response;
};

export const getProjectFormTemplate = async () => {
  const response = await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/portal/getFormTemplate`,
    { resourceType: formCategories.projects },
  );
  return response;
};

export const projectTemplateData = async (data) => {
  const response = await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/projectTemplates/projectTemplateData`,
    data,
  );
  return response;
};

export const spaceDataInProjectTemplate = async (data) => {
  const response = await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/projectTemplates/spacesDataInProjectTemplate`,
    data,
  );
  return response;
};
