import {
  ColumnDirective,
  ColumnsDirective,
  Page,
  Toolbar,
  Inject,
  Filter,
  Sort,
  ColumnChooser,
  Resize,
} from "@syncfusion/ej2-react-treegrid";
import TreeGridComponent from "../../cubeComponent/html/treeGrid";
import { Query, Predicate } from "@syncfusion/ej2-data";
import {
  contractEntitiesService,
  contractEntitiesUrl,
} from "../../services/dataManager";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ButtonComponent from "../../cubeComponent/form/ButtonComponent";
import { BUTTON_TYPES } from "../../cubeComponent/constants/globalVariable";
import IconComponent from "../../cubeComponent/icons";
import { GrClose } from "react-icons/gr";
import axiosWrapper from "../../services/axiosWrapper";
import { getTaskId } from "../../cubeComponent/constants";
import { useTranslation } from "react-i18next";

const ContractTasks = forwardRef(
  (
    {
      contractId,
      allowCheckBoxSelection = true,
      formId,
      setAttachBtnState,
      showAddToListOption = false,
      configParams = {},
      selectedTasksList = [],
      setSelectedTasksList,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const contractDetails = useSelector((state) => state.contractDetails);
    const portalData = useSelector((state) => state.portalData);
    const treeGrid = ref || useRef();
    const [addToListBtnState, setAddToListBtnState] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [showDisclaimer, setShowDisclaimer] = useState(false);

    const isVEPLPortal = portalData?.frontendUrl?.includes("vepl");

    // const taskListQuery = new Query()
    //   .addParams("scheduleId", contractDetails.linkedSchedule?._id)
    //   .addParams("parentContractId", contractId)
    //   .addParams("entity", "tasks")
    //   .addParams("contractVersionId", contractDetails.versionId)
    //   .addParams("formId", formId);

    const payload = {
      value: {
        scheduleId: contractDetails.linkedSchedule?._id,
        contractId,
        entity: "tasks",
        contractVersionId: contractDetails.versionId,
        formId,
      },
    };

    const {
      dataSourcePayload = payload,
      dataSourceUrl = contractEntitiesUrl,
      idMapping = "originalTaskId",
      toolbar = null,
    } = configParams;

    const fetchData = async () => {
      const res = await axiosWrapper.post(dataSourceUrl, dataSourcePayload);

      if (res.data.status) {
        setDataSource(() => res.data.result);
      }
    };

    useEffect(() => {
      fetchData();
    }, []);

    useEffect(() => {
      if (treeGrid.current) {
        treeGrid.current.dataSource = dataSource;
        if (formId && isVEPLPortal) {
          setShowDisclaimer(true);
          const todaysTimeStamp = Date.now();
          const sevenDayTimeStamp = 604800000;
          const startDateRange = todaysTimeStamp - sevenDayTimeStamp;
          const endDateRange = todaysTimeStamp + sevenDayTimeStamp;
          const startDateFilter = {
            field: "startDate",
            operator: "greaterthanorequal",
            value: startDateRange,
          };
          const endDateFilter = {
            field: "endDate",
            operator: "lessthanorequal",
            value: endDateRange,
          };
          const filterSettings = [startDateFilter, endDateFilter];
          treeGrid.current?.setProperties({
            filterSettings: { columns: filterSettings },
          });
        }
      }
    }, [dataSource.length, portalData]);

    function treeRowDataBound(params) {
      if (
        params.data.isAlreadyAddedInThisForm ||
        params.data.isAlreadyAddedInThisContract
      ) {
        params.isSelectable = false;
        params.row.classList.add("e-disabled");
      }
    }

    const selectionHandler = () => {
      const selectedData = treeGrid.current.getSelectedRecords();
      if (selectedData.length > 0) {
        let btnState = true;
        selectedData.map((item) => {
          if (
            item.isAlreadyAddedInThisForm ||
            item.isAlreadyAddedInThisContract
          ) {
            btnState = false;
          }
        });
        setAddToListBtnState(btnState);
        if (setAttachBtnState) {
          setAttachBtnState(btnState);
        }
      } else {
        setAddToListBtnState(false);
        if (setAttachBtnState) {
          setAttachBtnState(false);
        }
      }
    };

    const handleRowSelecting = (args) => {
      if (
        args.isInteracted &&
        (args?.data?.isAlreadyAddedInThisContract ||
          args?.data?.isAlreadyAddedInThisForm)
      ) {
        args.cancel = true;
      }
    };

    const plannedPercentTemplate = (args) => {
      if (new Date(args.plannedStartDate).getTime() >= new Date().getTime()) {
        return "0 %";
      }
      if (new Date(args.plannedEndDate).getTime() <= new Date().getTime()) {
        return "100 %";
      }
      const totalPlannedDuration = args.plannedEndDate - args.plannedStartDate;
      const currentDuration = new Date() - args.plannedStartDate;
      const plannedPercentage = (currentDuration / totalPlannedDuration) * 100;
      return `${plannedPercentage.toFixed(2)} %`;
    };

    const percentCompleteTemplate = (args) => {
      const milisecondsInADay = 86400000;
      const actualDuration =
        (new Date() - args.actualStartDate) / milisecondsInADay;
      const totalDuration = (args.EndDate - args.StartDate) / milisecondsInADay;

      if (args.actualStartDate && args.actualEndDate) {
        return "100 %";
      }
      if (!args.actualStartDate && !args.actualEndDate) {
        return "0 %";
      }
      if (actualDuration > totalDuration) {
        return `100 %`;
      }
      if (totalDuration > 0) {
        const progress = (actualDuration / totalDuration) * 100;
        return `${progress.toFixed(2)} %`;
      }
      return "0 %";
    };

    const getGrid = useCallback(
      () => (
        <div className="h-full flex overflow-auto">
          <TreeGridComponent
            ref={treeGrid}
            dataSource={dataSource}
            allowEditing={false}
            toolbar={toolbar ?? ["ExpandAll", "CollapseAll"]}
            hasChildMapping="hasChild"
            idMapping={idMapping}
            pageSettings={{ pageSizes: [10, 20, 50], pageSize: 10 }}
            parentIdMapping="parentId"
            allowPaging
            rowHeight={50}
            height="100%"
            rowSelected={selectionHandler}
            rowDeselected={selectionHandler}
            rowSelecting={handleRowSelecting}
            treeColumnIndex={allowCheckBoxSelection ? 1 : 0}
            allowFiltering
            filterSettings={{ type: "Menu", hierarchyMode: "Both" }}
            allowResizing
            allowSorting
            rowDataBound={treeRowDataBound}
            // query={dataSourceQuery}
          >
            <ColumnsDirective>
              {allowCheckBoxSelection && (
                <ColumnDirective
                  type="checkbox"
                  allowSorting={false}
                  allowFiltering={false}
                  width="50"
                  isPrimaryKey
                />
              )}
              <ColumnDirective
                field="scheduleCounter"
                headerText="ID"
                width="155"
                textAlign="Left"
                isPrimaryKey
                template={getTaskId}
              />
              <ColumnDirective
                field="title"
                headerText="Title"
                width="155"
                textAlign="Left"
              />
              <ColumnDirective
                field="startDate"
                headerText="Start Date"
                width="155"
                textAlign="Left"
                type="date"
              />
              <ColumnDirective
                field="endDate"
                headerText="End Date"
                width="155"
                textAlign="Left"
                type="date"
              />
              <ColumnDirective
                field="plannedStartDate"
                headerText="Planned Start Date"
                width="155"
                textAlign="Left"
                type="date"
              />
              <ColumnDirective
                field="plannedEndDate"
                headerText="Planned End Date"
                width="155"
                textAlign="Left"
                type="date"
              />
              <ColumnDirective
                field="actualStartDate"
                headerText="Actual Start Date"
                width="155"
                textAlign="Left"
                type="date"
              />
              <ColumnDirective
                field="actualEndDate"
                headerText="Actual End Date"
                width="155"
                textAlign="Left"
                type="date"
              />
              <ColumnDirective
                field="progress"
                headerText="Planned %"
                width="120"
                allowFiltering={false}
                allowSorting={false}
                template={plannedPercentTemplate}
                textAlign="Left"
                type="number"
              />
              <ColumnDirective
                field="progress"
                headerText="% Complete"
                width="120"
                allowFiltering={false}
                allowSorting={false}
                textAlign="Left"
                template={percentCompleteTemplate}
                type="number"
              />
              <ColumnDirective
                field="createdDate"
                headerText="Created On"
                width="150"
                clipMode="EllipsisWithTooltip"
                type="date"
              />
            </ColumnsDirective>
            <Inject
              services={[Page, Toolbar, Filter, Sort, ColumnChooser, Resize]}
            />
          </TreeGridComponent>
        </div>
      ),
      [contractDetails.linkedSchedule],
    );
    const getEmptyIllustration = () => (
      <div className="m-6">
        {t("No Schedule have been linked to this contract")}
      </div>
    );

    const handleAddToListTask = () => {
      const selectedTasks = treeGrid.current.getSelectedRecords();
      setSelectedTasksList((prev) => [...prev, ...selectedTasks]);
    };

    const removeTaskClick = (originalTaskId) => {
      setSelectedTasksList((prev) =>
        prev.filter((task) => task.originalTaskId != originalTaskId),
      );
    };

    const addToList = () => (
      <div className="w-full justify-between h-20 items-center overflow-auto flex">
        <div className="w-full h-full flex flex-wrap gap-3 overflow-auto">
          {selectedTasksList.map((task) => (
            <div className="border-2 h-8 max-w-40 gap-2 pl-1 pr-1 border-button_light rounded flex items-center">
              <span className="whitespace-nowrap overflow-hidden text-ellipsis">
                {getTaskId(task)}
              </span>
              <span>
                <GrClose
                  size={10}
                  className="cursor-pointer"
                  onClick={() => removeTaskClick(task.originalTaskId)}
                />
              </span>
            </div>
          ))}
        </div>
        <div>
          <ButtonComponent
            onClick={handleAddToListTask}
            disabled={!addToListBtnState}
            buttonType={BUTTON_TYPES.GRADIENT_BUTTON}
          >
            {t("Add To List")}
          </ButtonComponent>
        </div>
      </div>
    );

    const getDisclaimer = () => {
      return (
        <div className="opacity-50 p-2 bg-yellow-200 border rounded-lg flex justify-between items-center">
          <div className="text-sm">
            {t(
              "A Preset filter is being applied , please clear filters to see all activities",
            )}
          </div>
          <div
            onClick={() => setShowDisclaimer(false)}
            className="cursor-pointer items-start"
          >
            <IconComponent name="cross" />
          </div>
        </div>
      );
    };

    return (
      <div className="m-6 flex flex-col h-full gap-5">
        {formId && showDisclaimer && getDisclaimer()}
        {contractDetails.linkedSchedule?._id
          ? getGrid()
          : getEmptyIllustration()}
        {showAddToListOption && addToList()}
      </div>
    );
  },
);
export default ContractTasks;
