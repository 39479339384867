import React, {
  createRef,
  forwardRef,
  useId,
  useState,
  useEffect,
  useRef,
} from "react";
import "./index.css";
import {
  SlickgridReact,
  SlickRowDetailView,
  ExtensionName,
} from "slickgrid-react";
import "@slickgrid-universal/common/dist/styles/css/slickgrid-theme-material.css";
import PagerComponent from "./Pagination";
import { Predicate, Query } from "@syncfusion/ej2-data";
import CircularProgress from "@mui/material/CircularProgress";
import Shimmer from "../../../components/Queries/Shimmer";
import { ExcelExportService } from "@slickgrid-universal/excel-export";
import ToolBar from "./ToolBar";
import FilterComponent from "./FilterComponent";
import axiosWrapper from "../../../services/axiosWrapper";
import { createRoot } from "react-dom/client";

const NO_OF_FETCHED_ROWS = 1000000;

const SlickGrid = forwardRef((props, ref) => {
  const id = props.id || useId();
  const gridRef = ref || createRef(null);
  const [appliedFilters, setAppliedFilters] = useState([]);

  const [loaderOnPageChange, setLoaderOnPageChange] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItemCount, setTotalItemCount] = useState(1);
  const [allPrerequisiteLoaded, setAllPrerequisiteLoaded] = useState(false);

  const [totalPages, setTotalPages] = useState(
    Math.ceil(totalItemCount / pageSize) === 0
      ? 1
      : Math.ceil(totalItemCount / pageSize),
  );

  const [dataset, setDataset] = useState([]);
  const [updatedColumnDefinitions, setUpdatedColumnDefinitions] = useState([]);

  const [currentColumnId, setCurrentColumnId] = useState("");
  const [filterPredicates, setFilterPredicates] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterType, setFilterType] = useState("");
  const [filterOpened, setFilterOpened] = useState(false);

  const topOfDragRowRef = useRef();
  const [insertBefore, setInsertBefore] = useState(null);
  const [dataRow, setDataRow] = useState(null);
  const [top, setTop] = useState(null);

  useEffect(() => {
    if (dataset && props.columnDefinitions.length) {
      setAllPrerequisiteLoaded(true);
    }
  }, [dataset, props.columnDefinitions]);

  useEffect(() => {
    if (props.dataset) {
      setDataset(props.dataset);
      setTotalItemCount(props.dataset?.length);
    }
  }, [props.dataset]);

  useEffect(() => {
    setLoaderOnPageChange(true);
    if (props.allowPagination) {
      loadData((currentPage - 1) * pageSize, pageSize);
    } else {
      loadData(0, NO_OF_FETCHED_ROWS);
    }
  }, [pageSize, currentPage, props.query, filterPredicates]);

  const loadData = async (skip, take) => {
    let response;
    let modifiedResponse;

    if (props.query && props.remoteDataSource) {
      const modifiedQuery = props.query
        ? props.query.clone()
        : new Query().requiresCount();
      modifiedQuery.queries = modifiedQuery.queries.filter(
        (q) => q.fn !== "onSkip" || q.fn !== "onTake",
      );
      if (Object.keys(filterPredicates)?.length) {
        modifiedQuery.where(filterPredicates).skip(skip).take(take);
        response = await props.remoteDataSource.executeQuery(modifiedQuery);
      } else {
        modifiedQuery.skip(skip).take(take);
        response = await props.remoteDataSource.executeQuery(modifiedQuery);
      }
      if (props.beforeDataBound) {
        modifiedResponse = props.beforeDataBound(response);
      } else {
        modifiedResponse = response.result;
      }

      setTotalItemCount(response?.count);
    } else if (props.dataset) {
      modifiedResponse = dataset.slice(skip, take + skip);
    }

    if (props.treeGrid && modifiedResponse?.length) {
      modifiedResponse = modifiedResponse.map((data) => ({
        ...data,
        __hasChildren: data[props.hasChildMapping],
        __collapsed: data[props.hasChildMapping] ? true : undefined,
      }));
    }

    modifiedResponse = modifiedResponse?.map((data) => {
      if (!data.id) {
        data.id = data._id;
      }
      return data;
    });

    setLoaderOnPageChange(false);
    setDataset(modifiedResponse);
  };

  const excelExportService = new ExcelExportService();

  window.excelExportService = excelExportService;

  useEffect(() => {
    let checkBoxColumnDefinition;
    if (gridRef.current) {
      checkBoxColumnDefinition = gridRef.current.slickGrid.columns.filter(
        (col) => col.id === "_checkbox_selector",
      );
    }

    const columnDefinitions = props.columnDefinitions.map((column) => {
      const updatedColumn = { ...column };
      if (props.isEditMode) {
        const renderEditorTemplate = (updatedColumn, args) => {
          let value;
          if (updatedColumn._valueGetter) {
            value = column._valueGetter(args);
          } else {
            value = args[updatedColumn.field];
          }
          const container = document.createElement("div");
          const root = createRoot(container);

          root.render(
            updatedColumn.editorTemplate({
              value,
              updatedColumn,
              args,
            }),
          );
          return container;
        };

        if (updatedColumn.editorTemplate) {
          updatedColumn.formatter = (row, col, dataContext, grid, args) => {
            if (gridRef.current.slickGrid.selectedRows?.[0] === row) {
              return renderEditorTemplate(updatedColumn, args);
            }
            return column._valueGetter ? column._valueGetter(args) : "";
          };
        }
      }
      if (column.filterType) {
        return {
          ...updatedColumn,
          filterable: true,
          sortable: true,
          groupable: true,
          type: "string",
          header: {
            ...column.header,
            buttons: [
              {
                cssClass: "mdi mdi-filter-variant text-slate-900",
                command: "toggle-highlight",
                tooltip: column.id,
              },
            ],
          },
        };
      }

      return updatedColumn;
    });

    if (checkBoxColumnDefinition?.length) {
      setUpdatedColumnDefinitions([
        ...checkBoxColumnDefinition,
        ...columnDefinitions,
      ]);
    } else {
      setUpdatedColumnDefinitions(columnDefinitions);
    }
  }, [props.columnDefinitions, props.isEditMode]);

  useEffect(() => {
    if (props.dragAndDrop) {
      let intervalId = null;
      let lastTop = null;

      const checkPosition = () => {
        const element = document.querySelector(".slick-reorder-proxy");
        if (element) {
          const { top } = element.getBoundingClientRect();

          if (top !== lastTop) {
            topOfDragRowRef.current = top;
            setTop(top);

            lastTop = top;
          }
        }
      };
      const observer = new MutationObserver(() => {
        const element = document.querySelector(".slick-reorder-proxy");
        if (element && !intervalId) {
          intervalId = setInterval(checkPosition, 100);
        }
      });
      observer.observe(document.body, { childList: true, subtree: true });
      return () => {
        observer.disconnect();
        if (intervalId) clearInterval(intervalId);
      };
    }
  }, []);

  const handleFilterIconClick = (event, columnId, filterType, args) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setFilterOpened(true);
    setAnchorEl({ top: rect.top, left: rect.left });
    setCurrentColumnId(columnId);
    setFilterType(filterType);
  };

  const onMoveRows = async (e, args) => {
    const { rows, insertBefore, grid } = args;

    if (gridRef.current) {
      gridRef.current.dataView.sort(undefined, true);
    }

    const currentTop = topOfDragRowRef.current;
    let newInsertPosition = insertBefore;
    const [row] = rows;
    let newParentId = null;

    let data = [...grid.data.filteredItems];
    const movedRowData = data[row];
    const previousParentId = movedRowData.parentId;

    const droppedRowIndex = insertBefore === 0 ? null : insertBefore - 1;
    const droppedRowData =
      droppedRowIndex !== null ? data[droppedRowIndex] : null;

    const oddRowsArray = document.querySelectorAll(
      ".slick-widget-content.slick-row.odd",
    );
    const evenRowsArray = document.querySelectorAll(
      ".slick-widget-content.slick-row.even",
    );

    const movedRowElement =
      row % 2 === 0
        ? evenRowsArray[row / 2]
        : oddRowsArray[Math.floor(row / 2)];

    const droppedRowElement =
      droppedRowIndex % 2 === 0
        ? evenRowsArray[droppedRowIndex / 2]
        : oddRowsArray[Math.floor(droppedRowIndex / 2)];
    if (droppedRowElement) {
      const { top: movedRowTop } = movedRowElement.getBoundingClientRect();
      const { top: rowTop, height: rowHeight } =
        droppedRowElement.getBoundingClientRect();
      const midpointY = rowTop + rowHeight / 2;

      const tolerance = 10;

      if (
        movedRowTop < rowTop ||
        !droppedRowData ||
        droppedRowData.treeLevel !== movedRowData.treeLevel ||
        (droppedRowData.parentId !== movedRowData.parentId &&
          !(
            currentTop >= midpointY - tolerance &&
            currentTop <= midpointY + tolerance
          ))
      ) {
        return;
      }

      if (
        currentTop >= midpointY - tolerance &&
        currentTop <= midpointY + tolerance
      ) {
        newParentId = droppedRowData._id;
        newInsertPosition = insertBefore;
      } else if (
        currentTop < rowTop + rowHeight &&
        currentTop >= midpointY + tolerance
      ) {
        newInsertPosition = insertBefore;
      }
    }

    if (newParentId || insertBefore == 0) {
      setLoaderOnPageChange(true);
      const response = await axiosWrapper.post(
        `${process.env.REACT_APP_CUBE_BACKEND}/tasks/reorderSchedule`,
        {
          parentId: newParentId || null,
          _id: movedRowData._id,
        },
      );
      setLoaderOnPageChange(false);
      if (response.status) {
        movedRowData.parentId = newParentId;
        movedRowData.treeLevel = newParentId ? droppedRowData.treeLevel + 1 : 0;

        if (newParentId) {
          droppedRowData.__hasChildren = true;
          droppedRowData.hasChild = true;
        }

        if (previousParentId) {
          const oldParent = data.find((item) => item._id === previousParentId);
          if (oldParent) {
            const hasChildren = data.some(
              (item) => item.parentId === oldParent._id,
            );
            if (!hasChildren) {
              oldParent.__hasChildren = false;
              oldParent.hasChild = false;
            }
          }
        }
      }
    }

    data = data.filter((item) => item._id !== movedRowData._id);
    data.splice(newInsertPosition, 0, movedRowData);

    setDataset(data);
    gridRef.current.slickGrid.invalidate();
  };

  const handleDrag = () => {
    if (gridRef.current) {
      const reorderGuide = document.querySelector(".slick-reorder-guide");
      const datarows = [...gridRef.current.dataView.filteredItems];
      const currentTop = topOfDragRowRef.current;

      const oddRowsArray = document.querySelectorAll(
        ".slick-widget-content.slick-row.odd",
      );
      const evenRowsArray = document.querySelectorAll(
        ".slick-widget-content.slick-row.even",
      );

      const movedRowElement =
        dataRow % 2 === 0
          ? evenRowsArray[dataRow / 2]
          : oddRowsArray[Math.floor(dataRow / 2)];

      if (dataRow) {
        if (dataRow === insertBefore - 1) {
          return;
        }
        const droppedRowIndex = insertBefore - 1;
        if (droppedRowIndex == -1) {
          return;
        }
        const droppedRowData = datarows[droppedRowIndex];
        const droppedRowElement =
          droppedRowIndex % 2 === 0
            ? evenRowsArray[droppedRowIndex / 2]
            : oddRowsArray[Math.floor(droppedRowIndex / 2)];
        const { top: movedRowTop } = movedRowElement.getBoundingClientRect();
        const { top: rowTop, height: rowHeight } =
          droppedRowElement.getBoundingClientRect();
        const midpointY = rowTop + rowHeight / 2;
        const movedRowData = datarows[dataRow];
        const tolerance = 10;
        if (
          movedRowTop < rowTop ||
          !droppedRowData ||
          (droppedRowData.treeLevel != movedRowData.treeLevel &&
            insertBefore != 0) ||
          (droppedRowData.parentId != movedRowData.parentId &&
            !(
              currentTop >= midpointY - tolerance &&
              currentTop <= midpointY + tolerance
            ) &&
            insertBefore != 0)
        ) {
          reorderGuide.style.display = "none";
          return;
        }

        reorderGuide.style.display = "block";
        if (
          currentTop >= midpointY - tolerance &&
          currentTop <= midpointY + tolerance
        ) {
          reorderGuide.classList.add("parent-child-move");
        } else {
          reorderGuide.classList.remove("parent-child-move");
        }
      }
    }
  };

  useEffect(() => {
    handleDrag();
  }, [top]);

  const onBeforeMoveRows = (e, data) => {
    setInsertBefore(data.insertBefore);
    setDataRow(data.rows[0]);

    if (data.rows[0] < insertBefore) {
      return false;
    }
    for (const dataRow of data.rows) {
      if (dataRow === data.insertBefore - 1) {
        e.stopPropagation();
        return false;
      }
    }
    return true;
  };

  const gridOptions = {
    enableHeaderButton: true,
    showCustomFooter: !props.allowPagination,
    enableHeaderMenu: false,
    enableSorting: true,
    enableColumnReorder: true,
    enableCellNavigation: true,
    headerButton: {
      onCommand: (_e, args) =>
        handleFilterIconClick(_e, args.column.id, args.column.filterType, args),
    },
    gridMenu: {
      onCommand: (_e, args) => {
        console.log(args.command);
      },
    },
    ...(props.hasChild && {
      preRegisterExternalExtensions: (pubSubService) => {
        const rowDetail = new SlickRowDetailView(pubSubService);
        return [{ name: ExtensionName.rowDetailView, instance: rowDetail }];
      },
    }),
    enableRowDetailView: props.hasChild,
    ...(props.hasChild && {
      rowDetailView: {
        useRowClick: false,
        process: (item) => {
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(item);
            }, 0);
          });
        },
        panelRows: 10,
        singleRowExpand: true,
        viewComponent: props.childGridComponent,
        panelRowsCollapseCssClass: "custom-collapse-icon",
        panelRowsExpandCssClass: "custom-expand-icon",
      },
    }),
    externalResources: [excelExportService],
    enableExcelExport: true,
    createPreHeaderPanel: true,
    preHeaderPanelHeight: 28,
    rowHeight: 48,
    multiColumnSort: false,
    multiSelect: false,
    autoFitColumnsOnFirstLoad: false,
    enableAutoSizeColumns: false,
    autosizeColumnsByCellContentOnFirstLoad: true,
    enableAutoResizeColumnsByCellContent: true,
    enableFiltering: props.hasOwnProperty("enableFiltering")
      ? props.enableFiltering
      : true,
    enableDraggableGrouping: true,
    enableRowSelection: !props.isEditMode,
    createTopHeaderPanel: true,
    topHeaderPanelHeight: 35,
    enableCheckboxSelector: true,
    checkboxSelector: {
      toolTip: "",
    },
    rowSelectionOptions: {
      selectActiveRow: false,
    },
    ...(props.treeGrid && {
      enableTreeData: true,
      treeDataOptions: {
        columnId: "createdDate",
        parentPropName: props.parentIdMapping,
        levelPropName: "treeLevel",
        indentMarginLeft: 15,
        initiallyCollapsed: true,

        initialSort: {
          columnId: "createdDate",
          direction: "DESC",
        },

        sortComparer: () => 0,
      },
    }),
    ...(props.dragAndDrop && {
      enableRowMoveManager: true,
      rowMoveManager: {
        columnIndexPosition: 0,
        cancelEditOnDrag: true,
        disableRowSelection: true,
        hideRowMoveShadow: false,
        onBeforeMoveRows,
        onMoveRows,
      },
    }),
    autoResize: {
      container: `#${id.replace(/:/g, "_")}`,
      applyResizeToContainer: true,
      calculateAvailableSizeBy: "container",
    },
    autoHeight: !!props.hasChild,
    ...(props.gridOptions || {}),
  };

  if (props.allowGrouping) {
    gridOptions.draggableGrouping = {
      dropPlaceHolderText: "Drop a column header here to group by the column",
      deleteIconCssClass: "mdi mdi-close text-color-danger",
      sortAscIconCssClass: "mdi mdi-arrow-up",
      sortDescIconCssClass: "mdi mdi-arrow-down",
      onGroupChanged: props.onGroupChanged,
      onExtensionRegistered: props.onExtensionRegistered,
    };
  }

  const reactGridReady = (reactGrid) => {
    hideSelectAllCheckbox();
    if (props.setGridReady) {
      props.setGridReady(true);
    }

    gridRef.current = reactGrid;
    gridRef.current.gridService?._grid.invalidate();

    gridRef.current.refresh = () => {
      if (props.allowPagination) {
        loadData((currentPage - 1) * pageSize, pageSize);
      } else {
        loadData(0, NO_OF_FETCHED_ROWS);
      }
    };
    if (props.onReactGridCreated) {
      props.onReactGridCreated(reactGrid);
    }
  };

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.slickGrid.invalidate();
    }
  }, [dataset]);

  const hideSelectAllCheckbox = () => {
    const checkboxContainer = document.querySelector(
      ".slick-header .icon-checkbox-container",
    );
    if (checkboxContainer) {
      checkboxContainer.style.display = "none";
    }
  };

  useEffect(() => {
    if (gridRef.current?.deleteRow) {
      gridRef.current.deleteRow = false;
      gridRef.current.slickGrid.setSelectedRows([]);
    }
  }, [gridRef.current?.deleteRow]);

  const getChildData = async (id) => {
    const modifiedQuery = props.query.clone();
    const predicate = new Predicate(props.parentIdMapping, "equal", id);

    modifiedQuery.queries = modifiedQuery.queries.filter(
      (q) => q.fn !== "onWhere",
    );
    const filteredQuery = modifiedQuery.where(predicate);
    let data = await props.remoteDataSource.executeQuery(filteredQuery);
    if (props.beforeDataBound) {
      data = props.beforeDataBound(data);
    }
    data = (Array.isArray(data) ? data : data.result).map((data) => {
      if (!data.id) {
        data.id = data._id;
      }
      return data;
    });
    return data;
  };

  const onClick = async (e, args) => {
    if (props.selectCheckBoxOnRowClick) {
      if (gridRef.current.slickGrid.selectedRows.includes(e.detail.e.row)) {
        gridRef.current.slickGrid.setSelectedRows(
          gridRef.current.slickGrid.selectedRows.filter(
            (row) => row != e.detail.e.row,
          ),
        );
      } else {
        gridRef.current.slickGrid.setSelectedRows([
          ...gridRef.current.slickGrid.selectedRows,
          e.detail.e.row,
        ]);
      }
    }
    if (props.onClick) {
      props.onClick(e, args);
    }
    if (props.treeGrid && e.detail.args.cell == props.dropdownColumn) {
      const hasChildMapping = props.hasChildMapping;
      const row = e.detail.args.grid.data.items[e.detail.args.row];
      if (!row) return;
      if (!row || !row[`${hasChildMapping}`]) return;
      if (!row.isExpanded) {
        setLoaderOnPageChange(true);
        const indentLevel = row.indentLevel ? row.indentLevel + 1 : 1;
        const data = dataset;

        const childData = await getChildData(row.id);

        const parentIndex = data.findIndex((r) => r.id === row.id);

        const childRows = childData.map((child) => {
          return {
            ...child,
            indentLevel,
            parentId: row.id,
            [props.hasChildMapping]: child[`${hasChildMapping}`] || false,
            __hasChildren: child[`${hasChildMapping}`],
            treeLevel: indentLevel,
            __collapsed: true,
            isExpanded: false,
          };
        });

        const updatedData = [
          ...data.slice(0, parentIndex + 1),
          ...childRows,
          ...data.slice(parentIndex + 1),
        ];

        updatedData[parentIndex] = {
          ...updatedData[parentIndex],
          isExpanded: true,
        };

        setDataset([...updatedData]);
        setLoaderOnPageChange(false);
      }

      if (gridRef.current) {
        gridRef.current.dataset = [...dataset];
      }
    }
  };

  return (
    <div className="h-full min-w-full cube-slick-grid">
      {!allPrerequisiteLoaded && (
        <div className="bg-white pt-4 w-full">
          <Shimmer />
        </div>
      )}

      {allPrerequisiteLoaded && (
        <div className="flex flex-col h-full min-w-full overflow-auto">
          <ToolBar
            toolbar={props.toolbar}
            toolbarClickHandler={props.toolbarClickHandler}
            showToolbarBorder={props.showToolbarBorder}
          />
          <div
            id={id.replace(/:/g, "_")}
            className="h-full min-w-full overflow-auto relative flex"
          >
            <SlickgridReact
              {...props}
              dataset={dataset || false}
              gridId={`${`${id.replace(/:/g, "_")}_grid18`}`}
              gridOptions={gridOptions}
              onReactGridCreated={($event) => reactGridReady($event.detail)}
              columnDefinitions={updatedColumnDefinitions}
              setTotalItemCount={setTotalItemCount}
              onClick={onClick}
              onAfterSetColumns={() => {
                if (gridRef.current) {
                  hideSelectAllCheckbox();
                  if (props.handleUpdateCurrentView) {
                    props.handleUpdateCurrentView({
                      columns: gridRef.current.slickGrid
                        .getColumns()
                        .map((data) => data.id),
                    });
                  }

                  gridRef.current.dataView.refresh();
                }
              }}
            />
            {loaderOnPageChange && (
              <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50 pointer-events-none">
                <div className="flex items-center justify-center">
                  <CircularProgress />
                </div>
              </div>
            )}
          </div>
          {props.allowPagination && (
            <div className="!h-[6%] w-full flex items-center justify-between p-3 border-b sticky bottom-0 bg-white shadow-md">
              <PagerComponent
                pageSize={pageSize}
                currentPage={currentPage}
                totalItemCount={totalItemCount}
                setCurrentPage={setCurrentPage}
                gridRef={gridRef}
                setPageSize={setPageSize}
                setTotalPages={setTotalPages}
                totalPages={totalPages}
              />
            </div>
          )}

          {filterType && (
            <FilterComponent
              loadData={loadData}
              handleFilterIconClick={handleFilterIconClick}
              currentColumnId={currentColumnId}
              setCurrentColumnId={setCurrentColumnId}
              setFilterPredicates={setFilterPredicates}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              filterType={filterType}
              setFilterType={setFilterType}
              filterOpened={filterOpened}
              setFilterOpened={setFilterOpened}
              allowPagination={props.allowPagination}
              currentPage={currentPage}
              pageSize={pageSize}
              datasetForFetchingOptions={
                appliedFilters.length ? dataset : props.dataset
              }
              setAppliedFilters={setAppliedFilters}
              appliedFilters={appliedFilters}
              columnDefinitions={props.columnDefinitions}
              beforeDataBound={props.beforeDataBound}
              dataset={props.dataset}
              localData
              ref={gridRef}
              setFilteredDataset={setDataset}
              remoteDataSource={props.remoteDataSource}
              query={props.query}
              setTotalItemCount={setTotalItemCount}
            />
          )}
        </div>
      )}
    </div>
  );
});

export default SlickGrid;
