import { useDispatch, useSelector } from "react-redux";
import { modulesNameSynonymMapper } from "../../services/constants";
import { formCategories } from "../PortalSetings/Configuration/FormsAndFields/constants";
import { getPortalCustomModules } from "../../actions/portalSetting.helper";
import { getBackendUrl } from "@Utils/index";
import React, { useEffect, useRef, useState } from "react";
import axiosWrapper from "@Services/axiosWrapper";

function useStickySidebar({ layout, projectId, path, isFocused = true }) {
  const dispatch = useDispatch();
  const customModules = useSelector((state) => state.customModules) || [];
  const portalData = useSelector((state) => state.portalData);

  const [sideBarMidIcons, setSideBarMidIcons] = useState([]);
  const [sideBarUpperFixedIcons, setSideBarUpperFixedIcons] = useState([]);
  const [counts, setCounts] = useState({});

  const isRustomjeePortal =
    portalData?.frontendUrl?.includes("rustomjee") || false;
  const isDpmsPortal = portalData?.frontendUrl?.includes("dpmsportal") || false;

  useEffect(() => {
    dispatch(getPortalCustomModules());
  }, []);

  useEffect(() => {
    if (isFocused && customModules) {
      setCounts({});
      countData();
    }
  }, [isFocused, customModules]);

  async function countData() {
    [
      "INSPECTION",
      "PROGRESS_UPDATES",
      "GENERAL_FORMS",
      "PERMIT_TO_WORK",
      "BILLS",
      "CHANGE_ORDER",
      ...customModules.map((module) => {
        return module.resourceType;
      }),
    ].forEach(async (resourceType) => {
      const formLists = await axiosWrapper.post(
        `${getBackendUrl()}/forms/taskInspectionAndProgressFormsController`,
        {
          value: {
            requiresCounts: true,
            params: {
              projectId,
              resourceType,
            },
            projectId,
            resourceType,
            skip: 0,
            take: 1,
          },
        },
      );
      setCounts((prevState) => ({
        ...prevState,
        [resourceType]: formLists.data.count,
      }));
    });

    const spaceList = await axiosWrapper.post(
      `${getBackendUrl()}/spaces/spacesData`,
      {
        value: {
          requiresCounts: true,
          params: {
            projectId,
          },
          projectId,
          skip: 0,
          take: 1,
        },
      },
    );
    setCounts((prevState) => ({
      ...prevState,
      SPACES: spaceList.data.count,
    }));

    const contractList = await axiosWrapper.post(
      `${getBackendUrl()}/contracts/contractsController`,
      {
        value: {
          requiresCounts: true,
          where: [
            {
              isComplex: false,
              field: "parentId",
              operator: "equal",
              value: null,
              ignoreCase: false,
            },
          ],
          params: {
            projectId,
            IdMapping: "_id",
          },
          projectId,
          IdMapping: "_id",
          skip: 0,
          take: 1,
        },
      },
    );
    setCounts((prevState) => ({
      ...prevState,
      CONTRACT: contractList.data.count,
    }));
  }

  useEffect(() => {
    const midIcons = [
      {
        // TODO vaibhav to implement allow parameter for files from backend as implemented for other icons
        allow: path.Files && layout.FILES?.ENABLE,
        path: path.Files && path.Files(projectId),
        name: "Files",
        svg: "FilesIcon",
        toolTip: "Files",
      },
      {
        allow: path.Issues && layout.ISSUES?.ENABLE,
        path: path.Issues && path.Issues(projectId),
        name: "Issues",
        svg: "IssuesIcon",
        toolTip: "Issues",
      },
      {
        allow: path.Tasks && layout.TASKS?.ENABLE,
        path: path.Tasks && path.Tasks(projectId),
        name: "Tasks",
        svg: "TasksIcon",
        toolTip: "Tasks",
      },
      // {
      //   allow: layout.FILES?.ENABLE,
      //   path: '/changeOrderList',
      //   name: 'Change Order',
      //   svg: 'MdOutlineChangeCircle',
      //   toolTip: 'Change Order',
      // },
      {
        allow: path.Estimates && layout.ESTIMATES?.ENABLE,
        path: path.Estimates && path.Estimates(projectId),
        name: "Estimates",
        svg: "EstimateIcon",
        toolTip: "Estimates",
      },
      {
        allow: path.Schedules && layout.SCHEDULES?.ENABLE,
        path: path.Schedules && path.Schedules(projectId),
        name: "Schedules",
        svg: "ScheduleIcon",
        toolTip: "Schedules",
      },
      {
        allow: path.Contract && layout.CONTRACT?.ENABLE,
        count: counts.CONTRACT,
        historyPush: false,
        path: path.Contract && path.Contract(projectId),
        name: "Contracts",
        svg: "Contracts",
        toolTip: "Contracts",
      },
      {
        allow: path.Reviews && layout.WORKFLOW?.ENABLE,
        path: path.Reviews && path.Reviews(projectId),
        name: "Reviews",
        svg: "Review",
        toolTip: "Reviews",
      },
      {
        allow: path.Forms && layout.INSPECTION?.ENABLE,
        count: counts.INSPECTION,
        path: path.Forms && path.Forms(projectId, formCategories.inspection),
        name: isRustomjeePortal ? "Work Done" : "Inspections",
        svg: "inspectionForm",
        toolTip: `${isRustomjeePortal ? "Work Done" : "Inspection"} Forms`,
      },
      {
        allow: path.Forms && layout.PROGRESS_UPDATES?.ENABLE,
        count: counts.PROGRESS_UPDATES,
        path:
          path.Forms && path.Forms(projectId, formCategories.progressUpdates),
        name: "Progress Updates",
        svg: "ProgressUpdates",
        toolTip: "Progress updates",
      },
      {
        allow: path.Forms && layout.GENERAL_FORMS?.ENABLE,
        count: counts.GENERAL_FORMS,
        path: path.Forms && path.Forms(projectId, formCategories.generalForms),
        name: "General Forms",
        svg: "generalForms",
        toolTip: "General Forms",
      },
      {
        allow: path.Forms && layout.BILLS?.ENABLE,
        count: counts.BILLS,
        path: path.Forms && path.Forms(projectId, formCategories.bills),
        name: "Bills",
        svg: "bills",
        toolTip: "Bills",
      },
      {
        allow: path.Forms && layout.CHANGE_ORDER?.ENABLE,
        count: counts.CHANGE_ORDER,
        path: path.Forms && path.Forms(projectId, formCategories.changeOrder),
        name: "Change Orders",
        svg: "changeOrder",
        toolTip: "Change Order",
      },
      {
        allow: path.Forms && layout.PAYMENTS?.ENABLE,
        count: counts.PAYMENTS,
        path: path.Forms && path.Forms(projectId, formCategories.payments),
        name: "Payments",
        svg: "Payments",
        toolTip: "Payments",
      },
      {
        allow: path.Forms && layout.PERMIT_TO_WORK?.ENABLE,
        count: counts.PERMIT_TO_WORK,
        path: path.Forms && path.Forms(projectId, formCategories.permitToWork),
        name: "Permit To Work",
        svg: "PermitToWork",
        toolTip: "Permit To Work",
      },
      {
        allow: path.RiskManagement && layout.RISK_MANAGEMENT?.ENABLE,
        path: path.RiskManagement && path.RiskManagement(projectId),
        name: "Risk Management",
        svg: "RiskManagementIcon",
        toolTip: "Risk Management",
      },
      // {
      //   allow: layout.TENDER_LIST?.ENABLE,
      //   path: '/tendersList',
      //   name: 'Tenders',
      //   svg: 'TendersIcon',
      //   toolTip: 'Tenders',
      // },
      // {
      //   allow: layout.ASSET_REGISTER?.ENABLE,
      //   path: '/assetRegister',
      //   name: 'Asset Register',
      //   svg: 'AssetRegisterIcon',
      //   toolTip: 'Asset Register',
      // },
      // {
      //   allow: layout.PROCUREMENT?.ENABLE,
      //   path: '/procurement',
      //   name: 'Procurement',
      //   svg: 'ProcurementIcon',
      //   toolTip: 'Procurement',
      // },
      // {
      //   allow: layout.WORKFLOW?.ENABLE,
      //   path: '/workflow',
      //   name: 'Workflows',
      //   svg: 'TiFlowChildren',
      //   toolTip: 'Workflows',
      // },
      // {
      //   allow: layout.CHANGE_ORDER?.ENABLE,
      //   path: '/changeOrder',
      //   name: 'ChangeOrder',
      //   svg: 'RiExchangeBoxLine',
      //   toolTip: 'Change Order',
      // },
      {
        allow: path.GeneralClash && layout.CLASH?.ENABLE,
        path: path.GeneralClash && path.GeneralClash(projectId),
        name: "Clash",
        svg: "ClashIcon",
        toolTip: "Clash",
      },
    ];

    customModules.map((module) => {
      midIcons.push({
        allow: path.Forms,
        path: path.Forms && path.Forms(projectId, module.resourceType),
        count: counts[module.resourceType],
        name: module.name,
        svg: module.icon,
        toolTip: module.name,
      });
    });
    setSideBarMidIcons(midIcons);
    setSideBarUpperFixedIcons([
      {
        allow: path.Dashboard,
        path: path.Dashboard && path.Dashboard(projectId),
        name: "Home",
        svg: "HomeIcon",
        toolTip: isDpmsPortal
          ? `${modulesNameSynonymMapper.project} Dashboard`
          : "Project Dashboard",
      },
      {
        allow: path.WorkspacesList,
        path: path.WorkspacesList && path.WorkspacesList(projectId),
        count: counts.SPACES,
        // TODO: add additional properitey type because in dpmsportal, it is not highlighting
        name: isDpmsPortal ? `${modulesNameSynonymMapper.spaces}` : "Spaces",
        svg: "SpacesIcon",
        toolTip: isDpmsPortal ? `${modulesNameSynonymMapper.spaces}` : "Spaces",
      },
      {
        allow: path.Transmittals && layout.TRANSMITTAL?.ENABLE,
        path: path.Transmittals && path.Transmittals(projectId),
        name: "Transmittals",
        svg: "TransmittalIcon",
        toolTip: "Transmittals",
      },
      {
        allow: path.Mailbox && layout.MAILBOX?.ENABLE,
        path: path.Mailbox && path.Mailbox(projectId),
        name: "Mailbox",
        svg: "Mailbox",
        toolTip: "Mailbox",
      },
    ]);
  }, [layout, projectId, customModules, counts]);

  return { sideBarMidIcons, sideBarUpperFixedIcons };
}

export default useStickySidebar;
