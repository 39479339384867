import React from "react";
import ChangeColor from "../ChangeColor";

function Linear({ color, onClick }) {
  return (
    <svg
      onClick={onClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <linearGradient id="Axis" gradientTransform="rotate(45)">
        <stop offset="38%" stopColor={color} />
        <stop offset="92%" stopColor={ChangeColor(color)} />
      </linearGradient>
      <path
        d="M17 7C14.59 7 12.57 8.72 12.1 11H6.79C6.4 10.12 5.52 9.5 4.5 9.5C3.83696 9.5 3.20107 9.76339 2.73223 10.2322C2.26339 10.7011 2 11.337 2 12C2 12.663 2.26339 13.2989 2.73223 13.7678C3.20107 14.2366 3.83696 14.5 4.5 14.5C5.52 14.5 6.4 13.88 6.79 13H12.1C12.2892 13.9269 12.7375 14.7811 13.3928 15.4634C14.0481 16.1458 14.8835 16.6282 15.8021 16.8546C16.7206 17.0811 17.6845 17.0423 18.5819 16.7428C19.4792 16.4433 20.2732 15.8954 20.8716 15.1627C21.47 14.4299 21.8483 13.5425 21.9624 12.6034C22.0766 11.6642 21.922 10.712 21.5166 9.85726C21.1112 9.0025 20.4716 8.2803 19.6721 7.77454C18.8726 7.26877 17.946 7.00019 17 7ZM17 15C15.35 15 14 13.65 14 12C14 10.35 15.35 9 17 9C18.65 9 20 10.35 20 12C20 13.65 18.65 15 17 15Z"
        fill='url("#Axis")'
      />
    </svg>
  );
}
export default Linear;
