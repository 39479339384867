import { useRef, useState, useCallback, useEffect, forwardRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ColumnsDirective,
  ColumnDirective,
  Page,
  Toolbar,
  Inject,
  Filter,
  VirtualScroll,
  Sort,
  Resize,
  ExcelExport,
  ColumnChooser,
} from "@syncfusion/ej2-react-grids";
import { Predicate } from "@syncfusion/ej2-data";
import { convertDate } from "../../services/luxonHelper";
import GridComponent from "../../cubeComponent/html/grid";
import {
  BUTTON_TYPES,
  CONSTANTS,
} from "../../cubeComponent/constants/globalVariable";
import ModalStateDialogComponent from "../../cubeComponent/html/modal/ModalWithState";
import IconComponent from "../../cubeComponent/icons";
import ButtonComponent from "../../cubeComponent/form/ButtonComponent";
import { taskInspectionAndProgressFormService } from "../../services/dataManager";
import { Query } from "@syncfusion/ej2-data";
import {
  taskInspectionAndProgressFormsController,
  formUserSettingsController,
} from "../../actions/Forms";
import { showToastNotification } from "../../actions/toast";
import {
  TOAST_TYPE_ERROR,
  TOAST_TYPE_SUCCESS,
  TOAST_TYPE_WARNING,
} from "../../services/constants";
import ToolTipComponent from "@CubeComponents/html/tooltip";
import { formCategories } from "@Components/PortalSetings/Configuration/FormsAndFields/constants";
import InspectionCreation from "./InspectionCreation";
import DropdownButtonComponent from "@CubeComponents/form/DropdownButtonComponent";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  formCategoryObject,
  presetSectionsCodeObject,
} from "../PortalSetings/Configuration/FormsAndFields/constants";
import { MdArrowDropDown } from "react-icons/md";
import Views from "../Views/index";
import axiosWrapper from "../../services/axiosWrapper";
import css from "../Mailbox/index.module.css";
import { getUserDetails } from "../../actions/userDetails";
import { getInspectionUrl } from "@Helpers";
import { formStatusFilterDataSource } from "./constants";
import { createElement } from "@syncfusion/ej2-base";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import UserNameTagComponent from "../../cubeComponent/html/UserNameTagComponent";
import { statusTemplate } from "./formsHelpers";
import { disabledIllustration } from "../Contracts/ContractDetails/contractHelpers";
import Badge from "@mui/material/Badge";
import useFromList from "./useFromList";
import Views1 from "../ViewsNewFlow";

const TasksForms = forwardRef((props, ref) => {
  const params = useParams();
  const navigate = useNavigate();
  const viewNameRef = useRef(null);
  const currentLayoutData = useRef(null);
  const popupRef = useRef(null);
  const [defaultLayout, setDefaultLayout] = useState(null);
  const [layoutData, setLayoutData] = useState([]);

  const userDetails = useSelector((state) => state.userDetails);
  const contractDetails = useSelector((state) => state.contractDetails);
  const [showFormFlowCreationModal, setShowFormFlowCreationModal] = useState({
    status: false,
    category: props.formCategory,
  });
  const [showArchivedForms, setShowArchivedForms] = useState(false);

  const [preventUpdate, setPreventUpdate] = useState(true);
  const preventUpdateCurrentView = useRef(preventUpdate);
  const [gridReady, setGridReady] = useState(false);
  const [viewsLoaded, setViewsLoaded] = useState(false);
  const [triggerUpdateCurrentView, setTriggerUpdateCurrentView] =
    useState(false);

  const {
    userSettingsExists,
    resetConfig,
    getUserSettingsForFormConfig,
    showWarning,
    showWarningModal,
    hideWarningModal,
    resetConfigClick,
    moduleTxt,
  } = useFromList({
    projectId: params.projectId,
    formCategory: props.formCategory,
  });

  const dataGrid = ref || useRef();
  const dispatch = useDispatch();

  const isModuleDisabled =
    params.contractId && !contractDetails?.modulesEnabled[props.formCategory];
  let predicate = new Predicate(
    "isArchived.status",
    "equal",
    showArchivedForms,
  );
  if (props.source == "contract" && props.showOnlyApproved) {
    predicate = predicate.and("isApproved", "equal", true);
  }
  if (props.source == "forms" && props.formIds) {
    predicate = predicate.and("_id", "notequal", props.formIds);
  }

  const query = new Query()
    .where(predicate)
    .addParams("scheduleId", props.scheduleId)
    .addParams("projectId", props.projectId)
    .addParams("resourceType", props.formCategory)
    .addParams("contractId", params.contractId)
    .addParams("contractVersionId", contractDetails?.versionId);
  let dropInstance;
  const filterTemplate = {
    ui: {
      create: (args) => {
        const flValInput = createElement("input", { className: "flm-input" });
        args.target.appendChild(flValInput);
        dropInstance = new DropDownList({
          dataSource: formStatusFilterDataSource,
          fields: { text: "name", value: "value" },
          placeholder: "Select a value",
        });
        dropInstance.appendTo(flValInput);
      },
      read: (args) => {
        args.fltrObj.filterByColumn(
          args.column.field,
          args.operator,
          dropInstance.value,
        );
      },
      write: (args) => {
        dropInstance.value = args.filteredValue;
      },
    },
  };

  const progressTemplate = (args) => {
    let totalMandatoryFields = 0;
    let mandatoryFieldsFilled = 0;

    args.selectedForm.template.map((section) => {
      if (section.questionFields) {
        if (
          section.sectionHeading == "Table Section" &&
          section.sectionCode == presetSectionsCodeObject.tableSection
        ) {
        } else {
          section.questionFields.map((field) => {
            const answerValue = field.answer?.value;
            if (field.isMandatory) {
              totalMandatoryFields = totalMandatoryFields + 1;
              if (
                !(
                  ["", undefined, null, [], 0].includes(answerValue) ||
                  (Array.isArray(answerValue) &&
                    answerValue?.join("")?.trim() == "")
                )
              ) {
                mandatoryFieldsFilled = mandatoryFieldsFilled + 1;
              }
            }
          });
        }
      }
    });
    const formCompletionPercent =
      Number(
        ((mandatoryFieldsFilled * 100) / totalMandatoryFields).toFixed(2),
      ) || 0;

    return `${formCompletionPercent}%`;
  };

  const gridColumns = [
    {
      field: "_id",
      allowSorting: false,
      allowFiltering: false,
      width: "50",
      isPrimaryKey: true,
      visible: false,
      showInColumnChooser: false,
    },
    {
      type: "checkbox",
      allowSorting: false,
      allowFiltering: false,
      width: "50",
      visible: true,
    },
    {
      field: "counter",
      headerText: "Form No.",
      clipMode: "Ellipsis",
      textAlign: "Left",
      width: "120",
      allowEditing: false,
      visible: true,
    },
    {
      field: "title",
      headerText: "Title",
      width: "120",
      allowEditing: false,
      textAlign: "Left",
      clipMode: "Ellipsis",
      visible: true,
    },
    {
      field: "description",
      headerText: "Description",
      width: "120",
      allowEditing: false,
      clipMode: "Ellipsis",
      visible: true,
    },
    {
      field: "selectedForm.name",
      headerText: "Template Attached",
      width: "120",
      textAlign: "Left",
      clipMode: "Ellipsis",
      allowEditing: false,
    },
    {
      field: "selectedForm.type.name",
      headerText: "Attached Template Type",
      width: "120",
      textAlign: "Left",
      clipMode: "Ellipsis",
      allowEditing: false,
      visible: true,
    },
    {
      field: "selectedFormFlow.name",
      headerText: "Form Flow",
      width: "120",
      textAlign: "Left",
      clipMode: "Ellipsis",
      allowEditing: false,
      visible: true,
    },
    {
      field: "spaceName",
      headerText: "Space Name",
      width: "120",
      textAlign: "Left",
      clipMode: "Ellipsis",
      allowEditing: false,
      visible: true,
    },
    {
      field: "createdBy.fullName",
      headerText: "Raised by",
      width: "150",
      allowEditing: false,
      visible: true,
      template: (args) => createdByTemplate(args, "createdBy"),
    },
    {
      field: "createdDate",
      headerText: "Raised On",
      width: "120",
      type: "date",
      allowEditing: false,
      clipMode: "Ellipsis",
      visible: true,
      template: (args) => formatCreatedDate(args, "createdDate"),
    },
    {
      field: "dueDate",
      headerText: "Due Date",
      width: "120",
      type: "date",
      allowEditing: false,
      clipMode: "Ellipsis",
      visible: true,
      template: (args) =>
        args.dueDate ? formatCreatedDate(args, "dueDate") : "-",
    },
    {
      field: "status",
      headerText: "Status",
      width: "150",
      allowEditing: false,
      textAlign: "Left",
      clipMode: "Ellipsis",
      allowSorting: false,
      visible: true,
      filter: filterTemplate,
      template: (args) => statusTemplate(args.status),
    },
    {
      field: "status",
      headerText: "Form Progress",
      width: "150",
      allowEditing: false,
      textAlign: "Left",
      clipMode: "Ellipsis",
      allowFiltering: false,
      allowSorting: false,
      template: progressTemplate,
    },
    {
      field: "currentLevelOfForm",
      headerText: "Current Level",
      width: "150",
      allowEditing: false,
      allowFiltering: false,
      allowSorting: false,
      textAlign: "Left",
      clipMode: "Ellipsis",
      visible: true,
      template: levelTemplate,
    },
    {
      field: "lastAction.userName",
      headerText: "Last Action By",
      width: "150",
      allowEditing: false,
      textAlign: "Left",
      clipMode: "Ellipsis",
      template: (args) => createdByTemplate(args, "lastAction"),
      visible: true,
    },
    {
      field: "lastAction.actionName",
      headerText: "Last Action",
      width: "120",
      allowEditing: false,
      textAlign: "Left",
      clipMode: "Ellipsis",
      visible: true,
    },
    {
      field: "lastAction.timeStamp",
      headerText: "Last Action Time",
      width: "150",
      allowEditing: false,
      textAlign: "Left",
      type: "date",
      clipMode: "Ellipsis",
      visible: true,
      template: (args) => formatCreatedDate(args, "lastAction"),
    },
    {
      field: "contractOrgName",
      headerText: "Org Name",
      width: "150",
      allowEditing: false,
      textAlign: "Left",
      clipMode: "Ellipsis",
      allowFiltering: false,
      allowSorting: false,
      visible: true,
    },
    {
      field: "contractName",
      headerText: "Contract Name",
      width: "150",
      allowEditing: false,
      textAlign: "Left",
      clipMode: "Ellipsis",
      allowFiltering: false,
      allowSorting: false,
      visible: true,
    },
    {
      field: "contractType",
      headerText: "Contract Type",
      width: "150",
      allowEditing: false,
      textAlign: "Left",
      clipMode: "Ellipsis",
      allowFiltering: false,
      allowSorting: false,
      visible: true,
    },
  ];

  const handleChange = () => {
    setShowArchivedForms((prev) => !prev);
  };

  function onClickLayout(clickLayout) {
    setPreventUpdate(true);
    preventUpdateCurrentView.current = true;

    currentLayoutData.current = clickLayout;
    if (viewNameRef.current) {
      viewNameRef.current.innerText = currentLayoutData.current.name;
    }
    const filterSettings = currentLayoutData.current.data?.filterSettings || [];
    const sortSettings = currentLayoutData.current.data?.sortSettings || [];
    const hiddenColumns = currentLayoutData.current?.data?.hiddenColumns || [];
    dataGrid.current?.setProperties({
      filterSettings: { columns: filterSettings },
      sortSettings: { columns: sortSettings },
    });
    dataGrid.current.columns = gridColumns.map((data) => {
      if (hiddenColumns.includes(data.headerText)) {
        data.visible = false;
      }
      return data;
    });
    setPreventUpdate(false);
  }

  useEffect(() => {
    currentLayoutData.current = null;
    const defaultView = layoutData.find((data) => data.isDefault);
    if (!currentLayoutData.current) {
      const selectedView = layoutData.find(
        (data) => data._id == userDetails.customViews?.[props.formCategory],
      );
      currentLayoutData.current = selectedView ?? defaultView;
      const filterSettings =
        currentLayoutData.current?.data?.filterSettings || [];
      const sortSettings = currentLayoutData.current?.data?.sortSettings || [];
      const hiddenColumns =
        currentLayoutData.current?.data?.hiddenColumns || [];
      if (
        filterSettings.length ||
        sortSettings.length ||
        hiddenColumns.length
      ) {
        dataGrid.current?.setProperties({
          filterSettings: { columns: filterSettings },
          sortSettings: { columns: sortSettings },
        });
        dataGrid.current.columns = gridColumns.map((data) => {
          if (hiddenColumns.includes(data.headerText)) {
            data.visible = false;
          }
          return data;
        });
      }
      if (viewNameRef.current) {
        viewNameRef.current.innerText = selectedView?.name ?? defaultView?.name;
      }
    }
    setDefaultLayout(defaultView);
  }, [layoutData, props.formCategory]);

  useEffect(() => {
    if ((gridReady, viewsLoaded)) {
      /*
        preventUpdateCurrentView.current = false is used for preventing api fire for initial loading
      */
      preventUpdateCurrentView.current = false;
      /*
        end
      */
      onClickLayout(viewsLoaded);
    }
  }, [gridReady, viewsLoaded]);

  const handleUpdateCurrentView = (data) => {
    if (!preventUpdateCurrentView.current) {
      setTriggerUpdateCurrentView(data);
    }
  };

  useEffect(() => {
    preventUpdateCurrentView.current = preventUpdate;
  }, [preventUpdate]);

  const onViewsLoaded = (data) => {
    setViewsLoaded(data);
  };

  const viewsTemplate = () => (
    <Views1
      onClickLayout={onClickLayout}
      createNewLayoutData={createNewLayoutData}
      headerName="Views"
      name="View"
      resourceType={props.formCategory}
      selectedLayoutKey={`${props.formCategory}_SELECTED_LAYOUT`}
      fetchParams={{
        projectId: props.projectId,
      }}
      triggerUpdateCurrentView={triggerUpdateCurrentView}
      onViewsLoaded={onViewsLoaded}
    />
  );

  const dropDownTemplate = () => (
    <DropdownButtonComponent
      select={handleChange}
      cssClass="e-caret-hide"
      bgColor="transparent"
      items={
        showArchivedForms
          ? [{ text: "Show Active Forms", id: "showArchive" }]
          : [{ text: "Show Archived Forms", id: "showActive" }]
      }
    >
      <IconComponent name="threedots" cssClass="cursor-pointer" />
    </DropdownButtonComponent>
  );

  const archivedChipTemplate = () => (
    <span className="rounded-2xl pl-4 pr-4 pt-1 pb-1 text-sm bg-text_light text-white">
      Archieved Forms
    </span>
  );

  const formConfigTemplate = () => (
    <div className="flex items-center pr-3 gap-2">
      <IconComponent name="rollbackReviewIcon" />
      Reset Form Configuration
      {userSettingsExists && (
        <ToolTipComponent
          showTipPointer
          content="User settings exists, Meaning form will directly be created on clicking of create button skipping all form creation steps. To reset click here "
        >
          <Badge color="primary" variant="dot" />
        </ToolTipComponent>
      )}
    </div>
  );

  const toolbarOptions = [
    {
      tooltipText: "View",
      template: viewsTemplate,
      id: "custom_viewLayout",
    },
    ...(props.formCategory != formCategories.changeOrder &&
    ((props.source == "project" && !showArchivedForms) || params.contractId)
      ? [
          {
            text: "Create",
            prefixIcon: "e-add",
            id: "custom_create",
          },
        ]
      : []),
    ...(!showArchivedForms
      ? [
          {
            text: "Archive",
            prefixIcon: "e-transform-left",
            id: "custom_archive",
            disabled: true,
          },
        ]
      : []),
    ...(showArchivedForms
      ? [
          {
            text: "Un-Archive",
            prefixIcon: "e-transform-right",
            id: "custom_unArchive",
            disabled: true,
          },
        ]
      : []),
    {
      text: "Delete",
      prefixIcon: "e-delete",
      id: "custom_delete",
      disabled: true,
    },
    ...(showArchivedForms
      ? [
          {
            template: archivedChipTemplate,
            align: "Left",
          },
        ]
      : []),
    {
      text: "Reset Form Configuration",
      template: formConfigTemplate,
      prefixIcon: "e-transform-left",
      id: "reset_form_config",
      align: "Right",
    },
    "ColumnChooser",
    {
      template: dropDownTemplate,
      align: "Right",
    },
  ];

  const editSettings = {
    allowEditing: false,
    allowAdding: false,
    mode: "Normal",
  };

  const pageSettings = { pageSizes: true, pageSize: 10 };
  const FilterOption = {
    type: "Menu",
  };

  const handleDeleteForms = async () => {
    const selectedItem = dataGrid.current.getSelectedRecords();
    const resourceIds = selectedItem.map((data) => data._id);
    const res = await taskInspectionAndProgressFormsController({
      action: "delete",
      resourceIds,
      resourceType: props.formCategory,
    });
    if (res.data.status) {
      hideWarningModal();
      dispatch(
        showToastNotification({
          content:
            res.data?.message ??
            res.data?.warningMessage ??
            `Selected ${moduleTxt} have been deleted successfully`,
          type: res.data?.warningMessage
            ? TOAST_TYPE_WARNING
            : TOAST_TYPE_SUCCESS,
        }),
      );
      dataGrid.current.refresh();
    } else {
      hideWarningModal();
      dispatch(
        showToastNotification({
          content: res.data?.errorMessage,
          type: TOAST_TYPE_WARNING,
        }),
      );
    }
  };

  const changeFormState = async (state) => {
    let status;
    let message;
    if (state == "custom_archive") {
      status = true;
      message = "Archived";
    } else {
      status = false;
      message = "Un-Archived";
    }
    const allselectedRows = dataGrid.current.getSelectedRecords();
    const allIds = allselectedRows.map((data) => data._id);
    const res = await taskInspectionAndProgressFormsController({
      action: "archive",
      resourceIds: allIds,
      data: { isArchived: { status } },
    });
    if (res.data.status) {
      dispatch(
        showToastNotification({
          content: `Selected form(s) successfully ${message}`,
          type: TOAST_TYPE_SUCCESS,
        }),
      );
      dataGrid.current.refresh();
    }
  };

  const toolbarClickHandler = async (args) => {
    if (args.item.properties.id === "custom_delete") {
      showWarningModal("delete");
    }
    if (args.item.properties.id === "custom_create") {
      if (userSettingsExists) {
        const res = await formUserSettingsController({
          action: "createForm",
          projectId: params.projectId,
          resourceType: props.formCategory,
        });
        if (res.data.status) {
          const location = getInspectionUrl({
            resourceId: res.data.id,
            projectId: params.projectId,
            resourceType: props.formCategory,
            contractId: res.data.contractId,
          });
          navigate(location);
          dispatch(
            showToastNotification({
              content: "Form created successfully",
              type: TOAST_TYPE_SUCCESS,
            }),
          );
        } else {
          dispatch(
            showToastNotification({
              content: res.data.errorMessage,
              type: TOAST_TYPE_ERROR,
            }),
          );
        }
      } else {
        setShowFormFlowCreationModal({
          status: true,
          category: props.formCategory,
        });
      }
    }
    if (args.item.properties.id === "custom_archive") {
      changeFormState("custom_archive");
    }
    if (args.item.properties.id === "custom_unArchive") {
      changeFormState("custom_unArchive");
    }
    if (args.item.properties.id === "reset_form_config") {
      resetConfigClick();
    }
  };

  const getInspectionFormFLow = () => (
    <InspectionCreation
      projectId={params.projectId}
      showFormFlowCreationModal={showFormFlowCreationModal}
      setShowFormFlowCreationModal={setShowFormFlowCreationModal}
      source={params.contractId ? "contract" : "project"}
      spaceId={params.contractId ? contractDetails.spaceData?._id : null}
    />
  );

  const selectionEvent = (args) => {
    if (!dataGrid || !dataGrid.current || props.showOnlyGrid) return;

    const totalRowsSelected = dataGrid.current.getSelectedRowIndexes().length;
    if (totalRowsSelected == 0) {
      dataGrid.current.toolbarModule.enableItems(["custom_delete"], false);
      dataGrid.current.toolbarModule.enableItems(["custom_archive"], false);
      dataGrid.current.toolbarModule.enableItems(["custom_unArchive"], false);
    }
    if (totalRowsSelected >= 1) {
      dataGrid.current.toolbarModule.enableItems(["custom_delete"], true);
      dataGrid.current.toolbarModule.enableItems(["custom_archive"], true);
      dataGrid.current.toolbarModule.enableItems(["custom_unArchive"], true);
    }
    const allselectedRows = dataGrid.current.getSelectedRecords();
    allselectedRows.map((record) => {
      if (record.createdBy._id != userDetails.id) {
        dataGrid.current.toolbarModule.enableItems(["custom_delete"], false);
      }
    });
  };

  function formatCreatedDate(data, type) {
    let date;
    if (type === "lastAction") {
      if (data.lastAction.timeStamp == null) {
        return "-";
      }
      date = data.lastAction.timeStamp;
    } else {
      date = data[type];
    }
    const dateFormat = convertDate(Number(date), "dateForTooltip");
    return (
      <ToolTipComponent
        showTipPointer
        position="BottomCenter"
        content={dateFormat}
      >
        <span>{convertDate(Number(date), "dateForText")}</span>
      </ToolTipComponent>
    );
  }

  function createdByTemplate(params, type) {
    let _id;
    if (type === "lastAction") {
      if (params.lastAction.userId == "") {
        return "-";
      }
      _id = params.lastAction.userId;
    } else {
      _id = params.createdBy._id;
    }

    return <UserNameTagComponent id={_id} />;
  }

  function levelTemplate(params) {
    return <span> {params.formFlow[params.currentLevelOfForm].heading}</span>;
  }

  const recordClick = (args) => {
    if (
      args.cellIndex != 1 &&
      !args.rowData.isArchived?.status &&
      props.source !== "forms" &&
      !props.showOnlyGrid
    ) {
      const location = getInspectionUrl({
        resourceType: props.formCategory,
        resourceId: args.rowData._id,
        projectId: args.rowData.projectId,
        contractId: args.rowData.contractId,
      });
      navigate(location);
    }
  };

  const created = () => {
    setGridReady(true);
  };

  const getGridComponent = useCallback(
    () => (
      <div className="w-full h-full overflow-auto">
        <GridComponent
          ref={dataGrid}
          enableStickyHeader
          editSettings={editSettings}
          dataSource={taskInspectionAndProgressFormService}
          toolbar={props.showOnlyGrid ? null : toolbarOptions}
          filterSettings={FilterOption}
          allowFiltering
          dataBound={getUserSettingsForFormConfig}
          allowSorting
          allowPaging
          rowHeight={60}
          showColumnChooser
          height="100%"
          pageSettings={pageSettings}
          toolbarClick={toolbarClickHandler}
          rowSelected={selectionEvent}
          rowDeselected={selectionEvent}
          recordClick={recordClick}
          allowResizing
          query={query}
          created={created}
        >
          <ColumnsDirective>
            {gridColumns.map((column) => (
              <ColumnDirective {...column} />
            ))}
          </ColumnsDirective>
          <Inject
            services={[
              Page,
              Toolbar,
              Resize,
              Filter,
              Sort,
              VirtualScroll,
              ExcelExport,
              ColumnChooser,
            ]}
          />
        </GridComponent>
      </div>
    ),
    [showArchivedForms, props.formCategory, userSettingsExists],
  );

  const warningModal = () => {
    const source = showWarning.source;
    let btnText;
    let contentTxt;
    let headerText;
    let callBackFun;
    switch (source) {
      case "delete":
        btnText = "Delete";
        contentTxt = `${
          props.formCategory == formCategories.changeOrder
            ? "All Associated changes in the contract will also be lost"
            : ""
        } Are you sure you want to delete all selected ${moduleTxt} ?`;
        headerText = `Delete ${moduleTxt}`;
        callBackFun = handleDeleteForms;
        break;
      case "resetConfig":
        btnText = "Reset";
        contentTxt = "Are you sure you want to reset the saved configuration.?";
        headerText = "Reset Configuration";
        callBackFun = resetConfig;
        break;
    }
    return (
      <ModalStateDialogComponent
        footerContent={
          <div>
            <ButtonComponent
              onClick={callBackFun}
              buttonType={BUTTON_TYPES.GRADIENT_BUTTON}
            >
              {btnText}
            </ButtonComponent>
          </div>
        }
        width="30%"
        headerContent={
          <div className="flex w-full justify-between items-center">
            {headerText}
            <IconComponent
              onClick={hideWarningModal}
              cssClass="cursor-pointer"
              name="close"
            />
          </div>
        }
        onCancel={hideWarningModal}
        isVisible={showWarning.status}
        modelContent={<div className="mt-6 mb-6">{contentTxt} </div>}
      />
    );
  };

  function onRenameCallback({ renameData, renameDataIndex }) {
    if (renameData._id == currentLayoutData.current?._id) {
      currentLayoutData.current = renameData;
    }
  }
  async function onDeleteCallback({ deleteData, layoutDataIndex, belong }) {
    if (deleteData._id == currentLayoutData.current?._id) {
      currentLayoutData.current = defaultLayout || {};
      if (deleteData._id == userDetails.customViews?.[props.formCategory]) {
        const filterSettings =
          currentLayoutData.current?.data?.filterSettings || [];
        const sortSettings =
          currentLayoutData.current?.data?.sortSettings || [];
        const hiddenColumns =
          currentLayoutData.current?.data?.hiddenColumns || [];
        dataGrid.current?.setProperties({
          filterSettings: { columns: filterSettings },
          sortSettings: { columns: sortSettings },
        });
        dataGrid.current.columns = gridColumns.map((data) => {
          if (hiddenColumns.includes(data.headerText)) {
            data.visible = false;
          }
          return data;
        });
        await axiosWrapper.post(
          `${process.env.REACT_APP_CUBE_BACKEND}/layoutView/saveUserView`,
          {
            resourceType: props.formCategory,
            viewId: defaultLayout._id,
          },
        );
        dispatch(getUserDetails());
      }
    }
    setLayoutData((prev) => prev.filter((data) => data._id != deleteData._id));
  }

  function createNewLayoutData() {
    let filterSettings = dataGrid.current.filterSettings.properties.columns;
    filterSettings = filterSettings?.map(
      (setting) =>
        (setting = {
          field: setting.properties.field,
          operator: setting.properties.operator,
          value: setting.properties.value,
        }),
    );
    const sortProperties =
      dataGrid.current.sortSettings.properties.columns?.[0]?.properties;
    const sortSettings = sortProperties ? [sortProperties] : [];
    const allColumns = dataGrid.current.getColumns();
    const excludedColumns = allColumns.filter(
      (data) => !data.visible && data.field != "_id",
    );
    const excludedColumnsName = excludedColumns.map((data) => data.headerText);
    return {
      filterSettings,
      sortSettings,
      hiddenColumns: excludedColumnsName,
    };
  }

  const newViewData = {
    resourceType: props.formCategory,
  };

  return (
    <>
      {showWarning.status && warningModal()}
      {showFormFlowCreationModal.status && getInspectionFormFLow()}
      {isModuleDisabled ? disabledIllustration() : getGridComponent()}

      {/* {layoutDropdown && (
        <div
          ref={popupRef}
          className="flex flex-col justify-between absolute top-60 left-24 z-10 bg-white overflow-auto p-6 w-115 shadow-customBoxShadow"
          data-testid="layoutDropdown"
          onClick={(e) => e.preventDefault()}
        >
          <Views
            layoutData={layoutData}
            setLayoutData={setLayoutData}
            currentDataId={currentLayoutData.current?._id}
            onClickLayout={onClickLayout}
            onRenameCallback={onRenameCallback}
            onDeleteCallback={onDeleteCallback}
            createNewLayoutData={createNewLayoutData}
            sharedLayout={false}
            headerName="Views"
            name="View"
            newData={newViewData}
          />
        </div>
      )} */}
    </>
  );
});

export default TasksForms;
