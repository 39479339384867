import { BUTTON_TYPES } from "../../../../../cubeComponent/constants/globalVariable";
import ButtonComponent from "../../../../../cubeComponent/form/ButtonComponent/index";
import ModalStateDialogComponent from "../../../../../cubeComponent/html/modal/ModalWithState";
import { useRef } from "react";
import Forms from "./index.jsx";
import IconComponent from "../../../../../cubeComponent/icons/index";
import { showToastNotification } from "../../../../../actions/toast.js";
import { useDispatch } from "react-redux";
import {
  TOAST_TYPE_ERROR,
  TOAST_TYPE_SUCCESS,
} from "../../../../../services/constants.js";
import { projectTemplateConfigHandler } from "../../../../../actions/projectSetting.js";
import { useParams } from "react-router-dom";

function ProjectTemplateConfig({
  formCategory,
  source,
  setShowTemplatesGrid,
  refreshGrid,
  excludeTemplates,
}) {
  const dataGrid = useRef();
  const dispatch = useDispatch();
  const params = useParams();

  const handleAddTemplate = async () => {
    const selectedRecords = dataGrid.current.getSelectedRecords();
    if (selectedRecords.length) {
      const templateIds = selectedRecords.map((obj) => obj._id);
      const res = await projectTemplateConfigHandler({
        action: "add",
        templateIds,
        projectId: params.projectId,
      });

      setShowTemplatesGrid(false);
      if (res.data.status) {
        dispatch(
          showToastNotification({
            content: "Template(s) added successfully",
            type: TOAST_TYPE_SUCCESS,
          }),
        );
        refreshGrid();
      } else {
        dispatch(
          showToastNotification({
            content: res.data.errorMessage,
            type: TOAST_TYPE_SUCCESS,
          }),
        );
      }
    } else {
      dispatch(
        showToastNotification({
          content: "Select template first to add them to this project",
          type: TOAST_TYPE_ERROR,
        }),
      );
    }
  };

  return (
    <ModalStateDialogComponent
      allowModalPadding={false}
      isVisible
      onCancel={() => setShowTemplatesGrid(false)}
      headerContent={
        <div className="p-4 w-full bg-button_light justify-between flex">
          Select Template
          <IconComponent
            cssClass="cursor-pointer"
            onClick={() => setShowTemplatesGrid(false)}
            name="close"
          />
        </div>
      }
      footerContent={
        <div className="p-4">
          <ButtonComponent
            onClick={handleAddTemplate}
            buttonType={BUTTON_TYPES.BACKGROUND_BUTTON}
          >
            Add
          </ButtonComponent>
        </div>
      }
      modelContent={
        <div className="p-4 h-110">
          <Forms
            excludeTemplates={excludeTemplates}
            source={source}
            ref={dataGrid}
            formCategory={formCategory}
            showOnlyGrid
          />
        </div>
      }
      width="80%"
    />
  );
}
export default ProjectTemplateConfig;
