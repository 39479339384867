import axiosWrapper from "@Services/axiosWrapper";
import { getBackendUrl } from "@Utils/index";

export async function taskInspectionAndProgressFormsController(data) {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/forms/taskInspectionAndProgressFormsController`,
    data,
  );
  return response;
}
export async function formUserSettingsController(data) {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/forms/formUserSettingsController`,
    data,
  );
  return response;
}

export async function fetchFormEntries(data) {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/forms/fetchFormEntries`,
    data,
  );
  return response;
}
export async function formFlowController(data) {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/forms/formFlowController`,
    data,
  );
  return response;
}
export const formTypesController = async (data) => {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/forms/formTypesController`,
    data,
  );
  return response;
};
export const formController = async (data) => {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/forms/formController`,
    data,
  );
  return response;
};

export const addSectionToLibrary = async (data) => {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/forms/addSectionToLibrary`,
    data,
  );
  return response;
};
export const getSectionsFromLibrary = async (data) => {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/forms/getSectionsFromLibrary`,
    data,
  );
  return response;
};

export const deleteSectionsFromLibrary = async (data) => {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/forms/deleteSectionsFromLibrary`,
    data,
  );
  return response;
};
