import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { ReactComponent as ScheduleIcon } from "../../assets/icons/ScheduleIcon.svg";
import { ReactComponent as EstimateIcon } from "../../assets/icons/EstimateIcon.svg";
import { ReactComponent as SpacesIcon } from "../../assets/icons/SpacesIcon.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/SettingsIcon.svg";
import { ReactComponent as TasksIcon } from "../../assets/icons/TasksIcon.svg";
import { ReactComponent as TransmittalIcon } from "../../assets/icons/TransmittalIcon.svg";
import { ReactComponent as HomeIcon } from "../../assets/icons/HomeIcon.svg";
import { ReactComponent as IssuesIcon } from "../../assets/icons/IssuesIcon.svg";
import { ReactComponent as ClashIcon } from "../../assets/icons/ClashIcon.svg";
import { ReactComponent as RiskManagementIcon } from "../../assets/icons/RiskManagementIcon.svg";
import { ReactComponent as AssetRegisterIcon } from "../../assets/icons/AssetRegisterIcon.svg";
import { ReactComponent as TendersIcon } from "../../assets/icons/TendersIcon.svg";
import { ReactComponent as ProcurementIcon } from "../../assets/icons/ProcurementIcon.svg";
import { ReactComponent as FilesIcon } from "../../assets/icons/svg/FilesIcon.svg";
import { ReactComponent as ProgressUpdates } from "../../assets/icons/svg/ProgressUpdates.svg";
import { ReactComponent as generalForms } from "../../assets/icons/svg/generalForms.svg";
import { ReactComponent as changeOrder } from "../../assets/icons/svg/changeOrder.svg";
import { ReactComponent as PermitToWork } from "../../assets/icons/svg/PermitToWork.svg";
import { ReactComponent as Payments } from "../../assets/icons/svg/Payments.svg";
import { ReactComponent as bills } from "../../assets/icons/svg/bills.svg";
import { ReactComponent as inspectionForm } from "../../assets/icons/svg/inspectionForm.svg";
import { ReactComponent as Mailbox } from "../../assets/icons/svg/Mailbox.svg";
import { ReactComponent as Review } from "../../assets/icons/svg/Review.svg";
import { ReactComponent as Contracts } from "../../assets/icons/svg/Contracts.svg";
import { ReactComponent as CustomModule1 } from "../../assets/icons/svg/CustomModule1.svg";
import { ReactComponent as CustomModule2 } from "../../assets/icons/svg/CustomModule2.svg";
import { ReactComponent as CustomModule3 } from "../../assets/icons/svg/CustomModule3.svg";
import { ReactComponent as CustomModule4 } from "../../assets/icons/svg/CustomModule4.svg";
import { ReactComponent as CustomModule5 } from "../../assets/icons/svg/CustomModule5.svg";
import { ReactComponent as CustomModule6 } from "../../assets/icons/svg/CustomModule6.svg";
import { ReactComponent as CustomModule7 } from "../../assets/icons/svg/CustomModule7.svg";
import { ReactComponent as CustomModule8 } from "../../assets/icons/svg/CustomModule8.svg";
import { ReactComponent as CustomModule9 } from "../../assets/icons/svg/CustomModule9.svg";
import { ReactComponent as CustomModule10 } from "../../assets/icons/svg/CustomModule10.svg";
import { ReactComponent as CustomModule11 } from "../../assets/icons/svg/CustomModule11.svg";
import { ReactComponent as CustomModule12 } from "../../assets/icons/svg/CustomModule12.svg";

import Tool from "../HoopsViewer/ToolbarViewer/Tool";
import IconComponent from "../../cubeComponent/icons";
import {
  getProjectContractPageUrl,
  getProjectDashboardPageUrl,
  getProjectEstimatesPageUrl,
  getProjectFilesPageUrl,
  getProjectGeneralClashPageUrl,
  getProjectIssuesPageUrl,
  getProjectMailboxPageUrl,
  getProjectReviewsPageUrl,
  getProjectSchedulesPageUrl,
  getProjectTasksPageUrl,
  getProjectTransmittalsPageUrl,
  getProjectSettingsPageUrl,
  getProjectWorkspacesListPageUrl,
  getProjectFormsPageUrl,
} from "../../services/utils/helpers";
import css from "./index.module.css";
import { modulesNameSynonymMapper } from "../../services/constants";
import useStickySidebar from "./useStickySidebar";

function Sidebar({ userData }) {
  const { layout, currentSelectedSidebarCell: activeCell } = useSelector(
    (state) => state,
  );
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");

  const [isExpanded, setIsExpanded] = useState("");
  const [showScrollUp, setShowScrollUp] = useState(false);
  const [showScrollDown, setShowScrollDown] = useState(true);

  const midIconsDivRef = useRef();

  const { sideBarMidIcons, sideBarUpperFixedIcons } = useStickySidebar({
    layout,
    projectId,
    path: {
      Contract: (projectId) => getProjectContractPageUrl(projectId),
      Dashboard: (projectId) => getProjectDashboardPageUrl(projectId),
      Estimates: (projectId) => getProjectEstimatesPageUrl(projectId),
      Files: (projectId) => getProjectFilesPageUrl(projectId),
      GeneralClash: (projectId) => getProjectGeneralClashPageUrl(projectId),
      Issues: (projectId) => getProjectIssuesPageUrl(projectId),
      Mailbox: (projectId) => getProjectMailboxPageUrl(projectId),
      Reviews: (projectId) => getProjectReviewsPageUrl(projectId),
      Schedules: (projectId) => getProjectSchedulesPageUrl(projectId),
      Tasks: (projectId) => getProjectTasksPageUrl(projectId),
      Transmittals: (projectId) => getProjectTransmittalsPageUrl(projectId),
      Settings: (projectId) => getProjectSettingsPageUrl(projectId),
      WorkspacesList: (projectId) => getProjectWorkspacesListPageUrl(projectId),
      RiskManagement: (projectId) => () => {
        return "/riskManagement";
      },
      Forms: (projectId, moduleName) =>
        getProjectFormsPageUrl(projectId, moduleName),
    },
  });

  const icons = {
    ScheduleIcon,
    EstimateIcon,
    SpacesIcon,
    SettingsIcon,
    TasksIcon,
    TransmittalIcon,
    HomeIcon,
    IssuesIcon,
    ClashIcon,
    RiskManagementIcon,
    AssetRegisterIcon,
    TendersIcon,
    ProcurementIcon,
    FilesIcon,
    ProgressUpdates,
    generalForms,
    changeOrder,
    PermitToWork,
    Payments,
    bills,
    inspectionForm,
    Mailbox,
    Review,
    Contracts,
    CustomModule1,
    CustomModule2,
    CustomModule3,
    CustomModule4,
    CustomModule5,
    CustomModule6,
    CustomModule7,
    CustomModule8,
    CustomModule9,
    CustomModule10,
    CustomModule11,
    CustomModule12,
  };

  useEffect(() => {
    const sideBarState = localStorage.getItem("isSideBarExpanded");
    setIsExpanded(sideBarState);
  }, [sideBarMidIcons]);

  const handleScroll = () => {
    const scrollableContent = midIconsDivRef.current;
    if (scrollableContent) {
      setShowScrollUp(scrollableContent.scrollTop > 0);
      setShowScrollDown(
        scrollableContent.scrollHeight - scrollableContent.scrollTop !==
          scrollableContent.clientHeight,
      );
    }
  };

  const handleUpArrowClick = () => {
    const scrollableContent = midIconsDivRef.current;
    if (scrollableContent) {
      scrollableContent.scrollBy({ top: -100, behavior: "smooth" });
    }
  };

  const handleDownArrowClick = () => {
    const scrollableContent = midIconsDivRef.current;
    if (scrollableContent) {
      scrollableContent.scrollBy({ top: 100, behavior: "smooth" });
    }
  };

  useEffect(() => {
    handleScroll();
  });

  const [showSettings, setShowSettings] = useState(true);

  const ActiveColor = "rgb(107, 187, 227)";
  const InactiveColor = "rgb(25, 32, 72)";

  useEffect(() => {
    if (layout.PROJECT_SETTINGS?.ENABLE === false) {
      setShowSettings(false);
    } else if (
      typeof layout.PROJECT_SETTINGS?.ENABLE === "object" &&
      layout.PROJECT_SETTINGS?.ENABLE?.CONDITION?.COMPANY_NAME
    ) {
      setShowSettings(
        userData?.company?.name ==
          layout.PROJECT_SETTINGS?.ENABLE?.CONDITION?.COMPANY_NAME,
      );
    }
  }, [layout, userData]);

  const handleSideBarExpandClick = () => {
    if (isExpanded) {
      localStorage.setItem("isSideBarExpanded", "");
      setIsExpanded(false);
    } else {
      localStorage.setItem("isSideBarExpanded", "yes");
      setIsExpanded(true);
    }
  };

  const handleRowClick = (path) => {
    navigate(path);
  };

  function RowTemplate({ path, svg, toolTip, name, disabled = false }) {
    return (
      <div
        onClick={() => handleRowClick(path)}
        className={`relative px-6 flex flex-row gap-4 items-center cursor-pointer ${
          disabled ? "opacity-50 cursor-not-allowed" : "opacity-100 cursor-auto"
        }`}
      >
        {activeCell === name ? (
          <div className={`${css.activeIndicator} absolute left-0`} />
        ) : null}
        <Tool
          Svg={svg}
          Tooltip={toolTip}
          color={activeCell === name ? ActiveColor : InactiveColor}
        />
        <div
          className={`${css.descriptionClass} ${
            activeCell === name ? css.activeClass : ""
          }`}
          style={isExpanded ? null : { display: "none" }}
        >
          {name}
        </div>
      </div>
    );
  }

  const upperFixedIcon = useCallback(
    () => (
      <div className="gap-8 flex flex-col" data-testid="upperFixedIcons">
        {sideBarUpperFixedIcons.map(
          ({ path, svg, toolTip, name, allow }) =>
            allow && (
              <RowTemplate
                key={name}
                path={path}
                svg={icons[svg]}
                toolTip={toolTip}
                name={name}
              />
            ),
        )}
        <div className={css.line} />
      </div>
    ),
    [sideBarUpperFixedIcons, activeCell, isExpanded],
  );

  const middleIcons = useCallback(
    () => (
      <div
        className="py-4 gap-4 flex flex-col w-full h-full overflow-hidden"
        data-testid="middleIcons"
      >
        <div
          onClick={handleUpArrowClick}
          id="upArrow"
          className={`${css.upArrowClass} ${
            showScrollUp ? "visible" : "invisible"
          }`}
        >
          <IconComponent name="BoldUpArrow" />
        </div>
        <div
          onScroll={handleScroll}
          ref={midIconsDivRef}
          className="gap-8 flex flex-col overflow-hidden"
        >
          {sideBarMidIcons.map(
            ({ path, svg, toolTip, name, allow }) =>
              allow && (
                <RowTemplate
                  key={name}
                  path={path}
                  svg={icons[svg]}
                  toolTip={toolTip}
                  name={name}
                />
              ),
          )}
        </div>
        <div
          onClick={handleDownArrowClick}
          className={`${css.downArrowClass} ${
            showScrollDown ? "visible" : "invisible"
          }`}
        >
          <IconComponent name="BoldDownArrow" />
        </div>
      </div>
    ),
    [sideBarMidIcons, activeCell, isExpanded, showScrollUp, showScrollDown],
  );

  const lowerFixedIcons = () => (
    <div className="flex flex-col gap-4" data-testid="lowerFixedIcons">
      <div className={css.line} />
      <RowTemplate
        key="sidebar_Settings"
        disabled={!showSettings}
        path={getProjectSettingsPageUrl({
          projectId,
          tab: "general_information",
        })}
        svg={SettingsIcon}
        toolTip={
          window.location.host.includes("dpmsportal")
            ? `${modulesNameSynonymMapper.project} Settings`
            : "Project Settings"
        }
        name="Settings"
      />
    </div>
  );

  return (
    <div
      className={`${css.sidebar} ${
        localStorage.getItem("isSideBarExpanded") ? css.expanded : css.collapsed
      }`}
    >
      <div className="py-6 w-full flex flex-col">
        {upperFixedIcon()}
        {middleIcons()}
        {lowerFixedIcons()}
        <div
          onClick={handleSideBarExpandClick}
          className={
            isExpanded
              ? `${css.midHorizontalLineCollapsed}`
              : `${css.midHorizontalLineExpanded}`
          }
        />
      </div>
    </div>
  );
}

export default Sidebar;
