import Forms from "@Components/Forms";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formCategoryObject } from "../../components/PortalSetings/Configuration/FormsAndFields/constants";
import { useParams, useSearchParams } from "react-router-dom";
import { getProjectDashboardPageUrl } from "../../services/utils/helpers";
import { setBreadCrumbData } from "../../actions/breadcrumbsdata";
import {
  setShowStickySidebar,
  setSidebarSelectedCell,
} from "../../actions/stickySidebar";

function FormsList() {
  const projectData = useSelector((state) => state.projectData);
  const customModules = useSelector((state) => state.customModules) || [];
  const params = useParams();
  const dispatch = useDispatch();

  const moduleName = params.moduleName.toUpperCase();

  const init = async () => {
    if (formCategoryObject[moduleName]) {
      document.title = `${
        formCategoryObject[moduleName].tabTitle
      }-${projectData.description}`;
    } else {
      const categoryObj = customModules.find(
        (module) => module.resourceType == moduleName,
      );
      document.title = `${categoryObj?.tabTitle}-${projectData.description}`;
    }
  };

  useEffect(() => {
    const categoryObj = customModules.find(
      (module) => module.resourceType == moduleName,
    );
    const moduleTxt =
      categoryObj?.activeCell ||
      formCategoryObject[moduleName]?.activeCell ||
      "";
    const breadCrumb = [
      {
        text: "Projects",
        link: "/projects",
      },
      {
        text: projectData.description,
        link: getProjectDashboardPageUrl(projectData.id),
        showTooltip: true,
        tooltipData: projectData.description,
      },
      {
        text: moduleTxt,
      },
    ];
    dispatch(setBreadCrumbData(breadCrumb));

    if (moduleName) {
      dispatch(setSidebarSelectedCell(moduleTxt));
      dispatch(setShowStickySidebar(true));
    } else {
      dispatch(setSidebarSelectedCell(""));
      dispatch(setShowStickySidebar(false));
    }
  }, [moduleName, customModules]);

  useEffect(() => {
    init();
  }, [projectData, moduleName, customModules]);

  return <Forms formCategory={moduleName} />;
}
export default FormsList;
