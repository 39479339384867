import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showToastNotification } from "../../actions/toast";
import {
  TOAST_TYPE_ERROR,
  TOAST_TYPE_SUCCESS,
  TOAST_TYPE_WARNING,
} from "../../services/constants";
import { getInspectionUrl } from "@Helpers";
import {
  formCategories,
  formCategoryObject,
} from "../PortalSetings/Configuration/FormsAndFields/constants";
import axiosWrapper from "@Services/axiosWrapper";
import { getBackendUrl } from "@Utils/index";

const useCreateInspection = (props) => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [dueDate, setDueDate] = useState(null);
  const [description, setDescription] = useState("");
  const [selectedFormTypeId, setSelectedFormTypeId] = useState("");
  const [selectedForm, setSelectedForm] = useState({});
  const [rememberUserSettings, setRememberUserSettings] = useState(false);
  const [templateDataSource, setTemplateDataSource] = useState([]);

  const [showFormFlowModal, setShowFormFlowModal] = useState({
    step1: true,
    step2: false,
    step3: false,
    step4: false,
  });
  const [spaceId, setSpaceId] = useState(props?.spaceId || null);
  const [selectedFormFlow, setSelectedFormFlow] = useState({});
  const [attachEntity, setAttachEntity] = useState({ name: "budget" });

  const isRustomjeePortal =
    props.portalData?.frontendUrl?.includes("rustomjee") || false;
  const isMPMRCLPortal =
    ["mpmrcl.app.cube.ms", "cube-pmis.mpmrcl.in"].includes(
      props.portalData?.frontendUrl,
    ) || false;
  const progressUpdateEntityCondition =
    [formCategories.inspection, formCategories.bills].includes(
      props.resourceType,
    ) && !isRustomjeePortal;
  const inspectionEntityCondition = props.resourceType == formCategories.bills;
  const allowedAttachmentConstants = [
    formCategories.progressUpdates,
    formCategories.inspection,
  ];

  const handleBasicDetailsNextClick = () => {
    setShowFormFlowModal({
      step1: true,
      step2: true,
      step3: false,
      step4: false,
    });
  };
  const contractDetails = useSelector((state) => state.contractDetails);

  const customModules = useSelector((state) => state.customModules);

  const handleFormSelectNextClick = () => {
    // const formData = inspectionFormsRef.current.getSelectedRecords()[0];
    // formData.rowIndex = inspectionFormsRef.current.getSelectedRowIndexes()[0];
    // setSelectedForm(formData);
    // if (formData && formData.template && formData.template.length == 0) {
    //   dispatch(
    //     showToastNotification({
    //       content: 'Selected form does not have a valid template',
    //       type: TOAST_TYPE_ERROR,
    //     }),
    //   );
    // }
    // else {
    setShowFormFlowModal({
      step1: true,
      step2: true,
      step3: true,
      step4: false,
    });
    // }
  };

  const handleCancelFormAttachment = () => {
    setTitle("");
    setDescription("");
    setDueDate(null);
    setSpaceId(props.spaceId);
    props.setShowFormFlowCreationModal({ status: false, category: "" });
    setShowFormFlowModal({
      step1: true,
      step2: false,
      step3: false,
      step4: false,
    });
  };

  // const formSelectBtnStateEnable = (state) => {
  //   setEnableFormSelectNextBtn(state);
  // };
  const handleChange = (args) => {
    if (args?.target?.name == "title") {
      setTitle(args.value.trim().substring(0, 50));
    }
    if (args?.target?.name == "description") {
      setDescription(args.value.trim().substring(0, 150));
    }
  };

  function combineArrays(typeArray, templateArray) {
    const typeMap = new Map(
      typeArray.map((type) => [
        type._id,
        {
          _id: type._id,
          isChild: false,
          name: type.name,
          parentId: null,
        },
      ]),
    );

    const combinedArray = [];

    templateArray.forEach((template) => {
      const parentType = typeMap.get(template.type._id);

      typeMap.set(template.type._id, { ...parentType, hasChild: true });

      if (parentType) {
        combinedArray.push({
          ...template,
          isChild: true,
          typeName: parentType.name,
          parentId: parentType._id,
          hasChild: false,
        });
      }
    });

    const valuesArray = Array.from(typeMap.values());

    valuesArray.forEach((type) => {
      combinedArray.push({ ...type, parentId: null });
    });

    return combinedArray.filter((data) => data.parentId || data.hasChild);
  }
  const fetchTemplateDataSource = async () => {
    const templateTypes = await axiosWrapper.post(
      `${getBackendUrl()}/forms/formTypesController`,
      {
        value: {
          params: {
            resourceType: props.resourceType,
          },
          resourceType: props.resourceType,
        },
      },
    );
    const templates = await axiosWrapper.post(
      `${getBackendUrl()}/forms/formController`,
      {
        value: {
          where: [
            {
              isComplex: false,
              field: "projectId",
              operator: "equal",
              value: props.projectId,
              ignoreCase: false,
            },
          ],
          params: {
            resourceType: props.resourceType,
            isArchived: false,
          },
          resourceType: props.resourceType,
          isArchived: false,
        },
      },
    );

    const finalArray = combineArrays(
      templateTypes.data.result,
      templates.data.result,
    );
    setTemplateDataSource(finalArray);
  };
  useEffect(() => {
    fetchTemplateDataSource();
  }, []);

  const handleBackClick = () => {
    if (
      showFormFlowModal.step1 &&
      !showFormFlowModal.step2 &&
      !showFormFlowModal.step3 &&
      !showFormFlowModal.step4
    ) {
      props.createInspectionRef && props.createInspectionRef.current.close();
    }
    if (
      showFormFlowModal.step1 &&
      showFormFlowModal.step2 &&
      !showFormFlowModal.step3 &&
      !showFormFlowModal.step4
    ) {
      setShowFormFlowModal({
        step1: true,
        step2: false,
        step3: false,
        step4: false,
      });
    }
    if (
      showFormFlowModal.step1 &&
      showFormFlowModal.step2 &&
      showFormFlowModal.step3 &&
      !showFormFlowModal.step4
    ) {
      setShowFormFlowModal({
        step1: true,
        step2: true,
        step3: false,
        step4: false,
      });
    }
    if (
      showFormFlowModal.step1 &&
      showFormFlowModal.step2 &&
      showFormFlowModal.step3 &&
      showFormFlowModal.step4
    ) {
      setShowFormFlowModal({
        step1: true,
        step2: true,
        step3: true,
        step4: false,
      });
    }
  };

  const handleFormFlowSelection = (args) => {
    setSelectedFormFlow(args.itemData);
    if (args.itemData?.formFlow?.length == 0) {
      dispatch(
        showToastNotification({
          content: "ThisformFlow's flow is not defined",
          type: TOAST_TYPE_WARNING,
        }),
      );
    }
  };

  const handleFormFlowCreate = () => {
    setShowFormFlowModal({
      step1: true,
      step2: true,
      step3: true,
      step4: true,
    });
  };

  const handleCreateClick = async (isUpdateOpen = false, formFlow = []) => {
    let formFlowLevels;
    let formTemplate;
    if (selectedFormFlow.formFlow) {
      formFlowLevels = selectedFormFlow.formFlow;
      formTemplate = selectedFormFlow.selectedForm.template;
    }
    if (formFlow.length) {
      formFlowLevels = formFlow;
      formTemplate = selectedForm.template;
    }
    if (isUpdateOpen) {
      dispatch(
        showToastNotification({
          content: "Finish or cancel all updates before proceeding",
          type: TOAST_TYPE_ERROR,
        }),
      );
    } else {
      const permissionObj = {};
      formTemplate.map((section) => {
        permissionObj[section._id] = {};
        formFlowLevels.map((level, index) => {
          permissionObj[section._id][index] = {
            Fill: [],
            Modify: [],
            Verify: [],
          };
        });
      });
      formFlowLevels.map((level, index) => {
        level.gridDataSource.map((eachRow) => {
          eachRow.sections.map((section) => {
            permissionObj[section._id] = {
              ...permissionObj[section._id],
              [index]: {
                ...permissionObj[section._id][index],
                [eachRow.type]: [...eachRow.users.map((user) => user.id)],
              },
            };
          });
        });
      });

      const selectedFormTemplate = [...formTemplate];
      const templateWithPermissions = selectedFormTemplate.map((section) => ({
        ...section,
        permissions: permissionObj[section._id],
      }));

      const formData = {
        title,
        description,
        dueDate: dueDate ?? null,
        selectedForm: {
          _id: selectedForm._id,
          name: selectedForm.name,
          description: selectedForm.description,
          template: templateWithPermissions,
          type: selectedForm.type,
        },
        rememberUserSettings,
        resourceType: selectedForm.resourceType,
        spaceId,
        projectId: props.projectId,
        formFlow: [
          ...formFlowLevels.map((level) => ({
            dataSource: level.gridDataSource,
            allowedUsersToApprove: level.allowedUsersToApprove,
            allowedUsersToReject: level.allowedUsersToReject,
            allowedUsersToSubmit: level.allowedUsersToSubmit,
            heading: level.name,
            approveBtnTxt: level.approveBtnTxt,
            submitBtnTxt: level.subBtnTxt,
            rejectBtnTxt: level.rejectBtnTxt,
            actionStatus: false,
            actionDetails: {},
          })),
        ],
      };
      if (selectedFormFlow?._id) {
        formData.selectedFormFlow = {
          name: selectedFormFlow.name,
          _id: selectedFormFlow._id,
        };
      }
      if (props.contractId) {
        formData.basedOnBudget = true;
        formData.basedOnTasks = true;
        formData.basedOnMilestones = true;
        formData.contractId = props.contractId;
        formData.contractVersionId = contractDetails.versionId;
        formData.parentContract = contractDetails.parentId;
        formData.allParentContracts = contractDetails.allParents ?? [];
        formData.allChildContracts = contractDetails.allChilds ?? [];
      }

      if (attachEntity[formCategories.progressUpdates]) {
        formData.attachedProgressUpdates = [
          ...attachEntity[formCategories.progressUpdates],
        ];
        formData.basedOnProgressUpdates = true;
        delete formData.basedOnBudget;
        delete formData.basedOnTasks;
      } else if (attachEntity[formCategories.inspection]) {
        formData.attachedInspections = [
          ...attachEntity[formCategories.inspection],
        ];
        formData.basedOnInspections = true;
        delete formData.basedOnBudget;
        delete formData.basedOnTasks;
      }
      const data = {
        action: "save",
        data: formData,
      };
      const res = await props.taskInspectionAndProgressFormsController(data);
      if (res.data.status) {
        if (!props.mobile) {
          const urlObj = {
            resourceId: res.data.id,
            projectId: props.projectId,
            resourceType: selectedForm.resourceType,
            contractId: props.contractId,
          };
          const categoryObj = customModules.find(
            (module) => module.resourceType == selectedForm.resourceType,
          );
          const formHeader =
            categoryObj?.formHeader ||
            formCategoryObject[selectedForm.resourceType]?.formHeader ||
            "";
          dispatch(
            showToastNotification({
              content: `${formHeader} created successfully`,
              type: TOAST_TYPE_SUCCESS,
            }),
          );
          const location = getInspectionUrl(urlObj);
          if (
            selectedForm.resourceType == formCategories.changeOrder &&
            props.contractId
          ) {
            dispatch(
              props.getContractDetails({ contractId: props.contractId }),
            );
          }
          handleCancelFormAttachment();
          props.navigate(location);
        } else {
          props.createInspectionRef.current.close();
          props.router.push("Forms", {
            projectId: props.projectId,
            resourceId: res.data.id,
          });
        }
      } else {
        dispatch(
          showToastNotification({
            content: res.data.errorMessage,
            type: TOAST_TYPE_ERROR,
          }),
        );
      }
    }
  };

  // const handleSpaceSelect = (event) => {
  //   if (event.isInteracted) {
  //     setSpaceId(event.value);
  //   }
  // };

  // const handleTemplateTypeSelect = (args) => {
  //   setSelectedFormTypeId(args.value);
  //   setSelectedForm({});
  // };

  // const handleTemplateSelect = (args) => {
  //   if (args.itemData) {
  //     setSelectedForm(args.itemData);
  //   }
  // };

  const handleNextClick = () => {
    if (
      showFormFlowModal.step1 &&
      !showFormFlowModal.step2 &&
      !showFormFlowModal.step3
    ) {
      handleBasicDetailsNextClick();
    } else if (
      showFormFlowModal.step1 &&
      showFormFlowModal.step2 &&
      !showFormFlowModal.step3
    ) {
      handleFormSelectNextClick();
    } else if (
      showFormFlowModal.step1 &&
      showFormFlowModal.step2 &&
      showFormFlowModal.step3
    ) {
      handleCreateClick();
    }
  };

  return {
    title,
    setTitle,
    description,
    setDescription,
    setDueDate,
    dueDate,
    selectedFormTypeId,
    setSelectedFormTypeId,
    selectedForm,
    setSelectedForm,
    showFormFlowModal,
    setShowFormFlowModal,
    spaceId,
    setSpaceId,
    selectedFormFlow,
    setSelectedFormFlow,
    handleBasicDetailsNextClick,
    handleFormSelectNextClick,
    handleCancelFormAttachment,
    handleBackClick,
    handleFormFlowCreate,
    handleNextClick,
    attachEntity,
    setAttachEntity,
    handleCreateClick,
    progressUpdateEntityCondition,
    inspectionEntityCondition,
    isRustomjeePortal,
    isMPMRCLPortal,
    setRememberUserSettings,
    rememberUserSettings,
    allowedAttachmentConstants,
    templateDataSource,
  };
};
export default useCreateInspection;
