import { useCallback, useEffect, useRef, useState } from "react";
import css from "./index.module.css";
import {
  AccordionItemDirective,
  AccordionItemsDirective,
  Inject,
} from "@syncfusion/ej2-react-navigations";
import AccordionComponent from "../../cubeComponent/html/accordion";
import IconComponent from "../../cubeComponent/icons";
import useModulesMapper from "@Components/StickySidebarViewer/ModulesMapper";

import Accordion from "../../cubeComponent/html/accordion/materialUiAccordian/index";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function DesignViewerLayoutSettingComponent(props) {
  const [selectedData, setSelectedData] = useState(null);
  const [onPositionAccordion, setOnPositionAccordion] = useState(null);
  const [dragging, setDragging] = useState(null);
  const [selectedAccordion, setSelectedAccordion] = useState([]);
  const [modulesMapper] = useModulesMapper(props);

  function onDragOver1(e) {
    e.preventDefault();
  }
  function onDragOver2(e) {
    e.preventDefault();
  }
  function availableContent(itemArray, accordionName, index) {
    return itemArray.map((data) => (
      <div
        className={
          selectedAccordion.includes(index)
            ? css.insideContentSelected
            : css.insideContent
        }
        draggable
        droppable="true"
        onDragStart={(e) =>
          dragStartInsideAvailable(e, {
            accordionName,
            itemArray: [data],
            index,
          })
        }
      >
        <IconComponent
          name={`SidebarIcon/${modulesMapper[data].name}`}
          height="20px"
          width="20px"
        />
        <div>{modulesMapper[data].name}</div>
      </div>
    ));
  }

  function addedContent(itemArray, accordionName, index) {
    return itemArray.map((data) => (
      <div
        className={css.insideContent}
        draggable
        onDragStart={(e) =>
          dragStartInsideAdded(e, { accordionName, itemArray: [data], index })
        }
      >
        <IconComponent
          name={`SidebarIcon/${modulesMapper[data].name}`}
          height="20px"
          width="20px"
        />
        <div>{modulesMapper[data].name}</div>
      </div>
    ));
  }
  function headerAvailable(accordionName) {
    return <div className={css.componentHeader}>{accordionName}</div>;
  }
  function headerAdded(accordionName) {
    return <div className={css.componentHeader}>{accordionName}</div>;
  }

  // -------------------------------dragStart---------------------------

  function dragStartAvailable(e, itemsData) {
    setSelectedData(itemsData);
    setDragging(1);
  }
  function dragStartAdded(e, itemsData) {
    setSelectedData(itemsData);
    setDragging(2);
  }

  // -------------------------------dragStartInside-----------------------

  function dragStartInsideAvailable(e, itemsData) {
    setSelectedData(itemsData);
    setDragging(1);
    e.stopPropagation();
  }
  function dragStartInsideAdded(e, itemsData) {
    setSelectedData(itemsData);
    setDragging(2);
    e.stopPropagation();
  }

  // ---------------------------------onDrop-----------------------------
  function onDropAvailable(e) {
    if (dragging == 1) {
      if (onPositionAccordion == null) {
        return;
      }
      if (
        selectedData.itemArray.length !=
        props.availableData[selectedData.index][selectedData.accordionName]
          .widgets.length
      ) {
        return;
      }
      const dataAvailable = selfArrangeAccordion(props.availableData);
      props.setAvailableData(dataAvailable);
      return;
    }
    if (selectedData == null) {
      return;
    }
    // ADD
    const selectedDataItemArray = [...selectedData.itemArray];
    const dataAvailable = addToOtherSide(
      props.availableData,
      selectedDataItemArray,
    );
    props.setAvailableData(dataAvailable);
    // REMOVE
    const dataAdded = removeFromCurrentSide(
      props.addedData,
      selectedDataItemArray,
    );
    props.setAddedData(dataAdded);
  }

  function onDropAdded(e) {
    if (dragging == 2) {
      if (onPositionAccordion == null) {
        return;
      }
      if (
        selectedData.itemArray.length !=
        props.addedData[selectedData.index][selectedData.accordionName].widgets
          .length
      ) {
        return;
      }
      const dataAdded = selfArrangeAccordion(props.addedData);
      props.setAddedData(dataAdded);
      return;
    }
    if (selectedData == null) {
      return;
    }
    // ADD
    const selectedDataItemArray = [...selectedData.itemArray];
    const dataAdded = addToOtherSide(props.addedData, selectedDataItemArray);
    props.setAddedData(dataAdded);
    // REMOVE
    const dataAvailable = removeFromCurrentSide(
      props.availableData,
      selectedDataItemArray,
    );
    props.setAvailableData(dataAvailable);
  }

  function selfArrangeAccordion(items) {
    const data = [...items];
    const fromIndex = selectedData.index;
    const toIndex = onPositionAccordion;
    const element = data.splice(fromIndex, 1)[0];
    data.splice(toIndex, 0, element);
    setOnPositionAccordion(null);
    return data;
  }

  function addToOtherSide(item, selectedDataItemArray) {
    const data = [...item];
    let found = false;
    data.map((object) => {
      if (Object.keys(object)[0] == selectedData.accordionName) {
        object[selectedData.accordionName].widgets.push(
          ...selectedDataItemArray,
        );
        found = true;
      }
    });
    if (!found) {
      if (onPositionAccordion != null) {
        data.splice(onPositionAccordion, 0, {
          [selectedData.accordionName]: { widgets: [...selectedDataItemArray] },
        });
        setOnPositionAccordion(null);
      } else {
        data.push({
          [selectedData.accordionName]: { widgets: [...selectedDataItemArray] },
        });
      }
    }
    return data;
  }

  function removeFromCurrentSide(item, selectedDataItemArray) {
    const data = [...item];
    selectedDataItemArray.map((item) => {
      const indexOfItem =
        data[selectedData.index][selectedData.accordionName].widgets.indexOf(
          item,
        );
      data[selectedData.index][selectedData.accordionName].widgets.splice(
        indexOfItem,
        1,
      );
    });
    if (
      data[selectedData.index][selectedData.accordionName].widgets.length == 0
    ) {
      data.splice(selectedData.index, 1);
    }
    return data;
  }

  // ---------------------onDragEnter--------------------------------------

  function onDragEnterAvailable(index) {
    setOnPositionAccordion(index);
  }
  function onDragEnterAdded(index) {
    setOnPositionAccordion(index);
  }

  // -----------------------onDragEnd---------------------------------------
  function onDragEndAvailable() {
    setSelectedData(null);
    setOnPositionAccordion(null);
    setDragging(null);
  }
  function onDragEndAdded() {
    setSelectedData(null);
    setOnPositionAccordion(null);
    setDragging(null);
  }
  // /////////////////////////////////////
  // function onDragLeave() {
  //   console.log('leave');
  // }
  // /////////////////////////////////////
  function onClickAccordion(e) {
    const index = e.currentTarget.attributes["data-index"].value;
    const selectedaccordion = selectedAccordion;
    selectedaccordion.push(index);
    setSelectedAccordion(selectedaccordion);
  }

  return (
    <div className={css.panelBox}>
      <div className={`${css.panelInnerBox}`}>
        <div>Available</div>
        <div
          className={`${css.dndBox} px-4`}
          droppable="true"
          onDragOver={onDragOver1}
          onDrop={(e) => onDropAvailable(e)}
          onDragEnd={onDragEndAvailable}
        >
          {props.availableData.map((object, index) => {
            const accordionName = Object.keys(object)[0];
            return (
              <div
                data-index={index}
                onClick={onClickAccordion}
                draggable
                onDragStart={(e) =>
                  dragStartAvailable(e, {
                    accordionName,
                    itemArray: object[accordionName].widgets,
                    index,
                  })
                }
                onDragEnter={() => onDragEnterAvailable(index)}
              >
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    {headerAvailable(accordionName)}
                  </AccordionSummary>
                  <AccordionDetails>
                    {availableContent(
                      object[accordionName].widgets,
                      accordionName,
                      index,
                    )}
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })}
        </div>
      </div>
      {/* <div className={css.availableOptionContainer}>
        <div className={css.optionStyle}>
          <IconComponent
            name="ArrowsUp"
            height="16px"
            width="16px"
          />
        </div>
        <div className={css.optionStyle}>
          <IconComponent
            name="arrowDown"
            height="16px"
            width="16px"
          />
        </div>
        <div className={css.optionStyle}>
          <IconComponent
            name="BackwardArrow"
            height="16px"
            width="16px"
          />
        </div>
        <div className={css.optionStyle}>
          <IconComponent
            name="ForwardArrow"
            height="16px"
            width="16px"
          />
        </div>
        <div className={css.optionStyle}>
          <IconComponent
            name="BackwardArrow"
            height="16px"
            width="16px"
          />
          <IconComponent
            name="BackwardArrow"
            height="16px"
            width="16px"
          />
        </div>
        <div className={css.optionStyle}>
          <IconComponent
            name="ForwardArrow"
            height="16px"
            width="16px"
          />
          <IconComponent
            name="ForwardArrow"
            height="16px"
            width="16px"
          />
        </div>
      </div> */}
      <div className={`${css.panelInnerBox}`}>
        <div>Added</div>
        <div
          className={`${css.dndBox} px-4`}
          droppable="true"
          onDragOver={onDragOver2}
          onDrop={(e) => onDropAdded(e)}
          onDragEnd={onDragEndAdded}
        >
          {props.addedData.map((object, index) => {
            const accordionName = Object.keys(object)[0];
            return (
              <div
                draggable
                onDragStart={(e) =>
                  dragStartAdded(e, {
                    accordionName,
                    itemArray: object[accordionName].widgets,
                    index,
                  })
                }
                onDragEnter={() => onDragEnterAdded(index)}
              >
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    {headerAdded(accordionName)}
                  </AccordionSummary>
                  <AccordionDetails style={{ height: "100%" }}>
                    {addedContent(
                      object[accordionName].widgets,
                      accordionName,
                      index,
                    )}
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default DesignViewerLayoutSettingComponent;
