import { BUTTON_TYPES } from "../../cubeComponent/constants/globalVariable";
import ButtonComponent from "../../cubeComponent/form/ButtonComponent";
import TextBoxComponent from "../../cubeComponent/form/TextBoxComponent";
import ModalStateDialogComponent from "../../cubeComponent/html/modal/ModalWithState";
import IconComponent from "../../cubeComponent/icons";
import _ from "lodash";
import React from "react";


const AddURLModal = ({
    handleCustomFieldData,
    setURLData,
    showUrlModal={},
    handleURLModalState,
    handleSaveUrlData
}) => {

    const handleChange = (event: any) => {
        setURLData((prev: Object) => ({ ...prev, [event.target.name]: event.value }));
    };

    const modelContent = () => (
        <div className="pt-8 pb-8 flex flex-col gap-5">
            <div className="flex flex-col gap-3">
                <span>URL</span>
                <span>
                    <TextBoxComponent
                        name="URL"
                        type="text"
                        placeholder="Add URL"
                        showClearButton
                        onChange={handleChange}
                        value={showUrlModal.answerValue?.URL}
                    />
                </span>
            </div>
            <div className="flex flex-col gap-3">
                <span>Display Text</span>
                <span>
                    <TextBoxComponent
                        name="displayText"
                        type="text"
                        placeholder="Add Display Text"
                        showClearButton
                        onChange={handleChange}
                        value={showUrlModal.answerValue?.displayText}
                    />
                </span>
            </div>
        </div>
    );

    return (
        <ModalStateDialogComponent
            headerContent={
                <div className="flex justify-between w-full">
                    Add URL
                    <IconComponent
                        name="close"
                        onClick={() => handleURLModalState({ state: false })}
                        cssClass="cursor-pointer"
                    />
                </div>
            }
            modelContent={modelContent()}
            footerContent={
                <div className="flex gap-4 w-full">
                    <ButtonComponent
                        onClick={() => handleSaveUrlData(handleCustomFieldData)}
                        buttonType={BUTTON_TYPES.GRADIENT_BUTTON}
                    >
                        Save
                    </ButtonComponent>
                    <ButtonComponent
                        onClick={() => handleURLModalState({ state: false })}
                        buttonType={BUTTON_TYPES.BACKGROUND_BUTTON}
                    >
                        Cancel
                    </ButtonComponent>
                </div>
            }
            isVisible={showUrlModal.state}
        />
    )
}
export default AddURLModal