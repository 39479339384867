import React, { FC, forwardRef } from "react";
import { createUseStyles } from "react-jss";
import { AccordionComponent as SyncfusionAccordionComponent, AccordionModel } from '@syncfusion/ej2-react-navigations';
import { AccordionComponentProps } from "../../interface";
import arrowUp from "../../../assets/icons/svg/ArrowsUp.svg"

const AccordionComponent: FC<AccordionComponentProps & AccordionModel> = forwardRef((props: AccordionComponentProps & AccordionModel, ref) => {
  /******************* 
@Purpose : Accordion Component
@Parameter : any
@Author : Techture
 *******************/
  const accordionCss = props.normal ? {
    border: props.allowBorder ? "solid 1px var(--primary-blue)" : "none !important",
    borderRadius:  props.allowBorder ? "10px":"",
    padding: props.allowBorderPadding ? "25px":"",
    '& .e-acrdn-item .e-acrdn-header, & .e-acrdn-item .e-acrdn-header': {
      border: "none !important"
    },
    '& .e-acrdn-item .e-acrdn-header:hover, & & .e-acrdn-item .e-acrdn-header:focus': {
      background: "none !important"
    },
    '& .e-acrdn-item': {
      borderBottom: props.allowEachDirectiveBorder ?"solid 1px var(--primary-blue) !important":'none !important',
      borderTop: props.allowEachDirectiveBorder? "solid 1px var(--primary-blue) !important" : 'none !important',
    },
    '& .e-acrdn-item.e-selected.e-select': {
      borderBottom: props.allowEachDirectiveBorder ?"solid 1px var(--primary-blue) !important":'none !important',
      borderTop: props.allowEachDirectiveBorder ?"solid 1px var(--primary-blue) !important":'none !important',
      background: "none !important",
    },
  } : {
    background: `${props.bgColor? props.bgColor : 'var(--surface-neutral) !important'}`,
    border: "none !important",
    paddingLeft: '1.5rem',
    WebkitTextFillColor: "var(--primary-text)",
    WebkitTextStroke: '0 #192048',
    '& .e-acrdn-item.e-selected.e-select': {
      borderBottom: 'none !important',
      borderTop: 'none !important',
      background: "none !important",
    },
    '& .e-acrdn-item .e-acrdn-panel .e-acrdn-content': {
      padding: "0 !important"
    },
    '& .e-acrdn-item .e-acrdn-header, & .e-acrdn-item .e-acrdn-header': {
      border: "none !important",
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row-reverse',
      padding: '0',
      justifyContent: 'flex-end',
    },
    '& .e-acrdn-item .e-acrdn-header:hover, & & .e-acrdn-item .e-acrdn-header:focus': {
      background: "none !important"
    },
    '& .e-acrdn-item.e-item-focus': {
      '& .e-acrdn-header': {
        background: "none !important"
      }
    },
    '& .e-acrdn-item': {
      borderBottom: 'none !important',
      borderTop: 'none !important',
    },
    '& .e-acrdn-item.e-select.e-selected.e-expand-state > .e-acrdn-header, .e-accordion .e-acrdn-item.e-select.e-expand-state > .e-acrdn-header': {
      background: "none !important"
    },
    '& .e-acrdn-item .e-acrdn-header .e-toggle-icon': {
      display: 'flex',
      minWidth: 'auto',
      position: 'unset'
    },
    '& .e-acrdn-header-content': {
      marginLeft: '0.75rem'
    },
    '& .e-tgl-collapse-icon': {
      '&:before': {
        backgroundImage: `url(${arrowUp}) !important`,
        content: "''",
        backgroundSize: '16px',
        width: '16px',
        height: '16px',
        display: "flex",
        backgroundRepeat: 'no-repeat',
        margin: 'auto'
      }
    },
    "& .e-acrdn-item .e-acrdn-header .e-toggle-icon .e-tgl-collapse-icon.e-icons": {
      display: 'flex',
      transform: 'rotate(90deg)',
    },
    "& .e-tgl-collapse-icon.e-expand-icon": {
      transform: 'rotate(180deg) !important',
    },
    "& *": {
      color: 'pink'
    }

  }
  const useStyles = createUseStyles({
    accordion: accordionCss
  });
  const classes = useStyles() as any;

  return (
    <SyncfusionAccordionComponent
      animation={{
        expand: {
          effect: "SlideDown",
          duration: 0,
          easing: "linear",
        },
        collapse: {
          effect: "SlideUp",
          duration: 0,
          easing: "linear",
        },
      }}
      className={classes.accordion}
      ref={ref}
    >
      {props.children}
    </SyncfusionAccordionComponent>


  );
});
AccordionComponent.defaultProps = {};
export default AccordionComponent;
