import IconComponent from "../../../cubeComponent/icons";

export const mileStoneStatus = {
  yetToStart: "Yet to start",
  inProgress: "In progress",
  achieved: "Achieved",
  delayed: "Delayed",
  achievedButDelayed: "Achieved but delayed",
  beforeTimeCompletion: "Before time achieved",
};
export const contractsType = {
  PRIME: "PRIME",
  INTERNAL: "INTERNAL",
  WORK_ORDER: "WORK_ORDER",
  PURCHASE_ORDER: "PURCHASE_ORDER",
};
export const contractsTypeDatasource = [
  { name: "Prime", value: contractsType.PRIME },
  { name: "Internal", value: contractsType.INTERNAL },
  { name: "Work Order", value: contractsType.WORK_ORDER },
  { name: "Purchase Order", value: contractsType.PURCHASE_ORDER },
];
export const manageWBSItemSteps = {
  showLinkedItems: "showLinkedItems",
  linkedItemsFromEstimate: "linkedItemsFromEstimate",
  showCDB: "showCDB",
  linkManualItem: "linkManualItem",
  showCostDatabaseItems: "showCostDatabaseItems",
  showParentWBSItems: "showParentWBSItems",
};
export const linkWBSSources = {
  projectWBS: "projectWBS",
  parentContractWBS: "parentContractWBS",
  contractWBS: "contractWBS",
  contractWBSInForm: "contractWBSInForm",
};

export const contractsTypeHeading = {
  PRIME: "Prime",
  INTERNAL: "Internal",
  WORK_ORDER: "Work Order",
  PURCHASE_ORDER: "Purchase Order",
};

const FOLDER_UPLOAD = {
  id: 1,
  description: "FOLDER_UPLOAD",
  type: "RESOURCE_TYPE_FOLDER",
  status: "ACTIVE",
};
const FOLDER_DOWNLOAD = {
  id: 2,
  description: "FOLDER_DOWNLOAD",
  type: "RESOURCE_TYPE_FOLDER",
  status: "ACTIVE",
};
const FOLDER_COMMENT = {
  id: 3,
  description: "FOLDER_COMMENT",
  type: "RESOURCE_TYPE_FOLDER",
  status: "ACTIVE",
};
const FOLDER_SHARE = {
  id: 4,
  description: "FOLDER_SHARE",
  type: "RESOURCE_TYPE_FOLDER",
  status: "ACTIVE",
};
const FOLDER_STATUS_CHANGE = {
  id: 5,
  description: "FOLDER_STATUS_CHANGE",
  type: "RESOURCE_TYPE_FOLDER",
  status: "ACTIVE",
};
const FOLDER_RENAME = {
  id: 6,
  description: "FOLDER_RENAME",
  type: "RESOURCE_TYPE_FOLDER",
  status: "ACTIVE",
};
const FOLDER_DELETE = {
  id: 7,
  description: "FOLDER_DELETE",
  type: "RESOURCE_TYPE_FOLDER",
  status: "ACTIVE",
};

export const folderRoles = () => {
  return {
    FOLDER_ADMIN: {
      id: 1,
      description: "Can edit and share with others",
      frontendName: "Full Access",
      code: "FULL_ACCESS",
      type: "RESOURCE_TYPE_FOLDER",
      permissions: [
        FOLDER_UPLOAD.description,
        FOLDER_STATUS_CHANGE.description,
        FOLDER_DOWNLOAD.description,
        FOLDER_COMMENT.description,
        FOLDER_SHARE.description,
        FOLDER_RENAME.description,
        FOLDER_DELETE.description,
      ],
      permissionIds: [
        FOLDER_UPLOAD.id,
        FOLDER_STATUS_CHANGE.id,
        FOLDER_DOWNLOAD.id,
        FOLDER_COMMENT.id,
        FOLDER_SHARE.id,
        FOLDER_RENAME.id,
        FOLDER_DELETE.id,
      ],
    },
    FOLDER_EDITOR: {
      id: 2,
      description: "Can upload, download, modify & comment",
      frontendName: "Can Edit",
      code: "CAN_EDIT",
      type: "RESOURCE_TYPE_FOLDER",
      permissions: [
        FOLDER_UPLOAD.description,
        FOLDER_STATUS_CHANGE.description,
        FOLDER_DOWNLOAD.description,
        FOLDER_COMMENT.description,
        FOLDER_RENAME.description,
        FOLDER_DELETE.description,
      ],
      permissionIds: [
        FOLDER_UPLOAD.id,
        FOLDER_STATUS_CHANGE.id,
        FOLDER_DOWNLOAD.id,
        FOLDER_COMMENT.id,
        FOLDER_RENAME.id,
        FOLDER_DELETE.id,
      ],
    },
    FOLDER_STATUS_CHANGER: {
      id: 3,
      description: "Can download, change status or comment",
      frontendName: "Can Change Status",
      code: "CAN_CHANGE_STATUS",
      type: "RESOURCE_TYPE_FOLDER",
      permissions: [
        FOLDER_STATUS_CHANGE.description,
        FOLDER_DOWNLOAD.description,
        FOLDER_COMMENT.description,
      ],
      permissionIds: [
        FOLDER_STATUS_CHANGE.id,
        FOLDER_DOWNLOAD.id,
        FOLDER_COMMENT.id,
      ],
    },
    FOLDER_DOWNLOADER: {
      id: 4,
      description: "Can download & comment",
      frontendName: "Can Download",
      code: "CAN_DOWNLOAD",
      type: "RESOURCE_TYPE_FOLDER",
      permissions: [FOLDER_DOWNLOAD.description, FOLDER_COMMENT.description],
      permissionIds: [FOLDER_DOWNLOAD.id, FOLDER_COMMENT.id],
    },
    FOLDER_COMMENTER: {
      id: 5,
      description: "Can only comment",
      frontendName: "Can Comment",
      code: "CAN_COMMENT",
      type: "RESOURCE_TYPE_FOLDER",
      permissions: [FOLDER_COMMENT.description],
      permissionIds: [FOLDER_COMMENT.id],
    },
    FOLDER_VIEWER: {
      id: 6,
      description: "Cannot edit, download or share with others",
      frontendName: "Can View",
      code: "CAN_VIEW",
      type: "RESOURCE_TYPE_FOLDER",
      permissions: [],
      permissionIds: [],
    },
    FOLDER_NO_ACCESS: {
      id: 7,
      description: "",
      frontendName: "No access",
      code: "NO_ACCESS",
      type: "RESOURCE_TYPE_FOLDER",
      permissions: [],
      permissionIds: [],
    },
  };
};

export const getPermissionConstants = () => {
  const permissions = folderRoles();
  const obj = {};

  Object.values(permissions).map((role) => {
    obj[role.code] = role.id;
  });
  return obj;
};

export const getNoAccessPage = (module) => {
  return (
    <div className="w-full h-full flex gap-4 flex-col items-center justify-center overflow-auto">
      <IconComponent name="NoAccess" cssClass="h-32" />
      <span className="text-xl font-semibold">
        {`You Don’t Have Access to This ${module}`}
      </span>
    </div>
  );
};
