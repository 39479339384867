import React from "react";
import ChangeColor from "../../HoopsViewer/ToolbarViewer/ChangeColor";

function Layers({ onClick, color }) {
  return (
    <svg
      onClick={onClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <linearGradient id="layers" gradientTransform="rotate(45)">
        <stop offset="38%" stopColor={color} />
        <stop offset="92%" stopColor={ChangeColor(color)} />
      </linearGradient>
      <path
        d="M7.3457 7C7.24924 7.00001 7.15394 7.0223 7.06641 7.06534C6.97887 7.10838 6.9012 7.17112 6.83878 7.24922L2.15818 13.1045C2.07598 13.2073 2.0232 13.3329 2.00608 13.4665C1.98895 13.6001 2.0082 13.7362 2.06156 13.8586C2.11491 13.9809 2.20013 14.0845 2.30715 14.1571C2.41417 14.2297 2.53851 14.2682 2.66548 14.2681L20.3353 14.2552C20.4622 14.2551 20.5864 14.2165 20.6933 14.1438C20.8002 14.0712 20.8853 13.9676 20.9386 13.8453C20.9919 13.7229 21.0111 13.5869 20.9939 13.4534C20.9767 13.3198 20.924 13.1943 20.8418 13.0916L16.172 7.24922C16.1096 7.17112 16.032 7.10838 15.9444 7.06534C15.8569 7.0223 15.7616 7.00001 15.6651 7H7.3457ZM7.6533 8.41261H15.357L18.8985 12.8436L4.10281 12.8542L7.6533 8.41261ZM19.0828 15.265L17.339 15.2664L18.8991 17.2178L4.103 17.2285L5.66479 15.2747L3.91909 15.2763L2.15818 17.4787C2.07598 17.5815 2.0232 17.7071 2.00608 17.8408C1.98895 17.9744 2.0082 18.1104 2.06156 18.2328C2.11491 18.3552 2.20013 18.4588 2.30715 18.5313C2.41417 18.6039 2.53851 18.6424 2.66548 18.6423L20.3353 18.6292C20.4622 18.6291 20.5863 18.5905 20.6932 18.5179C20.8001 18.4452 20.8852 18.3417 20.9384 18.2193C20.9917 18.097 21.0109 17.9611 20.9938 17.8276C20.9766 17.6941 20.9239 17.5685 20.8418 17.4658L19.0828 15.265ZM19.0828 19.6225L17.339 19.6241L18.8991 21.5755L4.103 21.5862L5.66479 19.6323L3.91909 19.634L2.15818 21.8364C2.07598 21.9392 2.0232 22.0648 2.00608 22.1984C1.98895 22.3321 2.0082 22.4681 2.06156 22.5905C2.11491 22.7129 2.20013 22.8165 2.30715 22.889C2.41417 22.9616 2.53851 23.0001 2.66548 23L20.3353 22.9869C20.4622 22.9868 20.5864 22.9482 20.6933 22.8756C20.8002 22.8029 20.8853 22.6993 20.9386 22.577C20.9919 22.4547 21.0111 22.3187 20.9939 22.1851C20.9767 22.0516 20.924 21.926 20.8418 21.8233L19.0828 19.6225Z"
        fill='url("#layers")'
      />
    </svg>
  );
}

export default Layers;
