import { useCallback, useEffect, useRef, useState } from "react";
import css from "./index.module.css";
import IconComponent from "../../cubeComponent/icons";
import TextBoxComponent from "../../cubeComponent/form/TextBoxComponent";
import {
  AccordionComponent,
  AccordionItemDirective,
  AccordionItemsDirective,
  Inject,
} from "@syncfusion/ej2-react-navigations";
import ToggleSwitchComponent from "../../cubeComponent/form/ToggleSwitchComponent";
import ButtonComponent from "../../cubeComponent/form/ButtonComponent";
import DesignViewerLayoutSettingComponent from "./SettingComponent";
import axiosWrapper from "../../services/axiosWrapper";
import SliderComponent from "../../cubeComponent/form/SliderComponent";
import ToolTipComponent from "../../cubeComponent/html/tooltip";
import { useDispatch, useSelector } from "react-redux";
import { setViewerState } from "../../actions/HoopsViewer";

import Accordion from "../../cubeComponent/html/accordion/materialUiAccordian/index";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Slider from "@mui/material/Slider";

function DesignViewerLayoutSetting({
  setLayoutSettingMenu,
  availableData,
  setAvailableData,
  addedData,
  setAddedData,
  HoopsViewer,
  updateData,
  onSaveCallback,
  setUpdateData,
}) {
  const [zoomLevel, setZoomLevel] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (updateData) {
      setZoomLevel(updateData.data.zoomLevel);
    }
  }, [updateData]);

  function panelHeader() {
    return (
      <div className={css.panelsHeader}>
        <IconComponent name="layoutsIcon" height="24px" width="24px" />
        <div>Panels</div>
      </div>
    );
  }
  function zoomHeader() {
    return (
      <div className={css.panelsHeader}>
        <IconComponent name="SharingIcon" height="24px" width="24px" />
        <div>Zoom Level</div>
      </div>
    );
  }
  function sliderChanged(args) {
    setZoomLevel(args.value);
  }
  function shareHeader() {
    return (
      <div className={css.panelsHeader}>
        <IconComponent name="SharingIcon" height="24px" width="24px" />
        <div>Share</div>
      </div>
    );
  }
  function zoomContent() {
    return (
      <div className={css.zoomLevelContainer}>
        <div className={css.zoomIconAndSlider}>
          {/* <IconComponent
             name="SharingIcon"
             height="24px"
             width="24px"
           /> */}
          -
          <div className={css.zoomTextbox}>
            <SliderComponent
              max="100"
              min="1"
              change={sliderChanged}
              tooltip={{
                placement: "Before",
                isVisible: true,
                showOn: "Always",
              }}
              value={zoomLevel}
            />
          </div>
          {/* <IconComponent
             name="SharingIcon"
             height="24px"
             width="24px"
           /> */}
          +
        </div>
        <div className={css.manualZoomContainer}>
          <div>Enter Zoom Level</div>
          <div className={css.zoomTextboxAndPercentageSign}>
            <TextBoxComponent
              type="text"
              width={64}
              placeholder={zoomLevel}
              onChange={(args) => {
                setZoomLevel(args.value);
              }}
            />
            <div>%</div>
          </div>
        </div>
      </div>
    );
  }

  const panelsContent = () => (
    <DesignViewerLayoutSettingComponent
      availableData={availableData}
      setAvailableData={setAvailableData}
      addedData={addedData}
      setAddedData={setAddedData}
    />
  );

  const shareContent = () => (
    <div className={css.shareContainer}>
      <div>Share layout with other portal users</div>
      <ToggleSwitchComponent />
    </div>
  );
  const settingAccordion = () => (
    <div className="flex flex-col h-full overflow-auto">
      <div className={`${css.flexCointainer} flex-none`}>
        <div className={css.layoutName}>Layout Name</div>
        <div className={css.textBoxBorder}>
          <TextBoxComponent
            type="text"
            value={updateData.name}
            onChange={(args) => {
              setUpdateData({ ...updateData, name: args.value });
            }}
          />
        </div>
      </div>
      <div className="h-full overflow-hidden">
        <Accordion
          defaultExpanded
          className="h-full"
          sx={{
            "& .MuiCollapse-root": {
              display: "flex",
              overflow: "auto",
              height: "90%",
            },
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {panelHeader()}
          </AccordionSummary>
          <AccordionDetails>{panelsContent()}</AccordionDetails>
        </Accordion>
      </div>
      {/* <div className="flex-auto">
         <Accordion defaultExpanded>
           <AccordionSummary expandIcon={<ExpandMoreIcon />}>
             {zoomHeader()}
           </AccordionSummary>
           <AccordionDetails>
             {zoomContent()}
           </AccordionDetails>
         </Accordion>
       </div> */}
      <div className={`${css.saveContainer} flex-none`}>
        <ButtonComponent buttonType="borderButton" onClick={onCancel}>
          Cancel
        </ButtonComponent>
        <ButtonComponent buttonType="gradientButton" onClick={onSave}>
          Save
        </ButtonComponent>
      </div>
    </div>
  );
  async function onSave() {
    const addData = [];
    addedData.map((data) => {
      addData.push(...Object.values(data)[0].widgets);
    });
    updateData.data.sidebarLayout.addedData = addData;
    updateData.data.zoomLevel = zoomLevel;
    // stringify and parse to break refrence
    updateData.data.rcDock = JSON.parse(
      JSON.stringify(HoopsViewer.rcDockref.saveLayout()),
    );
    const response = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/layoutView/updateView`,
      {
        updateData: {
          name: updateData.name,
          data: updateData.data,
        },
        id: updateData._id,
      },
    );
    if (response.data.status) {
      onSaveCallback && onSaveCallback({ updateData });
    }
    setLayoutSettingMenu(false);
  }

  function onCancel() {
    setLayoutSettingMenu(false);
  }

  return (
    <div className={css.overlay}>
      <div className={css.mainContainer}>
        <div className={css.header}>
          <div>Layout Settings</div>
          <IconComponent
            name="close"
            height="24px"
            width="24px"
            onClick={onCancel}
          />
        </div>
        {settingAccordion()}
      </div>
    </div>
  );
}

export default DesignViewerLayoutSetting;
