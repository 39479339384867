import { SkeletonComponent } from "@syncfusion/ej2-react-notifications";

function Shimmer() {
  return (
    <div className="flex flex-col h-full gap-20 w-full">
      <SkeletonComponent shape="Rectangle" height="32px" />
      <SkeletonComponent shape="Rectangle" height="150px" />
      <SkeletonComponent shape="Rectangle" height="150px" />
    </div>
  );
}
export default Shimmer;
