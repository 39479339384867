import { useEffect, useRef, useState } from "react";
import IconComponent from "../../icons";
import { useResizeDetector } from "react-resize-detector";
import Button from "@mui/material/Button";
import ButtonComponent from "../../form/ButtonComponent";
import { BUTTON_TYPES } from "../../constants/globalVariable";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function ToolBar({
  toolbar = [],
  toolbarClickHandler,
  showToolbarBorder = false,
}) {
  const [showOverFlowBtns, setShowOverFlowBtns] = useState(false);
  const { width, ref: toolRef } = useResizeDetector();
  useEffect(() => {
    if (toolRef && toolRef.current) {
      const ele = toolRef.current;
      if (ele.scrollWidth - ele.scrollLeft !== ele.clientWidth) {
        setShowOverFlowBtns(true);
      } else {
        setShowOverFlowBtns(false);
      }
    }
  }, [width]);
  const handleArrowClick = (direction) => {
    if (toolRef.current) {
      const scrollAmount = direction === "forward" ? 200 : -200;
      toolRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };
  const handleClick = (id) => {
    if (toolbarClickHandler) {
      toolbarClickHandler(id);
    }
  };

  const renderToolbarItem = (item, index, rightItems) => {
    return (
      <div
        className={`flex gap-2 items-center ${
          item.disabled
            ? "pointer-events-none opacity-40 cursor-not-allowed grayscale select-none"
            : ""
        }`}
        key={index}
      >
        {(rightItems ? item.rightAllign : !item.rightAllign) && (
          <>
            {!item.template && (
              <ButtonComponent
                key={item.id}
                // disabled={item.disabled || false}
                className=""
                onClick={() => {
                  handleClick(item.id);
                }}
                buttonType={BUTTON_TYPES.BACKGROUND_BUTTON}
              >
                <div className="flex items-center">
                  <div className="mr-1">
                    {item.prefixIcon && (
                      <IconComponent name={item.prefixIcon} />
                    )}
                    {item.prefixIconTemplate && item.prefixIconTemplate}
                  </div>
                  {item.name}
                  <div className="ml-1">
                    {item.suffixIcon && (
                      <IconComponent name={item.suffixIcon} />
                    )}
                    {item.suffixIconTemplate && item.suffixIconTemplate}
                  </div>
                </div>
              </ButtonComponent>
            )}
            {item.template && item.template()}
          </>
        )}
      </div>
    );
  };
  return (
    <>
      {toolbar?.length > 0 && (
        <div
          className={`overflow-hidden flex gap-5 items-center bg-white ${showToolbarBorder && "border-b border-[var(--button-light)]"}`}
        >
          {showOverFlowBtns && (
            <div className="flex items-center cursor-pointer h-full">
              <Button
                sx={{
                  height: "100%",
                  minWidth: "36px",
                  borderRight: "1px solid #D1D5DB",
                  borderRadius: "8px 0 0 8px",
                  color: "#6B7280",
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: "#E5E7EB",
                  },
                }}
                onClick={() => handleArrowClick("backward")}
                size="small"
              >
                <ArrowBackIosNewIcon sx={{ fontSize: 18 }} />
              </Button>
            </div>
          )}
          <div
            ref={toolRef}
            className="flex gap-1 w-full overflow-hidden max-w-full items-center h-10 justify-between"
          >
            <div className="flex gap-5">
              {toolbar?.map((item, index) => {
                if (item.tooltip) {
                  return (
                    <Tooltip arrow title={`${item.tooltip}`}>
                      {renderToolbarItem(item, index, false)}
                    </Tooltip>
                  );
                }

                return renderToolbarItem(item, index, false);
              })}
            </div>
            <div className="flex">
              {toolbar?.map((item, index) => {
                if (item.tooltip) {
                  return (
                    <Tooltip arrow title={`${item.tooltip}`}>
                      {renderToolbarItem(item, index, true)}
                    </Tooltip>
                  );
                }

                return renderToolbarItem(item, index, true);
              })}
            </div>
          </div>
          {showOverFlowBtns && (
            <div className="flex items-center cursor-pointer h-full">
              <Button
                sx={{
                  height: "100%",
                  minWidth: "36px",
                  borderLeft: "1px solid #D1D5DB",
                  borderRadius: "0 8px 8px 0",
                  color: "#6B7280",
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: "#E5E7EB",
                  },
                }}
                onClick={() => handleArrowClick("forward")}
                size="small"
              >
                <ArrowForwardIosIcon sx={{ fontSize: 18 }} />
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
}
export default ToolBar;
