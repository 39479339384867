import React, { useState, useEffect } from "react";
import { formUserSettingsController } from "../../actions/Forms";
import { useDispatch, useSelector } from "react-redux";
import {
  TOAST_TYPE_ERROR,
  TOAST_TYPE_SUCCESS,
  TOAST_TYPE_WARNING,
} from "../../services/constants";
import { showToastNotification } from "../../actions/toast";
import { formCategoryObject } from "../PortalSetings/Configuration/FormsAndFields/constants";
import { getPortalCustomModules } from "../../actions/portalSetting.helper";

function useFromList({ projectId, formCategory, isFocused = true, mobile }) {
  const [userSettingsExists, setUserSettingExists] = useState(false);
  const [showWarning, setShowWarning] = useState({ status: false });
  const [moduleTxt, setModuleTxt] = useState("");

  const dispatch = useDispatch();

  const customModules = useSelector((state) => state.customModules) || [];

  useEffect(() => {
    if (customModules.length === 0) {
      dispatch(getPortalCustomModules());
    }
  }, []);

  const getUserSettingsForFormConfig = async () => {
    const checkUserSettingExists = await formUserSettingsController({
      action: "checkFormUserSettings",
      projectId,
      resourceType: formCategory,
    });
    setUserSettingExists(!!checkUserSettingExists.data?.isUserSettingExists);
  };

  useEffect(() => {
    if (isFocused && mobile) {
      getUserSettingsForFormConfig();
    }
  }, [formCategory, isFocused]);

  useEffect(() => {
    if (customModules.length) {
      const categoryObj = customModules.find(
        (module) => module.resourceType == formCategory,
      );
      const moduleText =
        categoryObj?.activeCell ||
        formCategoryObject[formCategory]?.activeCell ||
        "";

      setModuleTxt(moduleText);
    } else {
      setModuleTxt(formCategoryObject[formCategory]?.activeCell);
    }
  }, [customModules, formCategory]);

  const showWarningModal = (source) => {
    setShowWarning({ status: true, source });
  };
  const hideWarningModal = () => {
    setShowWarning({ status: false });
  };

  async function resetConfig() {
    if (userSettingsExists) {
      const res = await formUserSettingsController({
        action: "deleteFormUserSettings",
        projectId,
        resourceType: formCategory,
      });
      if (res.data.status) {
        dispatch(
          showToastNotification({
            content: "Config reseted successfully",
            type: TOAST_TYPE_SUCCESS,
          }),
        );
        setUserSettingExists(false);
        hideWarningModal();
      } else {
        hideWarningModal();
        dispatch(
          showToastNotification({
            content: res.data.errorMessage,
            type: TOAST_TYPE_ERROR,
          }),
        );
      }
    } else {
      dispatch(
        showToastNotification({
          content: "User settings do not exists",
          type: TOAST_TYPE_WARNING,
        }),
      );
    }
  }

  function resetConfigClick() {
    if (userSettingsExists) {
      showWarningModal("resetConfig");
    } else {
      dispatch(
        showToastNotification({
          content: "User settings does not exists",
          type: TOAST_TYPE_WARNING,
        }),
      );
    }
  }

  return {
    userSettingsExists,
    resetConfig,
    getUserSettingsForFormConfig,
    showWarning,
    setShowWarning,
    showWarningModal,
    hideWarningModal,
    resetConfigClick,
    moduleTxt,
  };
}

export default useFromList;
