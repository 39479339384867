import React, { FC, forwardRef, useRef } from "react";
import { SplitButtonComponentProps } from "../../interface";
import {
  SplitButtonComponent as SyncfusionSplitButtonComponent,
  ItemModel,
  MenuEventArgs,
  SplitButtonModel,
} from "@syncfusion/ej2-react-splitbuttons";

import {
  COLORS,
  CONSTANTS,
  defaultSplitButtonProps,
} from "../../constants/globalVariable";
import { createUseStyles } from "react-jss";
import {
  get_splitGradientButton,
  get_splitBackgroundButton,
  getSplitButtonPositionClassStyle,
  getSplitButtonCustomClassStyle,
} from "../../constants/globalFunctions";

const SplitButtonComponent: FC<SplitButtonComponentProps & SplitButtonModel> = forwardRef((props: SplitButtonComponentProps & SplitButtonModel,ref) => {
  const useStyles = createUseStyles({
    splitGradientButton: get_splitGradientButton(),
    splitBackgroundButton: get_splitBackgroundButton(),
    positionClass: getSplitButtonPositionClassStyle(
      props.gradientColor1 as string,
      props.gradientColor2 as string,
      props.bgColor as string
    ),
    customClass: getSplitButtonCustomClassStyle(
      props.color as string,
      props.dropdownArrowBgColor as string,
      props.barColor as string
    ),
  });


  const btnRef = ref ||  useRef()
  const SplitButtonComponentStyle = {
    ...(!props.isDisabled && {
      borderRight: CONSTANTS.NONE,
      color: `${props.color ? props.color : COLORS.BLACK}`,
      background: "var(--transparent)",
      ...(props.gradientTextColor1 &&
        props.gradientTextColor2 && {
        background: `-webkit-linear-gradient(${props.gradientTextColor1}, ${props.gradientTextColor2})`,
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "var(--transparent)",
      }),
    }),
    ...(props.isDisabled && {
      borderColor: "var(--border-gray)",
      color: "var(--border-gray)",
    }),
  };

  const classes = useStyles() as any;
  const addDisabled: any = (args: MenuEventArgs) => {
    if (props.isDisabled) args.element.classList.add("e-disabled");
  };
  /******************* 
@Purpose : Split Button  Component
@Parameter : SplitComponentProps
@Author : Techture
 *******************/

  return (
    <SyncfusionSplitButtonComponent
      {...props}
      style={!props.buttonType ? SplitButtonComponentStyle : {}}
      beforeItemRender={addDisabled}
      ref={btnRef}
      cssClass={
        props.buttonType
          ? `${classes[props.buttonType]} ${props.isDisabled && "disabled"}`
          : `${classes.customClass} ${classes.positionClass} ${props.isDisabled && "disabled"}`
      }
    ></SyncfusionSplitButtonComponent>
  );
});
SplitButtonComponent.defaultProps = defaultSplitButtonProps;
export default SplitButtonComponent;
