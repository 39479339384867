/* eslint no-loop-func: 0 */
import { useCallback, useEffect, useRef, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
import { BUTTON_TYPES } from "../../../cubeComponent/constants/globalVariable";
import ButtonComponent from "../../../cubeComponent/form/ButtonComponent";
import {
  closeFileUpload,
  documentTypeChange,
  documentTypeRemove,
  emptyFileUploadData,
  locationChange,
  minimiseFileUpload,
  setFileUploadData,
  setIsFileVersionUploading,
  updateFileUploadData,
  removeAllFileUploadData,
  mergeFileVersions,
  setFileUploadProperties,
  refreshFileManager,
  processFileList,
} from "../../../actions/fileUpload";
import axiosWrapper from "../../../services/axiosWrapper";
import minimiseIcon from "../../../assets/icons/svg/Minimise.svg";
import ModalStateDialogComponent from "../../../cubeComponent/html/modal/ModalWithState";
import FilesGrid from "./FilesSlickGrid";
import IconComponent from "../../../cubeComponent/icons";
import { showToastNotification } from "../../../actions/toast";
import {
  TOAST_TYPE_SUCCESS,
  TOAST_TYPE_ERROR,
} from "../../../services/constants";
import UploadFileMinimiser from "./UploadFileMinimiser";
import DetailsIcon from "../../../assets/icons/svg/details.svg";
import TextBoxComponent from "@CubeComponents/form/TextBoxComponent";
import ToolTipComponent from "../../../cubeComponent/html/tooltip";
import DropDownListComponent from "@CubeComponents/form/DropdownListComponent";
import { spacesDataService } from "../../../services/dataManager";
import { Query } from "@syncfusion/ej2-data";
import _ from "lodash";

function FileUpload() {
  const dispatch = useDispatch();
  const {
    currentFolder,
    isFileVersionUploading,
    portalData,
    projectData,
    spaceData,
    fileUploadState,
    selectedFiles,
    layout,
    fileUploadList,
    fileUploadProperties,
    currentFileUploadProperties,
  } = useSelector((state) => state);
  const {
    fileAllowedExtensions = {},
    fileObj,
    currentFiles = [],
    workspaceId,
    selectedSpaceTypeInFileManager = "this",
    draggedFiles,
    isFolderUpload = false,
    fileNomenclature,
    getPermission,
    formTemplate = { template: [] },
    formTemplateReceived,
    fileUploadStateRef,
    currentFolderRef = {},
    hierarchyId = "0",
  } = currentFileUploadProperties || {};
  // const fileUploadPropertiess = fileUploadProperties[`${currentFileUploadProperties.workspaceId}_${currentFileUploadProperties.hierarchyId}`]

  window.fileUploadProperties = fileUploadProperties;

  const [isDragOver, setIsDragOver] = useState(false);
  const fileUploadRef = useRef(null);
  const dropAreaRef = useRef(null);
  const filesRef = useRef(null);
  const fileUploadListRef = useRef(null);
  const isFolderUploadRef = useRef(false);
  const filesDataRef = useRef({});

  const title_mp_ref = useRef();
  const location_mp_ref = useRef();
  const remarks_mp_ref = useRef();

  const formObject = useRef(null);

  const [fileUploaded, setFileUploaded] = useState(false);
  const [currentBatchId, setCurrentBatchId] = useState(null);
  const [allBatchIds, setAllBatchIds] = useState([]);
  const [enableDone, setEnableDone] = useState(false);
  const [filesList, setFilesList] = useState([]);
  const [openFileUploadCancelModal, setOpenFileUploadCancelModal] =
    useState(false);
  const [uploadFolderData, setUploadFolderData] = useState({});

  const controllers = useRef({});
  const spaceDropDownRef = useRef();

  const query = new Query().addParams("projectId", projectData._id);

  const [uploadSpaceId, setUploadSpaceId] = useState(workspaceId);

  useEffect(() => {
    if (!uploadFolderData.id && currentFolderRef.current) {
      setUploadFolderData({
        id: currentFolderRef.current.hierarchyId,
        name: currentFolderRef.current.name,
        path: `${currentFolderRef.current.filterPath}${currentFolderRef.current.name}`,
      });
    } else if (isFileVersionUploading) {
      setUploadFolderData({
        id: hierarchyId,
        name: `/`,
        path: `/`,
      });
    }
  });

  useEffect(() => {
    if (!workspaceId && uploadSpaceId) {
      dispatch(
        setFileUploadProperties({
          [`${uploadSpaceId}_${hierarchyId}`]: {
            workspaceId: uploadSpaceId,
            formTemplate,
            // filesDataRef,
          },
        }),
      );
    }
  }, [uploadSpaceId]);

  useEffect(() => {
    isFolderUploadRef.current = isFolderUpload;
  }, [isFolderUpload]);

  useEffect(() => {
    if (draggedFiles?.length && !isFolderUploadRef.current) {
      fileUploadRef.current.files = draggedFiles;
      handleFileInputChange();
    }
  }, [draggedFiles]);

  useEffect(() => {
    if (!workspaceId && projectData.defaultSpace) {
      setUploadSpaceId(projectData.defaultSpace._id);
      setUploadFolderData({
        id: "0",
        name: "/",
        path: `/${projectData.defaultSpace.name}`,
      });
    }
  }, [workspaceId]);

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragOver(true);
    setFileUploaded(false);
  };

  function handleDragLeave(event) {
    event.preventDefault();
    setIsDragOver(false);
  }

  useEffect(() => {
    if (isFolderUpload && fileUploadRef.current) {
      fileUploadRef.current.setAttribute("directory", "");
      fileUploadRef.current.setAttribute("webkitdirectory", "");
      fileUploadRef.current.setAttribute("mozdirectory", "");
      fileUploadRef.current.setAttribute("multiple", "");
    }
  }, [fileUploadRef, isFolderUpload, fileUploadState]);

  useEffect(() => {
    if (fileUploadList.length) {
      fileUploadListRef.current = fileUploadList;
      if (!fileUploaded) {
        const batchId = uuid();
        setCurrentBatchId(batchId);
        if (!allBatchIds.includes(batchId)) {
          setAllBatchIds((prev) => [...prev, batchId]);
        }
        fileUploadList.forEach((file, index) => {
          if (
            file.progress == 0 &&
            !file.triggerFileUpload &&
            !file.isDeleted
          ) {
            handleFileUpload(index, batchId);
          }
        });
        setFileUploaded(true);
      }
      const allFiles = [];
      fileUploadList.forEach((file, index) => {
        allFiles.push({
          fileId: file.fileId,
          progressCompleted: file.progressCompleted,
          name: file.name,
          progress: file.progress,
          isFileNomenclatureCorrect: file.isFileNomenclatureCorrect,
          isDeleted: file.isDeleted,
          isFolder: file.isFolder,
        });
      });
      if (JSON.stringify(filesList) != JSON.stringify(allFiles)) {
        setFilesList(allFiles);
      }
    }
  }, [fileUploadList]);

  const handleClose = () => {
    dispatch(closeFileUpload());
  };

  const traverseFileTree = async (item, path) => {
    let output = [];
    path = path || "";
    if (item.isFile) {
      await new Promise((res) => {
        item.file((file) => {
          file.customWebkitRelativePath = path + file.name;
          output.push(file);
          res();
        });
      });
    } else if (item.isDirectory) {
      const dirReader = item.createReader();
      await new Promise((res) => {
        dirReader.readEntries(async (entries) => {
          for (let i = 0; i < entries.length; i++) {
            output = [
              ...output,
              ...(await traverseFileTree(entries[i], `${path + item.name}/`)),
            ];
          }
          res();
        });
      });
    }
    return output;
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    setIsDragOver(false);

    let fileList = [];
    const items = event.dataTransfer.items;
    const itemAsEntry = [];
    for (const data of items) {
      itemAsEntry.push(data.webkitGetAsEntry());
    }

    for (const itemData of itemAsEntry) {
      if (itemData.isDirectory) {
        const folderData = await traverseFileTree(itemData);
        fileList.push({
          name: itemData.name,
          size: folderData.reduce((acc, curr) => acc + curr.size, 0),
          resourcesList: folderData,
          isFolder: true,
        });
      } else {
        await new Promise((res) => {
          itemData.file((file) => {
            fileList.push(file);
            res();
          });
        });
      }
    }

    fileList = processFileList({
      fileList,
      currentFiles,
      fileNomenclature,
      isFileVersionUploading,
      uploadFolderData,
      uploadSpaceId,
      selectedSpaceTypeInFileManager,
      currentFolder,
    });
    dispatch(setFileUploadData(fileList));
  };

  const abortOnClose = () => {
    fileUploadList.forEach((file) =>
      file.controller?.forEach((item) => item.abort()),
    );
  };

  const handleCloseFileUploadModal = async () => {
    const currentFiles =
      fileUploadListRef?.current?.filter((file) => !file.isDeleted) || [];
    const initialFilesCount = currentFiles.length;
    let filesUploadedAsNewVersion = 0;
    for (const file of currentFiles) {
      const fileData = filesDataRef.current[file.id];
      if (fileData.uploadAsNewVersion && fileData.uploaded) {
        filesUploadedAsNewVersion = filesUploadedAsNewVersion + 1;
      }
    }
    abortOnClose();
    dispatch(removeAllFileUploadData());
    if (isFileVersionUploading && fileUploadListRef.current) {
      const fileVersionsUploadFailed = !!fileUploadListRef.current.filter(
        (item) => !item.progressCompleted && !item.isDeleted,
      ).length;
      if (fileVersionsUploadFailed) {
        dispatch(
          showToastNotification({
            content: "Error in File(s) upload.",
            type: TOAST_TYPE_ERROR,
          }),
        );
      } else {
        dispatch(
          showToastNotification({
            content: "File(s) uploaded successfully.",
            type: TOAST_TYPE_SUCCESS,
          }),
        );
      }
    } else if (currentBatchId && initialFilesCount) {
      axiosWrapper
        .post(
          `${process.env.REACT_APP_CUBE_BACKEND}/fileOperations/handleBatchNotification`,
          {
            batchIds: allBatchIds,
            initialFilesCount,
            isFolder: isFolderUploadRef.current,
            filesUploadedAsNewVersion,
          },
        )
        .then((response) => {
          if (response.data.status) {
            dispatch(
              showToastNotification({
                content: response.data.message,
                type: TOAST_TYPE_SUCCESS,
              }),
            );
          } else {
            dispatch(
              showToastNotification({
                content: response.data.message,
                type: TOAST_TYPE_ERROR,
              }),
            );
          }
        });
    }
    filesDataRef.current = {};
    dispatch(setIsFileVersionUploading(false));
    dispatch(closeFileUpload());
    dispatch(emptyFileUploadData());
    setOpenFileUploadCancelModal(false);
    setUploadFolderData({});

    if (fileUploadRef.current) {
      fileUploadRef.current.value = "";
    }
    fileObj?.refreshFiles();
  };

  const onCloseFileUploadModal = () => {
    if (fileUploadList.length && !enableDone) {
      setOpenFileUploadCancelModal(true);
    } else {
      handleCloseFileUploadModal();
    }
  };

  const handleDone = () => {
    handleCloseFileUploadModal();
    dispatch(refreshFileManager(true));
  };

  const handleFileInputChange = () => {
    if (!fileUploadRef.current.files.length) {
      return;
    }
    let fileList = null;

    if (isFolderUploadRef.current) {
      const folderSize = Array.from(fileUploadRef.current.files).reduce(
        (acc, curr) => {
          return acc + curr.size;
        },
        0,
      );
      const rootFolderName =
        fileUploadRef.current.files[0].webkitRelativePath.split("/")[0];

      fileList = [
        {
          name: rootFolderName,
          size: folderSize,
          resourcesList: [...fileUploadRef.current.files],
          isFolder: true,
        },
      ];
    } else {
      let error;
      if (getPermission && !getPermission({ current: fileObj })) {
        error = "Insufficient permission";
      }
      const newFileList = [...fileUploadRef.current.files].map((file) => {
        if (error) {
          file.error = error;
        }
        return file;
      });
      fileList = [...newFileList];
    }

    fileList = processFileList({
      fileList,
      currentFiles,
      fileNomenclature,
      isFileVersionUploading,
      uploadFolderData,
      uploadSpaceId,
      selectedSpaceTypeInFileManager,
      currentFolder,
    });
    dispatch(setFileUploadData(fileList));
    setFileUploaded(false);
  };

  const createFolder = async ({ hierarchyId, name, spaceId }) => {
    const response = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/fileSystemServer`,
      {
        action: "create",
        name,
        data: [
          {
            hierarchyId,
            name,
            spaceId,
          },
        ],
      },
    );
    return response;
  };

  const triggerFolderUpload = async (
    file,
    fileIndex,
    fileMetadata,
    batchId,
  ) => {
    const foldersCreated = {};
    setUploadSpaceId(fileMetadata.spaceId);
    dispatch(
      updateFileUploadData({
        index: fileIndex,
        spaceId: uploadSpaceId,
        hierarchyId,
        data: {
          progress: 0,
          status: "Uploading...",
        },
      }),
    );

    for (let i = 0; i < file.resourcesList.length; i++) {
      const currentFile = file.resourcesList[i];
      const splitWebkitpath = currentFile.customWebkitRelativePath
        ? currentFile.customWebkitRelativePath.split("/")
        : currentFile.webkitRelativePath.split("/");
      for (let j = 1; j < splitWebkitpath.length; j++) {
        const folderPath = splitWebkitpath.slice(0, j).join("/");
        const parentFolderPath = folderPath.split("/").slice(0, -1).join("/");
        const folderName = folderPath.split("/").at(-1);

        if (
          fileUploadListRef.current &&
          fileUploadListRef.current[fileIndex] &&
          fileUploadListRef.current[fileIndex].isDeleted
        ) {
          break;
        }
        try {
          if (!foldersCreated.hasOwnProperty(folderPath)) {
            const response = await createFolder({
              hierarchyId: parentFolderPath
                ? foldersCreated[parentFolderPath]
                : fileMetadata.hierarchyId,
              name: folderName,
              spaceId: fileMetadata.spaceId,
            });
            const data = JSON.parse(response.data);
            if (!data.status) {
              handleUploadError(fileIndex, {
                response: { ...response, data: JSON.parse(response.data) },
              });
              return;
            }
            const folderId = data.files._id;
            if (!parentFolderPath) {
              // only set for parent folder
              dispatch(
                updateFileUploadData({
                  index: fileIndex,
                  spaceId: uploadSpaceId,
                  hierarchyId,
                  data: {
                    // fileId: folderId,
                    folderId,
                  },
                }),
              );
            }
            foldersCreated[folderPath] = folderId;
          }

          if (j == splitWebkitpath.length - 1) {
            const response = await uploadFile({
              fileMetadata: {
                ...fileMetadata,
                hierarchyId: foldersCreated[folderPath],
              },
              file: currentFile,
              folderIndex: fileIndex,
              fileIndex: i,
              batchId,
            });
            if (response.data.status) {
              dispatch(
                updateFileUploadData({
                  index: fileIndex,
                  spaceId: uploadSpaceId,
                  hierarchyId,
                  data: {
                    bytesUploaded: currentFile.size,
                    hierarchyId: foldersCreated[folderPath],
                  },
                }),
              );
            } else {
              handleUploadError(fileIndex, { response });
              break;
            }
          }
        } catch (err) {
          handleUploadError(fileIndex, err);
          break;
        }
      }
    }

    const uploadClosed = isUploadPopupClosed();
    if (!uploadClosed) {
      dispatch(
        updateFileUploadData({
          index: fileIndex,
          spaceId: uploadSpaceId,
          hierarchyId,
          data: {
            progressCompleted: true,
          },
        }),
      );
    }
  };

  // const calculateMd5 = async (file) => {
  //   const hashingFn = new BroswerMD5();
  //   return await new Promise((res, rej) => {
  //     hashingFn.md5(file, async (err, md5) => {
  //       if (!err) {
  //         res(md5);
  //       } else {
  //         rej(err);
  //       }
  //     });
  //   });
  // };

  function isUploadPopupClosed() {
    return (
      fileUploadStateRef &&
      fileUploadStateRef.current &&
      fileUploadStateRef.current == "CLOSED"
    );
  }

  const uploadFile = async ({
    fileMetadata = {},
    file,
    fileIndex,
    folderIndex,
    onUploadProgress = () => {},
    batchId,
  }) => {
    setUploadSpaceId(fileMetadata.spaceId);
    const formData = new FormData();
    const data = {
      hierarchyId: fileMetadata.hierarchyId,
      spaceId: fileMetadata.spaceId,
      spaceType: fileMetadata.spaceType,
      location: fileMetadata.location,
      documentType: fileMetadata.documentType,
      Title_mp: fileMetadata.Title_mp,
      Location_mp: fileMetadata.Location_mp,
      Remarks_mp: fileMetadata.Remarks_mp,
      batchId,
    };

    formData.append("uploadFiles", file);
    formData.append("action", "save");
    formData.append("getFileId", true);
    formData.append("data", JSON.stringify(data));

    const controller = new AbortController();
    const signal = controller.signal;

    let upload = true;
    if (fileMetadata.isFolder) {
      upload =
        fileMetadata.isFolder &&
        !fileUploadListRef.current[folderIndex].isDeleted;
    } else {
      upload = !fileUploadListRef.current[fileIndex].isDeleted;
    }

    const index = fileMetadata.isFolder ? folderIndex : fileIndex;

    if (isUploadPopupClosed()) {
      upload = false;
    } else {
      const controllers = [controller];
      if (fileMetadata.isFolder) {
        fileUploadListRef.controller;
        controllers[fileIndex] = controller;
      }
      dispatch(
        updateFileUploadData({
          index,
          data: {
            progressStarted: true,
            controller: controllers,
          },
        }),
      );
    }

    if (upload) {
      return await axiosWrapper.post(
        `${process.env.REACT_APP_CUBE_BACKEND}/fileSystemServer/Upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (percentageComplete, totalBytesDone) =>
            onUploadProgress(index, percentageComplete, totalBytesDone),
          signal,
        },
      );
    }
    return false;
  };

  const handleUploadError = (fileIndex, err) => {
    const errorMessage = getErrorMessage(err);
    dispatch(
      updateFileUploadData({
        index: fileIndex,
        spaceId: uploadSpaceId,
        hierarchyId,
        data: {
          error: errorMessage,
        },
      }),
    );
    if (document.getElementById(`error_text_${fileIndex + 1}`)) {
      document.getElementById(`error_text_${fileIndex + 1}`).innerText =
        errorMessage;
    }
  };

  const getErrorMessage = (err) => {
    let errorMessage = _.get(err, "response.data.message");
    if (!errorMessage && _.get(err, "code") == "ERR_NETWORK") {
      errorMessage =
        "Upload Failed. Please check your network and ensure the file is stored on your device before retrying.";
    } else if (!errorMessage) {
      errorMessage = "Something went wrong";
    }
    return errorMessage;
  };

  const triggerFileUpload = async (file, fileIndex, fileMetadata, batchId) => {
    try {
      const response = await uploadFile({
        fileMetadata,
        file,
        fileIndex,
        onUploadProgress: uploadProgressCallback,
        batchId,
      });
      if (response.data.status) {
        dispatch(
          updateFileUploadData({
            index: fileIndex,
            spaceId: uploadSpaceId,
            hierarchyId,
            data: {
              progressCompleted: true,
              fileId: response.data.fileId,
            },
          }),
        );
      } else {
        handleUploadError(fileIndex, { response });
      }
    } catch (err) {
      handleUploadError(fileIndex, err);
    }
  };

  const uploadProgressCallback = (fileIndex, percentageComplete) => {
    const uploadClosed = isUploadPopupClosed();
    if (fileUploadList.length && fileUploadList[fileIndex] && !uploadClosed) {
      dispatch(
        updateFileUploadData({
          index: fileIndex,
          spaceId: uploadSpaceId,
          hierarchyId,
          data: {
            progress: percentageComplete,
            status: "Uploading...",
          },
        }),
      );
    }
  };

  const handleMinimiseModal = (event) => {
    dispatch(minimiseFileUpload());
    event.stopPropagation();
  };

  const handleBrowseFileButtonClick = (e) => {
    if (isFolderUpload && fileUploadRef.current) {
      fileUploadRef.current.setAttribute("directory", "");
      fileUploadRef.current.setAttribute("webkitdirectory", "");
      fileUploadRef.current.setAttribute("mozdirectory", "");
      fileUploadRef.current.setAttribute("multiple", "");
    }
    fileUploadRef.current.click();
    fileUploadRef.current.value = "";
  };

  const handleFileUploadAsNewFile = async (fileIndex, batchId) => {
    let { file } = fileUploadList[fileIndex];
    const { isFolder } = fileUploadList[fileIndex];
    const fileType = file.type;
    const { name } = file;

    if (!isFolder) {
      file = new File([file], name, {
        type: fileType,
      });
    }

    // TODO: not sure if duplicateFileStatus line is needed
    dispatch(
      updateFileUploadData({
        index: fileIndex,
        spaceId: uploadSpaceId,
        hierarchyId,
        data: {
          progress: 0,
          progressCompleted: false,
          triggerFileUpload: true,
        },
      }),
    );

    if (!isFolder) {
      await triggerFileUpload(
        file,
        fileIndex,
        fileUploadList[fileIndex],
        batchId,
      );
    } else {
      await triggerFolderUpload(
        file,
        fileIndex,
        fileUploadList[fileIndex],
        batchId,
      );
    }
  };

  const handleFileUploadAsNewVersion = async (fileIndex) => {
    try {
      const fileMetadata = fileUploadList[fileIndex];
      const fileUploaded = fileMetadata.file;
      let hierarchyId = "";

      dispatch(
        updateFileUploadData({
          index: fileIndex,
          spaceId: uploadSpaceId,
          hierarchyId,
          data: {
            duplicateFileStatus: { found: false },
            progress: 0,
            progressCompleted: false,
            triggerFileUpload: true,
          },
        }),
      );

      if (isFileVersionUploading === true) {
        hierarchyId = selectedFiles[0].hierarchyId;
      } else {
        const allFiles = currentFiles; // fileObj.detailsviewModule.gridObj.currentViewData;
        allFiles.forEach((file) => {
          if (file.name == fileUploaded.name) {
            hierarchyId = file.hierarchyId;
          }
        });
      }

      const formData = new FormData();
      const data = {
        location: fileMetadata.location,
        documentType: fileMetadata.documentType,
      };
      formData.append("uploadFiles", fileUploaded);
      formData.append("fileId", hierarchyId);
      formData.append("name", fileUploaded.name);
      formData.append("data", JSON.stringify(data));
      formData.append("spaceId", uploadSpaceId);

      const config = {
        onUploadProgress: uploadProgressCallback.bind(this, fileIndex),
      };

      const response = await axiosWrapper.post(
        `${process.env.REACT_APP_CUBE_BACKEND}/fileUpload/uploadNewVersion`,
        formData,
        config,
      );
      if (response.data.status) {
        dispatch(mergeFileVersions(response.data.data));
        dispatch(
          updateFileUploadData({
            index: fileIndex,
            spaceId: uploadSpaceId,
            hierarchyId,
            data: {
              progressCompleted: true,
            },
          }),
        );
      } else {
        handleUploadError(fileIndex, { response });
      }
    } catch (err) {
      handleUploadError(fileIndex, err);
    }

    // dispatch(updateFileUploadData({
    //   index: fileIndex,
    // spaceId: uploadSpaceId,
    //   data: {
    //     duplicateFileStatus: { found: false },
    //     progress: 0,
    //     progressCompleted: false,
    //   },
    // }));
  };

  const handleSpaceChange = (value) => {
    setUploadSpaceId(value);
  };

  const handleFileUpload = (fileIndex, batchId) => {
    if (isFileVersionUploading === true) {
      handleFileUploadAsNewVersion(fileIndex);
    } else {
      handleFileUploadAsNewFile(fileIndex, batchId);
    }
  };

  const handleDocumentTypeChange = (e, dataFileIndex) => {
    dispatch(
      documentTypeChange({
        index: dataFileIndex,
        data: e.itemData.text,
      }),
    );
  };

  const handleDocumentTypeRemove = (e, dataFileIndex) => {
    dispatch(
      documentTypeRemove({
        index: dataFileIndex,
        data: e.itemData.text,
      }),
    );
  };

  const handleLocationChange = (e, dataFileIndex) => {
    dispatch(
      locationChange({
        index: dataFileIndex,
        data: e.itemData.text,
      }),
    );
  };

  const handleLocationRemove = (e, dataFileIndex) => {
    dispatch(
      locationChange({
        index: dataFileIndex,
        data: e.itemData.text,
      }),
    );
  };

  const handleCustomParameters = (args, dataFileIndex) => {
    if (args.event) {
      dispatch(
        updateFileUploadData({
          index: dataFileIndex,
          data: {
            [args.event.target.name]: args.value,
          },
        }),
      );
    }
  };

  const handleCopyClick = () => {
    const title = title_mp_ref.current.value;
    const location = location_mp_ref.current.value;
    const remarks = remarks_mp_ref.current.value;

    fileUploadList.forEach((value, dataFileIndex) => {
      dispatch(
        updateFileUploadData({
          index: dataFileIndex,
          data: {
            Title_mp: title,
            Location_mp: location,
            Remarks_mp: remarks,
          },
        }),
      );
    });
  };

  const filesModalContent = () => (
    <div className="h-full py-4 overflow-none">
      {filesList.length ? (
        <div className="flex flex-col h-full">
          <div>
            <div
              className="flex border items-center pl-3 py-4 border-solid border-[color:var(--soft_gray)]"
              ref={dropAreaRef}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <input
                accept={fileAllowedExtensions.allowedExtensions}
                multiple={isFileVersionUploading !== true}
                onChange={handleFileInputChange}
                ref={fileUploadRef}
                type="file"
                hidden
              />
              <ButtonComponent
                onClick={handleBrowseFileButtonClick}
                cssClass="e-flat"
                disabled={!uploadSpaceId}
              >
                Browse...
              </ButtonComponent>
              <div
                style={{ fontSize: "14px", paddingLeft: "10px" }}
              >{`Or drop ${isFolderUpload ? "folders" : "files"} here`}</div>
            </div>
            {formTemplate.template &&
            formTemplate.template.length &&
            isFolderUpload ? (
              <div className="flex items-center gap-1 text-sm mt-4">
                <img src={DetailsIcon} className="h-4" />
                <p>
                  Custom fields value set for a folder will be applied to all
                  the files within that folder.
                </p>
              </div>
            ) : null}
          </div>

          {filesList.length ? (
            <FilesGrid
              ref={filesRef}
              fileUploadList={fileUploadList}
              template={formTemplate.template}
              formTemplateId={formTemplate.formTemplateId}
              workspaceId={uploadSpaceId}
              setEnableDone={setEnableDone}
              formObject={formObject}
              fileNomenclature={fileNomenclature}
              filesDataRef={filesDataRef}
              controllers={controllers}
              isFolderUpload={isFolderUpload}
              fileUploadListRef={fileUploadListRef}
            />
          ) : null}
        </div>
      ) : (
        <div className="h-full">
          <div
            className="flex border items-center justify-center pl-3 py-4 border-solid border-[color:var(--soft_gray)] h-full w-full"
            ref={dropAreaRef}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <input
              accept={fileAllowedExtensions.allowedExtensions}
              multiple={isFileVersionUploading !== true}
              onChange={handleFileInputChange}
              ref={fileUploadRef}
              type="file"
              hidden
            />
            <div className="flex flex-col justify-center items-center">
              <IconComponent name="NoData" className="h-40" />
              <div className="text-lg my-2 text-[color:var(--text-gray)]">{`No ${
                isFolderUpload ? "Folder" : "Files"
              } Uploaded`}</div>
              <div className="flex text-sm mb-2">
                <span className="font-semibold">Upload&nbsp;</span>
                <div>{`a ${
                  isFolderUpload ? "folder" : "file"
                } or drag in the boundary box to get started`}</div>
              </div>
              <ButtonComponent
                onClick={handleBrowseFileButtonClick}
                cssClass="e-flat"
                buttonType={BUTTON_TYPES.BACKGROUND_BUTTON}
                disabled={!uploadSpaceId}
              >
                Browse...
              </ButtonComponent>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  const getSpacesDropdown = useCallback(
    () => (
      <DropDownListComponent
        ref={spaceDropDownRef}
        dataSource={spacesDataService}
        query={query}
        fields={{ text: "spaceName", value: "_id" }}
        allowFiltering
        onChange={(event) => handleSpaceChange(event.target.value)}
        width="auto"
        value={uploadSpaceId}
        popupWidth="200px"
        enabled={!workspaceId && !fileUploadList.length}
      />
    ),
    [uploadSpaceId, fileUploadList],
  );

  const headerContent = () => (
    <div className="flex w-full justify-between" data-testid="headerContent">
      <span
        className="text-2xl text-[color:var(--primary-text)] font-semibold"
        data-testid="uploadFiles"
      >
        {isFolderUpload ? "Upload Folder" : "Upload Files"}
      </span>
      <div className="flex items-center gap-4">
        {!workspaceId && getSpacesDropdown()}
        <ToolTipComponent
          showTipPointer={false}
          position="BottomCenter"
          content={uploadFolderData.path || "/"}
        >
          <TextBoxComponent
            value={uploadFolderData.name || "/"}
            disabled
            className=""
          />
        </ToolTipComponent>
        <img
          src={minimiseIcon}
          className="w-5 h-5 cursor-pointer"
          onClick={handleMinimiseModal}
        />
        <IconComponent
          name="close"
          className="cursor-pointer"
          onClick={onCloseFileUploadModal}
        />
      </div>
    </div>
  );

  const footerContent = () =>
    fileUploadList.length ? (
      <div className="h-auto mt-4">
        <ButtonComponent
          buttonType={BUTTON_TYPES.GRADIENT_BUTTON}
          onClick={handleDone}
          data-testid="nextAddUserBtn"
          disabled={!enableDone}
        >
          Done
        </ButtonComponent>
      </div>
    ) : null;

  const uploadModal = () => (
    <ModalStateDialogComponent
      id="file-upload-modal"
      isVisible
      width="90%"
      onCancel={handleCloseFileUploadModal}
      zIndex={400}
      headerContent={headerContent()}
      modelContent={filesModalContent()}
      footerContent={footerContent()}
      height="95%"
    />
  );

  const onClickFileUploadCancelModalClose = () => {
    handleCloseFileUploadModal();
  };

  const handleFileUploadCancelModalClose = () => {
    setOpenFileUploadCancelModal(false);
  };

  const uploadFileMinimiser = useCallback(
    () => (
      <UploadFileMinimiser
        handleCloseFileUploadModal={onCloseFileUploadModal}
      />
    ),
    [fileUploadList],
  );
  return (
    <div>
      {fileUploadState == "MAXIMISED" && uploadModal()}
      {uploadFileMinimiser()}
      {openFileUploadCancelModal && (
        <ModalStateDialogComponent
          isVisible
          footerContent={
            <div className="flex w-full gap-4">
              <ButtonComponent
                onClick={onClickFileUploadCancelModalClose}
                buttonType={BUTTON_TYPES.GRADIENT_BUTTON}
              >
                Yes
              </ButtonComponent>
              <ButtonComponent
                onClick={handleFileUploadCancelModalClose}
                buttonType={BUTTON_TYPES.BORDER_BUTTON}
              >
                No
              </ButtonComponent>
            </div>
          }
          headerContent={
            <div className="flex justify-between w-full items-center">
              <div>Cancel Upload</div>
              <IconComponent
                onClick={handleFileUploadCancelModalClose}
                cssClass="cursor-pointer"
                name="close"
              />
            </div>
          }
          modelContent={
            <div className="w-full">
              <div className="my-4">
                Files in uploading state will be cancelled. Are you sure you
                want to cancel file upload?
              </div>
            </div>
          }
          width="40%"
          allowModalPadding
          zIndex={500}
        />
      )}
    </div>
  );
}
export default FileUpload;
