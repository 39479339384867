import React from "react";
import ChangeColor from "../../HoopsViewer/ToolbarViewer/ChangeColor";

function Relationship({ color, onClick }) {
  return (
    <svg
      onClick={onClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <linearGradient id="relationship" gradientTransform="rotate(45)">
        <stop offset="38%" stopColor={color} />
        <stop offset="92%" stopColor={ChangeColor(color)} />
      </linearGradient>
      <path
        d="M13.5 15.75L13.5 20.25C13.5 20.6478 13.658 21.0294 13.9393 21.3107C14.2206 21.592 14.6022 21.75 15 21.75H19.5C19.8978 21.75 20.2794 21.592 20.5607 21.3107C20.842 21.0294 21 20.6478 21 20.25V15.75C21 15.3522 20.842 14.9706 20.5607 14.6893C20.2794 14.408 19.8978 14.25 19.5 14.25H18V13.5C17.9975 12.7051 17.6807 11.9435 17.1186 11.3814C16.5565 10.8193 15.7949 10.5025 15 10.5L12.75 10.5L12.75 8.25H13.875C14.2728 8.25 14.6544 8.09196 14.9357 7.81066C15.217 7.52936 15.375 7.14782 15.375 6.75V3C15.375 2.60218 15.217 2.22064 14.9357 1.93934C14.6544 1.65804 14.2728 1.5 13.875 1.5L10.125 1.5C9.72718 1.5 9.34564 1.65804 9.06434 1.93934C8.78304 2.22064 8.625 2.60218 8.625 3V6.75C8.625 7.14782 8.78304 7.52936 9.06434 7.81066C9.34564 8.09196 9.72718 8.25 10.125 8.25L11.25 8.25L11.25 10.5H9C8.20511 10.5025 7.44349 10.8193 6.88141 11.3814C6.31934 11.9435 6.00247 12.7051 6 13.5V14.25H4.5C4.10217 14.25 3.72064 14.408 3.43934 14.6893C3.15804 14.9706 3 15.3522 3 15.75V20.25C3 20.6478 3.15804 21.0294 3.43934 21.3107C3.72064 21.592 4.10217 21.75 4.5 21.75L9 21.75C9.39782 21.75 9.77936 21.592 10.0607 21.3107C10.342 21.0294 10.5 20.6478 10.5 20.25V15.75C10.5 15.3522 10.342 14.9706 10.0607 14.6893C9.77936 14.408 9.39782 14.25 9 14.25H7.5V13.5C7.5 13.1022 7.65804 12.7206 7.93934 12.4393C8.22064 12.158 8.60218 12 9 12L15 12C15.3978 12 15.7794 12.158 16.0607 12.4393C16.342 12.7206 16.5 13.1022 16.5 13.5V14.25H15C14.6022 14.25 14.2206 14.408 13.9393 14.6893C13.658 14.9706 13.5 15.3522 13.5 15.75ZM10.125 6.75V3L13.875 3V6.75L10.125 6.75ZM9 15.75V20.25L4.5 20.25V15.75H9ZM19.5 15.75L19.5 20.25H15L15 15.75H19.5Z"
        fill='url("#relationship")'
      />
    </svg>
  );
}

export default Relationship;
