import React from "react";
import ChangeColor from "../../HoopsViewer/ToolbarViewer/ChangeColor";

function Views({ color, onClick }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <linearGradient id="Views" gradientTransform="rotate(45)">
        <stop offset="38%" stopColor={color} />
        <stop offset="92%" stopColor={ChangeColor(color)} />
      </linearGradient>
      <path
        d="M6.82043 12.6789C6.68837 12.9508 6.68837 13.2625 6.82043 13.532C7.90141 15.7188 9.57178 16.8438 11.7949 16.8438C14.018 16.8438 15.6883 15.7164 16.7693 13.532C16.9014 13.2625 16.9014 12.9508 16.7718 12.6813L16.7669 12.6766C15.6883 10.4922 14.018 9.36719 11.7949 9.36719C9.57178 9.36719 7.90141 10.4945 6.82043 12.6789ZM11.7949 10.8672C13.3136 10.8672 14.4215 11.5703 15.2457 13.1055C14.4239 14.6406 13.3136 15.3438 11.7949 15.3438C10.2761 15.3438 9.16825 14.6406 8.34407 13.1055C9.16825 11.5703 10.2761 10.8672 11.7949 10.8672Z"
        fill='url("#Views")'
      />
      <path
        d="M10.4375 13.1172C10.4375 13.4653 10.5818 13.7991 10.8386 14.0453C11.0955 14.2914 11.4438 14.4297 11.8071 14.4297C12.1703 14.4297 12.5187 14.2914 12.7755 14.0453C13.0323 13.7991 13.1766 13.4653 13.1766 13.1172C13.1766 12.7691 13.0323 12.4353 12.7755 12.1891C12.5187 11.943 12.1703 11.8047 11.8071 11.8047C11.4438 11.8047 11.0955 11.943 10.8386 12.1891C10.5818 12.4353 10.4375 12.7691 10.4375 13.1172Z"
        fill='url("#Views")'
      />
      <path
        d="M20.7826 6.68125H12.0027L9.13397 4.05156C9.09728 4.01875 9.04837 4 8.99946 4H2.78261C2.34973 4 2 4.33516 2 4.75V18.625C2 19.0398 2.34973 19.375 2.78261 19.375H20.7826C21.2155 19.375 21.5652 19.0398 21.5652 18.625V7.43125C21.5652 7.01641 21.2155 6.68125 20.7826 6.68125ZM19.8043 17.6875H3.76087V5.6875H8.37092L11.2959 8.36875H19.8043V17.6875Z"
        fill='url("#Views")'
      />
    </svg>
  );
}

export default Views;
