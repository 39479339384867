import { store } from "./App";
import { Provider } from "react-redux";
import { useState, createContext } from "react";
import { THEME_LIGHT, THEME_DARK } from "./App.constant";
import { PubSubContext } from "./App";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export const themes = {
  [THEME_LIGHT]: {
    rootBackground: "--surface-neutral",
    rootTextColor: "--primary-text",
    userNameTagComponentBackground: "--light-blue-tag",
  },
  [THEME_DARK]: {
    rootBackground: "--surface-black",
    rootTextColor: "--surface-neutral",
  },
};

export const ThemeContext = createContext({
  theme: themes[THEME_LIGHT],
});

export const muiTheme = createTheme({
  typography: {
    fontFamily: "inherit",
  },

  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
          "&:active": {
            boxShadow: "none",
          },
        },
      },
    },
    // MuiCssBaseline: {
    //   styleOverrides: {
    //     body: {
    //       fontFamily: "inherit",
    //     },
    //   },
    // },
  },
});

function ProviderApp({ children }) {
  const [theme, setTheme] = useState(THEME_LIGHT);

  return (
    <ThemeProvider theme={muiTheme}>
      <PubSubContext.Provider>
        <ThemeContext.Provider
          value={{
            theme: themes[theme],
            setTheme,
            themes,
            themeType: theme,
          }}
        >
          <Provider store={store}>{children}</Provider>
        </ThemeContext.Provider>
      </PubSubContext.Provider>
    </ThemeProvider>
  );
}

export default ProviderApp;
