import { createSlice } from "@reduxjs/toolkit";

const layoutSlice = createSlice({
  name: "layout",
  initialState: {},
  reducers: {
    setLayout: (state, action) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { setLayout } = layoutSlice.actions;

export default layoutSlice.reducer;
