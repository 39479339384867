import axiosWrapper from "../services/axiosWrapper";
import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from "../services/constants";
import { showToastNotification } from "./toast";

export function saveProjectUserDetails(data) {
  return {
    type: "SET_PROJECT_USER_DETAILS",
    data,
  };
}

export function saveSpaceUserDetails(data) {
  return {
    type: "SET_SPACE_USER_DETAILS",
    data,
  };
}

export function addUserToSpace(data, metadata) {
  return (dispatch) => {
    axiosWrapper
      .post(`${process.env.REACT_APP_CUBE_BACKEND}/spaces/addUserToSpace`, data)
      .then((response) => {
        metadata
          ? dispatch(
              showToastNotification({
                content: `${metadata.selectedAddUserName} has been added to Space ${metadata.spaceName} successfully!`,
                type: TOAST_TYPE_SUCCESS,
              }),
            )
          : null;
        if (!response.data.status) {
          dispatch(
            showToastNotification({
              content: response.data.errorMessage,
              type: TOAST_TYPE_ERROR,
            }),
          );
        }
      });
  };
}
export const DeleteUserFromSpace = async (data) => {
  const response = await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/spaces/DeleteUserFromSpace`,
    data,
  );
  return response;
};

export function addUserToProject(data, userName) {
  return async (dispatch) => {
    const responseData = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/projects/addUserToProject`,
      data,
    );
    if (responseData.data.status) {
      dispatch(
        showToastNotification({
          content:
            "Selected User(s) have been added to the project successfully!",
          type: TOAST_TYPE_SUCCESS,
        }),
      );
    } else {
      dispatch(
        showToastNotification({
          content: responseData.data.errorMessage,
          type: TOAST_TYPE_ERROR,
        }),
      );
    }
    return responseData;
  };
}

export function getAllUsersInProject(data) {
  return (dispatch) => {
    axiosWrapper
      .post(
        `${process.env.REACT_APP_CUBE_BACKEND}/accounts/getAllUsersInProject`,
        data,
      )
      .then((response) => {
        if (response.data.status) {
          dispatch(saveProjectUserDetails(response.data.data));
        }
      });
  };
}

export function getAllUsersInSpace(data) {
  return async (dispatch) => {
    await axiosWrapper
      .post(
        `${process.env.REACT_APP_CUBE_BACKEND}/accounts/getAllUsersInSpace`,
        data,
      )
      .then((response) => {
        if (response.data.status) {
          dispatch(saveSpaceUserDetails(response.data.result));
        }
      });
  };
}
