import ButtonComponent from "@CubeComponents/form/ButtonComponent";
import Accordion from "@CubeComponents/html/accordion/materialUiAccordian/index";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckboxComponent from "@CubeComponents/form/CheckboxComponent";
import IconComponent from "@CubeComponents/icons";
import { InPlaceEditorComponent } from "@syncfusion/ej2-react-inplace-editor";
import { useRef, createRef, useEffect, useState } from "react";
import {
  DeleteIcon,
  PlusIcon,
} from "@Components/StickySidebarViewer/assets/selectionIcons";
import GridComponent from "@CubeComponents/html/grid";
import {
  ColumnsDirective,
  ColumnDirective,
  Edit,
  Inject,
} from "@syncfusion/ej2-react-grids";
import MultiSelectComponent from "@CubeComponents/form/MultiselectComponent";
import DropDownListComponent from "@CubeComponents/form/DropdownListComponent";
import { useParams, useNavigate, useLocation } from "react-router";
import { formFlowController } from "../../actions/Forms";
import {
  postSubmissionTypeDataSource,
  preSubmissionTypeDataSource,
} from "./constants";
import { showToastNotification } from "../../actions/toast";
import {
  TOAST_TYPE_ERROR,
  TOAST_TYPE_SUCCESS,
  TOAST_TYPE_WARNING,
} from "../../services/constants";
import { useDispatch, useSelector } from "react-redux";
import { BUTTON_TYPES } from "@CubeComponents/constants/globalVariable";
import ToolTipComponent from "../../cubeComponent/html/tooltip";
import ModalStateDialogComponent from "../../cubeComponent/html/modal/ModalWithState";
import TextBoxComponent from "../../cubeComponent/form/TextBoxComponent";
import { formCategories } from "../PortalSetings/Configuration/FormsAndFields/constants";

function FormFlowCreation({
  selectedForm,
  handleCancelFormAttachment,
  source,
  selectedFormFlow = null,
  selectedRow = {},
  openFormFlowInViewMode = false,
  handleCreateClick,
  spaceId,
  refreshGrid,
  setShowFormFlowCreationModal,
  setChangeOrdeState,
}) {
  if (!selectedForm) {
    selectedForm = selectedRow.selectedForm;
  }
  const params = useParams();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const projectId = params.projectId || urlParams.get("projectId");
  const dispatch = useDispatch();
  const [sectionsSelectedToDelete, setSectionsSelectedToDelete] = useState([]);
  const approveBtnEditorRef = useRef();
  const rejectBtnEditorRef = useRef();
  const submitBtnEditorRef = useRef();
  const [sectionsDataForEachGridRow, setSectionsDataForEachGridRow] = useState(
    [],
  );
  const [usersDataForEachRow, setUsersDataForEachRow] = useState([]);
  const inPlaceEditorRef = useRef();
  const userDetails = useSelector((state) => state.userDetails);
  const sectionMultiSelectRef = useRef();
  // const [typeForEachRow, setTypeForEachRow] = useState("");
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [allSectionsUsedForFill, setAllSectionsUsedForFill] = useState([]);
  const [allSectionsUsedForVerify, setAllSectionsUsedForVerify] = useState([]);
  const [openNewFlowSaveModal, setOpenNewFlowSaveModal] = useState({
    status: false,
    title: "",
  });
  const spaceUsers = useSelector((state) => state.spaceUsers);

  const initialValue = [
    {
      name: "Level-1",
      headingEditMode: false,
      dataGridRef: createRef(),
      gridDataSource: [],
      subBtnTxt: "Submit",
      subBtnEditMode: false,
      allowedUsersToSubmit: [],
      rejectBtnTxt: "Reject",
      rejectBtnEditMode: false,
      allowedUsersToReject: [],
    },
    {
      name: "Post-submission",
      headingEditMode: false,
      dataGridRef: createRef(),
      gridDataSource: [],
      rejectBtnTxt: "Reject",
      rejectBtnEditMode: false,
      approveBtnTxt: "Approve",
      approveBtnEditMode: false,
      allowedUsersToApprove: [],
      allowedUsersToReject: [],
    },
  ];
  const [formFlowLevels, setFormFlowLevels] = useState([]);

  useEffect(() => {
    let allVerifySectionsUsed = [];
    let allFillSectionsUsed = [];

    formFlowLevels.map((level) => {
      level.gridDataSource.map((data) => {
        if (data.type == "Verify") {
          allVerifySectionsUsed = [
            ...allVerifySectionsUsed,
            ...data.sections.map((section) => section._id),
          ];
        }
        if (data.type == "Fill") {
          allFillSectionsUsed = [
            ...allFillSectionsUsed,
            ...data.sections.map((section) => section._id),
          ];
        }
      });
    });
    setAllSectionsUsedForFill(() => [...allFillSectionsUsed]);
    setAllSectionsUsedForVerify(() => [...allVerifySectionsUsed]);
  }, [formFlowLevels]);

  useEffect(() => {
    if (
      !selectedFormFlow &&
      selectedForm &&
      selectedForm.template &&
      selectedForm.template.length
    ) {
      const usersData = spaceUsers.map((user) => ({
        id: user.id,
        name: `${user.fullName}`,
        profilePic: user.profilePic,
      }));
      const level_0_DataSource = [
        {
          type: "Fill",
          sections: [
            ...selectedForm.template.map((section) => ({
              _id: section._id,
              sectionHeading: section.sectionHeading,
            })),
          ],
          users: [...usersData],
        },
      ];
      const postSubDataSource = [
        {
          type: "Verify",
          sections: [
            ...selectedForm.template.map((section) => ({
              _id: section._id,
              sectionHeading: section.sectionHeading,
            })),
          ],
          users: [...usersData],
        },
      ];

      const gridData = [...initialValue];
      gridData[0].gridDataSource = level_0_DataSource;
      gridData[0].allowedUsersToSubmit = [...usersData];
      gridData[0].allowedUsersToReject = [...usersData];

      gridData[initialValue.length - 1].gridDataSource = postSubDataSource;
      gridData[initialValue.length - 1].allowedUsersToReject = [...usersData];
      gridData[initialValue.length - 1].allowedUsersToApprove = [...usersData];

      setFormFlowLevels(gridData);
    } else {
      const formFlow = selectedFormFlow.map((level) => ({
        ...level,
        dataGridRef: createRef(),
        rejectBtnEditMode: false,
        approveBtnEditMode: false,
        headingEditMode: false,
        subBtnEditMode: false,
      }));
      setFormFlowLevels(formFlow);
    }
  }, []);

  const inPlaceEditorCreated = (ref) => {
    ref.current.enableEditMode = true;
  };

  const handleLevelSelect = (args, index) => {
    if (args.checked) {
      setSectionsSelectedToDelete((prev) => [...prev, index]);
    } else {
      const selectedSections = [...sectionsSelectedToDelete];
      const indexToRemove = selectedSections.indexOf(index);
      selectedSections.splice(indexToRemove, 1);
      setSectionsSelectedToDelete(selectedSections);
    }
  };

  const handleEditHeaderCancelClick = (index) => {
    const data = [...formFlowLevels];
    data[index] = { ...data[index], headingEditMode: false };
    setFormFlowLevels(() => data);
  };
  const saveNewHeaderName = (args, index) => {
    const data = [...formFlowLevels];
    data[index] = {
      ...data[index],
      name: args.value.substring(0, 100),
      headingEditMode: false,
    };
    setFormFlowLevels(() => data);
  };

  const handleHeaderEditClick = (header, index) => {
    if (!openFormFlowInViewMode) {
      const data = [...formFlowLevels];
      data[index] = { ...data[index], headingEditMode: true };
      setFormFlowLevels(() => data);
    }
  };

  const handleAddSection = (event, args) => {
    setSectionsDataForEachGridRow((prev) => [
      ...prev,
      {
        _id: event.itemData._id,
        sectionHeading: event.itemData.sectionHeading,
      },
    ]);
  };

  const handleRemoveSection = (event, args) => {
    setSectionsDataForEachGridRow((prev) =>
      prev.filter((section) => section._id != event.itemData._id),
    );
  };

  const actionTemplate = (args, header) => {
    if (openFormFlowInViewMode) {
      return "";
    }

    return (
      <div
        onClick={(e) => e.stopPropagation()}
        className="flex gap-3 items-center justify-center"
      >
        <span>
          <IconComponent
            onClick={(event) => handleDeleteRowClick(args, header)}
            name="delete"
            cssClass="cursor-pointer"
          />
        </span>
      </div>
    );
  };
  const errorToast = () => {
    dispatch(
      showToastNotification({
        content: "All three fields are mandatory",
        type: TOAST_TYPE_ERROR,
      }),
    );
  };

  const sectionTemplate = (args) => {
    const sectionName = args?.sections?.map(
      (section) => section.sectionHeading,
    );
    return (
      <ToolTipComponent
        showTipPointer
        position="TopCenter"
        content={sectionName?.join()}
      >
        <div>{sectionName?.join()}</div>
      </ToolTipComponent>
    );
  };
  const usersTemplate = (args) => {
    const userName = args.users?.map((user) => user.name);
    return (
      <ToolTipComponent
        showTipPointer
        position="TopCenter"
        content={userName?.join()}
      >
        <div>{userName?.join()}</div>
      </ToolTipComponent>
    );
  };

  const handleCreateInspectionClick = async () => {
    setShowFormFlowCreationModal({
      status: false,
      category: selectedForm.resourceType,
    });
    if (
      setChangeOrdeState &&
      selectedForm.resourceType == formCategories.changeOrder
    ) {
      setChangeOrdeState({ isEnabled: false });
    }
    handleCreateClick(isUpdateOpen, formFlowLevels);
  };

  const userDropDownForActionField = (actionBtnType, header, levelIndex) => {
    let value = [];
    if (actionBtnType == "submit") {
      value = header.allowedUsersToSubmit.map((data) => data.id);
    }
    if (actionBtnType == "approve") {
      value = header.allowedUsersToApprove.map((data) => data.id);
    }
    if (actionBtnType == "reject") {
      value = header.allowedUsersToReject.map((data) => data.id);
    }
    return (
      <MultiSelectComponent
        placeholder="select user"
        fields={{ text: "fullName", value: "id" }}
        dataSource={spaceUsers}
        value={value}
        enabled={!openFormFlowInViewMode}
        select={(event) =>
          selectUserForAction(event, actionBtnType, header, levelIndex)
        }
        removed={(event) =>
          removeUserForAction(event, actionBtnType, header, levelIndex)
        }
        widthInPercentage="90%"
      />
    );
  };

  const getActionButton = (btnType, btnText, levelIndex) => (
    <ButtonComponent
      disabled={openFormFlowInViewMode}
      onClick={() => handleEditBtnText(btnType, levelIndex)}
      buttonType="backgroundButton"
    >
      <span className="flex items-center gap-2">
        <span>{btnText.substring(0, 50)}</span>
        {!openFormFlowInViewMode && (
          <span className="h-4 w-4">
            <IconComponent name="editProfile" />
          </span>
        )}
      </span>
    </ButtonComponent>
  );

  const selectUserForAction = (event, actionBtnType, header, levelIndex) => {
    const allLevels = [...formFlowLevels];
    if (actionBtnType == "submit") {
      allLevels[levelIndex].allowedUsersToSubmit = [
        ...allLevels[levelIndex].allowedUsersToSubmit,
        {
          id: event.itemData.id,
          name: `${event.itemData.fullName}`,
          profilePic: event.itemData.profilePic,
        },
      ];
    }
    if (actionBtnType == "approve") {
      allLevels[levelIndex].allowedUsersToApprove = [
        ...allLevels[levelIndex].allowedUsersToApprove,
        {
          id: event.itemData.id,
          name: `${event.itemData.fullName}`,
          profilePic: event.itemData.profilePic,
        },
      ];
    }
    if (actionBtnType == "reject") {
      allLevels[levelIndex].allowedUsersToReject = [
        ...allLevels[levelIndex].allowedUsersToReject,
        {
          id: event.itemData.id,
          name: `${event.itemData.fullName}`,
          profilePic: event.itemData.profilePic,
        },
      ];
    }
    setFormFlowLevels(() => allLevels);
  };

  const removeUserForAction = (event, actionBtnType, header, levelIndex) => {
    const allLevels = [...formFlowLevels];
    if (actionBtnType == "submit") {
      allLevels[levelIndex].allowedUsersToSubmit = [
        ...allLevels[levelIndex].allowedUsersToSubmit.filter(
          (user) => user.id != event.itemData.id,
        ),
      ];
    }
    if (actionBtnType == "approve") {
      allLevels[levelIndex].allowedUsersToApprove = [
        ...allLevels[levelIndex].allowedUsersToApprove.filter(
          (user) => user.id != event.itemData.id,
        ),
      ];
    }
    if (actionBtnType == "reject") {
      allLevels[levelIndex].allowedUsersToReject = [
        ...allLevels[levelIndex].allowedUsersToReject.filter(
          (user) => user.id != event.itemData.id,
        ),
      ];
    }
    setFormFlowLevels(() => allLevels);
  };

  const saveNewBtnName = (args, btnType, levelIndex) => {
    const allLevels = [...formFlowLevels];
    if (btnType == "submit") {
      allLevels[levelIndex].subBtnEditMode = false;
      allLevels[levelIndex].subBtnTxt = args.value;
    }
    if (btnType == "approve") {
      allLevels[levelIndex].approveBtnEditMode = false;
      allLevels[levelIndex].approveBtnTxt = args.value;
    }
    if (btnType == "reject") {
      allLevels[levelIndex].rejectBtnEditMode = false;
      allLevels[levelIndex].rejectBtnTxt = args.value;
    }
    setFormFlowLevels(() => allLevels);
  };

  const cancelBtnNameChange = (btnType, levelIndex) => {
    const allLevels = [...formFlowLevels];
    if (btnType == "submit") {
      allLevels[levelIndex].subBtnEditMode = false;
    }
    if (btnType == "approve") {
      allLevels[levelIndex].approveBtnEditMode = false;
    }
    if (btnType == "reject") {
      allLevels[levelIndex].rejectBtnEditMode = false;
    }
    setFormFlowLevels(() => allLevels);
  };

  const actionBtnEdit = (btnType, btnTxt, levelIndex) => {
    let ref;
    if (btnType == "approve") {
      ref = approveBtnEditorRef;
    } else if (btnType == "submit") {
      ref = submitBtnEditorRef;
    } else {
      ref = rejectBtnEditorRef;
    }
    return (
      <InPlaceEditorComponent
        mode="Inline"
        ref={ref}
        created={() => inPlaceEditorCreated(ref)}
        value={btnTxt}
        actionSuccess={(args) => saveNewBtnName(args, btnType, levelIndex)}
        cancelClick={() => cancelBtnNameChange(btnType, levelIndex)}
        model={{ placeholder: "Type Here" }}
        type="Text"
        validationRules={{ Text: { required: true, maxLength: 50 } }}
        name="Text"
      />
    );
  };
  const handleEditBtnText = (btnType, levelIndex) => {
    const allLevels = [...formFlowLevels];
    if (btnType == "submit") {
      allLevels[levelIndex].subBtnEditMode = true;
    }
    if (btnType == "approve") {
      allLevels[levelIndex].approveBtnEditMode = true;
    }
    if (btnType == "reject") {
      allLevels[levelIndex].rejectBtnEditMode = true;
    }
    setFormFlowLevels(() => allLevels);
  };

  const handleTypeChange = (value, args, index) => {
    // setTypeForEachRow(() => value);
    setSectionsDataForEachGridRow(() => []);
    sectionMultiSelectRef?.current?.clear();
    let excludeSections = [];
    let allVerifySectionsUsed = [];
    let allFillSectionsUsed = [];
    let allFillSectionsUsedInPreviousLevel = [];

    formFlowLevels.map((level, levelIndex) => {
      level.gridDataSource.map((data) => {
        if (data.type == "Verify") {
          allVerifySectionsUsed = [
            ...allVerifySectionsUsed,
            ...data.sections.map((section) => section._id),
          ];
        }
        if (data.type == "Fill") {
          allFillSectionsUsed = [
            ...allFillSectionsUsed,
            ...data.sections.map((section) => section._id),
          ];
        }
        if (index > levelIndex && value == "Modify" && data.type == "Fill") {
          allFillSectionsUsedInPreviousLevel = [
            ...allFillSectionsUsedInPreviousLevel,
            ...data.sections.map((section) => section),
          ];
        }
      });
    });
    if (value == "Fill") {
      excludeSections = allFillSectionsUsed;
    }
    if (value == "Verify") {
      excludeSections = allVerifySectionsUsed;
    }
    let dataSource = [
      ...selectedForm.template.filter(
        (section) => !excludeSections.includes(section._id),
      ),
    ];

    if (value == "Modify") {
      dataSource = [...allFillSectionsUsedInPreviousLevel];
    }

    sectionMultiSelectRef.current.dataSource = dataSource;
  };

  const handleAddUsers = (event, args) => {
    setUsersDataForEachRow((prev) => [
      ...prev,
      {
        id: event.itemData.id,
        name: `${event.itemData.fullName}`,
        profilePic: event.itemData.profilePic,
      },
    ]);
  };

  const handleRemoveUser = (event, args) => {
    setUsersDataForEachRow((prev) =>
      prev.filter((user) => user.id != event.itemData.id),
    );
  };

  const usersEditTemplate = (args) => {
    const userIds = args.users?.map((user) => user.id);

    return (
      <div>
        <MultiSelectComponent
          dataSource={spaceUsers}
          value={userIds}
          fields={{ text: "fullName", value: "id" }}
          mode="Box"
          placeholder="Add Users"
          select={(event) => handleAddUsers(event, args)}
          removed={handleRemoveUser}
        />
      </div>
    );
  };

  const handleDeleteRowClick = (args, header) => {
    const currentGridIndex = header.dataGridRef.current.id;
    const data = [...formFlowLevels];
    const dataSource = [...data[currentGridIndex].gridDataSource];
    dataSource.splice(args.index, 1);
    data[currentGridIndex].gridDataSource = [...dataSource];
    setFormFlowLevels(() => [...data]);
    setAllSectionsUsedForFill(() => []);
    setAllSectionsUsedForVerify(() => []);
  };

  // const dropDownDataBound = (value) => {
  //   setTypeForEachRow(() => value);
  // };

  const typeTemplate = (args, index) => {
    let value = args.type || "Fill";
    let dataSource = preSubmissionTypeDataSource;
    let showVerify = false;
    if (index == formFlowLevels.length - 1) {
      dataSource = postSubmissionTypeDataSource;
      value = args.type || "Verify";
      showVerify = true;
    }
    return (
      <div>
        <DropDownListComponent
          name="type"
          placeholder="Select Type"
          value={value}
          dataSource={dataSource}
          // dataBound={() => dropDownDataBound(value)}
          onChange={(event) => handleTypeChange(event.value, args, index)}
          fields={{ text: "name", value: "value" }}
          buttonType={BUTTON_TYPES.DROPDOWN_BACKGROUND_BUTTON}
        />
        {/* <Select
          value={value}

          onChange={(event) => handleTypeChange(event.target.value, args, index)}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value={"Fill"}>Fill</MenuItem>
          <MenuItem value={"Modify"}>Modify</MenuItem>
          {showVerify && <MenuItem value={"Verify"}>Verify</MenuItem>}
        </Select> */}
      </div>
    );
  };

  const sectionEditTemplate = (args, index) => {
    let sections = [];
    if (args?.sections) {
      sections = [...args.sections];
    }
    const sectionIds = sections.map((section) => section._id);
    let excludeSections = [];
    let allVerifySectionsUsed = [];
    let allFillSectionsUsed = [];

    formFlowLevels.map((level, levelIndex) => {
      level.gridDataSource.map((data) => {
        if (data.type == "Verify") {
          allVerifySectionsUsed = [
            ...allVerifySectionsUsed,
            ...data.sections.map((section) => section._id),
          ];
        }
        if (data.type == "Fill") {
          allFillSectionsUsed = [
            ...allFillSectionsUsed,
            ...data.sections.map((section) => section._id),
          ];
        }
      });
    });
    if (index !== formFlowLevels.length - 1) {
      excludeSections = allFillSectionsUsed;
    } else {
      excludeSections = allVerifySectionsUsed;
    }
    if (args.type == "Fill") {
      excludeSections = allFillSectionsUsed;
    }
    if (args.type == "Verify") {
      excludeSections = allVerifySectionsUsed;
    }
    if (args.type == "Modify") {
      excludeSections = [];
    }
    return (
      <div>
        <MultiSelectComponent
          ref={sectionMultiSelectRef}
          dataSource={[
            ...sections,
            ...selectedForm.template.filter(
              (section) => !excludeSections.includes(section._id),
            ),
          ]}
          fields={{ text: "sectionHeading", value: "_id" }}
          value={sectionIds}
          enabled={!openFormFlowInViewMode}
          mode="Box"
          placeholder="Add sections"
          select={(event) => handleAddSection(event, args)}
          removed={(event) => handleRemoveSection(event, args)}
        />
      </div>
    );
  };

  const toolBarClickHandler = (args, header, index) => {
    const gridId = header.dataGridRef.current.id;
    if (args.item.properties.id == `${gridId}_add`) {
      header.dataGridRef.current.editSettings.newRowPosition = "Bottom";
      // if (index != formFlowLevels.length - 1) {
      //   setTypeForEachRow('Fill');
      // }
      // if (index == formFlowLevels.length - 1) {
      //   setTypeForEachRow('Verify');
      // }
    }
    if (args.item.properties.id == `${gridId}_cancel`) {
      // setTypeForEachRow("");
      setSectionsDataForEachGridRow([]);
      setUsersDataForEachRow([]);
    }
  };

  const toolBarItems = ["Add", "Update", "Cancel"];

  const actionBegin = (args, header) => {
    let index = header.gridDataSource.length;
    if (args.requestType == "beginEdit") {
      // setTypeForEachRow(() => args.rowData.type);
      setSectionsDataForEachGridRow(() => [...args.rowData.sections]);
      setUsersDataForEachRow(() => [...args.rowData.users]);
      if (args.type == "actionBegin") {
        setIsUpdateOpen(true);
      }
    }
    if (args.requestType == "cancel" || args.requestType == "save") {
      setIsUpdateOpen(false);
    }
    if (args.action == "edit") {
      index = args.rowIndex;
    }
    if (
      (args.action == "add" || args.action == "edit") &&
      args.requestType == "save"
    ) {
      if (
        !args.data.type ||
        usersDataForEachRow.length == 0 ||
        sectionsDataForEachGridRow.length == 0
      ) {
        errorToast();
        args.cancel = true;
      } else if (
        args.data.type == undefined ||
        usersDataForEachRow == undefined ||
        sectionsDataForEachGridRow == undefined
      ) {
        args.cancel = true;
      } else {
        const currentGridIndex = header.dataGridRef.current.id;
        const data = [...formFlowLevels];
        const dataSource = [...data[currentGridIndex].gridDataSource];
        dataSource[index] = {
          ...dataSource[index],
          users: [...usersDataForEachRow],
          sections: [...sectionsDataForEachGridRow],
          type: args.data.type,
        };
        data[currentGridIndex].gridDataSource = [...dataSource];
        // setAllSectionsUsedForFill([]);
        // setAllSectionsUsedForVerify([]);
        header.dataGridRef.current.dataSource = dataSource;
        header.dataGridRef.current.closeEdit();
        // setTypeForEachRow("");
        setSectionsDataForEachGridRow([]);
        setUsersDataForEachRow([]);
        setFormFlowLevels([...data]);
      }
    }
  };

  const gridEditSettings = {
    allowAdding: !openFormFlowInViewMode,
    allowEditing: !openFormFlowInViewMode,
    mode: "Normal",
  };

  const getGridComponent = (header, index) => (
    <div>
      <GridComponent
        ref={header.dataGridRef}
        toolbar={toolBarItems}
        id={`${index}`}
        dataSource={header.gridDataSource}
        rowHeight={40}
        height="100%"
        actionBegin={(args) => actionBegin(args, header)}
        toolbarClick={(args) => toolBarClickHandler(args, header, index)}
        allowResizing
        editSettings={gridEditSettings}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="type"
            headerText="Type"
            width="120"
            textAlign="Left"
            editTemplate={(args) => typeTemplate(args, index)}
          />
          <ColumnDirective
            field="sections"
            headerText="Sections"
            width="120"
            textAlign="Left"
            editTemplate={(args) => sectionEditTemplate(args, index)}
            template={(args) => sectionTemplate(args)}
          />
          <ColumnDirective
            field="users"
            headerText="Users"
            width="120"
            textAlign="Left"
            editTemplate={usersEditTemplate}
            template={(args) => usersTemplate(args)}
          />
          {!openFormFlowInViewMode && (
            <ColumnDirective
              field="action"
              headerText="Actions"
              width="60"
              allowAdding={false}
              textAlign="Center"
              allowEditing={false}
              template={(args) => actionTemplate(args, header)}
            />
          )}
        </ColumnsDirective>
        <Inject services={[Edit]} />
      </GridComponent>
    </div>
  );

  function AccordionHeader(header, index) {
    return header.headingEditMode ? (
      <span onClick={(e) => e.stopPropagation()}>
        <InPlaceEditorComponent
          ref={inPlaceEditorRef}
          mode="Inline"
          created={() => inPlaceEditorCreated(inPlaceEditorRef)}
          value={header.name}
          actionSuccess={(args) => saveNewHeaderName(args, index)}
          cancelClick={() => handleEditHeaderCancelClick(index)}
          model={{ placeholder: "Type Here" }}
          type="Text"
          validationRules={{ Text: { required: true, maxLength: 100 } }}
          name="Text"
        />
      </span>
    ) : (
      <div className="flex border-b pb-3 border-button_light w-full gap-1 items-center">
        {index != 0 &&
          index != formFlowLevels.length - 1 &&
          !openFormFlowInViewMode && (
            <span onClick={(e) => e.stopPropagation()}>
              <CheckboxComponent
                disabled={openFormFlowInViewMode}
                change={(args) => handleLevelSelect(args, index)}
                checked={sectionsSelectedToDelete.includes(index)}
              />
            </span>
          )}
        <span className="font-semibold text-lg w-fit whitespace-nowrap overflow-hidden text-ellipsis">
          {header.name}
        </span>
        {!openFormFlowInViewMode && (
          <span onClick={(e) => e.stopPropagation()}>
            <IconComponent
              onClick={() => handleHeaderEditClick(header, index)}
              name="editProfile"
              cssClass="cursor-pointer"
            />
          </span>
        )}
      </div>
    );
  }

  const accordianContent = (header, index) => (
    <div className="flex flex-col gap-1">
      <div className="flex flex-col gap-4">
        <span className="font-semibold">Steps</span>
        <span>{getGridComponent(header, index)}</span>
      </div>
      <div className="flex flex-col gap-1">
        <span className="font-semibold">Action</span>
        {index != formFlowLevels.length - 1 ? (
          <div className="flex flex-col w-full gap-1">
            <div className="flex gap-3 items-center">
              <span className="w-36">On Click of</span>
              <span>
                {header.subBtnEditMode
                  ? actionBtnEdit("submit", header.subBtnTxt, index)
                  : getActionButton("submit", header.subBtnTxt, index)}
              </span>
              <span>by</span>
              <span className="w-11/12">
                {userDropDownForActionField("submit", header, index)}
              </span>
              <span className="w-96">Proceed for form submission</span>
            </div>
            <div className="flex gap-3">
              <span className="w-36">On Click of</span>
              <span>
                {header.rejectBtnEditMode
                  ? actionBtnEdit("reject", header.rejectBtnTxt, index)
                  : getActionButton("reject", header.rejectBtnTxt, index)}
              </span>
              <span>by</span>
              <span className="w-11/12">
                {userDropDownForActionField("reject", header, index)}
              </span>
              <span className="w-64">Form gets Rejected</span>
            </div>
          </div>
        ) : (
          <div className="flex flex-col w-full gap-1">
            <div className="flex gap-3 items-center">
              <span className="w-36">On Click of</span>
              <span>
                {header.approveBtnEditMode
                  ? actionBtnEdit("approve", header.approveBtnTxt, index)
                  : getActionButton("approve", header.approveBtnTxt, index)}
              </span>
              <span>by</span>
              <span className="w-11/12">
                {userDropDownForActionField("approve", header, index)}
              </span>
              <span className="w-64">Form gets Approved</span>
            </div>
            <div className="flex gap-3">
              <span className="w-36">On Click of</span>
              <span>
                {header.rejectBtnEditMode
                  ? actionBtnEdit("reject", header.rejectBtnTxt, index)
                  : getActionButton("reject", header.rejectBtnTxt, index)}
              </span>
              <span>by</span>
              <span className="w-11/12">
                {userDropDownForActionField("reject", header, index)}
              </span>
              <span className="w-64">Form gets Rejected</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  const handleAddLevelClick = () => {
    const currentLevels = formFlowLevels;
    const newLevel = {
      name: `Level-${formFlowLevels.length}`,
      headingEditMode: false,
      dataGridRef: createRef(null),
      gridDataSource: [],
      allowedUsersToSubmit: [],
      subBtnTxt: "Submit",
      subBtnEditMode: false,
      rejectBtnTxt: "Reject",
      rejectBtnEditMode: false,
      allowedUsersToReject: [],
    };
    const lastLevel = currentLevels.pop();
    setFormFlowLevels(() => [...currentLevels, newLevel, lastLevel]);
    // setTotalLevels((prev) => prev + 1);
  };

  const handleDeleteLevelClick = () => {
    const notDeletedLevels = [];
    formFlowLevels.map((level, index) => {
      if (!sectionsSelectedToDelete.includes(index)) {
        notDeletedLevels.push(level);
      }
    });
    setSectionsSelectedToDelete([]);
    setFormFlowLevels(() => notDeletedLevels);
  };

  const handleCancelClick = () => {
    handleCancelFormAttachment();
  };

  const handleSaveFormFlow = async () => {
    const updatedFormFlow = formFlowLevels.map((level) => {
      delete level.dataGridRef;
      delete level.subBtnEditMode;
      delete level.headingEditMode;
      delete level.rejectBtnEditMode;
      delete level.approveBtnEditMode;

      return level;
    });
    const data = {
      _id: selectedRow._id,
      data: {
        formFlow: updatedFormFlow,
        projectId,
        "selectedForm.template": selectedForm.template,
        "selectedForm.name": selectedForm.name,
        "selectedForm.description": selectedForm.description,
      },
      action: "update",
    };
    const res = await formFlowController(data);
    if (res.data.status) {
      handleCancelFormAttachment();
      dispatch(
        showToastNotification({
          content: `Form flow "${selectedForm.name}" saved successfully`,
          type: TOAST_TYPE_SUCCESS,
        }),
      );
      refreshGrid();
    } else {
      dispatch(
        showToastNotification({
          content: "Something went wrong try again",
          type: TOAST_TYPE_ERROR,
        }),
      );
    }
  };

  const getBtnDisableStatus = () => {
    let disabledStatus = false;
    const fillSections = new Set(allSectionsUsedForFill);
    const verifySections = new Set(allSectionsUsedForVerify);
    if (
      fillSections.size < selectedForm?.template?.length ||
      verifySections.size < selectedForm?.template?.length
    ) {
      disabledStatus = true;
    }
    formFlowLevels.map((eachLevel) => {
      if (
        eachLevel.allowedUsersToApprove &&
        eachLevel.allowedUsersToApprove.length == 0
      ) {
        disabledStatus = true;
      }
      if (
        eachLevel.allowedUsersToReject &&
        eachLevel.allowedUsersToReject.length == 0
      ) {
        disabledStatus = true;
      }
      if (
        eachLevel.allowedUsersToSubmit &&
        eachLevel.allowedUsersToSubmit.length == 0
      ) {
        disabledStatus = true;
      }
    });
    return disabledStatus;
  };

  const footerContent = () => {
    const disabledStatus = getBtnDisableStatus();
    return !openFormFlowInViewMode ? (
      <div className="m-6 flex gap-4">
        {source === "SPACE_SETTINGS" ? (
          <ButtonComponent
            disabled={disabledStatus}
            buttonType={BUTTON_TYPES.GRADIENT_BUTTON}
            onClick={handleSaveFormFlow}
          >
            Save
          </ButtonComponent>
        ) : (
          <ButtonComponent
            disabled={disabledStatus}
            buttonType={BUTTON_TYPES.GRADIENT_BUTTON}
            onClick={handleCreateInspectionClick}
          >
            Create
          </ButtonComponent>
        )}
        <ButtonComponent
          buttonType={BUTTON_TYPES.BACKGROUND_BUTTON}
          onClick={handleCancelClick}
        >
          Cancel
        </ButtonComponent>
      </div>
    ) : (
      ""
    );
  };

  const handleSaveFlowClick = async () => {
    const updatedFormFlow = formFlowLevels.map((level) => {
      delete level.dataGridRef;
      delete level.subBtnEditMode;
      delete level.headingEditMode;
      delete level.rejectBtnEditMode;
      delete level.approveBtnEditMode;

      return level;
    });
    const data = {
      data: {
        name: openNewFlowSaveModal.title,
        selectedForm,
        formCategory: selectedForm.resourceType,
        formFlow: updatedFormFlow,
        projectId,
        spaceId,
      },
      action: "save",
    };
    const res = await formFlowController(data);
    if (res.data.status) {
      const content = `New Form Flow "${data.data.name}" has been created successfully`;
      dispatch(
        showToastNotification({
          content,
          type: TOAST_TYPE_SUCCESS,
        }),
      );
      handleCloseModalClick();
    }
  };

  const flowFlowCreation = () => (
    <div className="flex flex-col h-full gap-4 overflow-auto w-full p-5">
      {!openFormFlowInViewMode && (
        <div className="flex flex-col gap-10">
          {source == "INSPECTION_CREATION" && (
            <div className="flex gap-4 items-center">
              <span className="font-semibold h-5 w-5 flex justify-center items-center rounded-full bg-button_light">
                4
              </span>
              <span className="flex gap-3 items-center">
                <span>
                  You are creating a new form flow which will only apply for the
                  current case. You can also save this for re-use if you’d like.
                </span>
                <span>
                  <ButtonComponent
                    disabled={getBtnDisableStatus()}
                    onClick={() =>
                      setOpenNewFlowSaveModal({ status: true, title: "" })
                    }
                    buttonType={BUTTON_TYPES.BACKGROUND_BUTTON}
                  >
                    Save Form Flow
                  </ButtonComponent>
                </span>
              </span>
            </div>
          )}
          <div className="flex pl-3 gap-4">
            <span>
              <ButtonComponent
                disabled={openFormFlowInViewMode}
                onClick={handleAddLevelClick}
                buttonType="backgroundButton"
              >
                <span className="flex items-center gap-1">
                  <PlusIcon
                    color={
                      openFormFlowInViewMode
                        ? "var(--system-colour-37)"
                        : "var(--primary-text)"
                    }
                  />
                  <span>Add Level</span>
                </span>
              </ButtonComponent>
            </span>
            <span>
              <ButtonComponent
                disabled={
                  sectionsSelectedToDelete.length == 0 || openFormFlowInViewMode
                }
                onClick={handleDeleteLevelClick}
                buttonType="backgroundButton"
              >
                <span className="flex items-center gap-1">
                  <DeleteIcon
                    color={
                      sectionsSelectedToDelete.length == 0 ||
                      openFormFlowInViewMode
                        ? "var(--system-colour-37)"
                        : "var(--primary-text)"
                    }
                  />
                  <span>Delete level</span>
                </span>
              </ButtonComponent>
            </span>
          </div>
        </div>
      )}
      <div className="flex flex-col overflow-auto h-full w-full">
        {formFlowLevels.map((header, index) => (
          <Accordion key={index} defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {AccordionHeader(header, index)}
            </AccordionSummary>
            <AccordionDetails>
              {accordianContent(header, index)}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );

  const handleCloseModalClick = () => {
    setOpenNewFlowSaveModal({ status: false, title: "" });
  };

  const flowHeader = () => (
    <div className="bg-button_light  w-full flex pt-3 pb-3 pr-6 pl-6 justify-between rounded-md">
      <div className="flex gap-3 items-center justify-center">
        <div className="flex flex-col gap-1">
          <span>Save Form Flow</span>
        </div>
      </div>
      <div className="flex cursor-pointer">
        <IconComponent name="close" onClick={handleCloseModalClick} />
      </div>
    </div>
  );
  const handleFlowNameChange = (args) => {
    setOpenNewFlowSaveModal((prev) => ({
      ...prev,
      title: args.value,
    }));
  };
  const modelContent = () => (
    <div className="p-8 flex flex-col gap-1">
      <div className="flex flex-col gap-4">
        <span>Form Flow Name</span>
        <span>
          <TextBoxComponent
            name="name"
            type="text"
            placeholder="Add form flow name"
            showClearButton
            onChange={handleFlowNameChange}
            value={openNewFlowSaveModal.title}
          />
        </span>
      </div>
    </div>
  );

  return (
    <div className="w-full h-full flex overflow-auto flex-col">
      {flowFlowCreation()}
      {footerContent()}
      {openNewFlowSaveModal.status && (
        <ModalStateDialogComponent
          allowModalPadding={false}
          footerContent={
            <div className="p-8">
              <ButtonComponent
                disabled={openNewFlowSaveModal.title.trim() == ""}
                onClick={handleSaveFlowClick}
                buttonType={BUTTON_TYPES.GRADIENT_BUTTON}
              >
                Save
              </ButtonComponent>{" "}
            </div>
          }
          width="30%"
          headerContent={flowHeader()}
          onCancel={handleCloseModalClick}
          isVisible={openNewFlowSaveModal.status}
          modelContent={modelContent()}
        />
      )}
    </div>
  );
}

export default FormFlowCreation;
