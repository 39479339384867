import { useDispatch, useSelector } from "react-redux";
import UserNameTagComponent from "../../cubeComponent/html/UserNameTagComponent";
import { useEffect } from "react";
import { fetchUserTagData } from "../../actions/setUserTagData";
import IconComponent from "../../cubeComponent/icons";

function UserCard({
  field,
  id,
  removeUserFromList = () => {},
  fieldIndex = 0,
  sectionIndex = 0,
  section = {},
  source = "",
  tableRowIndex = 0,
  showAnswersOnly,
  handleCustomFieldData,
  extraParams,
}) {
  const { userTagData } = useSelector((state) => state);
  const userData = userTagData[id]?.data || {};
  const dataToDisplay = field.dataToDisplay;
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(fetchUserTagData(id));
    }
  }, [id]);

  return (
    <div className="rounded-md border-solid bg-slate-50 border-2 h-30 p-2 border-button_light flex flex-col gap-2">
      <div className="flex gap-2 items-center justify-around">
        <span>
          <UserNameTagComponent showAvatarOnly id={id} size="LSM" isCircle />
        </span>
        <span>{userData.fullName}</span>
        {!showAnswersOnly && (
          <span
            onClick={() =>
              removeUserFromList({
                fieldIndex,
                sectionIndex,
                user: userData,
                section,
                eachField: field,
                source,
                tableRowIndex,
                handleCustomFieldData,
                extraParams,
              })
            }
          >
            <IconComponent cssClass="cursor-pointer" name="close" />
          </span>
        )}
      </div>
      {dataToDisplay.includes("Email") && <div>{userData.email}</div>}
      {dataToDisplay.includes("Contact No") && <div>{userData.contactNo}</div>}
      {dataToDisplay.includes("Designation") && (
        <div>{userData.designation}</div>
      )}
      {dataToDisplay.includes("Org") && <div>{userData.companyName}</div>}
      {dataToDisplay.includes("Location") && <div>{userData.userLocation}</div>}
      {dataToDisplay.includes("user ID") && <div>{userData.userId ?? ""}</div>}
      {userData.signature && dataToDisplay.includes("Signature") && (
        <div>
          <img
            className="w-16 h-16"
            src={`${process.env.REACT_APP_CUBE_BACKEND}/fileSystemServer/getSignDocs/${userData.signature}`}
          />
        </div>
      )}
    </div>
  );
}
export default UserCard;
