import React from "react";
import ChangeColor from "../../HoopsViewer/ToolbarViewer/ChangeColor";

function Clash({ color, onClick }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <linearGradient id="Clash" gradientTransform="rotate(45)">
        <stop offset="38%" stopColor={color} />
        <stop offset="92%" stopColor={ChangeColor(color)} />
      </linearGradient>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.6 13.4V3.6H13.4V9H13.0909C10.5136 9 8.38364 10.9152 8.04649 13.4H3.6ZM8 15H3.27273C2.56982 15 2 14.4302 2 13.7273V3.27273C2 2.56982 2.56982 2 3.27273 2H13.7273C14.4302 2 15 2.56982 15 3.27273V9H16.9091C19.7207 9 22 11.2793 22 14.0909V16.9091C22 19.7207 19.7207 22 16.9091 22H13.0909C10.2793 22 8 19.7207 8 16.9091V15ZM15 10.6H16.9091C18.8371 10.6 20.4 12.1629 20.4 14.0909V16.9091C20.4 18.8371 18.8371 20.4 16.9091 20.4H13.0909C11.1629 20.4 9.6 18.8371 9.6 16.9091V15H13.7273C14.4302 15 15 14.4302 15 13.7273V10.6ZM13.4 10.6V13.4H9.66836C9.98899 11.803 11.3995 10.6 13.0909 10.6H13.4Z"
        fill='url("#Clash")'
      />
    </svg>
  );
}

export default Clash;
