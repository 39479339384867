export const optionsViewType = {
  LIST: "list",
  DROPDOWN: "dropDown",
  CHIP: "chip",
  CARD: "card",
};

export const fieldTypeToIconMap = {
  "single-line": "SingleLineTextBox",
  "multi-line": "MultiLineTextBox",
  "single-select": "RadioButtonIcon",
  "multi-select": "CheckBoxIcon",
  "date-time": "DateTimeIcon",
  "look-up": "LookUpIcon",
  number: "NumericIcon",
  users: "UserProfile",
  fileUpload: "upload",
  image: "image",
  url: "URL",
  default: "ErrorIcon",
};
