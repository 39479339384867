import { useEffect, useState } from "react";
import css from "./index.module.css";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import FormEntries from "./formEntries";
import FormAnalytics from "./formAnalytics";
import TasksForms from "@Components/CreateInspectionAndProgress/TaskInspectionAndProgressForm";
import { formCategoryObject } from "../PortalSetings/Configuration/FormsAndFields/constants";
import { useSelector } from "react-redux";

function Forms(props) {
  const tabItems = [
    { id: "list", name: "List" },
    { id: "entries", name: "Entries" },
    // { id: 'analytics', name: 'Analytics' },
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const url = useParams();
  const searchParams = new URLSearchParams(location.search);
  const [activeTab, setActiveTab] = useState(searchParams.get("selectedTab"));
  const [moduleTxt, setModuleTxt] = useState("");
  const customModules = useSelector((state) => state.customModules) || [];

  const changeActiveTab = (e) => {
    setActiveTab(e.target.id);
    navigate(
      `${location.pathname}?selectedTab=${e.target.id}&projectId=${url.projectId}`,
    );
  };

  useEffect(() => {
    setActiveTab(searchParams.get("selectedTab"));
    if (customModules.length) {
      const categoryObj = customModules.find(
        (module) => module.resourceType == props.formCategory,
      );
      const moduleText =
        categoryObj?.activeCell ||
        formCategoryObject[props.formCategory]?.activeCell ||
        "";
      setModuleTxt(moduleText);
    } else {
      setModuleTxt(formCategoryObject[props.formCategory]?.activeCell);
    }
  }, [customModules, props.formCategory]);

  const header = () => {
    return (
      <div className="flex-col gap-4 pb-2">
        <div className="font-semibold text-2xl">{moduleTxt}</div>
        <div>{`List of all ${moduleTxt} in this project`}</div>
      </div>
    );
  };

  const headerTabs = () => (
    <div className={`${css.upperPart} border border-button_light mb-2`}>
      {tabItems.map((item) => (
        <div className={css.tabOptions}>
          <div
            id={item.id}
            className={`${css.tabs} ${
              activeTab === `${item.id}` ? css.externalTab : ""
            }`}
            onClick={changeActiveTab}
          >
            {item.name}
          </div>
          {activeTab === `${item.id}` && <div className={css.activeTab} />}
        </div>
      ))}
    </div>
  );

  const getList = () => (
    <TasksForms
      source="project"
      formCategory={props.formCategory}
      projectId={url.projectId}
    />
  );

  const getEntries = () => <FormEntries formCategory={props.formCategory} />;

  const getAnalytics = () => (
    <FormAnalytics formCategory={props.formCategory} />
  );

  const dataGrid = () => (
    <div className="flex w-full h-full overflow-auto">
      {activeTab === "list" && getList()}
      {activeTab === "entries" && getEntries()}
      {activeTab === "analytics" && getAnalytics()}
    </div>
  );

  const content = () => (
    <div className="bg-surface_white w-full h-full overflow-auto flex flex-col gap-2">
      {headerTabs()}
      {dataGrid()}
    </div>
  );

  return (
    <div className="w-full h-full overflow-auto flex">
      <div className="w-full h-full overflow-auto flex flex-col">
        {header()}
        {content()}
      </div>
    </div>
  );
}

export default Forms;
