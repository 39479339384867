/* global PubSubHelper */
import { createContext, useContext } from "react";
import configureStore from "./store/configureStore";
import Router from "./Router";
import translationEN from "@locales/en/default.json";
import translationHi from "@locales/hi/default.json";
import translationAr from "@locales/ar/default.json";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import ProviderApp from "./ProviderApp";
import { ThemeContext } from "./ProviderApp";

export const store = configureStore();

export const PubSubContext = createContext({
  PubSubHelper,
});

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationEN },
    hi: { translation: translationHi },
    ar: { translation: translationAr },
  },
  lng: "en", // if you're using a language detector, do not define the lng option
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

function App() {
  const PubSubHelper = useContext(PubSubContext);
  const { theme } = useContext(ThemeContext);

  return (
    <ProviderApp>
      <div
        className="App"
        style={{
          opacity: 1,
          background: `var(${theme.rootBackground})`,
          color: `var(${theme.rootTextColor})`,
        }}
      >
        <Router PubSubHelper={PubSubHelper} />
      </div>
    </ProviderApp>
  );
}

export default App;
