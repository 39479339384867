import React, { useCallback, useEffect } from "react";
import { optionsViewType } from "../PortalSetings/Configuration/FormsAndFields/FormTemplate/constant.helper";
import { formSectionType, getPermissions,onURLClick } from "./constants";
import { convertDate } from "../../services/luxonHelper";
import IconComponent from "../../cubeComponent/icons";
import { BUTTON_TYPES } from "../../cubeComponent/constants/globalVariable";
import ButtonComponent from "../../cubeComponent/form/ButtonComponent";
import { PlusIcon } from "../StickySidebarViewer/assets/selectionIcons";
import ImageEditor from "../ImageEditor";
import { CircularProgress } from "@mui/material";
import _ from "lodash";
import { teamsService, locationService } from "../../services/dataManager";
import { Query } from "@syncfusion/ej2-data";
import { useParams } from "react-router-dom";
import UserNameTagComponent from "../../cubeComponent/html/UserNameTagComponent";
import UserCard from "./userCard";
import { addValidationToInputFields, getFileIcon } from "./formsHelpers";
import { BiLinkExternal } from "react-icons/bi";
import { DropDownTreeComponent } from "@syncfusion/ej2-react-dropdowns";
import MultiSelectComponent from "../../cubeComponent/form/MultiselectComponent";
import TextBoxComponent from "../../cubeComponent/form/TextBoxComponent";
import RadioButtonComponent from "../../cubeComponent/form/RadioButtonComponent";
import DropDownListComponent from "../../cubeComponent/form/DropdownListComponent";
import CheckboxComponent from "../../cubeComponent/form/CheckboxComponent";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";

const FormQuestionFields = ({
  eachField,
  fieldIndex=null,
  sectionIndex=null,
  section={},
  source=formSectionType.FORM,
  tableRowIndex = 0,
  tableRowId = null,
  formObject,
  disabled=false,
  handleCustomFieldData = null,
  showAnswersOnly = false,
  formData,
  width = "100%",
  modifySection = [],
  userDetails={},
  multiTextRows=5,
  handleInputChange,
  handleURLModalState,
  isFileUploading,
  handleFileUploadChange,
  removeUserFromList,
  saveEditedImage,
  handleRemoveUploadedFile,
  openAddUserModal,
  extraParams={}
}) => {

  const params = useParams();
  const locationQuery = new Query().addParams("projectId", params.projectId);
  const userId = userDetails.id

  const {
    _id,
    fieldType,
    answerChoices,
    placeholderText,
    lookUpAnsFieldType,
    minFieldRange,
    maxFieldRange,
    viewType = optionsViewType.LIST,
    selectionScope,
  } = eachField;

  const questionId = _id;
  const name = tableRowId ? `${tableRowId}${questionId}` : questionId;
  const { permissions } = section;
  const sectionId = section._id;

  const isCurrentUserAllowedToFill = permissions ? getPermissions({ inspectionData: formData, permissions, type: "Fill", userId }) : !disabled

  const isCurrentUserAllowedToModify = permissions ? getPermissions({ inspectionData: formData, permissions, type: "Modify", userId }) : !disabled;

  const enabled = isCurrentUserAllowedToFill || isCurrentUserAllowedToModify;

  const getLookUpDropDown = useCallback(
    ({
      selectedValue,
    }) => (
      <div>
        <DropDownTreeComponent
          enabled={enabled}
          width={width}
          showCheckBox
          placeholder={placeholderText ?? ""}
          allowMultiSelection={false}
          value={selectedValue}
          fields={{
            dataSource: locationService,
            value: "_id",
            text: "name",
            parentValue: "parentId",
            hasChildren: "hasChild",
            query: locationQuery,
          }}
          select={(args: Object) => {
            handleInputChange({
              args,
              fieldIndex,
              sectionIndex,
              eachField,
              section,
              handleCustomFieldData,
              extraParams,
              tableRowIndex,
              source,
            });
          }}
          name={name}
          showClearButton={false}
          close={() => {
            formObject.current.validate(name);
          }}
        />
      </div>
    ),
    [],
  );

  const getLookUpMultiSelect = useCallback(
    ({
      selectedValue,
    }) => (
      <div>
        <MultiSelectComponent
          enabled={enabled}
          dataSource={teamsService}
          value={selectedValue}
          fields={{ text: "name", value: "_id" }}
          width={width}
          query={locationQuery}
          placeholder={placeholderText ?? ""}
          removed={() => {
            formObject.current.validate(name);
          }}
          close={() => {
            formObject.current.validate(name);
          }}
          change={(args: any) => {
            const argsModified = {
              isInteracted: args.isInteracted,
              itemData: [
                ...args.element.ej2_instances[0].sortedData.filter((item: any) =>
                  args.value.includes(item._id),
                ),
              ],
            };
            handleInputChange({
              args: argsModified,
              fieldIndex,
              sectionIndex,
              eachField,
              handleCustomFieldData,
              extraParams,
              section,
              tableRowIndex,
              source,
            });
          }}
          name={name}
          changeOnBlur={false}
        />
      </div>
    ),
    [],
  );

  const handleFileOpen = (awsKey: string) => {
    const url = `${process.env.REACT_APP_CUBE_BACKEND}/fileSystemServer/getInspectionAttachmentFile/${awsKey}`;;
    const target = "_blank";
    return (
      <a href={url} target={target} aria-label="attachment">
        <BiLinkExternal />
      </a>
    );
  };

  const getUserCardAndChip = ({ user }) => {
    return viewType === optionsViewType.CHIP ? (
      !showAnswersOnly ? (
        <UserNameTagComponent
          id={user._id}
          handleDeleteIconClicked={() =>
            removeUserFromList({
              fieldIndex,
              sectionIndex,
              user,
              eachField,
              section,
              source,
              tableRowIndex,
              handleCustomFieldData,
              extraParams
            })
          }
        />
      ) : (
        <UserNameTagComponent id={user._id} />
      )
    ) : (
      <UserCard
        fieldIndex={fieldIndex}
        sectionIndex={sectionIndex}
        section={section}
        source={source}
        tableRowIndex={tableRowIndex}
        showAnswersOnly={showAnswersOnly}
        handleCustomFieldData={handleCustomFieldData}
        extraParams
        removeUserFromList={removeUserFromList}
        field={eachField}
        id={user._id}
      />
    );
  };

  useEffect(() => {
    if (formData) {
      addValidationToInputFields({ formData, formObject, modifySection });
    }
  }, [formData]);

  const getFieldAns = () => {
    let answer = eachField.answer ?? {};
    if (source == formSectionType.TABLE_SECTION) {
      answer = section.tableDataSource[tableRowIndex][_id] ?? {};
    }

    if (
      ["single-select", "number", "single-line", "multi-line"].includes(
        fieldType,
      )
    ) {
      return <div className="w-4/5 flex flex-wrap">{answer.value}</div>;
    }
    if (fieldType == "multi-select") {
      return answer.value?.join(",");
    }
    if (fieldType == "date-time") {
      return answer.value
        ? convertDate(Number(answer.value), "dateForTooltip")
        : "";
    }
    if (fieldType == "look-up") {
      const ansDisplay = answer.value?.map((ans) => ans.name);
      return ansDisplay?.length ? ansDisplay?.join(",") : "";
    }
    if (fieldType == "users") {
      return (
        <div className="flex flex-wrap gap-5 pt-5">
          {answer.value?.map((user: Object) =>
            getUserCardAndChip({ user }),
          )}
        </div>
      );
    }
    if (fieldType == "fileUpload") {
      return (
        <span className="flex flex-col pr-3 overflow-auto gap-4 w-full h-full">
          {answer.value?.map((data: any, index: number) => (
            <span className="flex gap-3 border-solid border-2 border-button_light w-fit pr-2 rounded-lg pl-2 items-center">
              <span>{data.awsKey && handleFileOpen(data.awsKey)}</span>
              <span className="w-14 h-10 flex object-contain">
                {getFileIcon(data.name)}
              </span>
              <span>{data.name}</span>
            </span>
          ))}
        </span>
      );
    }
    if (fieldType == "image") {
      let answerValue = eachField.answer?.value ?? [];
      if (source == formSectionType.TABLE_SECTION) {
        answerValue =
          section.tableDataSource[tableRowIndex]?.[_id]?.value ?? [];
      }
      return (
        <div className="flex flex-wrap flex-row pr-3 overflow-auto gap-4 w-full h-full">
          {answerValue?.map((data: any, index: number) => (
            <span className="w-32 h-32 border-button_light p-1 rounded flex border-2">
              <img
                src={`${process.env.REACT_APP_CUBE_BACKEND}/fileSystemServer/getInspectionAttachmentFile/${data.awsKey}`}
                alt="logo"
                width="100%"
              />
            </span>
          ))}
        </div>
      );
    }
    if (fieldType == "url") {
      return (
        <div
          className="text-gradient_color cursor-pointer underline underline-offset-2"
          onClick={() =>{
            const url =  onURLClick(_.get(answer, "value.URL", ""))
            window.open(url)
          }}
        >
          {_.get(answer, "value.displayText", "")}
        </div>
      );
    }

    return "";
  };

  const getFieldAnsComponent = () => {
    let answer = eachField.answer ?? {};
    if (source == formSectionType.TABLE_SECTION) {
      answer = section.tableDataSource[tableRowIndex]?.[_id] ?? {};
    }
    if (fieldType == "single-line") {
      return (
        <TextBoxComponent
          enabled={enabled}
          value={answer.value ?? ""}
          change={(args: Object) => {
            handleInputChange({
              args,
              fieldIndex,
              sectionIndex,
              eachField,
              handleCustomFieldData,
              extraParams,
              section,
              tableRowIndex,
              source,
            });
          }}
          placeholder={placeholderText ?? ""}
          width={width}
          name={name}
          input={(args: Object) => {
            formObject.current.validate(name);
          }}
        />
      );
    }
    if (fieldType == "multi-line") {
      return (
        <div>
          <textarea
            style={{
              border: "1px solid #19204880",
              outline: "none",
              width: source == formSectionType.FORM ? "80%" : "100%",
              padding: "0.42rem 0 0.42rem 0.5rem",
              height: "100%",
              color: "black",
            }}
            type="textarea"
            disabled={!enabled}
            rows={multiTextRows}
            name={name}
            placeholder={placeholderText ?? ""}
            defaultValue={answer.value ?? ""}
            onBlur={(args: Object) => {
              handleInputChange({
                args,
                fieldIndex,
                sectionIndex,
                eachField,
                handleCustomFieldData,
                extraParams,
                section,
                tableRowIndex,
                source,
              });
            }}
            onChange={(e) => {
              formObject.current.validate(name);
            }}
          />
        </div>
      );
    }
    if (fieldType == "single-select") {
      const dropDownDataSource = answerChoices.map((choices) => ({
        name: choices,
        value: choices,
      }));
      return viewType == optionsViewType.LIST ? (
        <div className="flex flex-col gap-3">
          {answerChoices.length > 0 &&
            answerChoices.map((choice: string, index: number) => (
              <RadioButtonComponent
                key={index}
                disabled={!enabled}
                change={(args: Object) =>
                  handleInputChange({
                    args,
                    fieldIndex,
                    sectionIndex,
                    eachField,
                    section,
                    handleCustomFieldData,
                    extraParams,
                    tableRowIndex,
                    source,
                  })
                }
                checked={answer.value == choice}
                label={choice}
                name={name}
              />
            ))}
        </div>
      ) : (
        <DropDownListComponent
          enabled={enabled}
          //   width={source == formSectionType.FORM ? "70%" : "100%"}
          width={width}
          value={answer.value}
          onChange={(args: Object) => {
            handleInputChange({
              args,
              fieldIndex,
              sectionIndex,
              eachField,
              section,
              handleCustomFieldData,
              extraParams,
              tableRowIndex,
              source,
            });
            formObject.current.validate(name);
          }}
          placeholder={placeholderText ?? ""}
          fields={{ text: "name", value: "value" }}
          dataSource={dropDownDataSource}
          name={name}
        />
      );
    }
    if (fieldType == "multi-select") {
      const dropDownDataSource = answerChoices.map((choices) => ({
        name: choices,
        value: choices,
      }));
      return viewType == optionsViewType.LIST ? (
        <div className="flex flex-col gap-3">
          {answerChoices?.length > 0 &&
            answerChoices.map((choice: string, index: number) => (
              <CheckboxComponent
                key={index}
                borderColor={
                  !enabled
                    ? "var(--border-gray)"
                    : ""
                }
                disabled={!enabled}
                checked={
                  typeof answer.value == "object" &&
                  answer.value.includes(choice)
                }
                change={(args: Object) => {
                  handleInputChange({
                    args,
                    fieldIndex,
                    sectionIndex,
                    eachField,
                    section,
                    handleCustomFieldData,
                    extraParams,
                    tableRowIndex,
                    source,
                  });
                }}
                label={choice}
                name={name}
              />
            ))}
        </div>
      ) : (
        <MultiSelectComponent
          enabled={enabled}
          placeholder={placeholderText ?? ""}
          value={answer.value}
          fields={{ text: "name", value: "value" }}
          dataSource={dropDownDataSource}
          //   width={source == formSectionType.FORM ? "50%" : "100%"}
          width={width}
          removed={(args: Object) => {
            handleInputChange({
              args,
              fieldIndex,
              sectionIndex,
              eachField,
              section,
              handleCustomFieldData,
              extraParams,
              tableRowIndex,
              source,
            });
            formObject.current.validate(name);
          }}
          select={(args: Object) => {
            handleInputChange({
              args,
              fieldIndex,
              sectionIndex,
              eachField,
              section,
              handleCustomFieldData,
              extraParams,
              tableRowIndex,
              source,
            });
          }}
          close={() => {
            formObject.current.validate(name);
          }}
          name={name}
          changeOnBlur={false}
        />
      );
    }
    if (fieldType == "date-time") {
      const timeStamp = answer?.value ?? Date.now();
      const min = minFieldRange
        ? new Date(minFieldRange)
        : selectionScope == "futureDateTime"
          ? new Date(timeStamp)
          : undefined;

      const max = maxFieldRange
        ? new Date(maxFieldRange)
        : selectionScope == "pastDateTime"
          ? new Date(timeStamp)
          : undefined;
      return selectionScope == "currentDateTime" ? (
        enabled ? (
          <div
            className={`${answer?.value
                ? "cursor-not-allowed pointer-events-none"
                : "cursor-pointer"
              } border border-slate-400 w-1/2 h-8 flex items-center p-2`}
            onClick={(args: Object) =>
              handleInputChange({
                args,
                fieldIndex,
                sectionIndex,
                eachField,
                handleCustomFieldData,
                extraParams,
                section,
                tableRowIndex,
                source,
              })
            }
          >
            {answer?.value ? (
              convertDate(Number(answer?.value), "dateForTooltip")
            ) : (
              <span className="text-xs text-slate-500">
                Click here to set date
              </span>
            )}
          </div>
        ) : (
          <div className="cursor-not-allowed pointer-events-none border bg-gray-100 border-slate-400 w-1/2 h-8 flex items-center p-2">
            {answer?.value &&
              convertDate(Number(answer?.value), "dateForTooltip")}
          </div>
        )
      ) : (
        <div>
          <DateTimePickerComponent
            enabled={enabled}
            min={min}
            max={max}
            value={answer.value ? new Date(answer.value) : null}
            id={`datetimepicker_${_id}`}
            // width={source == formSectionType.FORM ? "50%" : "100%"}
            width={width}
            change={(args: Object) =>
              handleInputChange({
                args,
                fieldIndex,
                sectionIndex,
                eachField,
                handleCustomFieldData,
                extraParams,
                section,
                tableRowIndex,
                source,
              })
            }
            placeholder={placeholderText ?? ""}
            format="dd/MM/yyyy hh:mm:a"
            name={name}
            onChange={() => {
              formObject.current.validate(name);
            }}
          />
        </div>
      );
    }
    if (fieldType == "look-up") {
      if (lookUpAnsFieldType == "Location") {
        const selectedValue =
          (Array.isArray(answer.value) && answer.value?.map((ans) => ans.id)) ||
          [];
        return getLookUpDropDown({
          source,
          placeholderText,
          selectedValue,
          fieldIndex,
          sectionIndex,
          eachField,
          section,
          tableRowIndex,
          name,
        });
      }
      if (lookUpAnsFieldType == "Teams") {
        const selectedValue = answer.value?.map((ans: any) => ans.id);
        return getLookUpMultiSelect({
          source,
          placeholderText,
          fieldIndex,
          sectionIndex,
          eachField,
          section,
          tableRowIndex,
          selectedValue,
          name,
        });
      }
    }
    if (fieldType == "number") {
      return (
        <NumericTextBoxComponent
          format="###.##"
          disabled={!enabled}
          placeholder={placeholderText ?? "Enter Value"}
          max={maxFieldRange ? +maxFieldRange : null}
          min={minFieldRange ? +minFieldRange : 0}
          value={answer.value ?? null}
          onBlur={(args: Object) => {
            handleInputChange({
              args,
              fieldIndex,
              sectionIndex,
              eachField,
              handleCustomFieldData,
              extraParams,
              section,
              tableRowIndex,
              source,
            });
            formObject.current.validate(name);
          }}
          //   width={source == formSectionType.FORM ? "30%" : "100%"}
          width={width}
          name={name}
        />
      );
    }
    if (fieldType == "users") {
      return (
        <div>
          {(enabled) && (
            <div
              onClick={() =>
                openAddUserModal({
                  eachField,
                  fieldIndex,
                  sectionIndex,
                  section,
                  source,
                  tableRowIndex,
                  handleCustomFieldData,
                  extraParams
                })
              }
              className="bg-button_light h-8 w-14 rounded-3xl justify-center flex cursor-pointer items-center"
            >
              <IconComponent name="UserProfile" />
            </div>
          )}
          <div className="flex flex-wrap gap-5 overflow-auto pt-5">
            {answer.value?.map((user: Object) =>
              getUserCardAndChip({ user })
            )}
          </div>
        </div>
      );
    }
    if (fieldType == "fileUpload") {
      let answerValue = eachField.answer?.value ?? [];
      if (source == formSectionType.TABLE_SECTION) {
        answerValue =
          section.tableDataSource[tableRowIndex]?.[_id]?.value ?? [];
      }
      return (
        <div className="flex h-full w-full overflow-auto flex-col gap-5">
          <span>
            <input
              accept={eachField.allowedFileTypes.join(",")}
              type="file"
              id={`${questionId}${tableRowIndex}`}
              onChange={(e) =>
                handleFileUploadChange({
                  e,
                  sectionId,
                  eachField,
                  sectionIndex,
                  fieldIndex,
                  source,
                  tableRowIndex,
                  tableRowId,
                  allowedFileTypes: eachField.allowedFileTypes,
                  formData,
                  handleCustomFieldData,
                  extraParams
                })
              }
              style={{ display: "none" }}
            />
            <ButtonComponent
              onClick={() =>
                document.getElementById(`${questionId}${tableRowIndex}`).click()
              }
              disabled={!enabled}
              buttonType={BUTTON_TYPES.BACKGROUND_BUTTON}
            >
              <span className="flex gap-1 items-center">
                <PlusIcon
                  color={
                    enabled
                      ? "var(--primary-text)"
                      : "var(--system-colour-37"
                  }
                />
                <span>{placeholderText}</span>
              </span>
            </ButtonComponent>
          </span>
          <span className="flex flex-col pr-3 overflow-auto gap-4 w-full h-full">
            {answerValue?.map((data: any, index: number) => (
              <span className="flex gap-3 border-solid border-2 border-button_light w-fit pr-2 rounded-lg pl-2 items-center">
                <span>{data.awsKey && handleFileOpen(data.awsKey)}</span>
                <span className="w-14 h-10 flex object-contain">
                  {" "}
                  {getFileIcon(data.name)}
                </span>
                <span>{data.name}</span>
                {enabled && <span className="w-8">
                  <IconComponent
                    cssClass="cursor-pointer"
                    onClick={() =>
                      handleRemoveUploadedFile({
                        awsKey: data.awsKey,
                        sectionIndex,
                        fieldIndex,
                        sectionId,
                        questionId,
                        tableRowIndex,
                        source,
                        handleCustomFieldData,
                        extraParams})
                    }
                    name="close"
                  />
                </span>}
              </span>
            ))}
            {isFileUploading.type == "fileUpload" &&
              (!tableRowId ||
                (tableRowId && isFileUploading.tableRowId == tableRowId)) && (
                <span className="flex gap-3 border-solid border-2 border-button_light w-fit pr-2 rounded-lg pl-2 items-center justify-center">
                  <CircularProgress />
                </span>
              )}
          </span>
        </div>
      );
    }
    if (fieldType == "image") {
      let answerValue = eachField.answer?.value ?? [];
      if (source == formSectionType.TABLE_SECTION) {
        answerValue =
          section.tableDataSource[tableRowIndex]?.[_id]?.value ?? [];
      }
      return (
        <div className="flex h-full w-full overflow-auto flex-col gap-5">
          <span>
            <input
              accept={eachField.allowedFileTypes.join(",")}
              type="file"
              id={`${questionId}${tableRowIndex}`}
              onChange={(e) =>
                handleFileUploadChange({
                  e,
                  sectionId,
                  eachField,
                  sectionIndex,
                  fieldIndex,
                  source,
                  tableRowIndex,
                  tableRowId,
                  allowedFileTypes: eachField.allowedFileTypes,
                  formData,
                  handleCustomFieldData,
                  extraParams
                })
              }
              style={{ display: "none" }}
            />
            <ButtonComponent
              onClick={() =>
                document.getElementById(`${questionId}${tableRowIndex}`).click()
              }
              disabled={!enabled}
              buttonType={BUTTON_TYPES.BACKGROUND_BUTTON}
            >
              <span className="flex gap-1 items-center">
                <PlusIcon
                  color={
                    enabled
                      ? "var(--primary-text)"
                      : "var(--system-colour-37"
                  }
                />
                <span>{placeholderText}</span>
              </span>
            </ButtonComponent>
          </span>
          <span className="flex flex-wrap flex-row pr-3 overflow-auto gap-4 w-full h-full">
            {answerValue?.map((data: any, index: number) => (
              <span className="w-32 h-32 border-button_light p-1 rounded flex border-2">
                <ImageEditor
                  isEditable={enabled}
                  imageData={`${process.env.REACT_APP_CUBE_BACKEND}/fileSystemServer/getInspectionAttachmentFile/${data.awsKey}`}
                  onSubmitFunction={(base64EncodedImg: string) =>
                    saveEditedImage({
                      base64EncodedImg,
                      handleCustomFieldData,
                      extraParams,
                      awsKey: data.awsKey,
                      sectionId,
                      questionId,
                    })
                  }
                  heading="Edit Image"
                  onDelete={() =>
                    handleRemoveUploadedFile({
                      awsKey: data.awsKey,
                      sectionIndex,
                      fieldIndex,
                      sectionId,
                      questionId,
                      source,
                      tableRowIndex,
                      handleCustomFieldData
                    })
                  }
                />
              </span>
            ))}
            {isFileUploading.type == "image" &&
              (!tableRowId ||
                (tableRowId && isFileUploading.tableRowId == tableRowId)) && (
                <span className="w-32 h-32 border-button_light p-1 items-center justify-center rounded flex border-2">
                  <CircularProgress />
                </span>
              )}
          </span>
        </div>
      );
    }
    if (fieldType == "url") {
      return (
        <div className="flex h-full w-full overflow-auto flex-col gap-5">
          {answer?.value?.displayText ? (
            <span className="flex items-center gap-3">
              <span
                className="text-gradient_color cursor-pointer underline underline-offset-2"
                onClick={() => {
                  const url =  onURLClick(_.get(answer, "value.URL", ""))
                  window.open(url)
                }}
              >
                {_.get(answer, "value.displayText", "")}
              </span>
              <ButtonComponent
                disabled={!enabled}
                onClick={() => {
                  handleURLModalState({
                    state: true,
                    sectionIndex,
                    eachField,
                    tableRowIndex,
                    answer,
                    fieldIndex,
                    sectionId,
                    source,
                  });

                }}
              >
                <IconComponent name="editProfile" cssClass="h-5" />
              </ButtonComponent>
            </span>
          ) : (
            <span>
              <ButtonComponent
                disabled={!enabled}
                onClick={() =>
                  handleURLModalState({
                    state: true,
                    sectionIndex,
                    eachField,
                    tableRowIndex,
                    answer,
                    fieldIndex,
                    sectionId,
                    source,
                  })
                }
                buttonType={BUTTON_TYPES.BACKGROUND_BUTTON}
              >
                <span className="flex gap-1 items-center">
                  <IconComponent
                    name="URL"
                    cssClass={`h-5 ${enabled ? "" : "opacity-50"}`}
                  />
                  <span>Enter URL</span>
                </span>
              </ButtonComponent>
            </span>
          )}
        </div>
      );
    }
    return "";
  };

  return (
    <>
      {showAnswersOnly ? getFieldAns() : getFieldAnsComponent()}
    </>
  )
}
export default FormQuestionFields