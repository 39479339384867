import axiosWrapper from "../services/axiosWrapper";
import { TOAST_TYPE_ERROR } from "../services/constants";
import {
  getDataManagerObj,
  getFilePropertiesUrl,
} from "../services/dataManager";
import { showToastNotification } from "./toast";
import { store } from "../App";
import { Query } from "@syncfusion/ej2-data";
import { unzip } from "../services/utils/unarchive";

export function setModalStatus(data) {
  return {
    type: "SET_MODAL_STATUS",
    data,
  };
}

export function updateCustomMetadataInModelProperties({
  fieldsData,
  fileVersionId,
  nodeId,
  selectedFormTemplate,
}) {
  return {
    type: "UPDATE_CUSTOM_METADATA_IN_MODEL_PROPERTIES",
    payload: {
      fieldsData,
      fileVersionId,
      nodeId,
      selectedFormTemplate,
    },
  };
}

export function setIsSheetActive(data) {
  return {
    type: "SET_IS_SHEET_ACTIVE",
    data,
  };
}

export function setFaceSelection(data) {
  return {
    type: "SET_FACE_SELECTION",
    data,
  };
}

export function saveModelTransform(data) {
  return () => {
    axiosWrapper
      .post(
        `${process.env.REACT_APP_CUBE_BACKEND}/fileOperations/saveMetadataInFileVersion`,
        data,
      )
      .then((res) => {});
  };
}

export async function getChildrenFilesName(data) {
  const response = await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/fileOperations/getDirectoryDataOfFile`,
    {
      value: {
        where: [
          {
            field: "parentId",
            value: data.finalParentId,
          },
          {
            field: "spaceId",
            value: data.spaceId,
          },
        ],
      },
    },
  );
  return response.data;
}

export function updateSettingModalAPIData(data) {
  return {
    type: "UPDATE_SETTING_MODAL_API_DATA",
    data,
  };
}

export function setSettingModalAPIData(data) {
  return {
    type: "SET_SETTING_MODAL_API_DATA",
    data,
  };
}

export function setModelProperties(data) {
  return {
    type: "SET_MODEL_PROPERTIES",
    data,
  };
}

export function setNodeIdAndUniqueIdMap(fileVersionId, data) {
  return {
    type: "SET_NODEID_AND_UNIQUEID_MAP",
    data: {
      fileVersionId,
      data,
    },
  };
}

export function setRefreshBottomToolbar(data) {
  return {
    type: "SET_BOTTOM_TOOLBAR_REFRESH",
    data,
  };
}

export function updateTransparentElements(data) {
  return {
    type: "UPDATE_TRANSPARENT_ELEMENTS",
    data,
  };
}

export function setSelectedNestedMeasurements(data) {
  return {
    type: "SET_SELECTED_NESTED_MEASUREMENTS",
    data,
  };
}

export function setMeasurementAttributePopup(show, callback, structure) {
  return {
    type: "SET_MEASUREMENT_ATTRIBUTE_POPUP",
    data: { show, callback, structure },
  };
}

export function getSettingModalData(data) {
  return async (dispatch) => {
    const response = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/userSettings/getDesignViewerSettings`,
      data,
    );
    dispatch(setSettingModalAPIData(response.data.data));
  };
}

export function updateSettingModalData(data) {
  return async (dispatch) => {
    const response = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/userSettings/saveDesignViewerSettings`,
      { data },
    );
    if (response.data.status) {
      dispatch(
        showToastNotification({
          content: "Saved successfully!",
        }),
      );
      dispatch(updateSettingModalAPIData(data));
    } else {
      dispatch(
        showToastNotification({
          content: "Something went wrong. Please try again!",
          type: TOAST_TYPE_ERROR,
        }),
      );
    }
  };
}

export function getNodeIdAndUniqueIdMap(data) {
  return async (dispatch) => {
    try {
      const response = await axiosWrapper.get(
        `${process.env.REACT_APP_CUBE_BACKEND}/fileSystemServer/Download?fileVersionIds=${data.fileVersionId}&type=cubeUniqueIdAndNodeIdMapKey`,
        { responseType: "arraybuffer" },
      );
      const result = await unzip(response.data);
      const merged = Object.assign({}, ...result);
      dispatch(setNodeIdAndUniqueIdMap(data.fileVersionId, merged));
    } catch (error) {}
  };
}

export function updateMeasurementAttributePopup(show, callback, structure) {
  return (dispatch) => {
    dispatch(setMeasurementAttributePopup(show, callback, structure));
  };
}

export function setViewerState(data) {
  return {
    type: "SET_VIEWER_STATE",
    data,
  };
}

export function getFaceSelectionItem(selectedItems) {
  for (let i = selectedItems.length - 1; i >= 0; i--) {
    if (selectedItems[i]._faceEntity !== null) {
      return selectedItems[i];
    }
  }
  return null;
}

export function getModelFileFormTemplates(data) {
  return async (dispatch) => {
    const state = store.getState();
    const {
      modelProperties: {
        isFileFormTemplatesLoading,
        isFileFormTemplatesLoaded,
      },
    } = state;
    if (!isFileFormTemplatesLoading && !isFileFormTemplatesLoaded) {
      dispatch(
        setModelProperties({
          isFileFormTemplatesLoading: true,
          isFileFormTemplatesLoaded: false,
        }),
      );

      let responseData = {};
      await Promise.all(
        data.fileVersionId.map(async (file) => {
          const response = await axiosWrapper.post(
            `${process.env.REACT_APP_CUBE_BACKEND}/projects/getModelFileFormTemplates`,
            {
              fileVersionId: file,
              projectId: data.projectId,
            },
          );
          if (
            response &&
            response.data.data.defaultTemplate &&
            response.data.status
          ) {
            const questionIdAndNameMapper = {};

            Object.values(response.data.data.formTemplates).map((data) => {
              Object.values(data).map((sections) => {
                sections.questionFields.map((question) => {
                  questionIdAndNameMapper[question._id] =
                    question.questionValue;
                });
              });
            });

            responseData = {
              ...responseData,
              defaultTemplate: response.data.data.defaultTemplate,
              formTemplates: response.data.data.formTemplates,
              questionIdAndNameMapper,
            };
          } else {
            responseData = {
              ...responseData,
              defaultTemplate: null,
              formTemplates: null,
              questionIdAndNameMapper: {},
            };
          }
        }),
      );
      dispatch(
        setModelProperties({
          ...responseData,
          isFileFormTemplatesLoading: false,
          isFileFormTemplatesLoaded: true,
        }),
      );
    }
  };
}

export function getModelProperties(data) {
  return async (dispatch) => {
    const state = store.getState();
    const {
      modelProperties: { isLoading, isLoaded },
    } = state;
    if (!isLoading && !isLoaded) {
      dispatch(
        setModelProperties({
          isLoading: true,
          isLoaded: false,
        }),
      );
      const responseData = {};
      await Promise.all(
        data.map(async (fileVersionId) => {
          const query = new Query().where("fileVersionId", "equal", [
            fileVersionId,
          ]);
          const [mongoData, response] = await Promise.all([
            getDataManagerObj(getFilePropertiesUrl).executeQuery(query),
            axiosWrapper.get(
              `${process.env.REACT_APP_CUBE_BACKEND}/fileSystemServer/Download?fileVersionIds=${fileVersionId}&type=modelTreeKey`,
              { responseType: "arraybuffer" },
            ),
          ]);

          if (!response.data) {
            responseData[fileVersionId] = {};
            return;
          }

          const result = await unzip(response.data);
          const nodeIdDataMap = {};
          for (const node of mongoData.result) {
            nodeIdDataMap[node._id] = node;
          }
          for (const node of result) {
            const mappedNode = nodeIdDataMap[node._id];
            node.properties.Quantity = mappedNode.Quantity;
            node.properties.Unit = mappedNode.Unit;
            node.customProperties = mappedNode.customProperties;
            node.selectedFormTemplate = mappedNode.selectedFormTemplate;
            if (!responseData.hasOwnProperty(fileVersionId)) {
              responseData[fileVersionId] = {};
            }
            responseData[fileVersionId][node.nodeId] = node;
          }
        }),
      );
      dispatch(
        setModelProperties({
          data: responseData,
          isLoading: false,
          isLoaded: true,
        }),
      );
    }
  };
}

export const saveConstituentMatrix = async (data) => {
  const response = await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/fileUpload/createConstituentFilesMatrix`,
    data,
  );
  return response;
};

export function changeOrientation(data) {
  const matrix = hwv.model.getNodeMatrix(data.nodeId);
  let initialMatrix = matrix.loadIdentity();

  const treeNode = hwv._modelStructure._assemblyTree.lookupAnyTreeNode(
    data.nodeId,
  );
  if (treeNode) {
    const nodeMatrix = treeNode._localTransform;
    if (nodeMatrix) {
      initialMatrix = Communicator.Matrix.createFromArray(nodeMatrix);
    }
  }

  let demoMatrix = matrix.loadIdentity();

  demoMatrix.setScaleComponent(
    data.data.scale.coordinates.x,
    data.data.scale.coordinates.y,
    data.data.scale.coordinates.z,
  ); // Scalaing the matrix

  const xmat = Communicator.Matrix.xAxisRotation(
    data.data.rotation.coordinates.x,
  );
  const ymat = Communicator.Matrix.yAxisRotation(
    data.data.rotation.coordinates.y,
  );
  const zmat = Communicator.Matrix.zAxisRotation(
    data.data.rotation.coordinates.z,
  );
  const xymat = Communicator.Matrix.multiply(ymat, xmat);
  const xyzmat = Communicator.Matrix.multiply(zmat, xymat);
  demoMatrix = Communicator.Matrix.multiply(xyzmat, demoMatrix);
  demoMatrix.setTranslationComponent(
    data.data.position.coordinates.x,
    data.data.position.coordinates.y,
    data.data.position.coordinates.z,
  );

  const finalMatrix = Communicator.Matrix.multiply(initialMatrix, demoMatrix);
  hwv.model.setNodeMatrix(data.nodeId, finalMatrix);
}

export const setFlagForAnimationAfterIssueCreation = (data) => ({
  type: "SET_ANIMATION_FOR_ISSUE_CREATION",
  data,
});

export const setDefaultView = (defaultView) => {
  return {
    type: "SET_DEFAULT_VIEW",
    payload: defaultView,
  };
};

export const downloadAndunzip = async (awsKey, isJson = true) => {
  const response = await axiosWrapper.get(
    `${process.env.REACT_APP_CUBE_BACKEND}/fileSystemServer/Download?viewId=${awsKey}`,
    { responseType: "arraybuffer" },
  );
  const result = await unzip(response.data, isJson);
  return result;
};

export const getDefaultView = (data) => {
  return async (dispatch) => {
    const responseData = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/views/getDefaultView`,
      {
        fileId: data.fileId,
      },
    );

    let defaultView = null;
    if (responseData.data.status) {
      const viewPointS3Key = responseData.data.data[0].viewpoint;
      if (viewPointS3Key) {
        const defaultViewPoint = await downloadAndunzip(viewPointS3Key);
        const viewData = defaultViewPoint;
        defaultView = {
          _id: responseData.data.data[0]._id,
          viewpoint: viewData,
        };
      }
    }

    dispatch(setDefaultView(defaultView));
  };
};
