import { useEffect, useRef, useState } from "react";
import { MdArrowDropDown } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import axiosWrapper from "../../services/axiosWrapper";
import SplitButtonComponent from "../../cubeComponent/form/SplitButtonComponent";
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import horizontalThreeDots from "../../assets/icons/svg/HorizontalThreeDots.svg";
import "./viewCss.css";
import TextBoxComponent from "../../cubeComponent/form/TextBoxComponent";
import cross from "../../assets/icons/svg/cross.svg";
import tick2 from "../../assets/icons/svg/tick2.svg";
import { showToastNotification } from "../../actions/toast";
import { TOAST_TYPE_SUCCESS } from "../../services/constants";
import Accordion from "../../cubeComponent/html/accordion/materialUiAccordian/index";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconComponent from "@CubeComponents/icons";
import UserNameTagComponent from "../../cubeComponent/html/UserNameTagComponent";
import { Popover } from "@mui/material";
import ButtonComponent from "../../cubeComponent/form/ButtonComponent";
import { BUTTON_TYPES } from "../../cubeComponent/constants/globalVariable";

function index({
  // layoutData,
  // currentDataId,
  onClickLayout,
  onEditCallback,
  // onRenameCallback,
  onDuplicateCallback,
  onDeleteCallback,
  // setLayoutData,
  headerName = "Layouts",
  enableUpdate = false,
  name = "Layout",
  createNewLayoutData,
  fetchParams = {},
  resourceType,
  selectedLayoutKey,
  triggerUpdateCurrentView,
  showSharedLayouts = true,
  onViewsLoaded = () => {},
}) {
  const [renameData, setRenameData] = useState({});
  const { userDetails } = useSelector((state) => state);
  const [currentLayoutData, setCurrentLayoutData] = useState({});
  const [layoutData, setLayoutData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : undefined;

  const dispatch = useDispatch();
  const splitButtonRef = useRef(null);

  const [dropDownItems, setdropDownItems] = useState([
    {
      text: "Rename",
      id: "rename",
      iconCss: "e-custom-fe-renameIcon",
    },
    {
      text: "Duplicate",
      id: "duplicate",
      iconCss: "e-custom-fe-duplicateIcon",
    },
    {
      text: "Delete",
      id: "delete",
      iconCss: "e-custom-fe-deleteIcon",
    },
  ]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    getSelectedLayout();
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getViews = async () => {
    const response = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/layoutView/getViewData`,
      {
        ...fetchParams,
        value: {
          where: [
            {
              field: "resourceType",
              operator: "equal",
              value: resourceType,
            },
          ],
        },
      },
    );

    setLayoutData(response.data.data);
    // if (Array.isArray(response.data?.data)) {
    //   setCurrentLayoutData(response.data?.data[0]);
    //   onViewsLoaded(response.data?.data[0]);
    // }
  };

  function handleSelectedOption({ args, layoutData, layoutDataIndex }) {
    if (args.element.id == "edit") {
      onEditCallback({ layoutData, layoutDataIndex });
    }
    if (args.element.id == "rename") {
      setRenameData(layoutData);
    }
    if (args.element.id == "delete") {
      onClickOptionDelete({ layoutData, layoutDataIndex });
    }
    if (args.element.id == "duplicate") {
      onClickOptionDuplicate({ layoutData, layoutDataIndex });
    }
  }

  async function onClickOptionDelete({ layoutData, layoutDataIndex }) {
    const response = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/layoutView/deleteView`,
      {
        id: layoutData._id,
      },
    );
    if (response.data.status) {
      setLayoutData((prevState) => {
        const newState = [...prevState];
        newState.splice(layoutDataIndex, 1);
        return newState;
      });
      onDeleteCallback &&
        (await onDeleteCallback({ deleteData: layoutData, layoutDataIndex }));
    }
  }

  async function onClickOptionDuplicate({ layoutData, layoutDataIndex }) {
    const response = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/layoutView/createView`,
      {
        createData: {
          ...layoutData,
        },
      },
    );
    if (response.data.status) {
      setLayoutData((prevState) => [...prevState, response.data.data]);
      onDuplicateCallback &&
        onDuplicateCallback({
          duplicatedData: layoutData,
          layoutDataIndex,
        });
    }
  }

  const updateDropdownItem = {
    text: "Update",
    id: "edit",
    iconCss: "e-custom-fe-updateIcon",
  };

  useEffect(() => {
    if (enableUpdate) {
      setdropDownItems([updateDropdownItem, ...dropDownItems]);
    }
  }, []);

  useEffect(() => {
    if (openPopover) {
      getViews();
    }
  }, [openPopover]);

  useEffect(() => {
    if (triggerUpdateCurrentView && !currentLayoutData.isDefault) {
      handleUpdateViewAPI({
        id: currentLayoutData._id,
        data: {
          data: {
            ...currentLayoutData.data,
            ...triggerUpdateCurrentView,
          },
        },
      });
    }
  }, [triggerUpdateCurrentView]);

  const getSelectedLayout = async () => {
    const selectedViewerLayout = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/layoutView/getSelectedViewerLayout`,
      {
        key: selectedLayoutKey,
        ...fetchParams,
      },
    );
    setCurrentLayoutData(selectedViewerLayout.data.data);
    onViewsLoaded(selectedViewerLayout.data.data);
  };

  const saveSelectedView = async (id) => {
    await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/layoutView/setSelectedViewerLayout`,
      {
        selectedViewerId: id,
        key: selectedLayoutKey,
        ...fetchParams,
      },
    );
  };

  const handleClickLayout = (data) => {
    saveSelectedView(data._id);
    setCurrentLayoutData(data);
    onClickLayout(data);
  };

  const handleUpdateViewAPI = async ({ id, data }) => {
    await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/layoutView/updateView`,
      {
        updateData: {
          ...data,
        },
        id,
      },
    );
  };

  const handleUpdateView = async () => {
    await handleUpdateViewAPI({
      id: renameData._id,
      data: {
        name: renameData.name,
      },
    });
    setLayoutData((prevState) =>
      prevState.map((data) => {
        if (data._id == renameData._id) {
          return {
            ...data,
            name: renameData.name,
          };
        }
        return data;
      }),
    );
    if (currentLayoutData._id == renameData._id) {
      setCurrentLayoutData((prev) => ({
        ...prev,
        name: renameData.name,
      }));
    }
    setRenameData({});
  };

  const layoutContent = (showShared) => {
    const layout = [];
    if (showShared) {
      layoutData.map((item, index) => {
        if (item.isShared) {
          layout.push({
            ...item,
            index,
          });
        }
      });
    } else {
      {
        layoutData.map((item, index) => {
          if (!item.isShared) {
            layout.push({
              ...item,
              index,
            });
          }
        });
      }
    }

    return (
      <div className="cursor-pointer font-normal">
        {layout.length > 0 ? (
          layout?.map((item, index) => (
            <>
              {renameData._id != item._id ? (
                <div className="group">
                  <div
                    className="flex-row flex items-center h-9 hover:bg-primary_blue"
                    onClick={() => handleClickLayout(item)}
                  >
                    <div className="ml-2 text-sm w-32 overflow-hidden whitespace-nowrap text-ellipsis flex-none">
                      {item.name}
                    </div>
                    <div className="flex-1 w-36 mr-2">
                      {item.isShared && (
                        <UserNameTagComponent id={item.createdByData._id} />
                      )}
                    </div>
                    {currentLayoutData._id == item._id && (
                      <div className="flex justify-center items-center h-4 w-11 rounded-md text-xxs bg-sky-200  text-black mr-2 flex-none">
                        Current
                      </div>
                    )}
                    {item.isDefault && (
                      <div className="flex justify-center items-center h-4 w-11 rounded-md text-xxs bg-gray-500 text-white mr-2 flex-none">
                        Default
                      </div>
                    )}
                    <div className="flex-none w-8 items-center">
                      <div className="group-hover:block">
                        {!item.isDefault &&
                          userDetails._id == item.createdBy && (
                            <DropDownButtonComponent
                              style={{
                                height: "10px",
                                // background: 'var( --primary-blue)',
                              }}
                              select={(args) =>
                                handleSelectedOption({
                                  args,
                                  layoutData: item,
                                  layoutDataIndex: item.index,
                                })
                              }
                              items={dropDownItems}
                              cssClass="e-caret-hide p-0 bg-white border-none h-10 preventLayoutClose group-hover:bg-primary_blue"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            >
                              <img
                                src={horizontalThreeDots}
                                style={{
                                  top: "-7px",
                                  display: "block",
                                  position: "relative",
                                }}
                              />
                            </DropDownButtonComponent>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-row">
                  <TextBoxComponent
                    type="text"
                    value={renameData.name}
                    onChange={(args) => {
                      setRenameData({ ...renameData, name: args.value });
                    }}
                  />
                  <img
                    src={cross}
                    className="cursor-pointer mx-2"
                    onClick={() => setRenameData({})}
                  />
                  <img
                    src={tick2}
                    className="cursor-pointer"
                    onClick={handleUpdateView}
                  />
                </div>
              )}
            </>
          ))
        ) : (
          <div className="flex flex-col w- items-center justify-center">
            <IconComponent cssClass="w-32" name="nodatasvg" />
            <div className="text-12">No Data</div>
          </div>
        )}
      </div>
    );
  };

  async function onSelectCreate(args) {
    const isShared = args.item.properties.text == "Shared View";

    const sharedName = isShared ? "Shared " : "";
    const data = {
      resourceType,
      data: createNewLayoutData(),
      name: `${sharedName}${name} ${
        isShared
          ? layoutData.filter((item) => item.isShared).length + 1
          : layoutData.filter((item) => !item.isShared).length + 1
      }`,
      ...fetchParams,
    };

    const created = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/layoutView/createView`,
      {
        createData: { ...data, isShared },
      },
    );
    if (created.data.status) {
      setLayoutData((prevState) => [
        ...prevState,
        { ...created.data.data, createdByData: userDetails },
      ]);
      handleClickLayout(created.data.data);
      dispatch(
        showToastNotification({
          content: `${created.data.data.name} view has been created`,
          type: TOAST_TYPE_SUCCESS,
        }),
      );
    }
  }

  async function onClickCreate(e) {
    await onSelectCreate({ item: splitButtonRef.current.properties.items[0] });
  }

  return (
    <div id="custom_viewLayout" className="relative">
      <ButtonComponent
        buttonType={BUTTON_TYPES.TEXT_BUTTON}
        aria-describedby={id}
        onClick={handleClick}
      >
        <div className="flex">
          {currentLayoutData?.name}
          <MdArrowDropDown size="1.5rem" />
        </div>
      </ButtonComponent>

      <Popover
        id={id}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        container={document.getElementById("custom_viewLayout")}
      >
        <div
          data-testid="layoutDropdown"
          onClick={(e) => e.preventDefault()}
          className="flex flex-col justify-between overflow-auto p-6 bg-white w w-115"
        >
          <div
            className="flex justify-between px-2 py-3 mb-4 items-center"
            data-testid="addNewLayout"
          >
            <div className="font-bold text-base" data-testid="LayoutsHeading">
              {headerName}
            </div>
            <SplitButtonComponent
              ref={splitButtonRef}
              buttonType="splitBackgroundButton"
              content="Create"
              data-testid="createBtn"
              cssClass="preventLayoutClose"
              items={[
                {
                  text: "My View",
                },
                {
                  text: "Shared View",
                },
              ]}
              onClick={onClickCreate}
              select={onSelectCreate}
            />
          </div>
          <div className="max-h-60 overflow-auto">
            <Accordion defaultExpanded data-testid="myLayoutsSection">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                data-testid="myLayoutsHeader"
              >
                <div
                  className="flex justify-between items-center px-2 py-3 text-sm font-semibold"
                  data-testid="myLayouts"
                >
                  {`My ${headerName}`}
                </div>
              </AccordionSummary>
              <AccordionDetails data-testid="myLayoutsContent">
                {layoutContent(false)}
              </AccordionDetails>
            </Accordion>
            {showSharedLayouts && (
              <Accordion defaultExpanded data-testid="sharedLayoutsSection">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  data-testid="sharedLayoutsHeader"
                >
                  <div
                    className="flex justify-between items-center px-2 py-3 text-sm font-semibold"
                    data-testid="sharedLayouts"
                  >
                    {`Shared ${headerName}`}
                  </div>
                </AccordionSummary>
                <AccordionDetails data-testid="sharedLayoutsContent">
                  {layoutContent(true)}
                </AccordionDetails>
              </Accordion>
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
}

export default index;
