import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setBreadCrumbData } from "../../../actions/breadcrumbsdata";
import {
  TOAST_TYPE_ERROR,
  TOAST_TYPE_SUCCESS,
  TOAST_TYPE_WARNING,
} from "../../../services/constants";
import { showToastNotification } from "../../../actions/toast";
import { getNameInitials } from "../../../cubeComponent/constants/globalFunctions";
import {
  BUTTON_TYPES,
  CONSTANTS,
} from "../../../cubeComponent/constants/globalVariable";
import TreeGridComponent from "../../../cubeComponent/html/treeGrid";
import {
  ColumnDirective,
  ColumnsDirective,
  Toolbar,
  Filter,
  Inject,
  InfiniteScroll,
  Sort,
  Resize,
  ColumnChooser,
  Page,
  Freeze,
} from "@syncfusion/ej2-react-treegrid";
import { mileStoneService } from "../../../services/dataManager";
import ButtonComponent from "../../../cubeComponent/form/ButtonComponent";
import ModalStateDialogComponent from "../../../cubeComponent/html/modal/ModalWithState";
import IconComponent from "../../../cubeComponent/icons";
import { Query, Predicate } from "@syncfusion/ej2-data";
import TextBoxComponent from "../../../cubeComponent/form/TextBoxComponent";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { mileStoneStatus } from "./constant";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  getContractDetails,
  mileStoneController,
  updateMilestonePreference,
} from "../../../actions/Contracts";
import { convertDate } from "../../../services/luxonHelper";
import ToolTipComponent from "../../../cubeComponent/html/tooltip";
import { BiLinkExternal } from "react-icons/bi";
import ToggleSwitchComponent from "../../../cubeComponent/form/ToggleSwitchComponent";
import ContractTasks from "../../CreateInspectionAndProgress/contractTaskGrid";
import { getProjectDashboardPageUrl } from "../../../services/utils/helpers";
import {
  disabledIllustration,
  getContractCRUDPermission,
  mileStonStatusTemplate,
  roundOffTemplate,
} from "./contractHelpers";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const MileStones = forwardRef(
  (
    {
      contractId,
      projectId,
      showOnlyGrid = false,
      setBtnState,
      formId,
      changeOrderState,
      setChangeOrdeState,
      contractDetails,
    },
    ref,
  ) => {
    const initialData = {
      title: "",
      description: "",
      mileStoneAmount: "",
      contractId,
      projectId,
      mileStoneDate: {},
    };
    const [mileStoneData, setMileStoneData] = useState(initialData);
    const dispatch = useDispatch();
    const projectData = useSelector((state) => state.projectData);
    const treeGrid = ref || useRef();
    const taskGridRef = useRef();
    const [showAddMileStoneModal, setShowAddMileStoneModal] = useState({
      status: false,
      isChildContract: false,
    });
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [toggleStatus, setToggleStatus] = useState(false);
    const [showTaskList, setShowTaskList] = useState({
      status: false,
      selectedTask: null,
    });

    const { t } = useTranslation();

    const isModuleDisabled = !contractDetails.modulesEnabled?.milestones;

    const mileStoneQuery = new Query()
      .addParams("formId", formId)
      .addParams("contractId", contractId)
      .addParams("contractVersionId", contractDetails.versionId);

    const textFieldCommonField = {
      width: "100%",
      "& .MuiOutlinedInput-input": {
        padding: "8px",
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "var(--info-light-blue)",
          borderWidth: "1px",
        },
      },
    };

    const handleInputChange = (args, fieldType) => {
      if (fieldType == "mileStoneAmount") {
        const value = Number(args.target.value);
        const percentageOfTotal = contractDetails.budgetAmount
          ? ((value * 100) / contractDetails.budgetAmount).toFixed(2)
          : 0;
        setMileStoneData((prev) => ({
          ...prev,
          percentageOfTotal: Number(percentageOfTotal),
          [fieldType]: value,
        }));
      } else if (fieldType == "percentageOfTotal") {
        const value = Number(args.target.value);
        const mileStoneAmount = (contractDetails.budgetAmount * value) / 100;
        setMileStoneData((prev) => ({
          ...prev,
          mileStoneAmount: Number(mileStoneAmount),
          [fieldType]: value,
        }));
      } else {
        setMileStoneData((prev) => ({
          ...prev,
          [fieldType]: args.value,
        }));
      }
    };

    const handleChange = (args, fieldType) => {
      if (fieldType === "basedOnTask") {
        setToggleStatus(args.target.checked);
        if (args.target.checked) {
          setMileStoneData((prev) => ({
            ...prev,
            mileStoneDate: {
              ...prev.mileStoneDate,
              [fieldType]: true,
              timeStamp: null,
              dateType: "startDate",
            },
          }));
        } else {
          setMileStoneData((prev) => ({
            ...prev,
            mileStoneDate: {
              ...prev.mileStoneDate,
              timeStamp: null,
              basedOnTask: false,
              dateType: null,
            },
          }));
          setShowTaskList({ status: false, selectedTask: null });
        }
      } else if (fieldType === "timeStamp") {
        setMileStoneData((prev) => ({
          ...prev,
          mileStoneDate: {
            timeStamp: new Date(args.value).getTime(),
            basedOnTask: false,
          },
        }));
      } else if (fieldType === "dateType") {
        let timeStamp;
        if (args.value === "startDate") {
          timeStamp = showTaskList.selectedTask?.startDate ?? null;
        } else {
          timeStamp = showTaskList.selectedTask?.endDate ?? null;
        }
        setMileStoneData((prev) => ({
          ...prev,
          mileStoneDate: {
            ...prev.mileStoneDate,
            dateType: args.value,
            timeStamp,
            selectedDate: timeStamp,
            offSet: 0,
          },
        }));
      } else if (fieldType === "offSet" && args.previousValue != args.value) {
        setMileStoneData((prev) => ({
          ...prev,
          mileStoneDate: {
            ...prev.mileStoneDate,
            offSet: args.value,
            timeStamp:
              (prev.mileStoneDate.selectedDate ?? 0) +
              args.value * 24 * 60 * 60 * 1000,
          },
        }));
      }
    };

    const handleOpenTaskList = () => {
      setShowTaskList((prev) => ({ ...prev, status: true }));
    };

    const mileStoneDateMapper = [
      {
        heading: "Based on Task",
        isVisible: true,
        component: (
          <ToggleSwitchComponent
            checked={toggleStatus || mileStoneData.mileStoneDate?.basedOnTask}
            name="basedOnTask"
            onChange={(args) => handleChange(args, "basedOnTask")}
          />
        ),
      },
      {
        heading: "Milestone Date",
        isVisible: !toggleStatus,
        component: (
          <DateTimePickerComponent
            onChange={(args) => handleChange(args, "timeStamp")}
            value={
              mileStoneData.mileStoneDate?.timeStamp
                ? new Date(mileStoneData.mileStoneDate?.timeStamp)
                : null
            }
            name="milestoneDate"
            width="80%"
            id="milestoneDate"
            placeholder="Select Date"
            format="dd/MM/yyyy hh:mm"
          />
        ),
      },
      {
        heading: "Select Task",
        isVisible: toggleStatus,
        component: (
          <div className="flex gap-3 items-center">
            <BiLinkExternal
              onClick={handleOpenTaskList}
              className="cursor-pointer"
            />
            <span>
              {showTaskList.selectedTask?.title ||
                mileStoneData?.mileStoneDate?.taskTitle}
            </span>
          </div>
        ),
      },
      {
        heading: "Based on Task's Date",
        isVisible: toggleStatus,
        component: (
          <DropDownListComponent
            dataSource={[
              { name: "Start Date", id: "startDate" },
              { name: "End Date", id: "endDate" },
            ]}
            placeholder="select date type"
            fields={{ text: "name", value: "id" }}
            value={mileStoneData.mileStoneDate?.dateType || "startDate"}
            name="dateType"
            width="80%"
            onChange={(args) => handleChange(args, "dateType")}
          />
        ),
      },
      {
        heading: "Offset (Days)",
        isVisible: toggleStatus,
        component: (
          <NumericTextBoxComponent
            width="80%"
            value={mileStoneData.mileStoneDate?.offSet}
            name="offSet"
            placeholder="Select Offset"
            change={(args) => handleChange(args, "offSet")}
          />
        ),
      },
      {
        heading: "Date",
        isVisible: toggleStatus,
        component: mileStoneData?.mileStoneDate?.timeStamp
          ? convertDate(
              Number(mileStoneData?.mileStoneDate?.timeStamp),
              "dateForTooltip",
            )
          : "",
      },
    ];

    const mileStoneParametersMapper = [
      {
        heading: "Title",
        component: (
          <TextBoxComponent
            showClearButton
            value={mileStoneData.title}
            placeholder="Enter title"
            name="title"
            change={(args) => handleInputChange(args, "title")}
          />
        ),
      },
      {
        heading: "Description",
        component: (
          <TextBoxComponent
            value={mileStoneData.description}
            showClearButton
            multiline
            placeholder="Enter description"
            name="description"
            change={(args) => handleInputChange(args, "description")}
          />
        ),
      },
      {
        heading: "Amount",
        component: (
          // <NumericTextBoxComponent
          //   value={mileStoneData?.mileStoneAmount}
          //   min={0}
          //   max={
          // contractDetails.remainingBudgetAmountForMileStones +
          // (editMode ? mileStoneData.revisedAmount : 0)
          //   }
          //   disabled={mileStoneData?.hasChild && editMode}
          //   showClearButton
          //   placeholder="Enter amount"
          //   name="mileStoneAmount"
          //   onChange={(args) => handleInputChange(args, "mileStoneAmount")}
          // />
          <TextField
            onChange={(args) => handleInputChange(args, "mileStoneAmount")}
            disabled={mileStoneData.hasChild && editMode}
            value={mileStoneData?.mileStoneAmount}
            placeholder="Enter amount"
            name="mileStoneAmount"
            inputProps={{
              min: 0,
              max:
                contractDetails.remainingBudgetAmountForMileStones +
                (editMode ? mileStoneData.revisedAmount : 0),
            }}
            sx={textFieldCommonField}
            type="number"
            focused
          />
        ),
      },
      {
        heading: "% of Total",
        component: (
          // <NumericTextBoxComponent
          //   value={mileStoneData.percentageOfTotal}
          //   min={0}
          // max={
          // (contractDetails.remainingBudgetAmountForMileStones * 100) /
          //   contractDetails.revisedAmount +
          // (editMode ? mileStoneData.revisedPercentageOfTotal : 0)
          // }
          //   disabled={mileStoneData.hasChild && editMode}
          //   showClearButton
          //   placeholder="Enter %"
          //   name="amount"
          //   onChange={(args) => handleInputChange(args, "percentageOfTotal")}
          // />
          <TextField
            onChange={(args) => handleInputChange(args, "percentageOfTotal")}
            value={mileStoneData.percentageOfTotal}
            name="amount"
            inputProps={{
              min: 0,
              max:
                (contractDetails.remainingBudgetAmountForMileStones * 100) /
                  contractDetails.revisedAmount +
                (editMode ? mileStoneData.revisedPercentageOfTotal : 0),
            }}
            placeholder="Enter %"
            disabled={mileStoneData.hasChild && editMode}
            sx={textFieldCommonField}
            type="number"
            focused
          />
        ),
      },
      {
        heading: "Mile Stone Date",
        component: (
          <div className="flex w-full flex-col gap-5 p-2 items-center">
            {mileStoneDateMapper.map(
              (field) =>
                field.isVisible && (
                  <span className="w-full flex">
                    <span className="flex flex-1">{field.heading}</span>
                    <span className="flex flex-2">{field.component}</span>
                  </span>
                ),
            )}
          </div>
        ),
      },
    ];

    const handleAddMileStoneModalShow = () => {
      setShowAddMileStoneModal((prev) => {
        if (prev.status) {
          setMileStoneData(initialData);
          setEditMode(false);
          setToggleStatus(false);
          closeTaskListModal();
        }
        return {
          status: !prev.status,
          isChildContract: false,
        };
      });
    };

    const showHideDeleteModal = () => {
      setShowDeleteModal((prev) => !prev);
    };

    const toolbarOptions = [
      {
        text: "Create",
        prefixIcon: "e-add",
        id: "create",
        disabled: !getContractCRUDPermission(contractDetails, changeOrderState),
      },
      {
        text: "Create Child",
        prefixIcon: "e-add",
        id: "create_child",
        disabled: true,
      },
      {
        text: "Edit",
        prefixIcon: "e-edit",
        id: "edit",
        disabled: true,
      },
      {
        text: "Delete",
        prefixIcon: "e-delete",
        id: "delete",
        disabled: true,
      },
      "ExpandAll",
      "CollapseAll",
      "ColumnChooser",
    ];
    useEffect(() => {
      const breadCrumb = [
        {
          text: "Projects",
          link: "/projects",
        },
        {
          text: projectData.description,
          link: getProjectDashboardPageUrl(projectData.id),
          showTooltip: true,
          tooltipData: projectData.description,
        },
        {
          text: "Contracts",
        },
      ];
      dispatch(setBreadCrumbData(breadCrumb));
    }, [projectData]);

    const createMileStoneClick = async () => {
      const data = {
        action: "insert",
        data: {
          ...mileStoneData,
        },
      };
      if (editMode) {
        data.action = "update";
        delete data.data.contractId;
        delete data.data.percentageOfTotal;
        delete data.data.projectId;
        data._id = treeGrid.current.getSelectedRecords()[0]._id;
        data.contractId = contractId;
      }
      const response = await mileStoneController(data);
      if (response.data.status) {
        let content = `Milestone ${mileStoneData.title} created successfully`;
        if (editMode) {
          content = `Milestone ${mileStoneData.title} updated successfully`;
        }
        dispatch(
          showToastNotification({
            content,
            type: TOAST_TYPE_SUCCESS,
          }),
        );
        treeGrid.current.query = mileStoneQuery.where(
          "parentId",
          "equal",
          null,
        );
        setMileStoneData(initialData);
        setEditMode(false);
        setToggleStatus(false);
        handleAddMileStoneModalShow();
        dispatch(getContractDetails({ contractId }));
      } else {
        dispatch(
          showToastNotification({
            content: response.data.errorMessage,
            type: TOAST_TYPE_ERROR,
          }),
        );
      }
    };

    // useEffect(()=>{
    //     if(treeGrid.current){
    //         treeGrid.current.toolbarModule?.enableItems(['create'], changeOrderState.isEnabled);
    //     }
    // },[changeOrderState.isEnabled])

    const toolbarClickHandler = async (args) => {
      const selectedData = treeGrid.current.getSelectedRecords()[0];

      if (args.item.properties.id === "create") {
        handleAddMileStoneModalShow();
      }

      if (args.item.properties.id === "create_child") {
        setMileStoneData((prev) => ({
          ...prev,
          parentId: selectedData?.mileStoneId,
        }));
        setShowAddMileStoneModal((prev) => ({
          status: true,
          isChildContract: true,
        }));
      }
      if (args.item.properties.id === "edit") {
        // handleAddMileStoneModalShow();
        setShowAddMileStoneModal((prev) => ({
          status: !prev.status,
          isChildContract: false,
        }));
        setEditMode(true);
        setMileStoneData({
          title: selectedData.title,
          hasChild: selectedData.hasChild,
          description: selectedData.description,
          mileStoneAmount: selectedData.revisedAmount,
          percentageOfTotal: selectedData.revisedPercentageOfTotal,
          mileStoneDate: selectedData.mileStoneDate,
          parentId: selectedData.parentId,
        });
        if (selectedData.mileStoneDate?.basedOnTask) {
          setToggleStatus(true);
        }
      }
      if (args.item.properties.id === "delete") {
        showHideDeleteModal();
      }
    };

    useEffect(() => {
      if (
        !getContractCRUDPermission(contractDetails, changeOrderState) &&
        treeGrid.current
      ) {
        treeGrid.current.toolbarModule?.enableItems(
          ["create_child", "edit", "delete", "link-item"],
          false,
        );
      }
    }, [contractDetails]);

    const selectionHandler = (args) => {
      const selectedData = treeGrid.current.getSelectedRecords();

      if (setBtnState) {
        if (selectedData.length > 0) {
          let btnState = true;
          selectedData.map((item) => {
            if (item.isAlreadyAddedInThisForm) {
              btnState = false;
            }
          });
          setBtnState(btnState);
        } else {
          setBtnState(false);
        }
      }
      if (
        showOnlyGrid ||
        !getContractCRUDPermission(contractDetails, changeOrderState)
      ) {
        return;
      }
      const totalRowsSelected = treeGrid.current.getSelectedRowIndexes().length;
      if (totalRowsSelected == 1) {
        treeGrid.current.toolbarModule.enableItems(
          ["create_child", "edit", "delete", "link-item"],
          true,
        );
      } else if (totalRowsSelected > 1) {
        treeGrid.current.toolbarModule.enableItems(["delete"], true);
        treeGrid.current.toolbarModule.enableItems(
          ["create_child", "edit", "link-item"],
          false,
        );
      } else {
        treeGrid.current.toolbarModule.enableItems(
          ["create_child", "edit", "delete", "link-item"],
          false,
        );
      }
    };

    const deleteMileStone = async () => {
      const selectedMileStones = treeGrid.current.getSelectedRecords();
      const mileStoneIds = selectedMileStones.map(
        (mileStone) => mileStone.mileStoneId,
      );
      const data = {
        action: "delete",
        mileStoneIds,
        contractId,
      };
      let isAnyMileStoneAddedInForm = false;
      selectedMileStones.map((milestone) => {
        if (milestone.isAddedInForms) {
          isAnyMileStoneAddedInForm = true;
        }
      });
      if (isAnyMileStoneAddedInForm) {
        dispatch(
          showToastNotification({
            content:
              "One or more of the selected milestone(s) is already added in forms and hence cannot be removed",
            type: TOAST_TYPE_ERROR,
          }),
        );
      } else {
        const res = await mileStoneController(data);
        if (res.data.status) {
          dispatch(
            showToastNotification({
              content: "Selected milestone(s) deleted successfully",
              type: TOAST_TYPE_SUCCESS,
            }),
          );
          showHideDeleteModal();
          treeGrid.current.query = mileStoneQuery.where(
            "parentId",
            "equal",
            null,
          );
          dispatch(getContractDetails({ contractId }));
        } else {
          dispatch(
            showToastNotification({
              content: res.data.message,
              type: TOAST_TYPE_ERROR,
            }),
          );
        }
      }
    };
    const boldHeaderTemplate = (data) => (
      <span className="font-semibold">{data}</span>
    );
    const dateTemplate = (args) => {
      if (args.mileStoneDate?.timeStamp == null) {
        return "-";
      }
      return convertDate(
        Number(args.mileStoneDate?.timeStamp),
        "dateForTooltip",
      );
    };
    const completionDateTemplate = (args) => {
      if (args.completionDate == null) {
        return "-";
      }
      return convertDate(Number(args.completionDate), "dateForTooltip");
    };

    function treeRowDataBound(params) {
      if (params.data.isAlreadyAddedInThisForm) {
        params.isSelectable = false;
        params.row.classList.add("e-disabled");
      }
    }

    const handleRowSelecting = (params) => {
      if (params.data.isAlreadyAddedInThisForm) {
        params.cancel = true;
      }
    };

    const getGridComponent = useCallback(
      () => (
        <TreeGridComponent
          ref={treeGrid}
          query={mileStoneQuery}
          treeColumnIndex={2}
          dataSource={mileStoneService}
          idMapping="mileStoneId"
          allowPaging
          parentIdMapping="parentId"
          hasChildMapping="hasChild"
          allowFiltering
          allowSorting
          allowResizing
          rowDeselected={selectionHandler}
          rowSelected={selectionHandler}
          toolbarClick={toolbarClickHandler}
          rowSelecting={handleRowSelecting}
          rowDataBound={treeRowDataBound}
          toolbar={showOnlyGrid ? null : toolbarOptions}
          showColumnChooser
          filterSettings={{ type: "Menu" }}
          //   enableInfiniteScrolling
          //   infiniteScrollSettings={{
          //         initialBlocks: 1,
          //     }}
          frozenColumns={6}
          height="100%"
        >
          <ColumnsDirective>
            <ColumnDirective
              field="_id"
              headerText="ID"
              width="0"
              isPrimaryKey
              showInColumnChooser={false}
              visible={false}
            />
            <ColumnDirective
              type="checkbox"
              allowSorting={false}
              allowFiltering={false}
              width="50"
            />
            <ColumnDirective field="title" headerText="Title" width="150" />
            <ColumnDirective
              field="description"
              headerText="Description"
              width="160"
            />
            <ColumnDirective
              field="mileStoneDate"
              type="date"
              headerText="Milestone Date"
              width="180"
              allowEditing={false}
              template={dateTemplate}
            />
            <ColumnDirective
              field="completionDate"
              type="date"
              headerText="Completion Date"
              width="180"
              allowEditing={false}
              template={completionDateTemplate}
            />
            <ColumnDirective
              field="status"
              headerText="Status"
              width="180"
              allowEditing={false}
              template={(args) => mileStonStatusTemplate(args.status)}
            />
            <ColumnDirective
              headerTemplate={() => boldHeaderTemplate("Milestone")}
              headerText="Milestone"
              textAlign="left"
              columns={[
                {
                  field: "mileStoneAmount",
                  headerText: "Amount",
                  width: "120",
                  clipMode: "Ellipsis",
                  textAlign: "Left",
                  template: (args) => roundOffTemplate(args.mileStoneAmount),
                },
                {
                  field: "mileStonePercentageOfTotal",
                  headerText: "% Of Total",
                  width: "150",
                  clipMode: "Ellipsis",
                  textAlign: "Left",
                  template: (args) =>
                    roundOffTemplate(args.mileStonePercentageOfTotal),
                },
              ]}
            />
            <ColumnDirective
              headerTemplate={() => boldHeaderTemplate("Change Order")}
              headerText="Change Order"
              textAlign="left"
              columns={[
                {
                  field: "changeOrderAmount",
                  headerText: "Amount",
                  width: "120",
                  clipMode: "Ellipsis",
                  textAlign: "Left",
                  template: (args) => roundOffTemplate(args.changeOrderAmount),
                },
                {
                  field: "changeOrderPercentageOfTotal",
                  headerText: "% Of Total",
                  width: "150",
                  clipMode: "Ellipsis",
                  textAlign: "Left",
                  template: (args) =>
                    roundOffTemplate(args.changeOrderPercentageOfTotal),
                },
              ]}
            />
            <ColumnDirective
              headerTemplate={() => boldHeaderTemplate("Revised")}
              headerText="Revised"
              textAlign="left"
              columns={[
                {
                  field: "revisedAmount",
                  headerText: "Amount",
                  width: "120",
                  clipMode: "Ellipsis",
                  textAlign: "Left",
                  template: (args) => roundOffTemplate(args.revisedAmount),
                },
                {
                  field: "revisedPercentageOfTotal",
                  headerText: "% Of Total",
                  width: "150",
                  clipMode: "Ellipsis",
                  textAlign: "Left",
                  template: (args) =>
                    roundOffTemplate(args.revisedPercentageOfTotal),
                },
              ]}
            />
            <ColumnDirective
              headerTemplate={() => boldHeaderTemplate("Used")}
              headerText="Used"
              textAlign="left"
              columns={[
                {
                  field: "usedAmount",
                  headerText: "Amount",
                  width: "120",
                  clipMode: "Ellipsis",
                  textAlign: "Left",
                  template: (args) => roundOffTemplate(args.usedAmount),
                },
                {
                  field: "usedPercentageOfTotal",
                  headerText: "% Of Total",
                  width: "150",
                  clipMode: "Ellipsis",
                  textAlign: "Left",
                  template: (args) =>
                    roundOffTemplate(args.usedPercentageOfTotal),
                },
              ]}
            />
            <ColumnDirective
              headerTemplate={() => boldHeaderTemplate("Remaining")}
              headerText="Remaining Amount"
              textAlign="left"
              columns={[
                {
                  field: "remainingAmount",
                  headerText: "Amount",
                  width: "120",
                  clipMode: "Ellipsis",
                  textAlign: "Left",
                  template: (args) => roundOffTemplate(args.remainingAmount),
                },
                {
                  field: "remainingPercentageOfTotal",
                  headerText: "% Of Total",
                  width: "150",
                  clipMode: "Ellipsis",
                  textAlign: "Left",
                  template: (args) =>
                    roundOffTemplate(args.remainingPercentageOfTotal),
                },
              ]}
            />
            <ColumnDirective
              headerTemplate={() => boldHeaderTemplate("Inspected")}
              headerText="Inspected"
              textAlign="left"
              columns={[
                {
                  field: "inspectedAmount",
                  headerText: "Amount",
                  width: "120",
                  clipMode: "Ellipsis",
                  textAlign: "Left",
                  template: (args) => roundOffTemplate(args.inspectedAmount),
                },
                {
                  field: "inspectedPercentageOfTotal",
                  headerText: "% Of Total",
                  width: "150",
                  clipMode: "Ellipsis",
                  textAlign: "Left",
                  template: (args) =>
                    roundOffTemplate(args.inspectedPercentageOfTotal),
                },
              ]}
            />
            <ColumnDirective
              headerTemplate={() => boldHeaderTemplate("Billed")}
              headerText="Billed"
              textAlign="left"
              columns={[
                {
                  field: "billedAmount",
                  headerText: "Amount",
                  width: "120",
                  clipMode: "Ellipsis",
                  textAlign: "Left",
                  template: (args) => roundOffTemplate(args.billedAmount),
                },
                {
                  field: "billedPercentageOfTotal",
                  headerText: "% Of Total",
                  width: "150",
                  clipMode: "Ellipsis",
                  textAlign: "Left",
                  template: (args) =>
                    roundOffTemplate(args.billedPercentageOfTotal),
                },
              ]}
            />
          </ColumnsDirective>
          <Inject
            services={[
              Resize,
              Freeze,
              Page,
              Sort,
              Filter,
              Toolbar,
              InfiniteScroll,
              ColumnChooser,
            ]}
          />
        </TreeGridComponent>
      ),
      [contractDetails.isInDraftMode, contractDetails.lockData?.isLocked],
    );

    const deleteModalFooter = () => (
      <div className="flex gap-4">
        <ButtonComponent
          buttonType={BUTTON_TYPES.GRADIENT_BUTTON}
          onClick={deleteMileStone}
        >
          {t("Delete")}
        </ButtonComponent>
        <ButtonComponent
          buttonType={BUTTON_TYPES.BACKGROUND_BUTTON}
          onClick={showHideDeleteModal}
        >
          {t("Cancel")}
        </ButtonComponent>
      </div>
    );
    const deleteModalHeader = () => (
      <div className="w-full flex justify-between rounded-md">
        <div className="flex  flex-col gap-1">Delete Milestones(s)</div>
        <div className="flex pr-7 cursor-pointer">
          <IconComponent name="close" onClick={showHideDeleteModal} />
        </div>
      </div>
    );
    const deleteModalContent = () => (
      <div className="flex mt-6 mb-6 flex-col gap-10">
        <span>
          {t(
            "All Child Milestones will also be deleted. Are you sure you want to continue",
          )}
          ?
        </span>
      </div>
    );

    const addModalHeader = () => (
      <div className="w-full p-4 bg-button_light flex items-center justify-between pr-5">
        <span className="flex flex-col pt-2">
          <span>{editMode ? "Edit Milestone" : "Create Milestone"}</span>
          {showAddMileStoneModal.isChildContract && (
            <span className="text-12">
              {t(
                "By adding a nested milestone, the Amount & % fields of the parent milestone will get overridden based on the sum of child values.",
              )}
            </span>
          )}
        </span>
        <IconComponent
          onClick={handleAddMileStoneModalShow}
          name="close"
          cssClass="cursor-pointer"
        />
      </div>
    );
    const addModalContent = () => {
      let amountContent = `Available amount : ${contractDetails.remainingBudgetAmountForMileStones}`;
      let percentContent = `Available % of total : ${
        contractDetails.budgetAmount
          ? (
              (contractDetails.remainingBudgetAmountForMileStones * 100) /
              contractDetails.budgetAmount
            ).toFixed(2)
          : 0
      }`;
      if (editMode && treeGrid.current?.getSelectedRecords()[0]?.hasChild) {
        amountContent = "Parent milestone's amount cannot be directly changed";
        percentContent =
          "Parent milestone's % of total cannot be directly changed";
      }
      return (
        <div className="p-5 flex flex-col gap-5">
          {mileStoneParametersMapper.map((data) => (
            <div className="flex w-full">
              <span className="flex-1 font-semibold">
                <span className="flex gap-5">
                  {data.heading}
                  {(data.heading == "Amount" ||
                    data.heading == "% of Total") && (
                    <span style={{ width: "fit-content" }}>
                      <ToolTipComponent
                        showTipPointer
                        position="BottomCenter"
                        content={
                          data.heading == "Amount"
                            ? amountContent
                            : percentContent
                        }
                      >
                        <IconComponent name="details" />
                      </ToolTipComponent>
                    </span>
                  )}
                </span>
              </span>
              <span className="flex-2">{data.component}</span>
            </div>
          ))}
        </div>
      );
    };
    const addModalFooter = () => {
      let isDisabled = false;
      const { title, description, mileStoneAmount, mileStoneDate } =
        mileStoneData;
      if (
        title == "" ||
        description == "" ||
        mileStoneAmount == "" ||
        !getContractCRUDPermission(contractDetails, changeOrderState) ||
        mileStoneDate.timeStamp == null ||
        mileStoneDate.timeStamp == undefined
      ) {
        isDisabled = true;
      }
      return (
        <div className="p-5">
          <ButtonComponent
            buttonType={BUTTON_TYPES.GRADIENT_BUTTON}
            disabled={isDisabled}
            onClick={createMileStoneClick}
          >
            {editMode ? "Save" : "Create"}
          </ButtonComponent>
        </div>
      );
    };

    const closeTaskListModal = () => {
      setShowTaskList((prev) => ({
        ...prev,
        status: false,
        selectedTask: null,
      }));
    };

    const handleTaskSelect = () => {
      const selectedTask = taskGridRef.current.getSelectedRecords();

      if (selectedTask.length) {
        let date = selectedTask[0].startDate;
        if (mileStoneData.dateType == "endDate") {
          date = selectedTask[0].endDate;
        }
        setMileStoneData((prev) => ({
          ...prev,
          mileStoneDate: {
            ...prev.mileStoneDate,
            timeStamp: date,
            selectedDate: date,
            taskId: selectedTask[0]._id,
            taskTitle: selectedTask[0].title,
            offSet: 0,
          },
        }));

        setShowTaskList({ status: false, selectedTask: selectedTask[0] });
      } else {
        dispatch(
          showToastNotification({
            content: "Select task first",
            type: TOAST_TYPE_WARNING,
          }),
        );
      }
    };

    const getTaskList = () => (
      <ModalStateDialogComponent
        allowModalPadding={false}
        footerContent={
          contractDetails.linkedSchedule?._id && (
            <div className="p-5">
              <ButtonComponent
                onClick={handleTaskSelect}
                buttonType={BUTTON_TYPES.GRADIENT_BUTTON}
              >
                {t("Select")}
              </ButtonComponent>
            </div>
          )
        }
        width="70%"
        headerContent={
          <div className="flex w-full p-5 bg-button_light justify-between items-center">
            {t("Select Task")}
            <IconComponent
              onClick={closeTaskListModal}
              cssClass="cursor-pointer"
              name="close"
            />
          </div>
        }
        onCancel={closeTaskListModal}
        isVisible={showTaskList.status}
        modelContent={
          <div className="h-125">
            <ContractTasks
              allowCheckBoxSelection={false}
              ref={taskGridRef}
              contractId={contractId}
            />
          </div>
        }
      />
    );

    return (
      <>
        {showAddMileStoneModal.status && (
          <ModalStateDialogComponent
            footerContent={addModalFooter()}
            width="50%"
            allowModalPadding={false}
            headerContent={addModalHeader()}
            onCancel={handleAddMileStoneModalShow}
            isVisible
            modelContent={addModalContent()}
          />
        )}
        {showDeleteModal && (
          <ModalStateDialogComponent
            footerContent={deleteModalFooter()}
            width="30%"
            headerContent={deleteModalHeader()}
            onCancel={showHideDeleteModal}
            isVisible
            modelContent={deleteModalContent()}
          />
        )}
        <div className="flex overflow-auto w-full h-full flex-col">
          {isModuleDisabled ? disabledIllustration() : getGridComponent()}
        </div>
        {showTaskList.status && getTaskList()}
      </>
    );
  },
);
export default MileStones;
