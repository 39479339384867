import React, { createRef, forwardRef, useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  TextField,
  Popover,
} from "@mui/material";
import ButtonComponent from "../../form/ButtonComponent";
import { Predicate } from "@syncfusion/ej2-data";
import Autocomplete from "@mui/material/Autocomplete";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const FILTER_MAPPER = {
  Text: [
    "Starts With",
    "Ends With",
    "Empty",
    "Equal",
    "Contains",
    "Does Not Start With",
    "Does Not End With",
    "Does Not Contain",
    "Not Equal",
  ],
  Date: [
    "Equal",
    "Greater Than",
    "Greater Than Or Equal",
    "Less Than",
    "Less Than Or Equal",
    "Between",
    "Not Equal",
    "Null",
    "Not Null",
  ],
  Number: [
    "Equal",
    "Greater Than",
    "Greater Than Or Equal",
    "Less Than",
    "Less Than Or Equal",
    "Between",
    "Not Equal",
    "Null",
    "Not Null",
  ],
};

const DEFAULT_FILTER_OPTIONS = {
  Text: { option: "startswith", text: "" },
  Date: { option: "equal", text: { date: dayjs() } },
  Number: { option: "equal", text: 0 },
};

const FilterComponent = forwardRef(
  (
    {
      currentColumnId,
      setCurrentColumnId,
      setFilterPredicates,
      anchorEl,
      setAnchorEl,
      filterType,
      filterOpened,
      setFilterOpened,
      dataset,
      remoteDataSource,
      beforeDataBound,
      columnDefinitions,
      setAppliedFilters,
      datasetForFetchingOptions,
      setFilteredDataset,
      query,
      localData,
      appliedFilters,
      setTotalItemCount,
    },
    ref,
  ) => {
    const gridRef = ref || createRef(null);
    const [tempFilterOption, setTempFilterOption] = useState("");
    const [tempFilterText, setTempFilterText] = useState("");
    const [filterConditions, setFilterConditions] = useState({});
    const [filterCleared, setFilterCleared] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [optionsOpen, setOptionsOpen] = useState(false);
    const openPopover = Boolean(anchorEl);
    const popoverId = openPopover ? "filter-popover" : undefined;

    const handleCloseOptions = () => setOptionsOpen(false);

    useEffect(() => {
      if (filterCleared) {
        if (!Object.keys(filterConditions).length) {
          setFilterPredicates("");
        }
        handleApplyFilter();
        setFilterCleared(false);
      }
    }, [filterCleared]);

    useEffect(() => {
      if (filterOpened) {
        if (appliedFilters.filter((data) => data === currentColumnId).length) {
          const filterCondition = filterConditions[currentColumnId];
          if (filterCondition) {
            setTempFilterText(filterCondition.text);
            setTempFilterOption(
              filterCondition.option ||
                DEFAULT_FILTER_OPTIONS[filterType].option,
            );
          }
        } else if (filterType) {
          setTempFilterOption(DEFAULT_FILTER_OPTIONS[filterType].option);
          setTempFilterText(DEFAULT_FILTER_OPTIONS[filterType].text);
        }
      }
      setFilterOpened(false);
    }, [filterOpened]);

    useEffect(() => {
      if (currentColumnId) {
        if (!filterConditions[currentColumnId]) {
          setFilterConditions((prev) => ({
            ...prev,
            [currentColumnId]: {
              ...prev[currentColumnId],
              id: currentColumnId,
              type: filterType,
              predicate: [
                new Predicate(
                  currentColumnId,
                  DEFAULT_FILTER_OPTIONS[filterType].option,
                  DEFAULT_FILTER_OPTIONS[filterType].text,
                ),
              ],
            },
          }));
        }
      }
    }, [currentColumnId]);

    useEffect(() => {
      if (optionsOpen && filterType == "Text") {
        fetchOptions(tempFilterText, currentColumnId);
      }
    }, [optionsOpen, tempFilterText]);

    const handleClearFilter = () => {
      setAppliedFilters((prev) =>
        prev.filter((data) => data !== currentColumnId),
      );
      setFilterConditions((prev) => {
        const newState = { ...prev };
        delete newState[currentColumnId];
        return newState;
      });
      setFilterCleared(true);
      const button = "mdi mdi-filter-variant text-slate-900";
      const updatedColumns = gridRef.current.slickGrid
        .getColumns()
        .map((col) => {
          if (col.id === currentColumnId) {
            col.header.buttons[0].cssClass = button;
            col.filterApplied = false;
          }
          return col;
        });

      gridRef.current.slickGrid.setColumns(updatedColumns);
      handleClosePopover();
    };

    const handleClosePopover = () => {
      setTempFilterText("");
      setTempFilterOption("");
      if (!appliedFilters.filter((data) => data === currentColumnId).length) {
        setFilterConditions((prev) => {
          const newState = { ...prev };
          delete newState[currentColumnId];
          return newState;
        });
      }
      setAnchorEl(null);
      setOptionsOpen(false);
      setCurrentColumnId("");
    };

    const getUpdatedFilterConditions = (filterConditions) => {
      const updatedFilterConditions = Object.keys(filterConditions).reduce(
        (result, filterKey) => {
          const filterValue = filterConditions[filterKey];
          if (filterValue.option === "between" && filterValue.type === "Date") {
            const { text, ...otherFields } = filterValue;
            const { startDate, endDate } = text || {};
            if (startDate) {
              result[`${filterKey}_start`] = {
                ...otherFields,
                option: "greaterthanorequal",
                text: startDate,
              };
            }
            if (endDate) {
              result[`${filterKey}_end`] = {
                ...otherFields,
                option: "lessthanorequal",
                text: endDate,
              };
            }
          } else if (
            filterValue.type === "Date" &&
            (filterValue.option === "equal" || !filterValue.option)
          ) {
            const { text, ...otherFields } = filterValue;
            const dayjsObject = dayjs();
            const isoDate = (text.date ? text.date : dayjsObject).toISOString();
            const dateOnly = isoDate.split("T")[0];
            result[`${filterKey}_start`] = {
              ...otherFields,
              option: "greaterthanorequal",
              text: `${dateOnly}T00:00:00`,
            };
            result[`${filterKey}_end`] = {
              ...otherFields,
              option: "lessthanorequal",
              text: `${dateOnly}T23:59:59`,
            };
          } else {
            result[filterKey] = filterValue;
          }
          return result;
        },
        {},
      );
      return updatedFilterConditions;
    };

    const getFilterPredicates = (updatedFilterConditions, callForData) => {
      let predicate = [];
      Object.keys(updatedFilterConditions).forEach((filterkey) => {
        predicate.push(...updatedFilterConditions[filterkey].predicate);
      });
      if (predicate.length === 1) {
        predicate = predicate[0];
      } else if (predicate.length > 1) {
        predicate = new Predicate(predicate[0], "and", predicate.slice(1));
      }
      if (callForData) {
        setFilterPredicates(predicate);
      }
      return predicate;
    };

    const getTempPredicateArray = () => {
      if (!currentColumnId) {
        return [];
      }
      const option =
        tempFilterOption ||
        DEFAULT_FILTER_OPTIONS[filterType]?.option ||
        "equal";

      if (filterType === "Date") {
        if (option === "between") {
          if (
            !tempFilterText ||
            (!tempFilterText.startDate && !tempFilterText.endDate)
          ) {
            return [
              new Predicate(
                currentColumnId,
                "greaterthanorequal",
                dayjs().subtract(1, "day").toISOString(),
              ),
              new Predicate(
                currentColumnId,
                "lessthanorequal",
                dayjs().subtract(1, "day").toISOString(),
              ),
            ];
          }
          const predicates = [];
          if (tempFilterText.startDate) {
            const startDateStr = dayjs(tempFilterText.startDate).toISOString();
            predicates.push(
              new Predicate(
                currentColumnId,
                "greaterthanorequal",
                startDateStr,
              ),
            );
          }

          if (tempFilterText.endDate) {
            const endDateStr = dayjs(tempFilterText.endDate).toISOString();
            predicates.push(
              new Predicate(currentColumnId, "lessthanorequal", endDateStr),
            );
          }

          return predicates.length
            ? predicates
            : [new Predicate(currentColumnId, "equal", dayjs().toISOString())];
        }
        if (option === "equal") {
          if (!tempFilterText || !tempFilterText.date) {
            const defaultDate = dayjs().toISOString().split("T")[0];
            return [
              new Predicate(
                currentColumnId,
                "greaterthanorequal",
                `${defaultDate}T00:00:00`,
              ),
              new Predicate(
                currentColumnId,
                "lessthanorequal",
                `${defaultDate}T23:59:59`,
              ),
            ];
          }

          const dayjsObject = dayjs(tempFilterText.date);
          const dateOnly = dayjsObject.toISOString().split("T")[0];

          return [
            new Predicate(
              currentColumnId,
              "greaterthanorequal",
              `${dateOnly}T00:00:00`,
            ),
            new Predicate(
              currentColumnId,
              "lessthanorequal",
              `${dateOnly}T23:59:59`,
            ),
          ];
        }
        if (!tempFilterText || !tempFilterText.date) {
          return [
            new Predicate(currentColumnId, option, dayjs().toISOString()),
          ];
        }
        const filterValue =
          tempFilterText.date && dayjs(tempFilterText.date).isValid()
            ? dayjs(tempFilterText.date).isDayjs
              ? tempFilterText.date.toISOString()
              : dayjs(tempFilterText.date).toISOString()
            : dayjs().toISOString();

        return [new Predicate(currentColumnId, option, filterValue)];
      }
      const defaultValue = filterType === "Number" ? 0 : "";
      const filterValue =
        tempFilterText !== undefined && tempFilterText !== null
          ? tempFilterText
          : defaultValue;

      return [new Predicate(currentColumnId, option, filterValue)];
    };
    const handleApplyFilter = () => {
      const predicateArray = getTempPredicateArray();
      if (currentColumnId) {
        setFilterConditions((prev) => ({
          ...prev,
          [currentColumnId]: {
            ...prev[currentColumnId],
            option: tempFilterOption,
            text: tempFilterText,
            predicate: predicateArray,
            location: "asdasds",
          },
        }));
      }
      const updatedFilterConditions = getUpdatedFilterConditions({
        ...filterConditions,
        ...(currentColumnId && {
          [currentColumnId]: {
            ...filterConditions[currentColumnId],
            option: tempFilterOption,
            text: tempFilterText,
            predicate: predicateArray,
          },
        }),
      });

      if (Object.keys(filterConditions).length && remoteDataSource) {
        getFilterPredicates(
          {
            ...filterConditions,
            [currentColumnId]: {
              ...filterConditions[currentColumnId],
              option: tempFilterOption,
              text: tempFilterText,
              predicate: predicateArray,
            },
          },
          true,
        );
      } else if (localData) {
        if (Object.keys(filterConditions).length || currentColumnId) {
          const filteredData = Object.keys(updatedFilterConditions).reduce(
            (currentData, filter) => {
              const filterCondition = updatedFilterConditions[filter];
              return applyFilter(
                currentData,
                filterCondition.id,
                filterCondition.option ||
                  DEFAULT_FILTER_OPTIONS[filterCondition.type].option,
                filterCondition.text,
                filterCondition.type,
              );
            },
            dataset,
          );
          setTotalItemCount(filteredData.length);
          setFilteredDataset(filteredData);
        } else {
          setFilteredDataset(dataset);
          setTotalItemCount(dataset.length);
        }
      }

      setAppliedFilters((prev) => {
        const data = Array.isArray(prev)
          ? [...prev, currentColumnId]
          : [currentColumnId];
        return [...new Set(data)];
      });

      setAnchorEl(null);
      setOptions([]);
      setOptionsOpen(false);
      setTempFilterText("");
      setTempFilterOption("");
      const button =
        "mdi mdi-filter-variant text-[var(--primary-gradient-color1)]";
      const updatedColumns = gridRef.current.slickGrid
        .getColumns()
        .map((col) => {
          if (col.id === currentColumnId && !col.filterApplied) {
            col.header.buttons[0].cssClass = button;
            col.filterApplied = true;
          }
          return col;
        });

      gridRef.current.slickGrid.setColumns(updatedColumns);
      setCurrentColumnId("");
    };

    const handleFilterChange = (event) => {
      const { value } = event.target;
      setTempFilterOption(value);

      if (value === "between" && filterType === "Date") {
        setTempFilterText({
          startDate: dayjs(),
          endDate: dayjs(),
        });
      } else if (value === "equal" && filterType === "Date") {
        setTempFilterText({ date: dayjs() });
      }
    };

    const handleInputChange = (_, newInputValue) => {
      setOptionsOpen(!!newInputValue && newInputValue !== tempFilterText);
      setTempFilterText(newInputValue);
    };

    const handleDateChange = (newDate) => {
      setOptionsOpen(false);

      if (newDate) {
        const dayjsObject = dayjs(newDate);
        setTempFilterText({ date: dayjsObject });
      }
    };

    const handleBetweenStartDateChange = (startDate) => {
      setOptionsOpen(false);

      if (startDate) {
        const dayjsObject = dayjs(startDate);
        setTempFilterText((prev) => ({
          ...prev,
          startDate: dayjsObject,
        }));
      }
    };

    const handleBetweenEndDateChange = (endDate) => {
      setOptionsOpen(false);

      if (endDate) {
        const dayjsObject = dayjs(endDate);
        setTempFilterText((prev) => ({
          ...prev,
          endDate: dayjsObject,
        }));
      }
    };

    const fetchOptions = async (text, field) => {
      if (!text) return;
      setLoading(true);
      let filteredData;
      if (remoteDataSource && query) {
        const filterConditionsWithCurrentConditionUpdated = {
          ...filterConditions,
        };
        filterConditionsWithCurrentConditionUpdated[currentColumnId].text =
          tempFilterText;
        filterConditionsWithCurrentConditionUpdated[currentColumnId].predicate =
          [
            new Predicate(
              currentColumnId,
              filterConditionsWithCurrentConditionUpdated[currentColumnId]
                .option || DEFAULT_FILTER_OPTIONS[filterType].option,
              tempFilterText,
            ),
          ];
        const updatedFilterConditions = getUpdatedFilterConditions(
          filterConditionsWithCurrentConditionUpdated,
        );
        const predicate = getFilterPredicates(updatedFilterConditions, false);
        const modifiedQuery = query.clone();
        modifiedQuery.skip(0).take(20).where(predicate);
        const dataForOptions =
          await remoteDataSource.executeQuery(modifiedQuery);
        filteredData = beforeDataBound
          ? beforeDataBound(dataForOptions)
          : dataForOptions.result;
      } else if (localData) {
        filteredData = applyFilter(
          datasetForFetchingOptions,
          currentColumnId,
          tempFilterOption,
          text,
          filterType,
        );
      }
      const valueGetter = columnDefinitions.find(
        (col) => col.id === currentColumnId,
      )?._valueGetter;
      setOptions([
        ...new Set(
          filteredData?.map((data) =>
            valueGetter ? valueGetter(data) : data[field]?.toString(),
          ),
        ),
      ]);
      setLoading(false);
    };

    const applyFilter = (
      data,
      columnId,
      filterOption,
      filterValue,
      filterType,
    ) => {
      return data.filter((item) => {
        const valueGetter = columnDefinitions.find(
          (col) => col.id === columnId,
        )?._valueGetter;
        const fieldValue = valueGetter
          ? valueGetter(item)
          : item[columnId] || "";

        if (filterType === "Date") {
          const format = "MMM D,YYYY [at] hh:mm:ss A";
          const itemDate = dayjs(fieldValue, format);
          const filterDate = dayjs(filterValue);

          switch (filterOption) {
            case "equal":
              return itemDate.isSame(filterDate, "day");
            case "greaterthan":
              return itemDate.isAfter(filterDate, "day");
            case "greaterthanorequal":
              return (
                itemDate.isSame(filterDate, "day") ||
                itemDate.isAfter(filterDate, "day")
              );
            case "lessthan":
              return itemDate.isBefore(filterDate, "day");
            case "lessthanorequal":
              return (
                itemDate.isSame(filterDate, "day") ||
                itemDate.isBefore(filterDate, "day")
              );
            case "notequal":
              return !itemDate.isSame(filterDate, "day");
            case "null":
              return !fieldValue || fieldValue.trim() === "";
            case "notnull":
              return fieldValue && fieldValue.trim() !== "";
            default:
              return false;
          }
        } else if (filterType === "Text") {
          const fieldValueStr = String(fieldValue ?? "").toLowerCase();
          const filterValueLower = String(filterValue ?? "").toLowerCase();

          switch (filterOption) {
            case "startswith":
              return fieldValueStr.startsWith(filterValueLower);
            case "endswith":
              return fieldValueStr.endsWith(filterValueLower);
            case "empty":
              return fieldValueStr === "";
            case "equal":
              return fieldValueStr === filterValueLower;
            case "contains":
              return fieldValueStr.includes(filterValueLower);
            case "doesnotstartwith":
              return !fieldValueStr.startsWith(filterValueLower);
            case "doesnotendwith":
              return !fieldValueStr.endsWith(filterValueLower);
            case "doesnotcontain":
              return !fieldValueStr.includes(filterValueLower);
            case "notequal":
              return fieldValueStr !== filterValueLower;
            case "notempty":
              return fieldValueStr !== "";
            case "like":
              return fieldValueStr.includes(filterValueLower);
            default:
              return false;
          }
        } else if (filterType === "Number") {
          const fieldValueAsNumber = Number(fieldValue);
          const filterValueAsNumber = Number(filterValue);

          switch (filterOption) {
            case "equal":
              return fieldValueAsNumber === filterValueAsNumber;
            case "greaterthan":
              return fieldValueAsNumber > filterValueAsNumber;
            case "greaterthanorequal":
              return fieldValueAsNumber >= filterValueAsNumber;
            case "lessthan":
              return fieldValueAsNumber < filterValueAsNumber;
            case "lessthanorequal":
              return fieldValueAsNumber <= filterValueAsNumber;
            case "notequal":
              return fieldValueAsNumber !== filterValueAsNumber;
            case "null":
              return isNaN(fieldValueAsNumber);
            case "notnull":
              return !isNaN(fieldValueAsNumber);
            default:
              return false;
          }
        }
        return false;
      });
    };

    const getFilterOptions = () => {
      return FILTER_MAPPER[filterType].map((selectedOption) => (
        <MenuItem
          key={selectedOption}
          value={`${selectedOption.toLowerCase().replace(/\s+/g, "")}`}
        >
          {selectedOption}
        </MenuItem>
      ));
    };

    return (
      <div>
        {filterType && (
          <Popover
            id={popoverId}
            open={Boolean(anchorEl)}
            anchorReference="anchorPosition"
            anchorPosition={anchorEl || { top: 0, left: 0 }}
            onClose={handleClosePopover}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            sx={{
              "& .MuiPaper-root": {
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                padding: "20px",
                width: `${filterType === "Date" && tempFilterOption === "between" ? "360px" : "250px"}`,
                marginTop: "10px",
                marginLeft: "10px",
              },
            }}
          >
            <div>
              <FormControl fullWidth>
                <Select
                  value={tempFilterOption}
                  displayEmpty
                  onChange={handleFilterChange}
                  style={{ marginBottom: "16px" }}
                >
                  {filterType && getFilterOptions()}
                </Select>
              </FormControl>

              {filterType === "Text" && (
                <Autocomplete
                  freeSolo
                  open={optionsOpen}
                  options={options}
                  onClose={handleCloseOptions}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : ""
                  }
                  noOptionsText="No Records Found"
                  value={tempFilterText}
                  onInputChange={handleInputChange}
                  onChange={() => setOptionsOpen(false)}
                  loading={loading}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Enter the Value" />
                  )}
                />
              )}

              {filterType === "Date" && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div style={{ display: "flex", gap: "16px" }}>
                    <DatePicker
                      onChange={
                        tempFilterOption === "between"
                          ? handleBetweenStartDateChange
                          : handleDateChange
                      }
                      value={
                        tempFilterOption === "between"
                          ? tempFilterText.startDate || dayjs()
                          : tempFilterText.date || dayjs()
                      }
                    />
                    {tempFilterOption === "between" && (
                      <DatePicker
                        onChange={handleBetweenEndDateChange}
                        value={tempFilterText.endDate || dayjs()}
                      />
                    )}
                  </div>
                </LocalizationProvider>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                  paddingTop: "16px",
                }}
              >
                <ButtonComponent
                  buttonType="gradientButton"
                  style={{ flex: "1", height: "35px", borderRadius: "8px" }}
                  onClick={handleApplyFilter}
                >
                  Apply
                </ButtonComponent>
                <ButtonComponent
                  buttonType="borderButton"
                  style={{ flex: "1", height: "35px", borderRadius: "8px" }}
                  onClick={handleClearFilter}
                >
                  Clear
                </ButtonComponent>
              </div>
            </div>
          </Popover>
        )}
      </div>
    );
  },
);

export default FilterComponent;
