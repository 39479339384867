function CreateIcon({ size, className }) {
  size = size || "16";

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.783 2.783a.783.783 0 1 0-1.566 0v4.434H2.783a.783.783 0 1 0 0 1.566h4.434v4.434a.783.783 0 1 0 1.566 0V8.783h4.434a.783.783 0 1 0 0-1.566H8.783V2.783z"
        fill="#192048"
      />
    </svg>
  );
}

export default CreateIcon;
