import React, { FC, createRef, forwardRef } from "react";

import { createUseStyles } from "react-jss";
import { TreeGridComponent as SyncfusionTeeGridComponent } from "@syncfusion/ej2-react-treegrid";
// import { defaultGridComponentProps } from "../../constants/globalVariable";
import { TreeGridComponentProps } from "../../interface";
import { CONSTANTS } from "../../constants/globalVariable";
// const GridComponent: FC<GridModel & GridComponentProps> = forwardRef((props: GridModel & GridComponentProps, ref: any) => {
  const TreeGridComponent = forwardRef((props : TreeGridComponentProps, ref) => {
    /******************* 
@Purpose : TreeGrid Component
@Parameter : TreeGridComponentProps
@Author : Techture
 *******************/

  const treeGridRef = ref || createRef(null)
  const useStyles = createUseStyles({
    TreeGridComponent: {
      '& .e-checkbox-wrapper .e-icons.e-uncheck': {
        width: "var(--size-20)",
        height: "var(--size-20)",
        borderRadius: "var(--size-2)",
        border: "solid var(--size-1) var(--text-light) !important",
      },
      '& .e-checkbox-wrapper .e-icons.e-check': {
        border: "none",
        background: 'linear-gradient(100.44deg, var(--primary-gradient-color1) 0%, var(--primary-gradient-color2) 110.4%)',
        width: 'var(--size-20)',
        height: 'var(--size-20)',
        display: 'var(--display-inline-flex)',
        alignItems: 'var(--align-center)',
        borderRadius: 'var(--size-2)',
        justifyContent: 'var(--align-center)',
        '&:before': {
          content: `"\\e7ff" !important`,
        }
      },
      '& .e-gridheader': {
        backgroundColor: 'var(--surface-white) !important',
        border: 'none',
        '& .e-columnheader': {
          '& th.e-rowdragheader, th.e-headercell, & th.e-detailheadercell, & th.e-headertext, & th.e-headercell[aria-sort="ascending"] .e-headertext,  & th.e-headercell[aria-sort="descending"] .e-headertext': {
            color: 'var(--primary-text) !important',
            backgroundColor: 'var(--surface-white) !important',
            backgroundImage: 'linear-gradient(101deg, var(--surface-stroke-gradient-color1) 29%, rgba(236, 243, 254, 0.15) 111%)',
            '& .e-headercelldiv': {
              '& .e-headertext': {
                fontWeight: "600 !important",
              }
            },
            '& .e-rhandler': {
              borderRight: '0px'
            },
            '& .e-icon-filter:before': {
              content: "url('/assets/icons/svg/Filter.svg')  !important",
            },
            '& .e-filtered::before' :{
              content: "url('/assets/icons/svg/HighlightFilter.svg')  !important",
            },
            '& .e-icon-descending::before': {
              content: "url('/assets/icons/svg/gridSortDescending.svg')  !important",
            },
            '& .e-icon-ascending': {
              transform: 'rotateX(180deg) !important',
              '&::before': {
                content: "url('/assets/icons/svg/gridSortDescending.svg')  !important",
              }
            },
          }
        }
      },
      '& .e-gridcontent': {
        '& .e-row': {
          '& .e-rowcell,& .e-rowdragdropcell, & .e-detailrowcollapse': {
            color: 'var(--primary-text) !important',
            borderColor: 'var(--button-light) !important',
            '&:not(td.e-active)': {
              backgroundColor: 'var(--natural-white) !important',
            }
          },
          '& .e-dragborder' : {
            borderColor :'var(--system-colour-1) !important',
          },
          '& .e-detailrowcollapse, & .e-detailrowexpand': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 'var(--size-48)',
            backgroundColor: 'var(--natural-white) !important',
            position: "relative"
          }
        }
      },
      '& .e-grid': {
        border: 'var(--size-1) solid var(--button-light)',
        borderRadius: 'var(--size-8) var(--size-8) var(--size-0) var(--size-0);',
        '& .e-pager' : {
          borderColor: 'var(--button-light)'
        }
      },
      '& .e-toolbar': {
        height: props.toolbarWidth ? `${props.toolbarWidth}!important` : CONSTANTS.NONE,
        borderRadius: 'var(--size-8) var(--size-8) var(--size-0) var(--size-0);',
        border: 'none',

        '& .e-toolbar-items .e-hscroll-bar .e-hscroll-content' :{
          display: 'var(--display-inline-block)',

          '& .e-toolbar-left' : {
            height : 'inherit'
          }
        }
      },
      // '& .e-icon-gdownarrow::before, & .e-icon-grightarrow::before': {
      //   content: "''",
      //   backgroundImage: `url(${require('../../../assets/icons/svg/arrowDown.svg')?.default})`,
      //   height: 'var(--size-10)',
      //   backgroundSize: 'cover',
      //   width: 'var(--size-13)',
      //   backgroundRepeat: 'no-repeat',
      //   position: 'absolute',
      //   top: '50%',
      //   left: 'var(--size-10)',
      //   transform: 'translateY(-50%)'
      // },
      // '& .e-icon-gdownarrow::before': {
      //   transform: 'translateY(-50%) rotate(180deg)',
      // },
      // '& .e-headercontent': {
      //   border: 'none !important',
      // },
      // '& .e-table': {
      //   backgroundColor: "var(--surface-neutral) !important"
      // },
    }
  });

  const classes = useStyles() as any;

  return (
    <div className={`${classes.TreeGridComponent} h-full overflow-auto`} id="TreeGridComponentWrapper" data-testid="treeGridComponentWrapper">
      <SyncfusionTeeGridComponent
      {...props}
      className={classes.TreeGridComponent}
      ref={treeGridRef}
      >
      {props.children}
    </SyncfusionTeeGridComponent>
    </div>
  );
});
// GridComponent.defaultProps = defaultGridComponentProps;
export default TreeGridComponent;