import React, { FC } from "react";
import { createUseStyles } from "react-jss";
import { getDialogHeaderStyle } from "../../constants/globalFunctions";
import { BUTTON_TYPES } from "../../constants/globalVariable";
import ButtonComponent from "../../form/ButtonComponent";
import IconComponent from "../../icons";
import { ModalStateDialogComponentProps } from "../../interface";

const ModalStateDialogComponent: FC<ModalStateDialogComponentProps> = (props: ModalStateDialogComponentProps) => {
    /******************* 
  @Purpose : ModalState Component
  @Parameter : ModalStateComponentProps
  @Author : Techture
   *******************/
    const useStyles = createUseStyles({
        dialog: {
            position: 'fixed',
            zIndex: props.zIndex || 200, //200 is done because in pdfviewer, syncfusion sidebar is having more z index than modal
            display: "flex",
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            overflow: 'auto',
            backgroundColor: 'var(--background-scrim)',
            '& .modalContent': {
                display: 'flex',
                flexDirection: 'column',
                width: `${props.width}`,
                height: props.height ? props.height : "auto",
                padding: props.allowModalPadding ? props.padding ? props.padding : 'var(--size-40)' : '0px',
                boxShadow: ' 4px 4px 15px 0 rgba(65, 92, 171, 0.15)',
                position: 'relative',
                backgroundColor: '#fefefe',
                margin: 'auto',
                border: '1px solid #888',
                borderRadius: "5px",
            },
            '& .modalHeader': {
                ...getDialogHeaderStyle(),
                fontStyle: "var(--font-style-normal)",
                fontWeight: "var(--font-weight-600) !important",
                fontSize: "var(--size-24) !important",
                lineHeight: "var(--size-30) !important",
                color: "var(--primary-text) !important",
                alignItems: "Baseline"

            },
            '& .modalFooter': {
                display: "var(--display-flex)",
                gap: "16px"
            }
        }
    });

    const handleSaveButtonClick = () => {
        if (props.onSave) {
            props.onSave();
        }
    };
    const handleCloseButtonClick = () => {
        if (props.onCancel) {
            props.onCancel();
        }
    };
    const classes = useStyles() as any;

    return (
        <>
            {props.isVisible ? <div id={props.id} className={`${"modal"} ${classes.dialog}`} >
                <div className="modalContent" data-testid="modalContent">
                    <div className="modalHeader" data-testid="modalHeader">
                        {props.headerContent && props.headerContent}
                        {props.headerTitle && <>
                            <span data-testid="modalHeaderTitle">{props.headerTitle}</span>
                            <IconComponent
                                name="close"
                                className="cursor-pointer"
                                onClick={() => {
                                    handleCloseButtonClick()
                                }}
                            />
                        </>}
                    </div>
                    <div className="h-full overflow-auto" data-testid="modalContent">
                        {props.modelContent}
                    </div>
                    <div className="modalFooter" data-testid="modalFooter">
                        {!props.disableDefaultFooter ? <>
                            <ButtonComponent buttonType={BUTTON_TYPES.GRADIENT_BUTTON} onClick={handleSaveButtonClick} cssClass="e-info">
                                Save
                            </ButtonComponent><ButtonComponent onClick={handleCloseButtonClick} cssClass="e-flat">
                                Cancel
                            </ButtonComponent>
                        </> :
                            props.footerContent
                        }
                    </div>
                </div>
            </div> : null}
        </>
    );
};
ModalStateDialogComponent.defaultProps = {
    width: '500px',
    allowModalPadding: true,
    disableDefaultFooter: true
};
export default ModalStateDialogComponent;
