import { MdOutlineWrapText } from "react-icons/md";
import { BUTTON_TYPES } from "../../../cubeComponent/constants/globalVariable";
import ButtonComponent from "../../../cubeComponent/form/ButtonComponent";
import IconComponent from "../../../cubeComponent/icons";
import {
  manageWBSItemSteps,
  getPermissionConstants,
  mileStoneStatus,
} from "./constant";
import { numToChar, toRoman } from "../../../services/utils/helpers";

export function getContractCRUDPermission(
  contractDetails = {},
  changeOrderState = {},
) {
  const permissionConstants = getPermissionConstants();
  if (
    ![permissionConstants.CAN_EDIT, permissionConstants.FULL_ACCESS].includes(
      contractDetails.userRole,
    )
  ) {
    return false;
  }
  return !contractDetails.lockData?.isLocked || changeOrderState?.isEnabled;
}

export function getTotal(records, fieldName) {
  if (!records) {
    return 0;
  }
  return records?.reduce((total, object) => {
    if (object.childRecords) {
      return total + getTotal(object.childRecords, fieldName);
    }
    return total + object[fieldName];
  }, 0);
}
export function getNumberFromNumberSystem(value, fromNumberSystem) {
  if (fromNumberSystem == 0) {
    return value;
  }

  if ([1, 2].includes(fromNumberSystem)) {
    return charToNum(value);
  }

  if ([3, 4].includes(fromNumberSystem)) {
    return fromRoman(value);
  }
}

export function getSymbolForNumber(number, toNumberSystem, fromNumberSystem) {
  let convertedNumber = number;

  if (fromNumberSystem) {
    convertedNumber = getNumberFromNumberSystem(number, fromNumberSystem);
  }

  if (toNumberSystem == 1) {
    return numToChar(convertedNumber).toLowerCase();
  }

  if (toNumberSystem == 2) {
    return numToChar(convertedNumber).toUpperCase();
  }

  if (toNumberSystem == 3) {
    return toRoman(convertedNumber).toLowerCase();
  }

  if (toNumberSystem == 4) {
    return toRoman(convertedNumber).toUpperCase();
  }

  return number;
}
export function changeTypeButtonTemplate() {
  return (
    <ButtonComponent buttonType={BUTTON_TYPES.TEXT_BUTTON} id="changeType">
      <span className="flex items-center gap-1">
        <IconComponent name="changeType" />
        Change Type
      </span>
    </ButtonComponent>
  );
}

export function editButtonTemplate() {
  return (
    <div className="">
      <ButtonComponent buttonType={BUTTON_TYPES.TEXT_BUTTON} id="edit">
        <span className="flex items-center gap-1">
          <IconComponent name="editProfile" />
          Edit
        </span>
      </ButtonComponent>
    </div>
  );
}
export function updateButtonTemplate() {
  return (
    <div className="">
      <ButtonComponent buttonType={BUTTON_TYPES.TEXT_BUTTON} id="update">
        <span className="flex items-center gap-1">
          <IconComponent name="Update" className="h-4" />
          Update
        </span>
      </ButtonComponent>
    </div>
  );
}

export function cancelButtonTemplate() {
  return (
    <div className="">
      <ButtonComponent buttonType={BUTTON_TYPES.TEXT_BUTTON} id="cancel">
        <span className="flex items-center gap-1">
          <IconComponent name="Cancel" className="h-4" />
          Cancel
        </span>
      </ButtonComponent>
    </div>
  );
}

export function deleteBtnTemplate() {
  return (
    <div className="">
      <ButtonComponent buttonType={BUTTON_TYPES.TEXT_BUTTON} id="delete">
        <span className="flex items-center gap-1">
          <IconComponent name="delete" />
          Delete
        </span>
      </ButtonComponent>
    </div>
  );
}
export function assignWBSTemplate() {
  return (
    <div className="">
      <ButtonComponent
        buttonType={BUTTON_TYPES.TEXT_BUTTON}
        id="attachToWBSItem"
      >
        <span className="flex items-center gap-1">
          <IconComponent name="smallAttachmentIcon" />
          Assign To WBS
        </span>
      </ButtonComponent>
    </div>
  );
}
export function wrapTextButtonTemplate(state = false) {
  return (
    <div className="">
      <ButtonComponent buttonType={BUTTON_TYPES.TEXT_BUTTON} id="wrap_text">
        <span className="flex items-center gap-1">
          <MdOutlineWrapText />
          {state ? "Un Wrap Text" : "Wrap Text"}
        </span>
      </ButtonComponent>
    </div>
  );
}

const topHeaderTemplate = (args) => (
  <span className="font-semibold">{args.headerText}</span>
);

export const roundOffTemplate = (value) => {
  const res = value?.toFixed(2) || "";
  return res;
};

export const getGridColumns = ({
  source,
  showManageItemModal,
  serialTemplate,
  editTemplate,
  itemTypeTemplate,
  HSN_SAC_EditTemplate,
  descEditTemplate,
  rateEditTemplate,
  unitEditTemplate,
  unitTemplate,
  quantityEditTemplate,
  quantityTemplate,
  availableQuantityTemplate,
  linkedQuantityTemplate,
  amountTemplate,
  assignedQuantityTemplate,
  detailTemplate,
  showInfoColumn = false,
  showDetailedColumns = false,
  wbsItemsTemplate,
  allowCheckBoxSelection,
  showQuantityInputBox,
  showRemarksColumn = false,
  remarksTemplate,
  isRustomjeePortal = false,
  changeOrderState,
}) => {
  const columnsList = [
    {
      field: "itemId",
      visible: false,
      showInColumnChooser: false,
      width: "50",
      isPrimaryKey: true,
    },
    ...(allowCheckBoxSelection
      ? [
          {
            type: "checkbox",
            width: "100",
            allowFiltering: false,
            allowEditing: false,
            allowSorting: false,
            editTemplate: () => "",
            textAlign: "Left",
          },
        ]
      : []),
    ...(showInfoColumn
      ? [
          {
            field: "sourceFormNo",
            headerText: "Info",
            allowEditing: false,
            allowSorting: false,
            allowFiltering: false,
            template: detailTemplate,
            width: "80",
          },
        ]
      : []),
    {
      field: "serial",
      headerText: "S. No",
      template: serialTemplate,
      allowEditing: false,
      width: "100",
      editTemplate,
    },
    {
      headerText: "Item Type",
      textAlign: "Left",
      field: "type",
      width: "150",
      template: itemTypeTemplate,
      allowEditing: false,
      editTemplate,
    },
    {
      headerText: "Code",
      textAlign: "Left",
      field: "code",
      width: "100",
      allowEditing: false,
      editTemplate,
    },
    {
      headerText: "HSN/SAC",
      textAlign: "Left",
      field: "HSN_SAC",
      width: "150",
      editTemplate: HSN_SAC_EditTemplate,
    },
    {
      headerText: "Description",
      textAlign: "Left",
      field: "description",
      width: "150",
      editTemplate: descEditTemplate,
    },
    ...(showRemarksColumn && source == "FormView"
      ? [
          {
            field: "description",
            headerText: "Remarks",
            width: 250,
            allowFiltering: false,
            allowEditing: false,
            allowSorting: false,
            template: remarksTemplate,
          },
        ]
      : []),
    {
      headerText: "Unit",
      textAlign: "Left",
      field: "purchaseUnit.name",
      width: "200",
      template: unitTemplate,
      editTemplate: unitEditTemplate,
    },
    {
      headerText: "Rate",
      textAlign: "Left",
      field: "rate",
      width: "200",
      type: "number",
      editTemplate: rateEditTemplate,
    },
    ...(source == "WBSView"
      ? [
          {
            headerText: "Assigned Quantity",
            textAlign: "Left",
            field: "availableQuantity",
            width: "200",
            allowFiltering: false,
            allowSorting: false,
            editTemplate,
            template: assignedQuantityTemplate,
            allowEditing: false,
          },
        ]
      : []),
    ...(source == "BoqView"
      ? [
          {
            headerText: "WBS Items",
            textAlign: "Left",
            field: "wbsItems",
            allowFiltering: false,
            allowSorting: false,
            template: wbsItemsTemplate,
            width: "200",
            editTemplate,
            allowEditing: false,
          },
        ]
      : []),
    ...(source == "FormView" || !showDetailedColumns
      ? [
          {
            headerText: "Quantity",
            textAlign: "Left",
            field: "quantity",
            width: "200",
            template: (args) =>
              quantityTemplate(
                args,
                showManageItemModal.step ===
                  manageWBSItemSteps.showParentWBSItems
                  ? "revisedQuantity"
                  : "quantity",
              ),
            editTemplate: quantityEditTemplate,
          },
          {
            headerText: "Amount",
            textAlign: "Left",
            field: "revisedAmount",
            width: "150",
            template: (args) =>
              amountTemplate(
                args,
                showManageItemModal.step ===
                  manageWBSItemSteps.showParentWBSItems
                  ? "revisedAmount"
                  : "amount",
              ),
            editTemplate,
            allowEditing: false,
          },
        ]
      : [
          {
            headerTemplate: topHeaderTemplate,
            headerText: "Budgeted",
            textAlign: "Left",
            columns: [
              {
                headerText: "Quantity",
                textAlign: "Left",
                field: "quantity",
                allowEditing: !changeOrderState.isEnabled,
                width: "200",
                template: (args) => quantityTemplate(args, "quantity"),
                editTemplate: quantityEditTemplate,
              },
              {
                headerText: "Amount",
                textAlign: "Left",
                field: "amount",
                width: "150",
                template: (args) => amountTemplate(args, "amount"),
                editTemplate,
              },
            ],
          },
          {
            headerTemplate: topHeaderTemplate,
            headerText: "Change Order",
            textAlign: "Left",
            allowEditing: false,
            columns: [
              {
                headerText: "Quantity",
                textAlign: "Left",
                field: "changeOrderQuantity",
                width: "200",
                allowEditing: false,
                template: (args) =>
                  quantityTemplate(args, "changeOrderQuantity"),
              },
              {
                headerText: "Amount",
                textAlign: "Left",
                field: "changeOrderAmount",
                width: "150",
                allowEditing: false,
                template: (args) => amountTemplate(args, "changeOrderAmount"),
              },
            ],
          },
          {
            headerTemplate: topHeaderTemplate,
            headerText: "Revised",
            textAlign: "Left",
            columns: [
              {
                headerText: "Quantity",
                textAlign: "Left",
                field: "revisedQuantity",
                width: "200",
                allowEditing: changeOrderState.isEnabled,
                editTemplate: quantityEditTemplate,
                template: (args) => quantityTemplate(args, "revisedQuantity"),
              },
              {
                headerText: "Amount",
                textAlign: "Left",
                field: "revisedAmount",
                width: "150",
                allowEditing: false,
                template: (args) => amountTemplate(args, "revisedAmount"),
              },
            ],
          },
          ...(isRustomjeePortal
            ? []
            : [
                {
                  headerTemplate: topHeaderTemplate,
                  headerText: "Used",
                  textAlign: "Left",
                  allowEditing: false,
                  columns: [
                    {
                      headerText: "Quantity",
                      textAlign: "Left",
                      field: "usedQuantity",
                      width: "200",
                      template: (args) =>
                        quantityTemplate(args, "usedQuantity"),
                      allowEditing: false,
                    },
                    {
                      headerText: "Amount",
                      textAlign: "Left",
                      field: "usedAmount",
                      width: "150",
                      template: (args) => amountTemplate(args, "usedAmount"),
                      allowEditing: false,
                    },
                  ],
                },
              ]),
          ...(isRustomjeePortal
            ? []
            : [
                {
                  headerTemplate: topHeaderTemplate,
                  headerText: "Remaining",
                  textAlign: "Left",
                  columns: [
                    {
                      headerText: "Quantity",
                      textAlign: "Left",
                      field: "remainingQuantity",
                      width: "200",
                      allowEditing: false,
                      template: (args) =>
                        quantityTemplate(args, "remainingQuantity"),
                    },
                    {
                      headerText: "Amount",
                      textAlign: "Left",
                      field: "remainingAmount",
                      width: "150",
                      template: (args) =>
                        amountTemplate(args, "remainingAmount"),
                      allowEditing: false,
                    },
                  ],
                },
              ]),
          {
            headerTemplate: topHeaderTemplate,
            headerText: `${isRustomjeePortal ? "Work Done" : "Inspected"}`,
            textAlign: "Left",
            allowEditing: false,
            columns: [
              {
                headerText: "Quantity",
                textAlign: "Left",
                field: "inspectedQuantity",
                width: "200",
                template: (args) => quantityTemplate(args, "inspectedQuantity"),
                allowEditing: false,
              },
              {
                headerText: "Amount",
                textAlign: "Left",
                field: "inspectedAmount",
                width: "150",
                template: (args) => amountTemplate(args, "inspectedAmount"),
                allowEditing: false,
              },
            ],
          },
          {
            headerTemplate: topHeaderTemplate,
            headerText: "Billed",
            textAlign: "Left",
            allowEditing: false,
            columns: [
              {
                headerText: "Quantity",
                textAlign: "Left",
                field: "billedQuantity",
                width: "200",
                template: (args) => quantityTemplate(args, "billedQuantity"),
                allowEditing: false,
              },
              {
                headerText: "Amount",
                textAlign: "Left",
                field: "billedAmount",
                width: "150",
                template: (args) => amountTemplate(args, "billedAmount"),
                allowEditing: false,
              },
            ],
          },
        ]),
    ...(showManageItemModal.step === manageWBSItemSteps.showParentWBSItems ||
    source == "FormView"
      ? [
          {
            headerText: "Available Quantity",
            textAlign: "Left",
            field: "availableQuantity",
            width: "150",
            allowEditing: false,
            // editTemplate,
            template: (args) => quantityTemplate(args, "availableQuantity"),
          },
        ]
      : []),
    ...(showManageItemModal.step == manageWBSItemSteps.showParentWBSItems ||
    showQuantityInputBox
      ? [
          {
            headerText: "Linked Quantity",
            textAlign: "Left",
            field: "availableQuantity",
            width: "200",
            allowEditing: false,
            template: linkedQuantityTemplate,
          },
        ]
      : []),
  ];
  return columnsList;
};
export const noAccessSection = () => (
  <div className="w-full h-full flex gap-4 flex-col items-center justify-center overflow-auto">
    <IconComponent name="NoAccess" cssClass="h-32" />
    <span className="text-xl font-semibold">
      You don't have access to this section because you dont have access to
      respective contract
    </span>
  </div>
);
export const mileStonStatusTemplate = (status) => {
  let color = "bg-system_colour_45";
  if (status == mileStoneStatus.yetToStart) {
    color = "bg-system_colour_45";
  } else if (status == mileStoneStatus.inProgress) {
    color = "bg-system_colour_12";
  } else if (status == mileStoneStatus.achieved) {
    color = "bg-positive_communication";
  } else if (status == mileStoneStatus.delayed) {
    color = "bg-system_colour_8";
  } else if (status == mileStoneStatus.beforeTimeCompletion) {
    color = "bg-positive_communication";
  } else if (status == mileStoneStatus.achievedButDelayed) {
    color = "bg-system_colour_8";
  }
  return (
    <div
      className={`h-8 ${color} w-fit pl-3 gap-2 bg-opacity-25 rounded-2xl pr-3 flex items-center`}
    >
      <span className={`${color} rounded-full h-3 w-3`} />
      <span className="font-semibold">{status}</span>
    </div>
  );
};
export const disabledIllustration = () => (
  <div className="flex justify-center items-center flex-col gap-4 w-full h-full">
    <IconComponent name="disabledModule" />
    <span className="font-semibold text-2xl text-text_gray">
      This Module is Disabled
    </span>
    <span>To Enable it, Please visit to the Detail Section</span>
  </div>
);
