import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formCategories,
  formCategoryObject,
  presetSectionsCodeObject,
} from "../PortalSetings/Configuration/FormsAndFields/constants";
import { formSectionType, getSectionPermission } from "./constants";
import _ from "lodash";
import {
  PLATFORM,
  TOAST_TYPE_ERROR,
  TOAST_TYPE_SUCCESS,
  TOAST_TYPE_WARNING,
} from "../../services/constants";
import { SignTypeValues } from "../../cubeComponent/constants";
import { optionsViewType } from "../PortalSetings/Configuration/FormsAndFields/FormTemplate/constant.helper";
import { showToastNotification } from "../../actions/toast";
import useFormValidator from "../FormValidation/useFormValidator";
import { getContractDetails } from "../../actions/Contracts";

const useForms = ({
  navigate,
  params,
  scheduleId,
  contractId,
  resourceId,
  taskInspectionAndProgressFormsController = () => {},
  setBreadCrumbData,
  getScheduleDetails,
  GanttDetails,
  userDetails,
  portalData,
  projectData,
  getProjectDashboardPageUrl,
  getCurrencyData,
  getContractDetailUrl,
  getFormsListUrl,
  uuid,
  downloadFile,
  mobile = false,
  navigation,
  Toast,
  axiosWrapper,
  bottomSheetRefFiles,
  bottomSheetRefUsers,
  bottomSheetRefAddedUsers,
  projectId,
  mobileBackendUrl,
}) => {
  const dispatch = useDispatch();
  const abortControllerList = useRef({});
  const customModules = useSelector((state) => state.customModules);

  const selectSignType = (args) => {
    const signType = mobile ? args : args.itemData.value;
    if (
      !userDetails.signature &&
      signType == SignTypeValues.IMPORT_PROFILE_SIGNATURE
    ) {
      dispatch(
        showToastNotification({
          content: "Profile sign not set",
          type: TOAST_TYPE_ERROR,
        }),
      );
      if (!mobile) {
        args.cancel = true;
      }
    } else {
      setFormActionDetails((prev) => ({
        ...prev,
        signType,
        signDoc: null,
      }));
    }
  };

  const [inspectionData, setInspectionData] = useState({});
  const [showAtttachmentModal, setShowAttachmentModal] = useState(false);
  const [attachmentModalOpenedFor, setAttachmentModalOpenedFor] = useState({});
  const [sideBarTaskDetails, setSideBarTaskDetails] = useState([]);
  const [eachAnswerToSave, setEachAnswerToSave] = useState({});
  const [answerSaved, setAnswerSaved] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState({ type: "" });
  const [openCommentDialog, setOpenCommentDialog] = useState({
    status: false,
    source: "",
  });
  const [formActionDetails, setFormActionDetails] = useState({
    comment: "",
    signDoc: null,
    signType: SignTypeValues.ATTACH_SIGNED_DOCUMENT,
  });
  const [accordionExpandedState, setAccordionExpandedState] = useState([]);
  const [showSideBar, setShowSideBar] = useState(true);
  const [showAddUserModal, setShowAddUsersModal] = useState({ status: false });
  const [showWarningModal, setShowWarningModal] = useState({ status: false });
  const [modifySection, setModifySection] = useState([]);
  const [actionBtnDisabled, setActionBtnDisabled] = useState(false);
  const [tableSectionConfig, setTableSectionConfig] = useState({
    height: "max-h-60",
    expandMode: false,
  });
  const [showWaitSpinner, setShowWaitSpinner] = useState(false);
  const formValidator = useFormValidator({});

  // teams and location states for mobile
  const [teamData, setTeamData] = useState(null);
  const [loactionData, setLoactionData] = useState(null);
  const [nameOfTeamLocationRespectToId, setNameOfTeamLocationRespectToId] =
    useState({});
  const [isDataFetched, setDataFetched] = useState(false);
  const [imageFieldSelectedIndex, setImageFieldSelectedIndex] = useState(null);
  const [openExportModal, setOpenExportModal] = useState({
    state: false,
    paperSize: "A4",
    tableFormat: "card",
  });
  const [showUrlModal, setShowUrlModal] = useState({ state: false });
  const [URLData, setURLData] = useState({});

  const formObject = useRef(null);

  const sideBarScheduleDetails = [
    {
      name: "Project",
      value: GanttDetails ? GanttDetails.projectsData?.projectId : "",
    },
    {
      name: "Space",
      value: GanttDetails ? GanttDetails.spaceData?.spaceName : "",
    },
    { name: "Schedule", value: GanttDetails ? GanttDetails.title : "" },
    {
      name: "Linked Estimate",
      value: GanttDetails ? GanttDetails.linkedEstimateData?.name : "",
    },
  ];

  const sideBarAccordions = [
    ...(contractId
      ? [
          {
            type: "contractDetails",
            heading: "Contract Details",
            iconName: "details",
          },
        ]
      : []),
    { type: "details", heading: "Details", iconName: "details" },
    { type: "formFlow", heading: "Form Flow", iconName: "activity" },
    { type: "issues", heading: "Issues", iconName: "IssuesIcon" },
  ];

  const fetchFormData = async () => {
    const res = await taskInspectionAndProgressFormsController({ resourceId });
    let data = {};
    if (_.get(res, "data.status", false)) {
      data = res.data.result[0];
      if (data) {
        let modifiedData = {
          ...data,
        };
        if (
          portalData?.frontendUrl?.includes("rustomjee") &&
          data.resourceType == formCategories.bills
        ) {
          const template = data.selectedForm.template?.map((section) => {
            const allQuestions = section.questionFields?.map((question) => {
              if (question.fieldLabel == "MBOOK_NO" && data.MBOOK_NO) {
                question.answer = {
                  value: data.MBOOK_NO,
                };
              }
              if (question.fieldLabel == "SES_NO" && data.SES_NO) {
                question.answer = {
                  value: data.SES_NO,
                };
              }
              return question;
            });
            section.questionFields = allQuestions;
            return section;
          });

          modifiedData = {
            ...data,
            selectedForm: {
              ...data.selectedForm,
              template,
            },
          };
        }
        setInspectionData(() => ({ ...modifiedData }));
      }
    }
    setDataFetched(true);
    return data;
  };
  const setInitialData = async () => {
    const data = await fetchFormData();
    if (data._id) {
      axiosWrapper.post(
        `${
          mobile ? mobileBackendUrl : process.env.REACT_APP_CUBE_BACKEND
        }/recentAccess/recentAccess`,
        {
          module: data.resourceType,
          metaData: {
            _id: resourceId,
            name: data.title,
            projectId: data.projectId,
            resourceType: data.resourceType,
          },
          projectId: data.projectId,
          platform: mobile ? PLATFORM.mobile : PLATFORM.web,
        },
      );
      if (!mobile) {
        const categoryObj = customModules.find(
          (module) => module.resourceType == data?.resourceType,
        );
        const module =
          categoryObj || formCategoryObject[data?.resourceType] || {};
        document.title = `${module?.tabTitle}`;
        const breadCrumb = [
          {
            text: "Projects",
            link: "/projects",
          },
          {
            text: projectData.description,
            link: getProjectDashboardPageUrl(projectData.id),
            showTooltip: true,
            tooltipData: projectData.description,
          },
          {
            text: `${module?.formHeader}`,
            link: getFormsListUrl({
              resourceType: data.resourceType,
              selectedTab: "list",
              projectId: projectData.id,
            }),
          },
        ];
        dispatch(setBreadCrumbData(breadCrumb));
        handleExpandAll(data.selectedForm?.template);
      }
    }
    if (data.contractId) {
      dispatch(getContractDetails({ contractId: data.contractId }));
      dispatch(getCurrencyData({ projectId: params.projectId }));
    }
    if (scheduleId) {
      dispatch(getScheduleDetails({ ganttId: scheduleId }));
    }
  };
  useEffect(() => {
    setInitialData();
  }, [customModules, projectData]);

  async function getLocationForMobile() {
    let teamResponse = await axiosWrapper.post(
      `${mobileBackendUrl}/projects/locationController`,
      { value: { params: { projectId }, projectId } },
    );
    teamResponse = teamResponse.data.result;
    const nameDataOfTeamRespectToId = {};
    teamResponse = teamResponse.map((data) => {
      nameDataOfTeamRespectToId[data._id] = data.name;
      return {
        label: data.name,
        value: data._id,
        ...data,
      };
    });
    setNameOfTeamLocationRespectToId({
      ...nameOfTeamLocationRespectToId,
      ...nameDataOfTeamRespectToId,
    });
    return teamResponse;
  }

  async function getTeamDataForMobile() {
    let locationResponse = await axiosWrapper.post(
      `${mobileBackendUrl}/projects/teamsController`,
      { value: { params: { projectId }, projectId } },
    );
    locationResponse = locationResponse.data.result;
    const nameDataOfTeamRespectToId = {};
    locationResponse = locationResponse.map((data) => {
      nameDataOfTeamRespectToId[data._id] = data.name;
      const dataToReturn = {
        label: data.name,
        value: data._id,
        ...data,
      };
      if (data.parentId) {
        dataToReturn.parent = data.parentId;
      }
      return dataToReturn;
    });
    setNameOfTeamLocationRespectToId({
      ...nameOfTeamLocationRespectToId,
      ...nameDataOfTeamRespectToId,
    });
    return locationResponse;
  }

  const handleInputChange = ({
    args,
    fieldIndex,
    sectionIndex,
    eachField,
    section,
    source = "",
    tableRowIndex,
    handleCustomFieldData,
    extraParams,
  }) => {
    const {
      _id,
      fieldType,
      lookUpAnsFieldType,
      viewType = optionsViewType.LIST,
      minFieldRange,
      maxFieldRange,
    } = eachField;

    const questionId = _id;
    const sectionId = section._id;

    let answer = eachField.answer ?? {};

    if (source == formSectionType.TABLE_SECTION) {
      answer = section.tableDataSource[tableRowIndex]?.[_id] ?? {};
    }
    let answerValue;
    const isInteractionValid = _.get(args, "isInteracted", true);
    if (mobile) {
      if (fieldType == "single-line") {
        answerValue = args;
      }
      if (fieldType == "multi-line") {
        answerValue = args;
      }
      if (fieldType == "single-select") {
        answerValue = args;
      }
      if (fieldType == "multi-select") {
        if (viewType == optionsViewType.LIST) {
          if (args.checked) {
            if (answer?.value && answer.value.length) {
              answerValue = [...answer.value, args.value];
            } else {
              answerValue = [args.value];
            }
          } else {
            let tempAnswers = [...answer?.value];
            tempAnswers = tempAnswers.filter((ans) => ans != args.value);
            answerValue = [...tempAnswers];
          }
        } else if (viewType == optionsViewType.DROPDOWN) {
          if (answer.value) {
            const currentData = args[0];
            let modifiedData = [...answer.value];
            const indexInvalue = answer.value.indexOf(currentData);
            if (indexInvalue !== -1) {
              modifiedData = answer.value.filter(
                (item) => item !== currentData,
              );
            } else {
              modifiedData.push(currentData);
            }
            answerValue = modifiedData;
          } else {
            answerValue = args;
          }
        }
      }
      if (fieldType == "date-time") {
        answerValue = args.getTime();
      }
      if (fieldType == "look-up") {
        args.map((data) => {
          if (answerValue && answerValue.length) {
            answerValue = [
              ...answerValue,
              { id: data, name: nameOfTeamLocationRespectToId[data] },
            ];
          } else {
            answerValue = [
              { id: data, name: nameOfTeamLocationRespectToId[data] },
            ];
          }
        });
      }
      if (fieldType == "number") {
        answerValue = Number(args.replace(/[^0-9]/g, ""));
      }
    } else if (
      fieldType == "single-select" ||
      fieldType == "multi-select" ||
      fieldType == "multi-line" ||
      (fieldType == "number" &&
        ![undefined, null].includes(args.currentTarget.value)) ||
      args.isInteracted == true ||
      fieldType == "fileUpload"
    ) {
      if (fieldType == "single-line" && args.isInteracted == true) {
        answerValue = args.value?.trim();
      }
      if (fieldType == "multi-line") {
        answerValue = args.currentTarget.value?.trim();
      }
      if (fieldType == "single-select") {
        if (
          viewType == optionsViewType.LIST &&
          args.event.target.ej2_instances[0].properties.checked
        ) {
          answerValue = args.event.target.ej2_instances[0].properties.label;
        } else {
          answerValue = args.value;
        }
      }
      if (fieldType == "multi-select") {
        if (viewType === optionsViewType.LIST) {
          if (args.checked) {
            if (answer?.value && answer.value.length) {
              answerValue = [...answer.value, args.event.target.textContent];
            } else {
              answerValue = [args.event.target.textContent];
            }
          } else {
            let tempAnswers = [...answer.value];
            tempAnswers = tempAnswers.filter(
              (ans) => ans != args.event.target.textContent,
            );
            answerValue = [...tempAnswers];
          }
        } else if (args.name == "select" && args.isInteracted) {
          if (answer.value && answer.value.length) {
            answerValue = [...answer.value, args.itemData.value];
          } else {
            answerValue = [args.itemData.value];
          }
        } else if (args.name == "removed" && args.isInteracted) {
          let tempAnswers = [...answer.value];
          tempAnswers = tempAnswers.filter((ans) => ans != args.itemData.value);
          answerValue = [...tempAnswers];
        }
      }
      if (fieldType == "date-time" && args.isInteracted == true) {
        if (eachField.selectionScope == "currentDateTime") {
          answerValue = Date.now();
        } else if (
          (minFieldRange && new Date(args.value).getTime() < minFieldRange) ||
          (maxFieldRange && new Date(args.value).getTime() > maxFieldRange)
        ) {
          return;
        }
        answerValue = args.value ? new Date(args.value).getTime() : null;
      }
      if (fieldType == "look-up" && args.isInteracted == true) {
        if (lookUpAnsFieldType == "Teams") {
          if (args.isInteracted) {
            const mappedItemData = args.itemData.map((item) => ({
              id: item._id,
              name: item.name,
            }));
            answerValue = mappedItemData;
          }
        }
        if (lookUpAnsFieldType == "Location") {
          if (args.action == "select") {
            if (answer.value && answer.value.length) {
              answerValue = [
                ...answer.value,
                { id: args.itemData.id, name: args.itemData.text },
              ];
            } else {
              answerValue = [
                { id: args.itemData.id, name: args.itemData.text },
              ];
            }
          }
          if (args.action == "un-select" && Array.isArray(answer.value)) {
            let tempAnswers = [...answer.value];
            tempAnswers = tempAnswers.filter(
              (location) => location.id != args.itemData.id,
            );
            answerValue = [...tempAnswers];
          }
        }
      }
      if (fieldType == "number") {
        answerValue = Number(args.target.value.replace(/,/g, ""));
      }
    }
    if (![undefined, NaN].includes(answerValue) && isInteractionValid) {
      if (handleCustomFieldData) {
        handleCustomFieldData({
          args: extraParams,
          eachField,
          answerValue,
          fieldIndex,
          sectionIndex,
          tableRowIndex,
          source,
        });
      } else if (inspectionData._id) {
        if (source == formSectionType.FORM) {
          setInspectionData((prev) => {
            const modifiedTemplate = [...prev.selectedForm.template];
            if (
              modifiedTemplate[sectionIndex].questionFields[fieldIndex].answer
            ) {
              modifiedTemplate[sectionIndex].questionFields[fieldIndex].answer =
                {
                  ...modifiedTemplate[sectionIndex].questionFields[fieldIndex]
                    .answer,
                  value: answerValue,
                };
            } else {
              modifiedTemplate[sectionIndex].questionFields[fieldIndex].answer =
                {
                  value: answerValue,
                };
            }
            return {
              ...prev,
              selectedForm: {
                ...prev.selectedForm,
                template: modifiedTemplate,
              },
            };
          });

          setEachAnswerToSave({
            sectionId,
            questionId,
            answer: { value: answerValue },
          });
        } else {
          let tableData = [];
          setInspectionData((prev) => {
            const modifiedTemplate = [...prev.selectedForm.template];

            const modifiedTableDataSource = modifiedTemplate[
              sectionIndex
            ].tableDataSource.map((row, index) => {
              if (index == tableRowIndex) {
                row[_id] = {
                  createdDate: Date.now(),
                  createdBy: userDetails._id,
                  value: answerValue,
                };
              }
              return row;
            });
            modifiedTemplate[sectionIndex].tableDataSource =
              modifiedTableDataSource;

            tableData = [...modifiedTableDataSource];

            setEachAnswerToSave({
              tableSectionId: sectionId,
              tableSectionDataSource: tableData,
            });

            return {
              ...prev,
              selectedForm: {
                ...prev.selectedForm,
                template: modifiedTemplate,
              },
            };
          });
        }
      }
    }
  };

  const fileToBase64 = async (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (e) => {
        console.log(e);
        reject(e);
      };
    });

  const handleFileUploadChange = async ({
    e,
    sectionId,
    eachField,
    sectionIndex,
    fieldIndex,
    tableRowIndex,
    source,
    tableRowId = null,
    formData = {},
    handleCustomFieldData,
  }) => {
    const allowedFileTypes = eachField.allowedFileTypes || [];
    const questionId = eachField._id;
    let prevAns = eachField.answer?.value;
    if (source == formSectionType.TABLE_SECTION) {
      prevAns =
        formData.selectedForm.template[sectionIndex].tableDataSource[
          tableRowIndex
        ]?.[eachField._id]?.value;
    }
    if (
      eachField.maxNoOfFiles &&
      prevAns &&
      prevAns?.length >= eachField.maxNoOfFiles
    ) {
      const content = `Max only ${eachField.maxNoOfFiles} files can be uploaded`;
      dispatch(
        showToastNotification({
          content,
          type: TOAST_TYPE_ERROR,
        }),
      );
    } else {
      let fileSize;
      let file;
      let fileType;
      if (mobile) {
        file = e;
        fileType = e.type;
        fileSize = file.size;
      } else {
        file = e.target.files?.[0];
        fileType = file?.type;
        fileSize = file?.size;
      }
      const maxFileSizeInBytes = 10 * 1024 * 1024;

      if (
        eachField.fieldType == "image" &&
        file &&
        !allowedFileTypes.includes(fileType)
      ) {
        dispatch(
          showToastNotification({
            content: "File type not allowed",
            type: TOAST_TYPE_ERROR,
          }),
        );
      } else if (
        eachField.fieldType == "fileUpload" &&
        file &&
        allowedFileTypes.length &&
        !allowedFileTypes.includes(fileType)
      ) {
        dispatch(
          showToastNotification({
            content: "File type not allowed",
            type: TOAST_TYPE_ERROR,
          }),
        );
      } else if (file && fileSize > maxFileSizeInBytes) {
        const message =
          "File size exceeds the limit. Max file size allowed is 10 MB";
        if (mobile) {
          dispatch(
            showToastNotification({
              content: message,
              type: TOAST_TYPE_WARNING,
            }),
          );
        } else {
          alert(message);
          e.target.value = "";
        }
      } else {
        let fileBase64Str;
        if (mobile) {
          fileBase64Str = file.base64;
        } else {
          fileBase64Str = await fileToBase64(file);
        }

        const awsKey = uuid();
        let answerValue = [
          { type: fileType, name: file.name || file.fileName, awsKey },
        ];
        let data = {};
        const modifiedTemplate = _.cloneDeep(formData.selectedForm.template);
        if (source == formSectionType.FORM) {
          if (eachField.answer?.value) {
            answerValue = [
              ...eachField.answer.value,
              { type: fileType, name: file.name || file.fileName, awsKey },
            ];
          }

          if (
            modifiedTemplate[sectionIndex].questionFields[fieldIndex].answer
          ) {
            modifiedTemplate[sectionIndex].questionFields[fieldIndex].answer = {
              ...modifiedTemplate[sectionIndex].questionFields[fieldIndex]
                .answer,
              value: answerValue,
            };
          } else {
            modifiedTemplate[sectionIndex].questionFields[fieldIndex].answer = {
              value: answerValue,
            };
          }
          data = {
            addFileUploadFieldData: { fileBase64Str, awsKey },
            answer: { value: answerValue },
            sectionId,
            questionId,
          };
        } else {
          const prevAns =
            formData.selectedForm.template[sectionIndex].tableDataSource[
              tableRowIndex
            ][questionId]?.value;
          if (prevAns) {
            answerValue = [
              ...prevAns,
              { type: fileType, name: file.name || file.fileName, awsKey },
            ];
          }
          modifiedTemplate[sectionIndex].tableDataSource = modifiedTemplate[
            sectionIndex
          ].tableDataSource.map((row, index) => {
            if (index == tableRowIndex) {
              row[questionId] = {
                createdBy: userDetails._id,
                createdDate: Date.now(),
                value: answerValue,
              };
            }
            return row;
          });
          data = {
            tableSectionId: sectionId,
            tableSectionDataSource: [
              ...modifiedTemplate[sectionIndex].tableDataSource,
            ],
            addFileUploadFieldData: { fileBase64Str, awsKey },
          };
        }
        setIsFileUploading({
          type: eachField.fieldType,
          tableRowId: tableRowId ?? "",
        });

        if (handleCustomFieldData) {
        } else if (inspectionData._id) {
          const res = await taskInspectionAndProgressFormsController({
            action: "update",
            _id: resourceId,
            data,
          });
          setIsFileUploading({ type: "" });
          if (res.data.status) {
            setAnswerSaved(true);
            if (!mobile) {
              setTimeout(() => {
                setAnswerSaved(false);
              }, [500]);
            }
            dispatch(
              showToastNotification({
                content: "File uploaded",
                type: TOAST_TYPE_SUCCESS,
              }),
            );
            setInspectionData((prev) => ({
              ...prev,
              lastSaved: res.data.lastSaved,
              selectedForm: {
                ...prev.selectedForm,
                template: modifiedTemplate,
              },
            }));
          } else {
            dispatch(
              showToastNotification({
                content: res.data.errorMessage,
                type: TOAST_TYPE_ERROR,
              }),
            );
          }
        }
      }
    }
  };

  const saveEditedImage = ({
    base64EncodedImg,
    awsKey,
    handleCustomFieldData,
    sectionId,
    questionId,
  }) => {
    if (handleCustomFieldData) {
    } else if (inspectionData._id) {
      setEachAnswerToSave({
        addFileUploadFieldData: { fileBase64Str: base64EncodedImg, awsKey },
        sectionId,
        questionId,
      });
    }
  };

  const handleRemoveUploadedFile = ({
    awsKey,
    sectionIndex,
    fieldIndex,
    sectionId,
    questionId,
    source,
    tableRowIndex,
    handleCustomFieldData,
  }) => {
    const modifiedTemplate = _.cloneDeep(inspectionData.selectedForm.template);

    if (handleCustomFieldData) {
    } else if (inspectionData._id) {
      if (source == formSectionType.FORM) {
        const modifiedAnswer = modifiedTemplate[sectionIndex].questionFields[
          fieldIndex
        ].answer?.value.filter((data) => data.awsKey != awsKey);

        modifiedTemplate[sectionIndex].questionFields[fieldIndex].answer = {
          ...modifiedTemplate[sectionIndex].questionFields[fieldIndex].answer,
          value: modifiedAnswer,
        };

        setInspectionData((prev) => ({
          ...prev,
          selectedForm: {
            ...prev.selectedForm,
            template: modifiedTemplate,
          },
        }));

        setEachAnswerToSave({
          removeFileUploadFieldData: { awsKey },
          answer: { value: modifiedAnswer },
          sectionId,
          questionId,
        });
      } else {
        const modifiedAnswer = modifiedTemplate[sectionIndex].tableDataSource[
          tableRowIndex
        ][questionId]?.value.filter((data) => data.awsKey != awsKey);

        modifiedTemplate[sectionIndex].tableDataSource = modifiedTemplate[
          sectionIndex
        ].tableDataSource.map((row, index) => {
          if (index == tableRowIndex) {
            row[questionId] = {
              createdBy: userDetails._id,
              createdDate: Date.now(),
              value: modifiedAnswer,
            };
          }
          return row;
        });

        setInspectionData((prev) => ({
          ...prev,
          selectedForm: {
            ...prev.selectedForm,
            template: modifiedTemplate,
          },
        }));

        setEachAnswerToSave({
          tableSectionId: sectionId,
          tableSectionDataSource: [
            ...modifiedTemplate[sectionIndex].tableDataSource,
          ],
          removeFileUploadFieldData: { awsKey },
        });
      }
    }
  };

  const setEachAnswer = async () => {
    if (Object.keys(eachAnswerToSave).length) {
      const abortController = new AbortController();
      const questionId =
        _.get(eachAnswerToSave, "questionId") ||
        _.get(eachAnswerToSave, "tableSectionId");
      if (questionId) {
        if (_.get(abortControllerList, `current.${questionId}`)) {
          _.get(abortControllerList, `current.${questionId}`).forEach(
            (controller) => {
              controller.abort();
            },
          );
          abortControllerList.current[questionId] = [];
        }
        if (_.get(abortControllerList, `current.${questionId}`)) {
          abortControllerList.current[questionId].push(abortController);
        } else {
          abortControllerList.current[questionId] = [abortController];
        }
      }
      try {
        const res = await taskInspectionAndProgressFormsController(
          {
            action: "update",
            _id: resourceId,
            data: eachAnswerToSave,
          },
          abortController,
        );
        if (res.data.addedAttachmentData) {
          const { obj } = res.data.addedAttachmentData;
          const { sectionIndex, fieldIndex } = eachAnswerToSave;

          setInspectionData((prev) => {
            prev.selectedForm.template[sectionIndex].questionFields[
              fieldIndex
            ].attachmentsData = [
              ...prev.selectedForm.template[sectionIndex].questionFields[
                fieldIndex
              ].attachmentsData,
              { ...obj },
            ];
            return {
              ...prev,
              lastSaved: res.data.lastSaved,
            };
          });
        }
        if (res.data.status) {
          setAnswerSaved(true);
          setTimeout(() => {
            setAnswerSaved(false);
          }, 500);
          setInspectionData((prev) => ({
            ...prev,
            lastSaved: res.data.lastSaved,
          }));
        } else {
          dispatch(
            showToastNotification({
              content: res.data.errorMessage,
              type: TOAST_TYPE_ERROR,
            }),
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    setEachAnswer();
  }, [eachAnswerToSave]);

  useEffect(() => {
    if (inspectionData && inspectionData._id && isDataFetched && mobile) {
      addValidationToForms();
    }
  }, [inspectionData, modifySection, isDataFetched]);

  function addValidationToForms() {
    const allSectionsInCurrentLevel = [];
    inspectionData.formFlow[inspectionData.currentLevelOfForm].dataSource.map(
      (data) => {
        if (data.type === "Fill") {
          data.sections.map((section) =>
            allSectionsInCurrentLevel.push(section._id),
          );
        } else if (data.type === "Modify") {
          data.sections.map((section) => {
            if (modifySection.includes(section._id)) {
              allSectionsInCurrentLevel.push(section._id);
            }
          });
        }
      },
    );
    const options = { rules: {} };
    inspectionData.selectedForm.template.some((section) => {
      const generateRuleObj = (eachField, row) => {
        const ruleObj = {};
        const value =
          (row ? row[eachField._id]?.value : eachField.answer?.value) ?? "";

        if (eachField.isMandatory === true) {
          ruleObj.required = true;
        }
        if (["single-line", "multi-line"].includes(eachField.fieldType)) {
          ruleObj.minLength = eachField.minFieldLimit ?? 0;
          if (eachField.maxFieldLimit) {
            ruleObj.maxLength = eachField.maxFieldLimit;
          }
        }
        if (eachField.fieldType === "number") {
          ruleObj.minRange = eachField.minFieldRange ?? 0;
          if (eachField.maxFieldRange) {
            ruleObj.maxRange = eachField.maxFieldRange;
          }
        }
        if (eachField.fieldType === "multi-select") {
          ruleObj.minSelection = eachField.minFiledSelection ?? 0;
          if (eachField.maxFieldSelection) {
            ruleObj.maxSelection = eachField.maxFieldSelection;
          }
        }
        if (["fileUpload", "image"].includes(eachField.fieldType)) {
          ruleObj.minNoOfFiles = eachField.minNoOfFiles ?? 0;
          if (eachField.maxNoOfFiles) {
            ruleObj.maxNoOfFiles = eachField.maxNoOfFiles;
          }
        }

        return { ...ruleObj, value };
      };

      if (
        section.sectionCode === presetSectionsCodeObject.tableSection &&
        allSectionsInCurrentLevel.includes(section._id)
      ) {
        section.questionFields.forEach((eachField) => {
          section.tableDataSource.forEach((row) => {
            const ruleName = `${row.rowId}${eachField._id}`;
            options.rules[ruleName] = generateRuleObj(eachField, row);
          });
        });
      } else if (
        section.questionFields &&
        allSectionsInCurrentLevel.includes(section._id)
      ) {
        section.questionFields.forEach((eachField) => {
          const ruleName = `${eachField._id}`;
          options.rules[ruleName] = generateRuleObj(eachField);
        });
      }
    });
    formValidator.updateRules(options.rules);
  }

  const openAddUserModal = ({
    eachField,
    fieldIndex,
    sectionIndex,
    section,
    source = formSectionType.FORM,
    tableRowIndex,
    handleCustomFieldData,
  }) => {
    if (eachField.onlyCurrentUser) {
      const currentUser = {
        _id: userDetails.id,
        signature: userDetails.signature,
        firstName: userDetails.firstName,
        fullName: userDetails.fullName,
        lastName: userDetails.lastName,
        profilePic: userDetails.profilePic,
      };
      if (handleCustomFieldData) {
      } else if (inspectionData._id) {
        const modifiedTemplate = _.cloneDeep(
          inspectionData.selectedForm.template,
        );
        if (source == formSectionType.FORM) {
          modifiedTemplate[sectionIndex].questionFields[fieldIndex].answer = {
            value: [currentUser],
          };
          setInspectionData((prev) => ({
            ...prev,
            selectedForm: {
              ...prev.selectedForm,
              template: modifiedTemplate,
            },
          }));

          setEachAnswerToSave({
            sectionId: section._id,
            questionId: eachField._id,
            answer: { value: [currentUser] },
          });
        } else {
          modifiedTemplate[sectionIndex].tableDataSource[tableRowIndex][
            eachField._id
          ] = {
            createdBy: userDetails._id,
            createdDate: Date.now(),
            value: [currentUser],
          };
          setInspectionData((prev) => ({
            ...prev,
            selectedForm: {
              ...prev.selectedForm,
              template: modifiedTemplate,
            },
          }));

          setEachAnswerToSave({
            tableSectionId: section._id,
            tableSectionDataSource: [
              ...modifiedTemplate[sectionIndex].tableDataSource,
            ],
          });
        }
        if (mobile) {
          dispatch(
            showToastNotification({
              content: "User Added",
              type: TOAST_TYPE_SUCCESS,
            }),
          );
        }
      }
    } else if (handleCustomFieldData) {
    } else if (inspectionData._id) {
      setShowAddUsersModal({
        status: true,
        fieldData: { ...eachField },
        fieldIndex,
        sectionIndex,
        section,
        source,
        tableRowIndex,
      });
      bottomSheetRefUsers && bottomSheetRefUsers.current.snapToIndex(0);
    }
  };

  const closeUsersModal = () => {
    setShowAddUsersModal({ status: false });
  };

  const removeUserFromList = ({
    fieldIndex,
    sectionIndex,
    user,
    eachField,
    section,
    source = formSectionType.FORM,
    tableRowIndex,
    handleCustomFieldData,
  }) => {
    const modifiedTemplate = _.cloneDeep(inspectionData.selectedForm.template);

    if (handleCustomFieldData) {
    } else if (inspectionData._id) {
      if (source == formSectionType.FORM) {
        const allUsers =
          modifiedTemplate[sectionIndex].questionFields[fieldIndex].answer
            .value;
        const filteredUsers = allUsers.filter((data) => data._id != user._id);

        modifiedTemplate[sectionIndex].questionFields[fieldIndex].answer = {
          value: filteredUsers,
        };

        setInspectionData((prev) => ({
          ...prev,
          selectedForm: {
            ...prev.selectedForm,
            template: modifiedTemplate,
          },
        }));

        setEachAnswerToSave({
          sectionId: section._id,
          questionId: eachField._id,
          answer: { value: filteredUsers },
        });

        mobile &&
          setShowAddUsersModal({
            ...showAddUserModal,
            status: true,
            fieldData:
              modifiedTemplate[sectionIndex].questionFields[fieldIndex],
            fieldIndex,
            sectionIndex,
            section,
            source,
            tableRowIndex,
          });
      } else {
        const allUsers =
          modifiedTemplate[sectionIndex].tableDataSource[tableRowIndex][
            eachField._id
          ].value;
        const filteredUsers = allUsers.filter((data) => data._id != user._id);

        modifiedTemplate[sectionIndex].tableDataSource[tableRowIndex][
          eachField._id
        ] = {
          createdBy: userDetails._id,
          createdDate: Date.now(),
          value: filteredUsers,
        };

        setInspectionData((prev) => ({
          ...prev,
          selectedForm: {
            ...prev.selectedForm,
            template: modifiedTemplate,
          },
        }));

        setEachAnswerToSave({
          tableSectionId: section._id,
          tableSectionDataSource: [
            ...modifiedTemplate[sectionIndex].tableDataSource,
          ],
        });
        mobile &&
          setShowAddUsersModal({
            ...showAddUserModal,
            status: true,
            fieldData:
              modifiedTemplate[sectionIndex].questionFields[fieldIndex],
            fieldIndex,
            sectionIndex,
            section,
            source,
            tableRowIndex,
          });
      }
    }
  };

  const openAddedUserModalInMobile = ({
    eachField,
    fieldIndex,
    sectionIndex,
    showCrossIcon = false,
    section,
    source,
    tableRowIndex,
  }) => {
    setShowAddUsersModal({
      status: true,
      fieldData: { ...eachField },
      fieldIndex,
      sectionIndex,
      showCrossIcon,
      section,
      source,
      tableRowIndex,
    });
    bottomSheetRefAddedUsers && bottomSheetRefAddedUsers.current.snapToIndex(0);
  };

  const verifySection = (sectionIds) => {
    const tempSectionIds = [];
    sectionIds.map((id) => {
      if (!inspectionData.sectionsVerified.includes(id)) {
        tempSectionIds.push(id);
      }
    });
    if (tempSectionIds.length) {
      setInspectionData((prev) => ({
        ...prev,
        sectionsVerified: [...prev.sectionsVerified, ...tempSectionIds],
      }));
      setEachAnswerToSave({ sectionsVerified: [...tempSectionIds] });
    }
  };

  function handleAttachmentClick(sectionIndex, fieldIndex) {
    setShowAttachmentModal(true);
    setAttachmentModalOpenedFor({
      sectionIndex,
      fieldIndex,
    });
    bottomSheetRefFiles && bottomSheetRefFiles.current.snapToIndex(0);
  }

  const closeAttachmentModal = () => {
    setShowAttachmentModal(false);
  };

  const handleModifyCancel = (event, sectionId) => {
    event && event.stopPropagation();
    setModifySection((prev) => prev.filter((id) => id != sectionId));
  };

  const verifyToggleStatus = (section) => {
    const allowedUsersToVerifyPostSub =
      section.permissions[inspectionData.currentLevelOfForm].Verify;
    return !(
      allowedUsersToVerifyPostSub.includes(userDetails.id) &&
      inspectionData.isSubmitted
    );
  };

  const saveInspectionData = async (type) => {
    const data = {};
    if (type == "submit") {
      if (
        inspectionData.currentLevelOfForm ==
        inspectionData.formFlow.length - 2
      ) {
        data.isSubmitted = true;
      } else {
        data.isSubmitted = false;
        data.proceedToNextLevel = true;
      }
    } else if (type == "approve") {
      data.isApproved = true;
      data.actionComment = formActionDetails.comment;
      data.actionSignDoc = formActionDetails.signDoc;
      data.actionSignType = formActionDetails.signType;
    } else if (type == "reject") {
      data.isRejected = true;
      data.actionComment = formActionDetails.comment;
      data.actionSignDoc = formActionDetails.signDoc;
      data.actionSignType = formActionDetails.signType;
    }
    setActionBtnDisabled(true);
    const res = await taskInspectionAndProgressFormsController({
      action: "update",
      _id: resourceId,
      data,
    });
    setActionBtnDisabled(false);
    return res;
  };

  const getSelectedFiles = (args) => {
    let sectionId = "";
    inspectionData.selectedForm.template.map((section) => {
      if (section.sectionHeading == "References") {
        sectionId = section._id;
      }
    });
    setInspectionData((prev) => ({
      ...prev,
      referenceFileVersionIds: [...args],
    }));
    setEachAnswerToSave({
      referenceFileVersionIds: [...args],
      referencesSectionId: sectionId,
    });
  };

  const handleFormActionClick = (args) => {
    if (args.item.id == "approve") {
      setOpenCommentDialog({ status: true, source: "Approve" });
    }
    if (args.item.id == "reject") {
      setOpenCommentDialog({ status: true, source: "Reject" });
    }
    if (args.item.id == "submit") {
      handleSubmitClick();
    }
  };

  const beforeOpenActionDropdown = (args) => {
    const allowedUserIdsToReject = inspectionData.formFlow[
      inspectionData.currentLevelOfForm
    ].allowedUsersToReject?.map((user) => user.id);
    const rejectBtnIsDisabled = !allowedUserIdsToReject.includes(
      userDetails.id,
    );

    if (inspectionData.isSubmitted) {
      const allowedUserIdsToApprove = inspectionData.formFlow[
        inspectionData.currentLevelOfForm
      ].allowedUsersToApprove?.map((user) => user.id);
      const approveBtnIsDisabled = !(
        allowedUserIdsToApprove.includes(userDetails.id) &&
        inspectionData.isSubmitted
      );

      if (approveBtnIsDisabled) {
        args.items[0].disabled = true;
      } else {
        args.items[0].disabled = false;
      }
    } else {
      const allowedUserIdsToSubmit = inspectionData.formFlow[
        inspectionData.currentLevelOfForm
      ].allowedUsersToSubmit?.map((user) => user.id);
      const submitBtnIsDisabled =
        !allowedUserIdsToSubmit.includes(userDetails.id) &&
        !inspectionData.isArchived.status;

      if (submitBtnIsDisabled) {
        args.items[0].disabled = true;
      } else {
        args.items[0].disabled = false;
      }
    }

    if (rejectBtnIsDisabled) {
      args.items[1].disabled = true;
    } else {
      args.items[1].disabled = false;
    }
  };

  const handleFormStateChange = async () => {
    const res = await taskInspectionAndProgressFormsController({
      action: "archive",
      resourceIds: [inspectionData._id],
      data: { isArchived: { status: true } },
    });

    if (res.data.status) {
      dispatch(
        showToastNotification({
          content: "Successfully Archived",
          type: TOAST_TYPE_SUCCESS,
        }),
      );
    }
    let urlLink;
    if (inspectionData?.contractId) {
      urlLink = getContractDetailUrl({
        selectedTab:
          formCategoryObject[inspectionData.resourceType].selectedTab,
        projectId: params.projectId,
        contractId: inspectionData?.contractId,
      });
    } else {
      urlLink = getFormsListUrl({
        selectedTab: "list",
        projectId: params.projectId,
        resourceType: inspectionData.resourceType,
      });
    }
    navigate(urlLink);
  };

  const handleCollapseAll = () => {
    setAccordionExpandedState([]);
  };
  const handleExpandAll = (template = []) => {
    const sectionIds = template.map((section) => section._id);
    setAccordionExpandedState([...sectionIds]);
  };

  const handleAccStateChange = (args, sectionId) => {
    setAccordionExpandedState((prev) => {
      if (prev.includes(sectionId)) {
        return prev.filter((id) => id != sectionId);
      }

      return [...prev, sectionId];
    });
  };

  const showToastAndRedirect = (content) => {
    dispatch(
      showToastNotification({
        content,
        type: TOAST_TYPE_SUCCESS,
      }),
    );
    if (inspectionData?.contractId) {
      if (!mobile) {
        const urlLink = getContractDetailUrl({
          selectedTab:
            formCategoryObject[inspectionData.resourceType].selectedTab,
          projectId: params.projectId,
          contractId: inspectionData?.contractId,
        });
        navigate(urlLink);
      } else {
        navigation.replace("FormsList", {
          formList: inspectionData.resourceType,
          projectId: params.projectId,
          contractId: inspectionData?.contractId,
        });
      }
    } else if (!mobile) {
      const urlLink = getFormsListUrl({
        selectedTab: "list",
        projectId: params.projectId,
        resourceType: inspectionData.resourceType,
      });
      navigate(urlLink);
    } else {
      navigation.replace("FormsList", {
        formList: inspectionData.resourceType,
        projectId: params.projectId,
      });
    }
  };

  const handleSubmitClick = async () => {
    const allSectionsInCurrentLevel = [];
    inspectionData.formFlow[inspectionData.currentLevelOfForm].dataSource.map(
      (data) => {
        data.sections.map((section) =>
          allSectionsInCurrentLevel.push(section._id),
        );
      },
    );
    let errorToastContent =
      "Form submission failed: Not all mandatory fields filled.";
    let isAllMandatoryFieldsFilled = true;
    inspectionData.selectedForm.template.some((section) => {
      if (
        section.sectionCode == presetSectionsCodeObject.tableSection &&
        allSectionsInCurrentLevel.includes(section._id)
      ) {
        section.questionFields.some((eachField) => {
          section.tableDataSource.some((row) => {
            if (eachField.isMandatory) {
              if (
                ["", undefined, null, [], {}].includes(
                  row[eachField._id]?.value,
                ) ||
                (Array.isArray(row[eachField._id]?.value) &&
                  row[eachField._id].value?.join("")?.trim() == "")
              ) {
                isAllMandatoryFieldsFilled = false;
                return true;
              }
              if (
                ["single-line", "multi-line"].includes(eachField.fieldType) &&
                eachField.minFieldLimit &&
                row[eachField._id]?.value?.length < eachField.minFieldLimit
              ) {
                isAllMandatoryFieldsFilled = false;
                return true;
              }
            }
            if (
              !eachField.isMandatory &&
              eachField.minFieldLimit &&
              row[eachField._id]?.value?.length > 0
            ) {
              if (
                ["single-line", "multi-line"].includes(eachField.fieldType) &&
                eachField.minFieldLimit > row[eachField._id]?.value?.length
              ) {
                isAllMandatoryFieldsFilled = false;
                return true;
              }
            }
            if (
              eachField.isMandatory &&
              eachField.minNoOfFiles &&
              eachField.minNoOfFiles > row[eachField._id]?.value?.length
            ) {
              isAllMandatoryFieldsFilled = false;
              return true;
            }
            if (
              eachField.isMandatory &&
              eachField.maxNoOfFiles &&
              eachField.maxNoOfFiles < row[eachField._id]?.value?.length
            ) {
              isAllMandatoryFieldsFilled = false;
              errorToastContent =
                "Form submission failed: Files uploaded exceeds the max limit set for this field";
              return true;
            }
            if (
              ["single-line", "multi-line"].includes(eachField.fieldType) &&
              eachField.maxFieldLimit &&
              row[eachField._id]?.value?.length > eachField.maxFieldLimit
            ) {
              isAllMandatoryFieldsFilled = false;
              return true;
            }
          });
        });
      } else if (section.questionFields && !section.sectionCode) {
        if (allSectionsInCurrentLevel.includes(section._id)) {
          section.questionFields.some((eachField) => {
            if (
              eachField.isMandatory &&
              (["", undefined, null, [], {}].includes(
                eachField.answer?.value,
              ) ||
                (Array.isArray(eachField.answer?.value) &&
                  eachField.answer?.value.join("")?.trim() == ""))
            ) {
              isAllMandatoryFieldsFilled = false;
              return true;
            }

            if (
              eachField.isMandatory &&
              eachField.minNoOfFiles &&
              eachField.minNoOfFiles > eachField.answer?.value?.length
            ) {
              isAllMandatoryFieldsFilled = false;
              return true;
            }
            if (
              eachField.isMandatory &&
              eachField.maxNoOfFiles &&
              eachField.maxNoOfFiles < eachField.answer?.value?.length
            ) {
              isAllMandatoryFieldsFilled = false;
              errorToastContent =
                "Form submission failed: Files uploaded exceeds the max limit set for this field";
              return true;
            }

            if (
              eachField.isMandatory &&
              eachField.minFieldLimit &&
              eachField.answer?.value?.length < eachField.minFieldLimit
            ) {
              isAllMandatoryFieldsFilled = false;
              return true;
            }

            if (
              !eachField.isMandatory &&
              eachField.minFieldLimit &&
              eachField.answer?.value?.length > 0
            ) {
              if (
                ["single-line", "multi-line"].includes(eachField.fieldType) &&
                eachField.minFieldLimit > eachField.answer?.value?.length
              ) {
                isAllMandatoryFieldsFilled = false;
                return true;
              }
            }

            if (
              ["single-line", "multi-line"].includes(eachField.fieldType) &&
              eachField.maxFieldLimit &&
              eachField.answer?.value?.length > eachField.maxFieldLimit
            ) {
              isAllMandatoryFieldsFilled = false;
              return true;
            }

            return false;
          });
          return !isAllMandatoryFieldsFilled;
        }
        return false;
      }
      return false;
    });
    if (isAllMandatoryFieldsFilled) {
      const res = await saveInspectionData("submit");
      if (res.data.status) {
        await fetchFormData();
        dispatch(
          showToastNotification({
            content:
              "Form submitted successfully navigate back to see full list",
            type: TOAST_TYPE_SUCCESS,
          }),
        );
      } else {
        dispatch(
          showToastNotification({
            content: res.data.errorMessage,
            type: TOAST_TYPE_ERROR,
          }),
        );
      }
    } else {
      dispatch(
        showToastNotification({
          content: errorToastContent,
          type: TOAST_TYPE_ERROR,
        }),
      );
      if (!mobile) {
        formObject.current.validate();
      } else {
        formValidator.validateAll();
      }
    }
  };

  const closeCommentDialog = () => {
    setOpenCommentDialog(false);
    setShowWaitSpinner(false);
    setFormActionDetails({
      comment: "",
      signDoc: null,
      signType: SignTypeValues.ATTACH_SIGNED_DOCUMENT,
    });
  };

  const handleApproveClick = async () => {
    if (
      inspectionData.sectionsVerified?.length !=
      inspectionData.selectedForm.template.length
    ) {
      dispatch(
        showToastNotification({
          content: "Please verify all sections before approving the form",
          type: TOAST_TYPE_ERROR,
        }),
      );
    } else {
      setShowWaitSpinner(true);
      const res = await saveInspectionData("approve");
      if (res.data.status) {
        showToastAndRedirect("Form Approved Successfully");
        setShowWaitSpinner(false);
      } else {
        dispatch(
          showToastNotification({
            content: res.data.errorMessage,
            type: TOAST_TYPE_ERROR,
          }),
        );
        setShowWaitSpinner(false);
      }
    }
  };

  function verifyAllState() {
    let isVerifyAllDisabled = true;
    _.get(inspectionData, "selectedForm.template", []).map((section) => {
      const secPermission = getSectionPermission({
        section,
        inspectionData,
        userId: userDetails.id,
        type: "Verify",
      });
      if (
        secPermission &&
        inspectionData.sectionsVerified.length <
          inspectionData.selectedForm.template.length
      ) {
        isVerifyAllDisabled = false;
      }
    });
    return isVerifyAllDisabled;
  }

  const handleSaveCmntClick = async () => {
    if (openCommentDialog.source == "Reject") {
      setShowWaitSpinner(true);
      const res = await saveInspectionData("reject");
      if (res.data.status) {
        showToastAndRedirect(res.data.message);
        setShowWaitSpinner(false);
      } else {
        dispatch(
          showToastNotification({
            content: res.data.errorMessage,
            type: TOAST_TYPE_ERROR,
          }),
        );
        setShowWaitSpinner(false);
      }
    } else {
      handleApproveClick();
    }
  };

  function onClickWarningModalConfirm({ source, showWarningModal }) {
    if (source == "verify") {
      verifySection([showWarningModal.data._id]);
    }
    if (source == "modify") {
      setModifySection((prev) => [...prev, showWarningModal.data._id]);
    }
    if (source == "verifyAll") {
      const tempSectionIds = [];
      inspectionData.selectedForm.template.map((section) => {
        const permission = getSectionPermission({
          section,
          inspectionData,
          userId: userDetails.id,
          type: "Verify",
        });
        if (permission) {
          tempSectionIds.push(section._id);
        }
      });
      verifySection([...tempSectionIds]);
    }
    hideWarningModal();
  }

  const handleShowWarningModal = (event, source, section) => {
    event && event.stopPropagation();
    setShowWarningModal({ status: true, source, data: section });
  };
  const hideWarningModal = () => {
    setShowWarningModal({ status: false });
  };

  const handleExportPdf = async () => {
    const formData = {
      formId: inspectionData._id,
      projectId: inspectionData.projectId,
      paperSize: openExportModal.paperSize,
      tableSectionFormat: openExportModal.tableFormat,
    };
    setOpenExportModal({ state: false, paperSize: "A4", tableFormat: "card" });
    downloadFile({ formData, type: "Download" });
  };

  const handleAddTableRowClick = ({ section, sectionIndex }) => {
    const newObjRow = {
      rowId: uuid(),
    };
    inspectionData.selectedForm.template[sectionIndex].questionFields.map(
      (field) => {
        newObjRow[field._id] = {};
      },
    );
    const modifiedTemplate = _.cloneDeep(inspectionData.selectedForm.template);

    modifiedTemplate[sectionIndex].tableDataSource = [
      ...modifiedTemplate[sectionIndex].tableDataSource,
      newObjRow,
    ];

    setInspectionData((prev) => ({
      ...prev,
      selectedForm: {
        ...prev.selectedForm,
        template: modifiedTemplate,
      },
    }));

    setEachAnswerToSave({
      tableSectionId: section._id,
      tableSectionDataSource: [
        ...modifiedTemplate[sectionIndex].tableDataSource,
      ],
    });
  };

  const handleDeleteTableRow = ({ section, sectionIndex, rowIndex }) => {
    const modifiedTemplate = _.cloneDeep(inspectionData.selectedForm.template);

    const dataSource = modifiedTemplate[sectionIndex].tableDataSource;

    dataSource.splice(rowIndex, 1);

    modifiedTemplate[sectionIndex].tableDataSource = [...dataSource];

    setInspectionData((prev) => ({
      ...prev,
      selectedForm: {
        ...prev.selectedForm,
        template: modifiedTemplate,
      },
    }));

    setEachAnswerToSave({
      tableSectionId: section._id,
      tableSectionDataSource: [...dataSource],
    });
  };

  function handleAddUserClick({ preSelectedUsers, sectionIndex, fieldIndex }) {
    const selectedUsers = [...preSelectedUsers];

    if (showAddUserModal.source == formSectionType.FORM) {
      const modifiedTemplate = _.cloneDeep(
        inspectionData.selectedForm.template,
      );
      if (modifiedTemplate[sectionIndex].questionFields[fieldIndex].answer) {
        modifiedTemplate[sectionIndex].questionFields[fieldIndex].answer = {
          ...modifiedTemplate[sectionIndex].questionFields[fieldIndex].answer,
          value: selectedUsers,
        };
      } else {
        modifiedTemplate[sectionIndex].questionFields[fieldIndex].answer = {
          value: selectedUsers,
        };
      }

      setInspectionData((prev) => ({
        ...prev,
        selectedForm: {
          ...prev.selectedForm,
          template: modifiedTemplate,
        },
      }));

      setEachAnswerToSave({
        sectionId: modifiedTemplate[sectionIndex]._id,
        questionId:
          modifiedTemplate[sectionIndex].questionFields[fieldIndex]._id,
        answer: { value: selectedUsers },
      });
    } else {
      const modifiedTemplate = _.cloneDeep(
        inspectionData.selectedForm.template,
      );

      const modifiedTableDataSource = modifiedTemplate[
        sectionIndex
      ].tableDataSource.map((row, index) => {
        if (index == showAddUserModal.tableRowIndex) {
          row[showAddUserModal.fieldData._id] = {
            createdDate: Date.now(),
            createdBy: userDetails._id,
            value: selectedUsers,
          };
        }
        return row;
      });

      modifiedTemplate[sectionIndex].tableDataSource = modifiedTableDataSource;

      setInspectionData((prev) => ({
        ...prev,
        selectedForm: {
          ...prev.selectedForm,
          template: modifiedTemplate,
        },
      }));

      setEachAnswerToSave({
        tableSectionId: modifiedTemplate[sectionIndex]._id,
        tableSectionDataSource: modifiedTableDataSource,
      });
    }
  }
  const handleURLModalState = ({
    state,
    sectionIndex = 0,
    eachField = 0,
    tableRowIndex = 0,
    answer = {},
    fieldIndex = 0,
    sectionId,
    source,
  }) => {
    setShowUrlModal((prev) => ({
      ...prev,
      state,
      sectionIndex,
      eachField,
      tableRowIndex,
      answerValue: answer.value,
      fieldIndex,
      sectionId,
      source,
    }));
    if (state) {
      setURLData({
        URL: _.get(answer, "value.URL", ""),
        displayText: _.get(answer, "value.displayText", ""),
      });
    } else {
      setURLData({});
    }
  };

  const handleSaveUrlData = (handleCustomFieldData) => {
    if (!URLData.displayText || !URLData.URL) {
      dispatch(
        showToastNotification({
          content: `${!URLData.displayText ? "Display Text" : "URL"} is Missing`,
          type: TOAST_TYPE_ERROR,
        }),
      );
      return;
    }
    handleURLModalState({ state: false });
    const answerValue = {
      ...URLData,
    };
    const {
      eachField,
      sectionIndex,
      tableRowIndex,
      fieldIndex,
      sectionId,
      source,
    } = showUrlModal;
    const { _id } = eachField;

    if (handleCustomFieldData) {
      handleCustomFieldData({
        eachField: showUrlModal.eachField,
        answerValue,
        fieldIndex: showUrlModal.fieldIndex,
        sectionIndex: showUrlModal.sectionIndex,
        tableRowIndex: showUrlModal.tableRowIndex,
        source: showUrlModal.source,
      });
    } else if (inspectionData._id) {
      if (source == formSectionType.TABLE_SECTION) {
        setInspectionData((prev) => {
          const modifiedTemplate = _.cloneDeep(prev.selectedForm.template);
          const modifiedTableDataSource = modifiedTemplate[
            sectionIndex
          ].tableDataSource.map((row, index) => {
            if (index == tableRowIndex) {
              row[_id] = {
                createdDate: Date.now(),
                createdBy: userDetails._id,
                value: answerValue,
              };
            }
            return row;
          });
          modifiedTemplate[sectionIndex].tableDataSource =
            modifiedTableDataSource;

          setEachAnswerToSave({
            tableSectionId: sectionId,
            tableSectionDataSource: modifiedTableDataSource,
          });

          return {
            ...prev,
            selectedForm: {
              ...prev.selectedForm,
              template: modifiedTemplate,
            },
          };
        });
      } else {
        setInspectionData((prev) => {
          const modifiedTemplate = _.cloneDeep(prev.selectedForm.template);
          modifiedTemplate[sectionIndex].questionFields[fieldIndex].answer = {
            value: answerValue,
          };
          return {
            ...prev,
            selectedForm: {
              ...prev.selectedForm,
              template: modifiedTemplate,
            },
          };
        });

        setEachAnswerToSave({
          sectionId,
          questionId: _id,
          answer: { value: answerValue },
        });
      }
    }
  };

  const handleChangeUrl = ({ name, value }) => {
    setURLData((prev) => ({ ...prev, [name]: value }));
  };

  return {
    inspectionData,
    isFileUploading,
    setInspectionData,
    showAtttachmentModal,
    setShowAttachmentModal,
    attachmentModalOpenedFor,
    setAttachmentModalOpenedFor,
    sideBarTaskDetails,
    setSideBarTaskDetails,
    eachAnswerToSave,
    setEachAnswerToSave,
    answerSaved,
    setAnswerSaved,
    openCommentDialog,
    setOpenCommentDialog,
    formActionDetails,
    setFormActionDetails,
    accordionExpandedState,
    setAccordionExpandedState,
    showSideBar,
    setShowSideBar,
    showAddUserModal,
    setShowAddUsersModal,
    showWarningModal,
    setShowWarningModal,
    modifySection,
    setModifySection,
    sideBarScheduleDetails,
    sideBarAccordions,
    setInitialData,
    handleInputChange,
    setEachAnswer,
    // dateFieldFocused,
    openAddUserModal,
    closeUsersModal,
    saveInspectionData,
    getSelectedFiles,
    removeUserFromList,
    verifySection,
    handleAttachmentClick,
    closeAttachmentModal,
    handleModifyCancel,
    verifyToggleStatus,
    handleFormActionClick,
    beforeOpenActionDropdown,
    handleFormStateChange,
    handleCollapseAll,
    handleExpandAll,
    handleAccStateChange,
    showToastAndRedirect,
    handleSubmitClick,
    closeCommentDialog,
    handleShowWarningModal,
    hideWarningModal,
    GanttDetails,
    userDetails,
    projectData,
    handleSaveCmntClick,
    teamData,
    setTeamData,
    loactionData,
    setLoactionData,
    nameOfTeamLocationRespectToId,
    setNameOfTeamLocationRespectToId,
    setDataFetched,
    isDataFetched,
    openAddedUserModalInMobile,
    onClickWarningModalConfirm,
    handleFileUploadChange,
    handleRemoveUploadedFile,
    setTableSectionConfig,
    tableSectionConfig,
    handleExportPdf,
    actionBtnDisabled,
    formObject,
    // addValidationToInputFields,
    handleDeleteTableRow,
    handleAddTableRowClick,
    handleAddUserClick,
    getTeamDataForMobile,
    getLocationForMobile,
    setAnswerSaved,
    showWaitSpinner,
    selectSignType,
    saveEditedImage,
    verifyAllState,
    imageFieldSelectedIndex,
    setImageFieldSelectedIndex,
    customModules,
    formValidator,
    setOpenExportModal,
    openExportModal,
    handleURLModalState,
    showUrlModal,
    handleSaveUrlData,
    setURLData,
    handleChangeUrl,
  };
};
export default useForms;
