import React, { FC, createRef, forwardRef } from "react";
import Accordion from '@mui/material/Accordion';
import MUIAccordianProps from '../../../interface';

const Accordian = forwardRef((props: MUIAccordianProps, ref: any) => {
  const accordianRef = ref || createRef(null)

  return (
    <Accordion
      {...props}
      className={props.className}
      ref={accordianRef}
      disableGutters={true}
      classes={{ region: 'h-full' }}
      sx={{
        boxShadow: "none",
        border: props.border ? props.border : "",
        borderRadius: props.borderRadius ? `${props.borderRadius} !important` : "",
        '&::before': {
          height: '0px', //For removing extra top line
        },
        '& .MuiAccordionDetails-root': {
          height: '100%',
          padding: '8px'
        },
        '& .MuiAccordionSummary-root': {
          padding: '0px'
        },
        '& .MuiAccordionSummary-content': {
          margin: 0,
        },
        '& .MuiButtonBase-root.MuiListItemButton-root.Mui-selected': {
          backgroundColor: '#CAECFF'
        },
        ...props.sx
      }}
    >
      {props.children}
    </Accordion>
  );
});
export default Accordian;