const { folderRoles } = require("../server/roles/roles.constant")

const getPermissionConstants = () => {
  const permissions = folderRoles()
  const obj = {}

  Object.values(permissions).map((role) => {
    obj[role.code] = role.id
  })
  return obj
}

const MAX_FILE_UPLOAD_SIZE = 10 * 1024 * 1024 * 1024 // 10 GB
const RESOURCE_TYPE_PROJECT = 'RESOURCE_TYPE_PROJECT';
const RESOURCE_TYPE_PORTAL = 'RESOURCE_TYPE_PORTAL';
const RESOURCE_TYPE_TRANSMITTAL = 'RESOURCE_TYPE_TRANSMITTAL';
const RESOURCE_TYPE_SPACE = 'RESOURCE_TYPE_SPACE';

const getPermissions = () => {
  return {
    ACCEPT_COMPANIES: {
      description: 'Accept Company',
      id: 41,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    ACCESS_PORTAL_SETTINGS: {
      description: 'Access Portal Settings',
      id: 63,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    ACCESS_PROJECTS_PART_OF: {
      description: 'Access projects that they are part of',
      id: 64,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    ASSIGN_PROJECT_TO_REGION: {
      description: 'Assign projects to a region',
      id: 44,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    CHANGE_PORTAL_INFORMATION: {
      description: 'Change Portal information',
      id: 65,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    CONFIGURATION_SETTINGS_MODIFY: {
      description: 'Modify Configuration Settings',
      id: 18,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PROJECT
    },
    CONFIGURATION_SETTINGS_VIEW: {
      description: 'View Configuration Settings',
      id: 17,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PROJECT
    },
    CREATE_PORTAL_ROLES: {
      description: 'Create Portal Role',
      id: 50,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    CREATE_REGION: {
      description: 'Create regions',
      id: 47,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    DELETE_PORTAL_ROLES: {
      description: 'Delete Portal Role',
      id: 51,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    DELETE_REGION: {
      description: 'Delete regions',
      id: 46,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    EDIT_INFORMATION_OF_COMPANIES: {
      description: 'Edit Company information',
      id: 43,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    EDIT_PORTAL_ROLES: {
      description: 'Edit Portal Roles',
      id: 53,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    EDIT_PROJECT_INFO: {
      description: 'Edit project information',
      id: 5,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PROJECT
    },
    EDIT_REGION: {
      description: 'Edit regions',
      id: 48,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    FOLDER_ROOT_PERMISSION_CHANGE: {
      description: 'Change Root folder permissions of user',
      id: 11,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PROJECT
    },
    PORTAL_ADD_COMPANIES: {
      description: 'Add company to the portal',
      id: 40,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    PORTAL_REMOVE_COMPANIES: {
      description: 'Remove company from the portal',
      id: 39,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    PROJECT_ADD_TO_PORTAL: {
      description: 'Add projects to the portal',
      id: 33,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    PROJECT_ARCHIVE_OF_PORTAL: {
      description: 'Archive projects of the portal',
      id: 35,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    PROJECT_INFORMATION_EDIT: {
      description: 'Edit Project Information',
      id: 36,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    PROJECT_LIST_VIEW_IN_PORTAL: {
      description: 'View all Projects List of Portal',
      id: 34,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    REJECT_COMPANIES: {
      description: 'Reject Company',
      id: 42,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    REMOVE_PROJECT_FROM_REGION: {
      description: 'Remove projects from a region',
      id: 45,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    ROLES_ADD_TO_PROJECT: {
      description: 'Add Project Roles',
      id: 13,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PROJECT
    },
    ROLES_CHANGE_OF_USER_IN_PROJECT: {
      description: 'Change Project role of a user',
      id: 15,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PROJECT
    },
    ROLES_DELETE_IN_PROJECT: {
      description: 'Delete Project Roles',
      id: 16,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PROJECT
    },
    ROLES_EDIT_IN_PROJECT: {
      description: 'Edit Project Roles',
      id: 14,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PROJECT
    },
    ROLES_VIEW_IN_PROJECT: {
      description: 'View Project Roles',
      id: 12,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PROJECT
    },
    SPACE_ADD_COMPANIES_TO_SPACE: {
      description: 'Add companies to space',
      id: 22,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_SPACE
    },
    SPACE_ADD_USERS_TO_SPACE: {
      description: 'Add Users to Space',
      id: 20,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_SPACE
    },
    SPACE_ASSIGN_SHARING_TO_FILE_FOLDER: {
      description: 'Assign sharing process to files/folder',
      id: 31,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_SPACE
    },
    SPACE_CHANGE_SPACE_INFORMATION: {
      description: 'Change Space Information',
      id: 19,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_SPACE
    },
    SPACE_COMMENT_ON_FILE: {
      description: 'Comment on a file',
      id: 28,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_SPACE
    },
    SPACE_CREATE_FILE_PLACEHOLDERS: {
      description: 'Create File Spaceholders',
      id: 27,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_SPACE
    },
    SPACE_CREATE_FOLDERS_SUBFOLDERS: {
      description: 'Create folders and sub folders',
      id: 24,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_SPACE
    },
    SPACE_DELETE_OTHER_COMMENTS: {
      description: 'Delete other comments',
      id: 30,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_SPACE
    },
    SPACE_DELETE_OWN_COMMENTS: {
      description: 'Delete own comments',
      id: 29,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_SPACE
    },
    SPACE_DOWNLOAD_FILES_FOLDERS: {
      description: 'Download Files/Folders',
      id: 26,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_SPACE
    },
    SPACE_REMOVE_COMPANIES_FROM_SPACE: {
      description: 'Remove companies from space',
      id: 23,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_SPACE
    },
    SPACE_REMOVE_USERS_FROM_SPACE: {
      description: 'Remove users from space',
      id: 21,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_SPACE
    },
    SPACE_UNASSIGN_SHARING_TO_FILE_FOLDER: {
      description: 'Unassign sharing process to files/folder',
      id: 32,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_SPACE
    },
    SPACE_UPLOAD_FILES: {
      description: 'Upload files',
      id: 25,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_SPACE
    },
    SPACES_ADD_TO_PROJECT: {
      description: 'Add project spaces',
      id: 6,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PROJECT
    },
    SPACES_DELETE_FROM_PROJECT: {
      description: 'Delete project spaces',
      id: 7,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PROJECT
    },
    SPACES_EDIT: {
      description: 'Edit Project spaces',
      id: 8,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PROJECT
    },
    SPACES_VIEW_IN_PROJECT: {
      description: 'View Project Spaces',
      id: 9,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PROJECT
    },
    STATUS_CREATE_OF_PORTAL_ENTITIES: {
      description: 'Create Status of Portal Entities',
      id: 54,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    STATUS_DELETE_OF_PORTAL_ENTITIES: {
      description: 'Delete Status of Portal Entities',
      id: 55,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    STATUS_EDIT: {
      description: 'Edit Status',
      id: 56,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    STATUS_VIEW: {
      description: 'View Status',
      id: 57,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    TRANSMITTAL_CREATE: {
      description: 'Create Transmittal',
      id: 10,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PROJECT
    },
    USERS_ADD_TO_PROJECT: {
      description: 'Add users to current project',
      id: 0,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PROJECT
    },
    USERS_ADD_TO_SPACE: {
      description: 'Add User to a space',
      id: 2,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PROJECT
    },
    USERS_ASSIGN_ROLE_TO_PROJECTS: {
      description: 'Assign users and their role to projects',
      id: 59,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    USERS_PORTAL_ROLE_CHANGE: {
      description: 'Change portal role of any user(s)',
      id: 61,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    USERS_PROJECT_ROLE_CHANGE: {
      description: 'Change project roles of users in any projects of that portal',
      id: 62,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    USERS_REMOVE_ACCESS_TO_PROJECTS: {
      description: 'Remove which users can access which projects',
      id: 60,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    USERS_REMOVE_FROM_PROJECT: {
      description: 'Remove users from a project',
      id: 1,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PROJECT
    },
    USERS_REMOVE_FROM_SPACE: {
      description: 'Remove User from a Space',
      id: 3,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PROJECT
    },
    USERS_VIEW_ALL_IN_PORTAL: {
      description: 'View all users of Portal',
      id: 58,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    USERS_VIEW_IN_PROJECT: {
      description: 'View Project Users',
      id: 4,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PROJECT
    },
    VIEW_ACCEPTED_COMPANIES: {
      description: 'View Accepted Companies',
      id: 37,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    VIEW_INVITED_COMPANIES: {
      description: 'View Invited Companies',
      id: 38,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    VIEW_LIST_OF_PORTAL_ROLES: {
      description: 'View Portal Roles',
      id: 52,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    VIEW_LIST_OF_REGION: {
      description: 'View regions',
      id: 49,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PORTAL
    },
    SPECIFIC_CREATE_FORM_FLOW: {
      description: 'Create Form Flow',
      id: 66,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PROJECT
    },
    USERS_ADD_TO_VISIBILITY: {
      description: 'Add users to project mailbox visibility',
      id: 67,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PROJECT
    },
    USERS_REMOVE_FROM_VISIBILITY: {
      description: 'Remove users from project mailbox visibility',
      id: 68,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PROJECT
    },
    PROJECT_SELECT_FORM_TEMPLATES: {
      description: 'Select form templates to be used in a project',
      id: 69,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PROJECT
    },
    SPECIFIC_ACCESS_REVIEW_SETTINGS: {
      description: 'Access Review settings',
      id: 70,
      status: 'ACTIVE',
      type: RESOURCE_TYPE_PROJECT
    },
  }
}

module.exports = {
  getPermissionConstants,
  MAX_FILE_UPLOAD_SIZE,
  getPermissions
};
