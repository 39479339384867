import React from "react";
import ChangeColor from "../../HoopsViewer/ToolbarViewer/ChangeColor";

function Resources({ color, onClick }) {
  return (
    <svg
      onClick={onClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <linearGradient id="Resources" gradientTransform="rotate(45)">
        <stop offset="38%" stopColor={color} />
        <stop offset="92%" stopColor={ChangeColor(color)} />
      </linearGradient>
      <path
        d="M20.5 2A1.5 1.5 0 0 1 22 3.5v9.084a5.291 5.291 0 0 0-.586-.567 8.779 8.779 0 0 0-.664-.497V7.093H3.25V17.28h8.477A5.325 5.325 0 0 0 12 18.553H3.5a1.5 1.5 0 0 1-1.5-1.5V3.5A1.5 1.5 0 0 1 3.5 2h17zm.25 1.273H3.25V5.82h17.5V3.273zM4.5 16.006v-7.64h6.25v7.64H4.5zm1.25-1.273H9.5V9.64H5.75v5.093zm10-5.093H12V8.366h3.75V9.64zM12 10.933h3.75a5.899 5.899 0 0 0-2.11 1.253H12v-1.253z"
        fill='url("#Resources")'
      />
      <path
        d="m14.45 19.944-.055-.051A4.6 4.6 0 1 1 22.1 16.5m-7.65 3.443L22 16.501m-7.55 3.443.003.002a4.6 4.6 0 0 0 6.095 0l.001-.002.056-.051-6.154.051zm7.65-3.443H22m.1 0H22m.1 0a4.586 4.586 0 0 1-1.495 3.392l-.067-.074A4.486 4.486 0 0 0 22 16.501m-4.5 3.706a3.7 3.7 0 0 1-2.197-.722 2.67 2.67 0 0 1 4.394 0 3.7 3.7 0 0 1-2.197.723zm-3.708-3.706a3.706 3.706 0 0 1 5.024-3.467 3.707 3.707 0 0 1 1.538 5.832 3.527 3.527 0 0 0-1.38-1.11 2.177 2.177 0 1 0-2.947 0 3.528 3.528 0 0 0-1.381 1.11 3.69 3.69 0 0 1-.854-2.365h-.1.1zm2.423-.347a1.284 1.284 0 1 1 2.569 0 1.284 1.284 0 0 1-2.569 0z"
        fill='url("#Resources")'
      />
    </svg>
  );
}

export default Resources;
