import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  getCurrProjectData,
  getCurrSpaceData,
  getSpaceDataBySpaceId,
} from "../../actions/breadcrumbsdata";
import classes from "./index.module.css";
import {
  getEstimateDateVersion,
  getEstimateData,
} from "../../actions/EstimateList";
import IconComponent from "../../cubeComponent/icons";
import ToolTipComponent from "../../cubeComponent/html/tooltip";
import { getProjectDashboardPageUrl } from "../../services/utils/helpers";
import { modulesNameSynonymMapper } from "../../services/constants";
import { getTransmittals } from "../../actions/transmitals";
import { useTranslation } from "react-i18next";

function BreadCrumbNav(props) {
  const { t } = useTranslation();
  const location = useLocation();
  const pathArray = location.pathname.split("/");
  const pathArrayAtSecondIndex = pathArray[pathArray.length - 2];
  const pathArrayAtThirdIndex = pathArray[pathArray.length - 3];
  const clashDetails = useSelector((state) => state.clashDetails);
  const breadCrumbData = useSelector((state) => state.breadCrumbData);

  const dispatch = useDispatch();

  const nameArray = location.pathname
    .replace(/\/$/g, "")
    .replace(/^\//g, "")
    .split("/");

  const [name, setName] = useState(nameArray[nameArray.length - 1]);
  const [id, setId] = useState(pathArrayAtSecondIndex);
  const urlParams = new URLSearchParams(location?.search);

  useEffect(() => {
    if (
      name === "listTransmittals" ||
      name === "createTasks" ||
      name === "EstimateList" ||
      name === "scheduleList" ||
      name === "assetRegister" ||
      name === "tendersList" ||
      name === "procurement" ||
      name === "riskManagement" ||
      name === "clashTests" ||
      name === "clashDetails" ||
      name === "createNewProject" ||
      name === "forms"
    ) {
      props.getProjectData(id);
    }
    if (pathArrayAtSecondIndex === "formsList") {
      props.getProjectData(pathArrayAtThirdIndex);
    }
    if (
      pathArrayAtSecondIndex === "listTransmittals" &&
      pathArrayAtThirdIndex
    ) {
      props.getTransmittalData({ transmittalVersionId: pathArray[4] });
    }
    if (name === "scheduleDetails") {
      props.getProjectData(pathArray[2]);
    }
    if (
      pathArrayAtSecondIndex === "estimate" ||
      pathArrayAtSecondIndex === "version"
    ) {
      props.getProjectData(pathArray[2]);
      if (pathArrayAtSecondIndex === "version") {
        dispatch(getEstimateDateVersion({ versionId: pathArray[6] }));
        props.getEstimateData({
          estimateId: pathArray[4],
          versionId: pathArray[6],
        });
      } else {
        props.getEstimateData({ estimateId: pathArray[4] });
      }
    }
    if (name === "explorer" || name === "transmitals" || name === "review") {
      props.getSpaceData(id);
    }
    if (
      name === "spaceRoles" ||
      name === "reviewFlows" ||
      name === "formFlows"
    ) {
      props.getSpaceDataBySpaceId(id);
    }
  }, [name, id]);

  useEffect(() => {
    if (location.pathname.includes("listTask")) {
      setName("listTask");
      // Patch in case of specific task being opened up
      if (pathArrayAtSecondIndex == "listTask") {
        setId(pathArrayAtThirdIndex);
      } else {
        setId(pathArrayAtSecondIndex);
      }
    } else if (location.pathname.includes("scheduleList")) {
      setName("scheduleList");
      // Patch in case of specific task being opened up
      if (pathArrayAtSecondIndex == "scheduleList") {
        setId(pathArrayAtThirdIndex);
      } else {
        setId(pathArrayAtSecondIndex);
      }
    } else if (location.pathname.includes("scheduleDetails")) {
      setName("scheduleDetails");
      // Patch in case of specific task being opened up
      if (pathArray[pathArray.length - 1] == "scheduleDetails") {
        setId(pathArray[pathArray.length - 4]);
      }
    } else if (location.pathname.includes("clashDetails")) {
      setName("clashDetails");
      // Patch in case of specific schedule being opened up
      if (pathArray[pathArray.length - 2] == "clashDetails") {
        setId(pathArray[pathArray.length - 3]);
      }
    } else if (location.pathname.includes("listTransmittals")) {
      setName("listTransmittals");
      // Patch in case of specific transmittal being opened up
      if (pathArrayAtSecondIndex == "listTransmittals") {
        // Individual Transmittal Case
        setId(pathArrayAtThirdIndex);
      } else {
        // General List Transmittal Case
        setId(pathArrayAtSecondIndex);
      }
    } else if (location.pathname.includes("files")) {
      setName("files");
      if (pathArrayAtSecondIndex == "files") {
        setId(pathArrayAtThirdIndex);
      } else {
        setId(pathArrayAtSecondIndex);
      }
    } else if (location.pathname.includes("listFiles")) {
      setName("listFiles");
      setId("");
    } else if (location.pathname.includes("portalSettings")) {
      setName("portalSettings");
      setId("");
    } else if (location.pathname.includes("companySettings")) {
      setName("companySettings");
      setId("");
    } else if (location.pathname.includes("assetRegister/")) {
      setName("asset");
      setId("");
    } else if (location.pathname.includes("diagram")) {
      setName("diagram");
      setId("");
    } else if (location.pathname.includes("reviewFlows")) {
      setName("reviewFlows");
      setId("");
    } else if (location.pathname.includes("createNewProject")) {
      setName("createNewProject");
      setId("");
    } else if (location.pathname.includes("estimatePortalList")) {
      setName("portalEstimates");
      setId("");
    } else if (location.pathname.includes("generalFormsList")) {
      setName("generalFormsList");
      if (pathArrayAtSecondIndex == "generalFormsList") {
        setId(pathArrayAtThirdIndex);
      } else {
        setId(pathArrayAtSecondIndex);
      }
    } else if (location.pathname.includes("estimate")) {
      setName("estimate");
    } else if (location.pathname.includes("formsList")) {
      setName("formsList");
      if (pathArrayAtSecondIndex == "formsList") {
        setId(pathArrayAtThirdIndex);
      } else {
        setId(pathArrayAtSecondIndex);
      }
    } else if (location.pathname.includes("editTask")) {
      setName(nameArray[nameArray.length - 2]);
      setId(nameArray[nameArray.length - 3]);
    } else if (
      !location.pathname.includes("contractsList") &&
      location.pathname.includes("contract")
    ) {
      setName(nameArray[nameArray.length - 2]);
      setId(nameArray[nameArray.length - 3]);
    } else if (location.pathname.includes("review")) {
      const urlParams = new URLSearchParams(location?.search);
      const spaceId = urlParams.get("spaceId");
      if (spaceId) {
        setName("review");
        setId(spaceId);
      } else {
        setName("reviews");
        setId("");
      }
    } else if (pathArrayAtSecondIndex == "forms") {
      setName(pathArrayAtSecondIndex);
      setId(pathArrayAtThirdIndex);
    } else if (location.pathname.includes("escrowFiles")) {
      setName("notification");
      setId("");
    } else {
      setName(nameArray[nameArray.length - 1]);
      setId(pathArrayAtSecondIndex);
    }
  }, [location.pathname]);

  return (
    <>
      {name === "dsr" && (
        <Link to={location.pathname}>
          <p className={`${classes.heading} ${classes.element}`}>
            {t("DSR 2021")}
          </p>
        </Link>
      )}

      {name === "portalSettings" && (
        <Link to={location.pathname}>
          <p className={`${classes.heading} ${classes.element}`}>
            {t("Portal Settings")}
          </p>
        </Link>
      )}

      {name === "companySettings" && (
        <Link to={location.pathname}>
          <p className={`${classes.heading} ${classes.element}`}>
            {t("Org Settings")}
          </p>
        </Link>
      )}

      {name === "estimation1" && (
        <Link to={location.pathname}>
          <p className={`${classes.heading} ${classes.element}`}>
            {t("Sample Estimate")}
          </p>
        </Link>
      )}

      {name === "orgStructure" && (
        <Link to={location.pathname}>
          <p className={`${classes.heading} ${classes.element}`}>
            {t("Org Structure")}
          </p>
        </Link>
      )}

      {name === "orgUnit" && (
        <Link to={location.pathname}>
          <p className={`${classes.heading} ${classes.element}`}>
            {t("Org Unit")}
          </p>
        </Link>
      )}

      {name === "asset" && (
        <Link to={location.pathname}>
          <p className={`${classes.heading} ${classes.element}`}>
            {t("Assets")}
          </p>
        </Link>
      )}

      {name === "dashboard" && (
        <Link to={location.pathname}>
          <p className={`${classes.heading} ${classes.element}`}>
            {t("Portal Dashboard")}
          </p>
        </Link>
      )}

      {name === "apps" && (
        <Link to={location.pathname}>
          <p className={`${classes.heading} ${classes.element}`}>
            <img style={{ width: "9vw" }} src="/assets/images/image.png" />
          </p>
        </Link>
      )}

      {name === "portalSettings/users" && (
        <>
          <Link to="/projects">
            <p className={`${classes.heading} ${classes.element}`}>
              {t("Portal Settings")}
            </p>
          </Link>
        </>
      )}
      {name === "listTransmittals" && (
        <>
          <Link to="/projects">
            <p className={`${classes.heading} ${classes.element}`}>
              {t("Projects")}
            </p>
          </Link>
          <div className={`${classes.chevronRightIcon} ${classes.element}`}>
            <IconComponent name="arrowRight" className="h-3.5" />
          </div>
          <ToolTipComponent
            showTipPointer
            position="BottomCenter"
            content={props.projectData.description}
          >
            <Link to={getProjectDashboardPageUrl(props.projectData.id)}>
              <p
                className={`${classes.heading} ${classes.element} ${classes.ellipsis}`}
              >
                {props.projectData.description}
              </p>
            </Link>
          </ToolTipComponent>
          <div className={`${classes.chevronRightIcon} ${classes.element}`}>
            <IconComponent name="arrowRight" className="h-3.5" />
          </div>
          <Link to={`/project/${props.projectData.id}/listTransmittals`}>
            <p className={`${classes.heading} ${classes.element}`}>
              {t("Transmittals")}
            </p>
          </Link>
          {pathArrayAtSecondIndex === "listTransmittals" &&
            pathArrayAtThirdIndex &&
            props.transmittalData && (
              <>
                <div
                  className={`${classes.chevronRightIcon} ${classes.element}`}
                >
                  <IconComponent name="arrowRight" className="h-3.5" />
                </div>
                <Link
                  to={`/project/${props.projectData.id}/listTransmittals/${props?.transmittalData?.id}`}
                >
                  <p className={`${classes.heading} ${classes.element}`}>
                    {props?.transmittalData?.transmittalId}
                  </p>
                </Link>
              </>
            )}
        </>
      )}

      {name == "EstimateList" && (
        <>
          <Link to="/projects">
            <p className={`${classes.heading} ${classes.element}`}>
              {t("Projects")}
            </p>
          </Link>
          <div className={`${classes.chevronRightIcon} ${classes.element}`}>
            <IconComponent name="arrowRight" className="h-3.5" />
          </div>
          <ToolTipComponent
            showTipPointer
            position="BottomCenter"
            content={props.projectData.description}
          >
            <Link to={getProjectDashboardPageUrl(props.projectData.id)}>
              <p
                className={`${classes.heading} ${classes.element} ${classes.ellipsis}`}
              >
                {props.projectData.description}
              </p>
            </Link>
          </ToolTipComponent>
          <div className={`${classes.chevronRightIcon} ${classes.element}`}>
            <IconComponent name="arrowRight" className="h-3.5" />
          </div>
          <Link to={`/project/${props.projectData.id}/EstimateList`}>
            <p className={`${classes.heading} ${classes.element}`}>
              {t("Estimates")}
            </p>
          </Link>
        </>
      )}

      {name === "clashTests" && (
        <>
          <Link to="/projects">
            <p className={`${classes.heading} ${classes.element}`}>
              {t("Projects")}
            </p>
          </Link>
          <div className={`${classes.chevronRightIcon} ${classes.element}`}>
            <IconComponent name="arrowRight" className="h-3.5" />
          </div>
          <ToolTipComponent
            showTipPointer
            position="BottomCenter"
            content={props.projectData.description}
          >
            <Link to={getProjectDashboardPageUrl(props.projectData.id)}>
              <p
                className={`${classes.heading} ${classes.element} ${classes.ellipsis}`}
              >
                {props.projectData.description}
              </p>
            </Link>
          </ToolTipComponent>
          <div className={`${classes.chevronRightIcon} ${classes.element}`}>
            <IconComponent name="arrowRight" className="h-3.5" />
          </div>
          <Link to={`/project/${props.projectData.id}/clashTests`}>
            <p className={`${classes.heading} ${classes.element}`}>
              {t("Clash Tests")}
            </p>
          </Link>
        </>
      )}

      {name === "clashDetails" && (
        <>
          <Link to="/projects">
            <p className={`${classes.heading} ${classes.element}`}>
              {t("Projects")}
            </p>
          </Link>
          <div className={`${classes.chevronRightIcon} ${classes.element}`}>
            <IconComponent name="arrowRight" className="h-3.5" />
          </div>
          <ToolTipComponent
            showTipPointer
            position="BottomCenter"
            content={props.projectData.description}
          >
            <Link to={getProjectDashboardPageUrl(props.projectData.id)}>
              <p
                className={`${classes.heading} ${classes.element} ${classes.ellipsis}`}
              >
                {props.projectData.description}
              </p>
            </Link>
          </ToolTipComponent>
          <div className={`${classes.chevronRightIcon} ${classes.element}`}>
            <IconComponent name="arrowRight" className="h-3.5" />
          </div>
          <Link to={`/project/${props.projectData.id}/clashTests`}>
            <p className={`${classes.heading} ${classes.element}`}>
              {t("Clash Tests")}
            </p>
          </Link>
          <IconComponent name="arrowRight" className="h-3.5" />
          <div className={`${classes.chevronRightIcon} ${classes.element}`}>
            <p className={`${classes.heading} ${classes.element}`}>
              {clashDetails.name}
            </p>
          </div>
        </>
      )}
      {name == "workflow" && (
        <>
          <Link to="/projects">
            <p className={`${classes.heading} ${classes.element}`}>
              {t("Projects")}
            </p>
          </Link>
          <div className={`${classes.chevronRightIcon} ${classes.element}`}>
            <IconComponent name="arrowRight" className="h-3.5" />
          </div>
          <ToolTipComponent
            showTipPointer
            position="BottomCenter"
            content={props.projectData.description}
          >
            <Link to={getProjectDashboardPageUrl(props.projectData.id)}>
              <p
                className={`${classes.heading} ${classes.element} ${classes.ellipsis}`}
              >
                {props.projectData.description}
              </p>
            </Link>
          </ToolTipComponent>
          <div className={`${classes.chevronRightIcon} ${classes.element}`}>
            <IconComponent name="arrowRight" className="h-3.5" />
          </div>
          <Link to={`/project/${props.projectData.id}/workflow`}>
            <p className={`${classes.heading} ${classes.element}`}>
              {t("Workflow")}
            </p>
          </Link>
        </>
      )}
      {[
        "issuesList",
        "tasks",
        "projectdashboard",
        "issues",
        "explorer",
        "listFiles",
        "contractsList",
        "contract",
        "forms",
        "setting",
        "Workspaces",
        "createNewProject",
        "formsList",
        "scheduleList",
        "listTask",
        "createTasks",
        "security",
        "personalInformation",
        "estimate",
        "notification",
        "projects",
        "review",
        "mails",
        "mailThread",
        "scheduleDetails",
        "reviews",
      ].includes(name) &&
        breadCrumbData.map((data, index) => {
          if (data.text == "Projects") {
            data.text = window.location.host.includes("dpmsportal")
              ? `${modulesNameSynonymMapper.projects}`
              : "Projects";
          } else if (data.text == "Spaces") {
            data.text = window.location.host.includes("dpmsportal")
              ? `${modulesNameSynonymMapper.spaces}`
              : "Spaces";
          }
          const output = [];

          if (data.template) {
            output.push(data.template());
          } else if (data.showTooltip && data.link) {
            output.push(
              <ToolTipComponent
                showTipPointer
                position="BottomCenter"
                content={data.tooltipData}
                key={index}
              >
                <Link to={data.link}>
                  <p
                    className={`${classes.heading} ${classes.element} ${classes.ellipsis}`}
                  >
                    {data.text}
                  </p>
                </Link>
              </ToolTipComponent>,
            );
          } else if (!data.showTooltip && data.link) {
            output.push(
              <Link to={data.link} key={index}>
                <p
                  className={`${classes.heading} ${classes.element} ${classes.ellipsis}`}
                >
                  {data.text}
                </p>
              </Link>,
            );
          } else if (!data.showTooltip && !data.link) {
            output.push(
              <p
                key={index}
                className={`${classes.heading} ${classes.element} ${classes.ellipsis}`}
              >
                {data.text}
              </p>,
            );
          } else if (data.text && !data.showTooltip) {
            output.push(
              <p
                key={index}
                className={`${classes.heading} ${classes.element} ${classes.ellipsis}`}
              >
                {data.text}
              </p>,
            );
          } else if (data.text && data.showTooltip) {
            output.push(
              <ToolTipComponent
                showTipPointer
                position="BottomCenter"
                content={data.tooltipData}
                key={index}
              >
                <p
                  className={`${classes.heading} ${classes.element} ${classes.ellipsis}`}
                >
                  {data.text}
                </p>
              </ToolTipComponent>,
            );
          }

          if (index + 1 < breadCrumbData.length) {
            output.push(
              <div
                key={`arrow_${index}`}
                className={`${classes.chevronRightIcon} ${classes.element}`}
              >
                <IconComponent name="arrowRight" className="h-3.5" />
              </div>,
            );
          }
          return output;
        })}

      {(name === "transmittals" || name === "Files") &&
        urlParams.get("byPortal") && (
          <>
            <Link to="/dashboard">
              <p className={`${classes.heading} ${classes.element}`}>
                {t("Portal Dashboard")}
              </p>
            </Link>
            <div className={`${classes.chevronRightIcon} ${classes.element}`}>
              <IconComponent name="arrowRight" className="h-3.5" />
            </div>
            <Link to={`${location.pathname}?byPortal=true`}>
              <p className={`${classes.heading} ${classes.element}`}>
                {`Portal ${name}`}
              </p>
            </Link>
          </>
        )}
      {name === "403" && (
        <Link to="/403">
          <p className={`${classes.heading} ${classes.element}`}>
            {" "}
            {t(" Error 403 ")}{" "}
          </p>
        </Link>
      )}
      {name === "search" && (
        <Link to="/search">
          <p className={`${classes.heading} ${classes.element}`}>
            {t("File Search")}
          </p>
        </Link>
      )}
      {name === "notifications" && (
        <Link to={location.pathname}>
          <p className={`${classes.heading} ${classes.element}`}>
            {t("Notifications")}
          </p>
        </Link>
      )}
      {/* {name === "createTransmittals" && (
        <>
          <Link
            to={`/project/${props.spaceData?.data?.id}/Workspaces?projectId=${props.spaceData?.data?.id}`}
          >
            <p className={`${classes.heading} ${classes.element}`}>Spaces</p>
          </Link>
          <div className={`${classes.chevronRightIcon} ${classes.element}`}>
            <IconComponent name="arrowRight" className="h-3.5" />
          </div>
          <Link
            to={`/workspace/${props.spaceData?.data?.id}/explorer?spaceType=this`}
          >
            <p className={`${classes.heading} ${classes.element}`}>
              {props.spaceData?.data?.spaceName}
            </p>
          </Link>
          <div className={`${classes.chevronRightIcon} ${classes.element}`}>
            <IconComponent name="arrowRight" className="h-3.5" />
          </div>
          <Link to={`/spaceSettings/${props.spaceData?.data?.id}/spaceRoles`}>
            <p className={`${classes.heading} ${classes.element}`}>Settings</p>
          </Link>
        </>
      )} */}
      {(name === "createTransmittals" ||
        name === "createTasks" ||
        name === "editTask") && (
        <>
          <Link to="/projects">
            <div className={`${classes.heading} ${classes.element}`}>
              {t("Projects")}
            </div>
          </Link>
          <div className={`${classes.chevronRightIcon} ${classes.element}`}>
            <IconComponent name="arrowRight" className="h-3.5" />
          </div>
          <ToolTipComponent
            showTipPointer
            position="BottomCenter"
            content={props.projectData.description}
          >
            <Link to={getProjectDashboardPageUrl(props.projectData.id)}>
              <p
                className={`${classes.heading} ${classes.element} ${classes.ellipsis}`}
              >
                {props.projectData.description}
              </p>
            </Link>
          </ToolTipComponent>
          <div className={`${classes.chevronRightIcon} ${classes.element}`}>
            <IconComponent name="arrowRight" className="h-3.5" />
          </div>
          <Link
            to={`/project/${props.projectData.id}/${
              name === "createTransmittals" ? "listTransmittals" : "listTask"
            }`}
          >
            <div className={`${classes.heading} ${classes.element}`}>
              {name === "createTransmittals" ? "Transmittals" : "Tasks"}
            </div>
          </Link>
          <div className={`${classes.chevronRightIcon} ${classes.element}`}>
            <IconComponent name="arrowRight" className="h-3.5" />
          </div>
          <Link to={location.pathname}>
            <div className={`${classes.heading} ${classes.element}`}>
              {name === "createTransmittals"
                ? "Create Transmittal"
                : name === "editTask"
                  ? "Edit Task"
                  : "Create Task"}
            </div>
          </Link>
        </>
      )}

      {name === "portalEstimates" && (
        <>
          <Link to="/">
            <p className={`${classes.heading} ${classes.element}`}>
              {t("PortalDashboard")}
            </p>
          </Link>
          <div className={`${classes.chevronRightIcon} ${classes.element}`}>
            <IconComponent name="arrowRight" className="h-3.5" />
          </div>
          <p
            className={`${classes.heading} ${classes.element} ${classes.boldClass}`}
          >
            {t("Estimates")}
          </p>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  projectData: state.projectData,
  spaceData: state.spaceData,
  fileManagerBreadCrumb: state.fileManagerBreadCrumb,
  estimateData: state.estimateData,
  transmittalData: state.transmittals,
});

const mapDispatchToProps = (dispatch) => ({
  getProjectData: (prjid) => dispatch(getCurrProjectData(prjid)),
  getSpaceData: (prjid) => dispatch(getCurrSpaceData(prjid)),
  getSpaceDataBySpaceId: (spaceid) => dispatch(getSpaceDataBySpaceId(spaceid)),
  getEstimateData: (data) => dispatch(getEstimateData(data)),
  getTransmittalData: (data) => dispatch(getTransmittals(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BreadCrumbNav);
