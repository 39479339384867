import { RiBookLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import ObjectTree from "./assets/ObjectTree";
import { ReactComponent as MetaData } from "./assets/MetaData.svg";
import { ReactComponent as AssetData } from "./assets/AssetData.svg";
import { ReactComponent as CustomMetaData } from "./assets/CustomMetaData.svg";
import { ReactComponent as Asset } from "./assets/Asset.svg";
import { ReactComponent as QueriesIcon } from "../../assets/icons/svg/SidebarIcon/QueriesIcon.svg";
import { ReactComponent as MeasurementIcon } from "../../assets/icons/svg/measurements_icon.svg";
import { ReactComponent as DataBankIcon } from "./assets/DataBankIcon.svg";
import { ReactComponent as IotDataIcon } from "./assets/IotDataIcon.svg";
import { ReactComponent as CollaborationIcon } from "./assets/CollaborationIcon.svg";
import { ReactComponent as ConstituentFilesIcon } from "./assets/Constituent Files.svg";
import Views from "./assets/Views";
import Clash from "./assets/Clash";
import { ReactComponent as IssuesIcon } from "../../assets/icons/svg/IssuesIcon.svg";
import Sheets from "./assets/Sheets";
import Relationships from "./assets/Relationship";
import { setModalStatus } from "../../actions/HoopsViewer";
import Layers from "./assets/Layers";
import SelectionSetIcon from "./assets/SelectionSetIcon";
import Types from "./assets/Types";
import Linear from "../HoopsViewer/ToolbarViewer/assets/Linear";
import Schedules from "./assets/Schedules";
import Resources from "./assets/Resources";
import { useLocation } from "react-router";
import { useParams } from "react-router-dom";
import { ReactComponent as details } from "../../assets/icons/svg/details.svg";

const useModulesMapper = (props = {}) => {
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const HoopsModalStatus = useSelector((state) => state.HoopsModalStatus);
  const params = useParams();
  const filesData = useSelector((state) => state.filesData);
  const dispatch = useDispatch();

  const ActiveColor = "rgb(107, 187, 227)";
  const InactiveColor = "rgb(25, 32, 72)";

  const handleToolClick = (e) => {
    const clickedTool = e.currentTarget.attributes.type.value;
    const clickedValue = !HoopsModalStatus[clickedTool]?.show;
    let initializeState = {};

    if (clickedValue) {
      // TODO: Settings is not in the mapper
      initializeState = modulesMapper[clickedTool]?.initialState || {};
    }

    const clickedObj = {
      [clickedTool]: {
        show: clickedValue,
        ...initializeState,
      },
    };
    const hoopsStatuses = {
      ...HoopsModalStatus,
      ...clickedObj,
    };
    dispatch(setModalStatus(hoopsStatuses));
  };

  const getDefaultSidebarValues = ({ type, isVisible = true }) => ({
    isVisible,
    isEnabled: true,
    color: HoopsModalStatus[type]?.show ? ActiveColor : InactiveColor,
    onClick: handleToolClick,
  });

  const modulesMapper = {
    ObjectTree: {
      type: "ObjectTree",
      Icon: ObjectTree,
      Tooltip: "Object Tree",
      belongToGroup: "Others",
      name: "Object Tree",
      ...getDefaultSidebarValues({ type: "ObjectTree" }),
    },
    ObjectTreeClone: {
      type: "ObjectTreeClone",
      Icon: ObjectTree,
      Tooltip: "Object Tree",
      belongToGroup: "Others",
      name: "Object Tree Clone",
      ...getDefaultSidebarValues({ type: "ObjectTreeClone" }),
    },
    Layers: {
      type: "Layers",
      Icon: Layers,
      Tooltip: "Layers",
      belongToGroup: "Others",
      name: "Layers",
      ...getDefaultSidebarValues({ type: "Layers" }),
    },
    Types: {
      type: "Types",
      Icon: Types,
      Tooltip: "Types",
      belongToGroup: "Others",
      name: "Types",
      ...getDefaultSidebarValues({ type: "Types" }),
    },
    Metadata: {
      type: "Metadata",
      ...getDefaultSidebarValues({ type: "Metadata" }),
      Tooltip: "Metadata",
      belongToGroup: "Others",
      name: "Metadata",
      Svg: MetaData,
    },
    OldMetadata: {
      type: "OldMetadata",
      ...getDefaultSidebarValues({ type: "OldMetadata" }),
      Tooltip: "OldMetadata",
      belongToGroup: "Others",
      name: "OldMetadata",
      Svg: MetaData,
    },
    AssetData: {
      type: "AssetData",
      Tooltip: "Asset Data",
      belongToGroup: "Others",
      name: "Asset Data",
      ...getDefaultSidebarValues({
        type: "AssetData",
        isVisible: !["prod"].includes(process.env.REACT_APP_ENV),
      }),
      Svg: AssetData,
    },
    CustomMetadata: {
      type: "CustomMetadata",
      Tooltip: "Custom Metadata",
      belongToGroup: "Others",
      name: "Custom Metadata",
      ...getDefaultSidebarValues({
        type: "CustomMetadata",
        isVisible: !["prod"].includes(process.env.REACT_APP_ENV),
      }),
      Svg: CustomMetaData,
    },
    Assets: {
      type: "Assets",
      Tooltip: "Assets",
      belongToGroup: "Assets & Maintenance",
      name: "Assets",
      ...getDefaultSidebarValues({
        type: "Assets",
        isVisible: !["prod"].includes(process.env.REACT_APP_ENV),
      }),
      Svg: Asset,
    },
    Schedules: {
      type: "Schedules",
      Tooltip: "Schedule",
      belongToGroup: "Scheduling",
      name: "Schedule",
      ...getDefaultSidebarValues({
        type: "Schedules",
        isVisible: !!urlSearchParams.get("scheduleId"),
      }),
      Icon: Schedules,
    },
    Resources: {
      type: "Resources",
      Tooltip: "Resources",
      belongToGroup: "Scheduling",
      name: "Resources",
      ...getDefaultSidebarValues({
        type: "Resources",
        isVisible: !!urlSearchParams.get("scheduleId"),
      }),
      Icon: Resources,
    },
    Views: {
      type: "Views",
      Icon: Views,
      Tooltip: "Views",
      belongToGroup: "Others",
      name: "Views",
      ...getDefaultSidebarValues({ type: "Views" }),
    },
    Clash: {
      type: "Clash",
      Icon: Clash,
      Tooltip: "Clash Detection",
      belongToGroup: "Design Management",
      name: "Clash Detection",
      currentStage: "Clash Tests",
      backStage: "Clash Tests",
      initialState: {
        currentStage: "Clash Tests",
        backStage: "Clash Tests",
        defaultStage: "Clash Tests",
      },
      ...getDefaultSidebarValues({
        type: "Clash",
        isVisible:
          filesData[params.fileId]?.resourceType == "RESOURCE_TYPE_MERGED_FILE",
      }),
    },
    Sheets: {
      type: "Sheets",
      Icon: Sheets,
      Tooltip: "Sheets",
      belongToGroup: "Others",
      name: "Sheets",
      ...getDefaultSidebarValues({ type: "Sheets" }),
    },
    ConstituentFiles: {
      type: "ConstituentFiles",
      Svg: ConstituentFilesIcon,
      Tooltip: "Constituent Files",
      belongToGroup: "Others",
      name: "Constituent Files",
      ...getDefaultSidebarValues({ type: "ConstituentFiles" }),
      initialState: {
        currentStage: "constituentFiles",
        defaultStage: "constituentFiles",
        backStage: "constituentFiles",
      },
    },
    Relationship: {
      type: "Relationship",
      Icon: Relationships,
      Tooltip: "Relationship",
      belongToGroup: "Others",
      name: "Relationship",
      ...getDefaultSidebarValues({ type: "Relationship" }),
    },
    Divider: {
      type: "Divider",
      name: "Divider",
      isVisible: true,
    },

    Properties: {
      type: "Properties",
      Svg: details,
      Tooltip: "Properties",
      belongToGroup: "Others",
      name: "Properties",
      ...getDefaultSidebarValues({ type: "Properties", isVisible: true }),
    },
    Issues: {
      type: "Issues",
      Icon: IssuesIcon,
      Tooltip: "Issues",
      belongToGroup: "Design Management",
      name: "Issues",
      ...getDefaultSidebarValues({ type: "Issues" }),
      initialState: {
        currentStage: "issues",
        defaultStage: "issues",
        backStage: "issues",
        stageMapper: {
          issues: { title: "Issues" },
          create_issue: { title: "Create Issue" },
          edit_issue: { title: "Edit Issue" },
          view_issue: { title: "View Issue" },
        },
      },
    },
    Attachments: {
      type: "Attachments",
      Icon: Linear,
      Tooltip: "Attachments",
      belongToGroup: "Assets & Maintenance",
      name: "Attachments",
      ...getDefaultSidebarValues({ type: "Attachments" }),
    },
    Queries: {
      type: "Queries",
      Svg: QueriesIcon,
      Tooltip: "Queries",
      belongToGroup: "Others",
      name: "Queries",
      ...getDefaultSidebarValues({ type: "Queries" }),
      initialState: {
        currentStage: "queries_list",
        defaultStage: "queries_list",
        backStage: "queries_list",
      },
    },
    SearchSet: {
      type: "SearchSet",
      Svg: DataBankIcon,
      Tooltip: "Data Bank",
      belongToGroup: "Others",
      name: "Data Bank",
      ...getDefaultSidebarValues({
        type: "SearchSet",
        isVisible: !!props.estimateId,
      }),
    },
    // SearchSet1: {
    //   type: 'SearchSet1',
    //   Svg: DataBankIcon,
    //   Tooltip: 'Data Bank Material UI',
    //   belongToGroup: 'Others',
    //   name: 'Data Bank  Material UI',
    //   ...getDefaultSidebarValues({ type: 'SearchSet1', isVisible: !!props.estimateId }),
    // },
    Measurements: {
      type: "Measurements",
      Svg: MeasurementIcon,
      Tooltip: "Measurements",
      belongToGroup: "Estimation",
      name: "Measurement",
      ...getDefaultSidebarValues({
        type: "Measurements",
        isVisible: !!props.estimateId,
      }),
    },
    Takeoffs: {
      type: "Takeoffs",
      Icon: RiBookLine,
      Tooltip: "Takeoffs",
      belongToGroup: "Estimation",
      name: "Takeoffs",
      initialState: {
        currentStage: "Takeoffs",
        backStage: "Takeoffs",
        defaultStage: "Takeoffs",
      },
      ...getDefaultSidebarValues({
        type: "Takeoffs",
        isVisible: !!props.estimateId,
      }),
    },
    SelectionSet: {
      type: "SelectionSet",
      Icon: SelectionSetIcon,
      Tooltip: "Selection Set",
      belongToGroup: "Others",
      name: "Selection Set",
      ...getDefaultSidebarValues({ type: "SelectionSet" }),
    },
    WorkOrder: {
      type: "WorkOrder",
      Icon: RiBookLine,
      Tooltip: "Work Order",
      belongToGroup: "Assets & Maintenance",
      name: "Work Order",
      ...getDefaultSidebarValues({
        type: "WorkOrder",
        isVisible: !["prod"].includes(process.env.REACT_APP_ENV),
      }),
    },
    Temperature: {
      type: "Temperature",
      Svg: IotDataIcon,
      Tooltip: "IoT Data",
      belongToGroup: "Others",
      name: "IoT Data",
      ...getDefaultSidebarValues({
        type: "Temperature",
        isVisible: !["prod"].includes(process.env.REACT_APP_ENV),
      }),
    },
    Collaboration: {
      type: "Collaboration",
      Svg: CollaborationIcon,
      Tooltip: "Collaboration",
      belongToGroup: "Others",
      name: "Collaboration",
      ...getDefaultSidebarValues({
        type: "Collaboration",
        isVisible: !["prod"].includes(process.env.REACT_APP_ENV),
      }),
    },
  };

  return [modulesMapper, handleToolClick];
};

export default useModulesMapper;
