import { useEffect, useRef, useState } from "react";
import { ImageEditorComponent } from "@syncfusion/ej2-react-image-editor";
import IconComponent from "@CubeComponents/icons";
import ButtonComponent from "@CubeComponents/form/ButtonComponent";
import { BUTTON_TYPES } from "@CubeComponents/constants/globalVariable";
import ModalStateDialogComponent from "@CubeComponents/html/modal/ModalWithState";
import { useSelector } from "react-redux";

function ImageEditorModal({
  width = "900px",
  allowModalPadding = false,
  heading = "Edit Picture",
  closeModal,
  onSubmitFunction,
  existingImg,
}) {
  const imageEditorRef = useRef(null);
  const [isFileImported, setIsFileImported] = useState(false);
  useEffect(() => {
    if (existingImg) {
      imageEditorRef.current.open(existingImg);
    }
  }, []);

  const saveImageData = () => {
    const canvasCreated = imageEditorRef.current.exportModule.exportToCanvas();
    const base64EncodedImg = canvasCreated.toDataURL("image/jpeg");

    onSubmitFunction(base64EncodedImg);
    closeModal();
  };

  const fileOpened = (e) => {
    setIsFileImported(true);
  };

  const headerContent = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "15px 30px",
        background: "#ecf3fe",
      }}
    >
      <span>{heading}</span>
      <IconComponent
        cssClass="cursor-pointer"
        name="close"
        onClick={closeModal}
      />
    </div>
  );

  const toolbar = [
    "Open",
    "Undo",
    "Redo",
    "ZoomIn",
    "ZoomOut",
    "Crop",
    "Annotate",
    "Finetune",
    "Filter",
    "Frame",
    "Resize",
    "Reset",
  ];

  const modelContent = () => (
    <div style={{ padding: "20px" }}>
      <div className="profileCropperContent" style={{ height: "400px" }}>
        <ImageEditorComponent
          id="image-editor-modal"
          toolbar={toolbar}
          ref={imageEditorRef}
          fileOpened={fileOpened}
        />
      </div>
    </div>
  );

  const footerContent = () => (
    <div style={{ padding: "20px" }} className="flex gap-4">
      <ButtonComponent
        buttonType={BUTTON_TYPES.BORDER_BUTTON}
        onClick={closeModal}
      >
        Cancel
      </ButtonComponent>
      <ButtonComponent
        disabled={!isFileImported}
        buttonType={BUTTON_TYPES.GRADIENT_BUTTON}
        onClick={saveImageData}
      >
        Save Changes
      </ButtonComponent>
    </div>
  );

  return (
    <ModalStateDialogComponent
      width={width}
      allowModalPadding={allowModalPadding}
      headerContent={headerContent()}
      footerContent={footerContent()}
      modelContent={modelContent()}
      isVisible
    />
  );
}

export default ImageEditorModal;
