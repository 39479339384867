import { unzip as fflateUnzip } from 'fflate'
import JSZip from 'jszip'

export async function unzip(zippedData, isJson=true) {
    if(isJson) {
        return new Promise(async (resolve, reject) => {
            fflateUnzip(new Uint8Array(zippedData), async (err, files) => {
                if (err) {
                    return reject(err)
                }
    
                const worker = new Worker(new URL('./jsonParserWorker.js', import.meta.url))
                worker.postMessage(files['data'])
    
                worker.onmessage = (event) => {
                    if (event.data.error) {
                        reject(event.data.error)
                    } else {
                        resolve(event.data.result)
                    }
                }
    
                worker.onerror = (error) => reject(`Worker error: ${error.message}`)
            })
        })
    } else {
        const zip = new JSZip();
        const loadedZip = await zip.loadAsync(zippedData);
        const file = Object.values(loadedZip.files)[0];
    
        const fileContent = await file.async('uint8array'); 
        const base64String = new TextDecoder().decode(fileContent);

        return base64String
    }
}