import { BUTTON_TYPES } from "@CubeComponents/constants/globalVariable";
import DropDownListComponent from "@CubeComponents/form/DropdownListComponent";
import GridComponent from "@CubeComponents/html/grid";
import {
  ColumnsDirective,
  ColumnDirective,
  Page,
  Inject,
  Filter,
  Sort,
  ExcelExport,
  Toolbar,
  Resize,
  ColumnChooser,
} from "@syncfusion/ej2-react-grids";
import { useCallback, useEffect, useRef, useState } from "react";
import { FormService, getDataManagerObj } from "../../services/dataManager";
import { Query } from "@syncfusion/ej2-data";
import ButtonComponent from "@CubeComponents/form/ButtonComponent";
import { useParams } from "react-router-dom";
import IconComponent from "@CubeComponents/icons";
import ToolTipComponent from "@CubeComponents/html/tooltip";
import { convertDate } from "../../services/luxonHelper";
import axiosWrapper from "../../services/axiosWrapper";
import { FaSyncAlt } from "react-icons/fa";
import { showToastNotification } from "../../actions/toast";
import { TOAST_TYPE_ERROR } from "../../services/constants";
import ModalStateDialogComponent from "../../cubeComponent/html/modal/ModalWithState";
import { presetSectionsCodeObject } from "../PortalSetings/Configuration/FormsAndFields/constants";
import {
  getFileIcon,
  statusTemplate,
} from "../CreateInspectionAndProgress/formsHelpers";
import {
  formStatusFilterDataSource,
  onURLClick,
} from "../CreateInspectionAndProgress/constants";
import { DropDownList } from "@syncfusion/ej2-react-dropdowns";
import { createElement } from "@syncfusion/ej2-base";

function FormEntries(props) {
  const dataGrid = useRef();
  const [selectedTemplate, setSelectedTemplate] = useState([]);
  const [formId, setFormId] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [renderGrid, setRenderGrid] = useState(false);
  const [statusFilterValue, setStatusFilterValue] = useState("");
  const [viewImage, setViewImage] = useState({ data: [], status: false });
  const params = useParams();
  const editSettings = {
    allowEditing: false,
    allowAdding: false,
    allowDeleting: false,
  };

  const filterSetting = {
    type: "Menu",
  };

  const pageSettings = { pageSizes: true, pageSize: 10 };
  const formQuery = new Query()
    .addParams("resourceType", props.formCategory)
    .where("isArchived", "equal", false);
  const gridQuery = new Query()
    .addParams("formId", formId)
    .addParams("projectId", params.projectId)
    .addParams("getExtractedEntries", true);
  const ApiUrl = `${process.env.REACT_APP_CUBE_BACKEND}/forms/fetchFormEntries`;
  const formEntriesService = getDataManagerObj(ApiUrl);

  const getSubColumns = ({
    questionFields,
    sectionHeading,
    _id,
    sectionCode,
  }) => {
    const columns = questionFields?.map((question, questionIndex) => {
      const field = `${_id}#@#${question._id}`;
      // if (sectionCode && sectionCode == presetSectionsCodeObject.tableSection) {
      //   field = `${_id}#@#${question._id}#@#${sectionCode}`;
      // }
      let allowFiltering = true;
      let allowSorting = true;
      if (
        ["image", "fileUpload", "look-up"].includes(question.fieldType) ||
        sectionCode
      ) {
        allowFiltering = false;
        allowSorting = false;
      }
      const column = {
        clipMode: "EllipsisWithTooltip",
        headerText: question.questionValue,
        width: "200px",
        field,
        name: "",
        allowFiltering,
        allowSorting,
        textAlign: "left",
      };
      if (sectionCode == presetSectionsCodeObject.tableSection) {
        column.template = (args) => tableSectionTemplate({ args, question });
        column.name = "tableSection";
        column.colType = question.fieldType;
      } else if (question.fieldType == "date-time") {
        column.type = "datetime";
        column.allowTooltip = false;
      } else if (question.fieldType == "number") {
        column.type = "number";
      } else if (question.fieldType == "image") {
        column.allowTooltip = false;
        column.type = "image";
        column.template = (args) =>
          imageTemplate({
            imageData: args[field],
            columnType: sectionCode ? "tableSection" : "normal",
          });
      } else if (question.fieldType == "fileUpload") {
        column.allowTooltip = false;
        column.type = "file";
        column.template = (args) =>
          fileTemplate({
            filesData: args[field],
            columnType: sectionCode ? "tableSection" : "normal",
          });
      } else if (question.fieldType == "url") {
        column.allowTooltip = false;
        column.type = "url";
        column.template = (args) => (
          <div
            className="text-gradient_color cursor-pointer underline underline-offset-2"
            onClick={(e) => {
              e.stopPropagation();
              const url = onURLClick(_.get(answer, "value.URL", ""));
              window.open(url);
            }}
          >
            {_.get(args, `${field}.displayText`, "")}
          </div>
        );
      }
      return column;
    });
    return columns;
  };

  const tableSectionTemplate = ({ args, question, source = "table" }) => {
    if (source == "excelExport") {
      let returnValue = "";
      args.tableDataSource.map((row) => {
        if (question.fieldType == "users") {
          const value =
            row[question._id]?.value?.map((value) => value.fullName) || [];
          returnValue =
            returnValue + (row[question._id]?.value ? value.join(",") : "");
        } else if (question.fieldType == "fileUpload") {
          const value = Array.isArray(row[question._id]?.value)
            ? row[question._id]?.value.map((data) => data.name).join(",")
            : "";
          returnValue = returnValue + value;
        } else if (question.fieldType == "image") {
          returnValue = `${returnValue}`;
        } else if (question.fieldType == "url") {
          returnValue =
            returnValue + (row[question._id]?.value?.displayText ?? "");
        } else if (question.fieldType == "look-up") {
          const value =
            row[question._id]?.value?.map((value) => value.name) || [];
          returnValue =
            returnValue + (row[question._id]?.value ? value.join(",") : "");
        } else if (question.fieldType == "date-time") {
          returnValue =
            returnValue +
            (row[question._id]?.value
              ? convertDate(Number(row[question._id]?.value), "dateForTooltip")
              : "");
        } else if (Array.isArray(row[question._id]?.value)) {
          const value = row[question._id]?.value.join(",") || [];
          returnValue = returnValue + (row[question._id]?.value ? value : "");
        } else {
          returnValue = returnValue + (row[question._id]?.value ?? "");
        }
        returnValue = `${returnValue}\n`;
      });
      return returnValue;
    }
    return (
      <div className="flex flex-col gap-2 w-full h-full">
        {args.tableDataSource.map((row) => {
          let returnValue = "";
          if (question.fieldType == "users") {
            const value =
              row[question._id]?.value?.map((value) => value.fullName) || [];
            returnValue = row[question._id]?.value ? value.join(",") : "";
          } else if (question.fieldType == "fileUpload") {
            returnValue = fileTemplate({ filesData: row[question._id]?.value });
          } else if (question.fieldType == "image") {
            returnValue = imageTemplate({
              imageData: row[question._id]?.value,
            });
          } else if (question.fieldType == "look-up") {
            const value =
              row[question._id]?.value?.map((value) => value.name) || [];
            returnValue = row[question._id]?.value ? value.join(",") : "";
          } else if (question.fieldType == "date-time") {
            returnValue = row[question._id]?.value
              ? convertDate(Number(row[question._id]?.value), "dateForTooltip")
              : "";
          } else if (Array.isArray(row[question._id]?.value)) {
            const value = row[question._id]?.value.join(",") || [];
            returnValue = row[question._id]?.value ? value : "";
          } else if (question.fieldType == "url") {
            const value = row[question._id]?.value?.displayText || "";
            returnValue = row[question._id]?.value ? value : "";
            return (
              <div
                className="flex h-16 -mr-2 -ml-2 border-button_light border-b pl-2 pr-2 items-center text-gradient_color cursor-pointer underline underline-offset-2"
                onClick={(e) => {
                  e.stopPropagation();
                  const url = onURLClick(_.get(answer, "value.URL", ""));
                  window.open(url);
                }}
              >
                {returnValue}
              </div>
            );
          } else {
            returnValue = row[question._id]?.value ?? "";
          }
          return (
            <span className="flex h-16 -mr-2 -ml-2 border-button_light border-b pl-2 pr-2 items-center">
              {returnValue}
            </span>
          );
        })}
      </div>
    );
  };

  const handleImageViewClick = (e, data, clickedIndex) => {
    e.stopPropagation();
    setViewImage(() => {
      return {
        data,
        status: true,
        clickedIndex,
        disableNxt: clickedIndex == data.length - 1,
        disablePrev: clickedIndex == 0,
      };
    });
  };

  function fileTemplate({ filesData, columnType }) {
    return (
      <span className="flex pr-3 overflow-auto gap-4  w-full h-full">
        {Array.isArray(filesData) &&
          filesData?.map((data, index) => (
            <span className="flex gap-3 border-solid border-2 border-button_light w-fit pr-2 rounded-lg pl-2 items-center">
              <span className="w-14 h-10 flex object-contain">
                {getFileIcon(data.name)}
              </span>
              <span>{data.name}</span>
            </span>
          ))}
      </span>
    );
  }

  function imageTemplate({ imageData, columnType }) {
    return (
      <div
        onClick={(e) => e.stopPropagation()}
        className="w-full overflow-auto flex gap-2"
      >
        {Array.isArray(imageData) &&
          imageData?.map((data, index) => (
            <span
              onClick={(e) => handleImageViewClick(e, imageData, index)}
              className="min-w-16 max-w-16 h-16 flex cursor-zoom-in"
            >
              <img
                alt="img"
                src={`${process.env.REACT_APP_CUBE_BACKEND}/fileSystemServer/getInspectionAttachmentFile/${data.awsKey}`}
              />
            </span>
          ))}
      </div>
    );
  }

  const dateTemplate = (args, question) => {
    if (question.answer?.value) {
      const date = question.answer?.value;
      const dateFormat = convertDate(Number(date), "dateForTooltip");
      return <span>{convertDate(Number(date), "dateForTooltip")}</span>;
    }
    return "-";
  };

  const formatDateTemplate = (data) => {
    if (data.lastAction.timeStamp) {
      const date = data.lastAction.timeStamp;
      const dateFormat = convertDate(Number(date), "dateForTooltip");
      return (
        <ToolTipComponent
          showTipPointer
          position="BottomCenter"
          content={dateFormat}
        >
          <span>{convertDate(Number(date), "dateForText")}</span>
        </ToolTipComponent>
      );
    }

    return "-";
  };

  const stateTemplate = (params) => {
    if (params.isArchived?.status) {
      return "Archived";
    }
    return "Active";
  };

  const handleChange = (e) => {
    setStatusFilterValue(e.value);
  };

  const stateFilterTemplate = () => (
    <DropDownListComponent
      dataSource={[
        { name: "Active", value: "active" },
        { name: "Archived", value: "archived" },
      ]}
      fields={{ text: "name", value: "value" }}
      floatLabelType="Always"
      value={statusFilterValue}
      bgColor="white"
      border="0.5px solid black"
      onChange={handleChange}
    />
  );
  let dropInstance;
  const statusFilterTemplate = {
    ui: {
      create: (args) => {
        const flValInput = createElement("input", { className: "flm-input" });
        args.target.appendChild(flValInput);
        dropInstance = new DropDownList({
          dataSource: formStatusFilterDataSource,
          fields: { text: "name", value: "value" },
          placeholder: "Select a value",
        });
        dropInstance.appendTo(flValInput);
      },
      read: (args) => {
        args.fltrObj.filterByColumn(
          args.column.field,
          args.operator,
          dropInstance.value,
        );
      },
      write: (args) => {
        dropInstance.value = args.filteredValue;
      },
    },
  };

  const actionBegin = (args) => {
    if (args.action == "filter") {
      args.columns.map((column) => {
        if (column.field == "isArchived.status") {
          column.value = statusFilterValue != "active";
        }
      });
    }
  };

  function levelTemplate(args) {
    return <span> {args.formFlow[args.currentLevelOfForm].heading}</span>;
  }

  const getColumnsDirective = () => (
    <ColumnsDirective>
      <ColumnDirective
        key="columnDirective_id}"
        headerText="_id"
        isPrimaryKey
        allowSorting={false}
        allowFiltering={false}
        showInColumnChooser={false}
        width="0"
        visible={false}
      />
      <ColumnDirective
        key="columnDirective_counter"
        field="counter"
        width="120"
        headerText="Form No"
      />
      <ColumnDirective
        key="columnDirective_title"
        field="title"
        headerText="Title"
      />
      <ColumnDirective
        key="columnDirective_description"
        field="description"
        headerText="Description"
      />
      <ColumnDirective
        key="columnDirective_lastActionName"
        field="lastAction.actionName"
        headerText="Last Action"
      />
      <ColumnDirective
        key="columnDirective_currentLevel"
        field="formFlow.heading"
        headerText="Current Level"
        allowFiltering={false}
        allowSorting={false}
        template={levelTemplate}
      />
      <ColumnDirective
        key="columnDirective_state"
        field="isArchived.status"
        width="120"
        headerText="State"
        template={stateTemplate}
        filterTemplate={stateFilterTemplate}
      />
      <ColumnDirective
        key="columnDirective_status"
        field="status"
        width="150"
        headerText="Status"
        template={(args) => statusTemplate(args.status)}
        filter={statusFilterTemplate}
      />
      <ColumnDirective
        key="columnDirective_lastActionTimeStamp"
        field="lastAction.timeStamp"
        headerText="Last Action At"
        template={formatDateTemplate}
        format="yyyy/mm/dd"
        type="datetime"
      />
      <ColumnDirective
        key="columnDirective_lastActionBy"
        field="lastAction.userName"
        headerText="Last Action By"
      />
      {selectedTemplate?.map(
        (column, sectionIndex) =>
          !(
            column.sectionCode &&
            column.sectionCode != presetSectionsCodeObject.tableSection
          ) && (
            <ColumnDirective
              key={`columnDirective_${column._id}`}
              headerText={column.sectionHeading}
              textAlign="center"
              columns={getSubColumns({
                questionFields: column.questionFields,
                sectionHeading: column.sectionHeading,
                _id: column._id,
                sectionCode: column.sectionCode,
              })}
            />
          ),
      )}
    </ColumnsDirective>
  );

  const toolbarOptions = ["ColumnChooser"];

  async function excelQueryCellInfo(args) {
    if (args.column.name === "tableSection") {
      const questionId = args.column.field.split("#@#")[1];
      args.value = tableSectionTemplate({
        args: args.data,
        question: { fieldType: args.column.colType, _id: questionId },
        source: "excelExport",
      });
    } else if (args.column.type === "datetime") {
      args.value = args.value
        ? convertDate(Number(args.value), "dateForTooltip")
        : "";
    } else if (args.column.headerText === "State") {
      args.value = args.value ? "Archived" : "Active";
    } else if (args.column.headerText === "Current Level") {
      args.value = args.data.formFlow[args.data.currentLevelOfForm].heading;
    } else if (args.column.type === "file") {
      const modifiedValue = args.value?.map((val) => val.name).join(", ");
      args.value = modifiedValue;
    } else if (args.column.type === "url") {
      args.value = _.get(args, "value.displayText", "");
    } else if (args.column.type === "image") {
      const questionId = args.column.field.split("#@#")[1];
      args.image = {
        height: 75,
        base64:
          args.data[questionId] && typeof args.data[questionId] == "string"
            ? args.data[questionId].replace(/data:.*;base64,/, "")
            : "",
        width: 75,
      };
      args.value = "";
    }
  }

  const getGridComponent = useCallback(
    () => (
      <div className="w-full h-full overflow-auto">
        {selectedTemplate.length > 0 ? (
          <GridComponent
            ref={dataGrid}
            dataSource={formEntriesService}
            toolbar={toolbarOptions}
            excelQueryCellInfo={excelQueryCellInfo}
            actionBegin={actionBegin}
            showColumnChooser
            allowFiltering
            allowSorting
            allowExcelExport
            allowPaging
            rowHeight={50}
            gridLines="Both"
            filterSettings={filterSetting}
            height="100%"
            editSettings={editSettings}
            pageSettings={pageSettings}
            allowResizing
            query={gridQuery}
          >
            {getColumnsDirective()}
            <Inject
              services={[
                Page,
                Filter,
                Sort,
                Toolbar,
                Resize,
                ExcelExport,
                ColumnChooser,
              ]}
            />
          </GridComponent>
        ) : (
          <div className="flex w-full items-center h-full justify-center flex-col gap-4">
            <IconComponent cssClass="h-80 w-80" name="InspectionFormNoData" />
            <span className="font-semibol text-xl">
              Select a template to view entries
            </span>
          </div>
        )}
      </div>
    ),
    [selectedTemplate, statusFilterValue],
  );

  useEffect(() => {
    setRenderGrid(true);
  }, [selectedTemplate]);

  const handleDropDownChange = (event) => {
    if (event.isInteracted) {
      setRenderGrid(false);
      setFormId(event.value);
      setSelectedTemplate(event.itemData.template);
    }
  };

  const handleExportCsv = async () => {
    setShowLoader(() => true);
    const res = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/forms/getBase64StringForFormEntires/`,
      {
        templateId: formId,
        projectId: params.projectId,
      },
    );
    if (res.data.status) {
      const base64Data = res.data?.data || {};
      const newDataSource = dataGrid.current.currentViewData.map((data) => {
        if (base64Data[data._id]) {
          Object.keys(base64Data[data._id]).map((key) => {
            const questionId = key.split("#@#")[1];
            data[questionId] = base64Data[data._id][key];
          });
        }
        return data;
      });
      const exportProperties = {
        dataSource: newDataSource,
      };
      dataGrid.current.excelExport(exportProperties);
    } else {
      dispatch(
        showToastNotification({
          content: "Something went wrong",
          type: TOAST_TYPE_ERROR,
        }),
      );
    }
    setShowLoader(() => false);
  };

  const header = () => (
    <div className="flex items-center gap-3 full pl-6">
      <div>Form Template</div>
      <div className="w-3/5">
        <DropDownListComponent
          fields={{ text: "name", value: "_id" }}
          placeholder="Select Form"
          change={handleDropDownChange}
          dataSource={FormService}
          query={formQuery}
          value={formId}
          noRecordsTemplate="No Forms Found"
        />
      </div>
      <div>
        {showLoader ? (
          <div className="animate-spin">
            <FaSyncAlt />
          </div>
        ) : (
          <ButtonComponent
            disabled={formId == "" || selectedTemplate.length == 0}
            onClick={handleExportCsv}
            buttonType={BUTTON_TYPES.BACKGROUND_BUTTON}
          >
            Export CSV
          </ButtonComponent>
        )}
      </div>
    </div>
  );

  const handlePrevImgClick = () => {
    if (!viewImage.disablePrev) {
      setViewImage((prev) => {
        const currentIndex = prev.clickedIndex;
        const clickedIndex = Math.max(0, currentIndex - 1);
        return {
          ...prev,
          clickedIndex,
          disableNxt: false,
          disablePrev: clickedIndex == 0,
        };
      });
    }
  };

  const handleNxtImgClick = () => {
    if (!viewImage.disableNxt) {
      setViewImage((prev) => {
        const currentIndex = prev.clickedIndex;
        const clickedIndex = Math.min(prev.data.length - 1, currentIndex + 1);
        return {
          ...prev,
          clickedIndex,
          disablePrev: false,
          disableNxt: clickedIndex == prev.data.length - 1,
        };
      });
    }
  };

  const imageViewContent = () => {
    const selectedIndex = viewImage.clickedIndex;
    return (
      <div className="h-125 justify-center items-center overflow-auto flex w-full">
        <span className="flex gap-4 overflow-auto items-center">
          <IconComponent
            onClick={handlePrevImgClick}
            cssClass={`absolute left-6 ${viewImage.disablePrev ? "cursor-not-allowed	opacity-30" : "cursor-pointer"}`}
            name="ArrowLeft"
          />
          <img
            alt="img"
            className="w-full p-5 flex overflow-auto h-full"
            src={`${process.env.REACT_APP_CUBE_BACKEND}/fileSystemServer/getInspectionAttachmentFile/${viewImage.data[selectedIndex].awsKey}`}
          />
          <IconComponent
            onClick={handleNxtImgClick}
            cssClass={`absolute right-6	${viewImage.disableNxt ? "cursor-not-allowed	opacity-30" : "cursor-pointer"}`}
            name="arrowRight"
          />
        </span>
      </div>
    );
  };

  return (
    <div className="flex w-full flex-col gap-1 h-full overflow-auto">
      <div>{header()}</div>
      {renderGrid && getGridComponent()}
      {viewImage.status && (
        <ModalStateDialogComponent
          footerContent={<></>}
          width="70%"
          headerContent={
            <div className="flex w-full justify-end">
              <IconComponent
                name="close"
                onClick={() => setViewImage({ status: false })}
                cssClass="cursor-pointer"
              />
            </div>
          }
          onCancel={() => setViewImage({ status: false })}
          isVisible
          modelContent={imageViewContent()}
        />
      )}
    </div>
  );
}

export default FormEntries;
