import { useCallback, useEffect, useRef, useState } from "react";
import ModalStateDialogComponent from "@CubeComponents/html/modal/ModalWithState";
import ButtonComponent from "@CubeComponents/form/ButtonComponent";
import { BUTTON_TYPES } from "@CubeComponents/constants/globalVariable";
import IconComponent from "@CubeComponents/icons";
import Forms from "@Components/PortalSetings/Configuration/FormsAndFields/Forms";
import TextBoxComponent from "@CubeComponents/form/TextBoxComponent";
import DropDownListComponent from "@CubeComponents/form/DropdownListComponent";
import { getAllUsersInSpace } from "../../actions/projectUsers";
import { useDispatch, useSelector } from "react-redux";
import {
  FormService,
  FormTypeService,
  formFlowService,
  spacesDataService,
} from "../../services/dataManager";
import { Query, Predicate } from "@syncfusion/ej2-data";
import FormFlowCreation from "./formFlowCreation";
import { showToastNotification } from "../../actions/toast";
import {
  TOAST_TYPE_ERROR,
  TOAST_TYPE_SUCCESS,
  TOAST_TYPE_WARNING,
} from "../../services/constants";
import { taskInspectionAndProgressFormsController } from "../../actions/Forms";
import { getInspectionUrl } from "@Helpers";
import { useNavigate, useParams } from "react-router-dom";
import FormPreview from "../PortalSetings/Configuration/FormsAndFields/FormTemplate/formPreview";
import {
  formCategories,
  formCategoryObject,
} from "../PortalSetings/Configuration/FormsAndFields/constants";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TasksForms from "@Components/CreateInspectionAndProgress/TaskInspectionAndProgressForm";
import useCreateInspection from "./useCreateInspection";
import RadioButtonComponent from "../../cubeComponent/form/RadioButtonComponent";
import MileStones from "../Contracts/ContractDetails/mileStones";
import ContractTasks from "./contractTaskGrid";
import { getContractDetails } from "../../actions/Contracts";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import CheckboxComponent from "../../cubeComponent/form/CheckboxComponent";
import { DropDownTreeComponent } from "@syncfusion/ej2-react-dropdowns";
// import { getPermissions } from "../../services/utils/helpers";
const { getPermissions } = require("@Helpers");

function InspectionCreation(props) {
  const navigate = useNavigate();
  const params = useParams();
  const milestoneRef = useRef();
  const treeGrid = useRef();
  const formsRef = useRef();
  const dispatch = useDispatch();
  const { projectData, portalData, customModules } = useSelector(
    (state) => state,
  );
  const permissions = getPermissions();

  const {
    title,
    setTitle,
    description,
    setDescription,
    dueDate,
    setDueDate,
    selectedFormTypeId,
    setSelectedFormTypeId,
    selectedForm,
    setSelectedForm,
    showFormFlowModal,
    setShowFormFlowModal,
    spaceId,
    setSpaceId,
    selectedFormFlow,
    setSelectedFormFlow,
    handleBasicDetailsNextClick,
    handleFormSelectNextClick,
    handleCancelFormAttachment,
    handleBackClick,
    handleFormFlowCreate,
    handleNextClick,
    attachEntity,
    setAttachEntity,
    handleCreateClick,
    progressUpdateEntityCondition,
    inspectionEntityCondition,
    isRustomjeePortal,
    isMPMRCLPortal,
    allowedAttachmentConstants,
    setRememberUserSettings,
    rememberUserSettings,
    templateDataSource,
  } = useCreateInspection({
    mobile: false,
    taskInspectionAndProgressFormsController,
    projectId: props.projectId,
    ...props,
    formCategoryObject,
    getInspectionUrl,
    navigate,
    contractId: params.contractId,
    resourceType: props.showFormFlowCreationModal.category,
    portalData,
    getContractDetails,
  });
  useEffect(() => {
    if (spaceId) {
      dispatch(
        getAllUsersInSpace({
          spaceId,
        }),
      );
    }
  }, [spaceId]);

  const handleChange = (args) => {
    if (args?.target?.name == "title") {
      setTitle(args.value.trim().substring(0, 50));
    } else if (args?.target?.name == "description") {
      setDescription(args.value.trim().substring(0, 150));
    } else if (args?.target?.name == "dueDate" && args.value) {
      setDueDate(new Date(args.value).setHours(23, 59, 0, 0));
    } else if (args?.target?.name == "dueDate" && !args.value) {
      setDueDate(null);
    }
  };

  const predicate = new Predicate("projectId", "equal", props.projectId);
  const templateNameQuery = new Query()
    .addParams("resourceType", props.showFormFlowCreationModal.category)
    .addParams("isArchived", false)
    .where(predicate);

  const formsHeaderContent = () => {
    const categoryObj = customModules.find(
      (module) =>
        module.resourceType == props.showFormFlowCreationModal.category,
    );
    const modalHeader =
      categoryObj?.modalHeader ||
      formCategoryObject[props.showFormFlowCreationModal.category].modalHeader;
    return (
      <div className="bg-button_light  w-full flex pt-3 pb-3 pr-6 pl-6 justify-between rounded-md">
        <div className="flex gap-3 items-center justify-center">
          {showFormFlowModal.step1 && showFormFlowModal.step2 && (
            <span onClick={handleBackClick}>
              <IconComponent cssClass="cursor-pointer" name="BackwardArrow" />
            </span>
          )}
          <div className="flex flex-col gap-1">
            <span>{modalHeader}</span>
          </div>
        </div>
        <div className="flex cursor-pointer">
          <IconComponent name="close" onClick={handleCancelFormAttachment} />
        </div>
      </div>
    );
  };

  const handleFormFlowSelection = (args) => {
    setSelectedFormFlow(args.itemData);
    if (args.itemData?.formFlow?.length == 0) {
      dispatch(
        showToastNotification({
          content: "This formFlow's flow is not defined",
          type: TOAST_TYPE_WARNING,
        }),
      );
    }
  };

  const formsModelContent = () => {
    if (
      showFormFlowModal.step1 &&
      !showFormFlowModal.step2 &&
      !showFormFlowModal.step3 &&
      !showFormFlowModal.step4
    ) {
      return basicDetails();
    }
    if (
      showFormFlowModal.step1 &&
      showFormFlowModal.step2 &&
      !showFormFlowModal.step3 &&
      !showFormFlowModal.step4
    ) {
      return formSelection();
    }
    if (
      showFormFlowModal.step1 &&
      showFormFlowModal.step2 &&
      showFormFlowModal.step3 &&
      !showFormFlowModal.step4
    ) {
      return (
        <div className="flex flex-col h-144 gap-5 m-5">
          <div className="flex h-20 items-center gap-5">
            <div className="flex gap-4 items-center">
              <span className="font-semibold h-5 w-5 flex justify-center items-center rounded-full bg-button_light">
                3
              </span>
              <span className="font-semibold">Select an existing formFlow</span>
              <span>
                <DropDownListComponent
                  buttonType={BUTTON_TYPES.DROPDOWN_BACKGROUND_BUTTON}
                  fields={{ text: "name", value: "_id" }}
                  placeholder="Select form flow"
                  change={handleFormFlowSelection}
                  dataSource={formFlowService}
                  query={new Query()
                    .addParams("selectedFormId", selectedForm._id)
                    .addParams("spaceId", spaceId)
                    .addParams("projectId", props.projectId)}
                  popupWidth="273px"
                  width="273px"
                  value={selectedFormFlow._id}
                />
              </span>
            </div>
            {projectData &&
              projectData.permissions?.includes(
                permissions.SPECIFIC_CREATE_FORM_FLOW.id,
              ) && (
                <>
                  <span>or</span>
                  <div className="pl-3 gap-2">
                    <ButtonComponent
                      buttonType={BUTTON_TYPES.BACKGROUND_BUTTON}
                      onClick={handleFormFlowCreate}
                    >
                      Create New Form Flow
                    </ButtonComponent>
                  </div>
                </>
              )}
          </div>
          {Object.keys(selectedFormFlow).length != 0 &&
          selectedFormFlow.formFlow.length != 0 ? (
            <div className="h-full w-full overflow-auto">
              <Accordion
                key={selectedFormFlow._id}
                defaultExpanded={false}
                border="1px solid var(--primary-blue)"
                borderRadius="10px"
                width="100%"
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div className="font-semibold text-lg">
                    {`${selectedFormFlow.name} Preview`}{" "}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <FormFlowCreation
                    selectedFormFlow={selectedFormFlow.formFlow}
                    openFormFlowInViewMode
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          ) : (
            <div className="flex items-center w-full h-full justify-center flex-col gap-4">
              <IconComponent cssClass="h-80 w-80" name="InspectionFormNoData" />
              <span className="font-semibol text-xl">
                Select a form flow first
              </span>
            </div>
          )}
        </div>
      );
    }
    return (
      <div className="flex overflow-auto h-175 w-full">
        <FormFlowCreation
          handleCreateClick={handleCreateClick}
          handleCancelFormAttachment={handleCancelFormAttachment}
          selectedForm={selectedForm}
          spaceId={spaceId}
          source="INSPECTION_CREATION"
          setChangeOrdeState={props.setChangeOrdeState}
          setShowFormFlowCreationModal={props.setShowFormFlowCreationModal}
        />
      </div>
    );
  };

  const handleSpaceSelect = (event) => {
    if (event.isInteracted) {
      setSpaceId(event.value);
    }
  };

  const handleTemplateSelect = (args) => {
    if (args.action == "select" && args.isInteracted) {
      if (!args.itemData.parentID) {
        setSelectedForm({});
        dispatch(
          showToastNotification({
            content:
              "Please select a template and not template type to continue",
            type: TOAST_TYPE_WARNING,
          }),
        );
        args.cancel = true;
      } else {
        const itemData = templateDataSource.find(
          (data) => data._id == args.itemData.id,
        );
        if (itemData) {
          setSelectedForm(itemData);
        }
      }
    }
    if (args.action == "un-select" && args.isInteracted) {
      setSelectedForm({});
    }
  };
  const itemTemplate = (args) => {
    return (
      <div
        style={
          args.hasChild
            ? { pointerEvents: "none", fontWeight: 600, cursor: "not-allowed" }
            : {}
        }
      >
        {args.name}
      </div>
    );
  };

  const formSelection = () => (
    <div className="flex flex-col h-144 m-5 gap-5">
      <div className="flex gap-4 items-center">
        <span className="font-semibold h-5 w-5 flex justify-center items-center rounded-full bg-button_light">
          2
        </span>
        <span className="font-semibold">
          Select the template on which this form will be based
        </span>
      </div>
      <div className="overflow-auto pl-8 h-16 w-full">
        <DropDownTreeComponent
          placeholder="Select Template"
          width="100%"
          allowMultiSelection={false}
          value={selectedForm._id ? [selectedForm._id] : null}
          sortOrder="Ascending"
          filterType="contains"
          itemTemplate={itemTemplate}
          allowFiltering
          fields={{
            dataSource: templateDataSource,
            value: "_id",
            text: "name",
            parentValue: "parentId",
            hasChildren: "hasChild",
            selectable: "isChild",
          }}
          select={handleTemplateSelect}
          showClearButton={false}
        />
      </div>
      {Object.keys(selectedForm).length > 0 ? (
        <div className="mt-5 h-full w-full overflow-auto">
          <Accordion
            key={selectedFormFlow._id}
            defaultExpanded={false}
            border="1px solid var(--primary-blue)"
            borderRadius="10px"
            width="100%"
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div className="font-semibold text-lg">PREVIEW</div>
            </AccordionSummary>
            <AccordionDetails>
              <FormPreview formData={selectedForm} showNewFields />
            </AccordionDetails>
          </Accordion>
        </div>
      ) : (
        <div className="flex w-full items-center justify-center flex-col gap-4">
          <IconComponent cssClass="h-80 w-80" name="InspectionFormNoData" />
          <span className="font-semibol text-xl">
            Select a template type and template name first
          </span>
        </div>
      )}
    </div>
  );

  const handleClearClick = () => {
    setAttachEntity({ name: "" });
  };

  const basicDetails = () => {
    const categoryObj = customModules.find(
      (module) =>
        module.resourceType == props.showFormFlowCreationModal.category,
    );

    const formHeader =
      categoryObj?.formHeader ||
      formCategoryObject[props.showFormFlowCreationModal.category].formHeader;
    return (
      <div className="h-144 flex-col justify-between overflow-auto flex p-5">
        <div className="flex flex-col gap-5 overflow-auto">
          <div className="flex gap-4 items-center">
            <span className="font-semibold h-5 w-5 flex justify-center items-center rounded-full bg-button_light">
              1
            </span>
            <span className="font-semibold">Fill in some basic Details</span>
          </div>
          <div className="flex gap-10 items-center">
            <span className="font-semibold w-64 gap-1 items-center flex">
              Title
              <span className="text-2xl font-light text-red-500">*</span>
            </span>
            <span className="w-full">
              <TextBoxComponent
                name="title"
                placeholder="Title"
                showClearButton
                value={title}
                onChange={handleChange}
              />
            </span>
          </div>
          <div className="flex gap-10 items-center">
            <span className="font-semibold w-64">Description</span>
            <span className="w-full">
              <TextBoxComponent
                name="description"
                placeholder="Description"
                showClearButton
                value={description}
                multiline
                onChange={handleChange}
              />
            </span>
          </div>
          {props.source == "project" && (
            <div className="flex gap-10 items-center">
              <span className="font-semibold w-64 flex gap-1 items-center">
                Space
                <span className="text-2xl font-light text-red-500">*</span>
              </span>
              <span className="w-full">
                <DropDownListComponent
                  buttonType={BUTTON_TYPES.DROPDOWN_BACKGROUND_BUTTON}
                  fields={{ text: "spaceName", value: "id" }}
                  placeholder="Select Space"
                  change={handleSpaceSelect}
                  dataSource={spacesDataService}
                  query={new Query().addParams("projectId", props.projectId)}
                  value={spaceId}
                />
              </span>
            </div>
          )}
          {!isMPMRCLPortal && (
            <div className="flex gap-10 items-center">
              <span className="font-semibold w-64 gap-1 items-center flex">
                Due Date
              </span>
              <span className="w-full">
                {" "}
                <DatePickerComponent
                  min={new Date()}
                  value={dueDate ? new Date(dueDate) : null}
                  id="datetimepicker"
                  width="100%"
                  onChange={handleChange}
                  placeholder="Enter Due Date"
                  name="dueDate"
                  format="dd/MM/yyyy"
                />
              </span>
            </div>
          )}
          {props.source == "contract" &&
            (progressUpdateEntityCondition || inspectionEntityCondition) && (
              <div className="flex gap-10">
                <span className="font-semibold w-64">
                  {`${formHeader} based on`}
                </span>
                <span className="flex w-full justify-between">
                  <span className="w-full flex gap-5">
                    {progressUpdateEntityCondition && (
                      <RadioButtonComponent
                        label="Progress Updates"
                        change={() =>
                          setAttachEntity({
                            name: formCategories.progressUpdates,
                          })
                        }
                        checked={
                          attachEntity.name ===
                            formCategories.progressUpdates ||
                          attachEntity[formCategories.progressUpdates]?.length >
                            0
                        }
                      />
                    )}

                    {inspectionEntityCondition && (
                      <RadioButtonComponent
                        label={isRustomjeePortal ? "Work Done" : "Inspections"}
                        change={() =>
                          setAttachEntity({ name: formCategories.inspection })
                        }
                        checked={
                          attachEntity.name === formCategories.inspection ||
                          attachEntity[formCategories.inspection]?.length > 0
                        }
                      />
                    )}
                  </span>
                  <span
                    onClick={handleClearClick}
                    className="bg-button_light rounded-2xl h-6 pl-2 pr-2 cursor-pointer"
                  >
                    clear
                  </span>
                </span>
              </div>
            )}
        </div>
        <div>
          <CheckboxComponent
            change={(args) => setRememberUserSettings(args.checked)}
            checked={rememberUserSettings}
            label="Remember the configuration being applied in this series of steps for subsequent form(s) creation"
          />
        </div>
      </div>
    );
  };

  const formsFooterContent = () => {
    if (
      showFormFlowModal.step1 &&
      showFormFlowModal.step2 &&
      showFormFlowModal.step3 &&
      showFormFlowModal.step4
    ) {
      return "";
    }
    let isNextDisable = true;
    let showCreateBtn = false;
    if (
      showFormFlowModal.step1 &&
      !showFormFlowModal.step2 &&
      !showFormFlowModal.step3 &&
      !showFormFlowModal.step4
    ) {
      isNextDisable = title == "" || (props.source == "project" && !spaceId);
    } else if (
      showFormFlowModal.step1 &&
      showFormFlowModal.step2 &&
      !showFormFlowModal.step3 &&
      !showFormFlowModal.step4
    ) {
      isNextDisable =
        Object.keys(selectedForm).length == 0 ||
        selectedForm.template?.length == 0;
    } else if (
      showFormFlowModal.step1 &&
      showFormFlowModal.step2 &&
      showFormFlowModal.step3 &&
      !showFormFlowModal.step4
    ) {
      isNextDisable =
        Object.keys(selectedFormFlow).length == 0 ||
        selectedFormFlow.formFlow.length == 0;
      showCreateBtn = true;
    }
    return (
      <div className="m-6 flex gap-4">
        <ButtonComponent
          disabled={isNextDisable}
          buttonType={BUTTON_TYPES.GRADIENT_BUTTON}
          onClick={handleNextClick}
        >
          {showCreateBtn ? "Create" : "Next"}
        </ButtonComponent>
        <ButtonComponent
          buttonType={BUTTON_TYPES.BACKGROUND_BUTTON}
          onClick={handleCancelFormAttachment}
        >
          Cancel
        </ButtonComponent>
      </div>
    );
  };

  const getInspectionFormFlow = () => (
    <ModalStateDialogComponent
      allowModalPadding={false}
      footerContent={formsFooterContent()}
      width="70%"
      headerContent={formsHeaderContent()}
      onCancel={handleCancelFormAttachment}
      isVisible={props.showFormFlowCreationModal.status}
      modelContent={formsModelContent()}
    />
  );

  const closeAttachEntityModal = () => {
    setAttachEntity({ name: "budget" });
  };

  const handleAttachEntityClick = () => {
    if (attachEntity.name == "tasks") {
      const selectedTasks = treeGrid.current.getSelectedRecords();
      if (selectedTasks.length) {
        const taskIds = selectedTasks.map((task) => task._id);
        setAttachEntity({ name: "", [attachEntity.name]: taskIds });
      } else {
        dispatch(
          showToastNotification({
            content: "Select task(s) first",
            type: TOAST_TYPE_WARNING,
          }),
        );
      }
    } else if (attachEntity.name == formCategories.progressUpdates) {
      const selectedProgressUpdates = formsRef.current.getSelectedRecords();
      if (selectedProgressUpdates.length) {
        const progressUpdateIds = selectedProgressUpdates.map(
          (form) => form._id,
        );
        setAttachEntity({ name: "", [attachEntity.name]: progressUpdateIds });
      } else {
        dispatch(
          showToastNotification({
            content: "Select Progress Updates(s) first",
            type: TOAST_TYPE_WARNING,
          }),
        );
      }
    } else if (attachEntity.name == formCategories.inspection) {
      const selectedInspection = formsRef.current.getSelectedRecords();
      if (selectedInspection.length) {
        const inspectionIds = selectedInspection.map((form) => form._id);
        setAttachEntity({ name: "", [attachEntity.name]: inspectionIds });
      } else {
        dispatch(
          showToastNotification({
            content: "Select Inspections(s) first",
            type: TOAST_TYPE_WARNING,
          }),
        );
      }
    } else if (attachEntity.name == "milestones") {
      const selectedMileStones = milestoneRef.current.getSelectedRecords();
      if (selectedMileStones.length) {
        const milestoneIds = selectedMileStones.map(
          (mileStone) => mileStone._id,
        );
        setAttachEntity({ name: "", [attachEntity.name]: milestoneIds });
      } else {
        dispatch(
          showToastNotification({
            content: "Select Milestone(s) first",
            type: TOAST_TYPE_WARNING,
          }),
        );
      }
    }
  };

  const attachEntityFooter = () => (
    <div className="m-6 flex gap-4">
      <ButtonComponent
        buttonType={BUTTON_TYPES.GRADIENT_BUTTON}
        onClick={handleAttachEntityClick}
      >
        Attach
      </ButtonComponent>
      <ButtonComponent
        buttonType={BUTTON_TYPES.BACKGROUND_BUTTON}
        onClick={closeAttachEntityModal}
      >
        Cancel
      </ButtonComponent>
    </div>
  );
  const attachEntityHeader = () => (
    <div className="bg-button_light  w-full flex justify-between rounded-md">
      <div className="flex m-6 flex-col gap-1">
        <div>{`Attach ${attachEntity.name}`}</div>
        <span className="text-sm font-normal">{`All the ${attachEntity.name} related to this Contract`}</span>
      </div>
      <div className="flex pr-7 cursor-pointer">
        <IconComponent name="close" onClick={closeAttachEntityModal} />
      </div>
    </div>
  );
  const attachEntityContent = () => {
    if (attachEntity.name === "tasks") {
      return <ContractTasks ref={treeGrid} contractId={params.contractId} />;
    }
    if (attachEntity.name === formCategories.progressUpdates) {
      return (
        <div className="m-6 h-96 flex overflow-auto">
          <TasksForms
            showOnlyApproved
            source="contract"
            ref={formsRef}
            showOnlyGrid
            formCategory={formCategories.progressUpdates}
            projectId={params.projectId}
          />
        </div>
      );
    }
    if (attachEntity.name === formCategories.inspection) {
      return (
        <div className="m-6 h-96 flex overflow-auto">
          <TasksForms
            showOnlyApproved
            source="contract"
            ref={formsRef}
            showOnlyGrid
            formCategory={formCategories.inspection}
            projectId={params.projectId}
          />
        </div>
      );
    }
    if (attachEntity.name === "milestones") {
      return (
        <div className="m-6 h-96 flex overflow-auto">
          <MileStones
            showOnlyGrid
            ref={milestoneRef}
            contractId={params.contractId}
            projectId={params.projectId}
          />
        </div>
      );
    }
  };
  return (
    <div>
      {props.showFormFlowCreationModal.status && getInspectionFormFlow()}
      {allowedAttachmentConstants.includes(attachEntity.name) && (
        <ModalStateDialogComponent
          allowModalPadding={false}
          footerContent={attachEntityFooter()}
          width="80%"
          headerContent={attachEntityHeader()}
          onCancel={closeAttachEntityModal}
          isVisible
          modelContent={attachEntityContent()}
        />
      )}
    </div>
  );
}
export default InspectionCreation;
