import React, { useEffect, useRef, useState, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import ButtonComponent from "@CubeComponents/form/ButtonComponent";
import ToolTipComponent from "../../../cubeComponent/html/tooltip";
import { getFileImage } from "../../../services/utils/helpers";
import axiosWrapper from "../../../services/axiosWrapper";
import { showToastNotification } from "../../../actions/toast";
import {
  TOAST_TYPE_SUCCESS,
  TOAST_TYPE_ERROR,
} from "../../../services/constants";
import FileDelete from "../FileDelete/FileDelete";
import {
  saveCustomFieldsData,
  removeFileUploadData,
} from "../../../actions/fileUpload";
import { ProgressBarComponent } from "@syncfusion/ej2-react-progressbar";
import { BUTTON_TYPES } from "@CubeComponents/constants/globalVariable";
import _ from "lodash";
import { getNomenclatureField } from "../../../services/utils/useCustomFields";
import useCustomFields from "../../../services/utils/useCustomFields";
import SlickGrid from "../../../cubeComponent/html/SlickGrid";
import { useResizeDetector } from "react-resize-detector";
import { createRoot } from "react-dom/client";
import {
  MdDeleteOutline,
  MdOutlineEdit,
  MdCheckCircleOutline,
} from "react-icons/md";
import { FaRegSave } from "react-icons/fa";
import { getFilesGridColumns } from "./FilesGridHelper";
import { BsExclamationCircle } from "react-icons/bs";
import Upload from "../../../assets/icons/svg/upload.svg";
import { addValidationToInputFields } from "../../CreateInspectionAndProgress/formsHelpers";
import { useNavigate } from "react-router-dom";
import useForms from "../../CreateInspectionAndProgress/useForms";
import FormQuestionFields from "../../CreateInspectionAndProgress/questionFields";

const {
  isFileNomenclatureCorrect,
} = require("../../../services/utils/helpers");

const FilesGrid = forwardRef(
  (
    {
      fileUploadList,
      template,
      formTemplateId,
      workspaceId,
      setEnableDone,
      fileNomenclature,
      batchId,
      filesDataRef = { current: null },
      formObject,
      isFolderUpload,
    },
    ref,
  ) => {
    const newRecordValues = useRef({});
    const uploadType = {
      UPLOAD_AS_NEW_FILE: "uploadAsNewFile",
      UPLOAD_AS_NEW_VERSION: "uploadAsNewVersion",
    };
    const dispatch = useDispatch();

    const { width, ref: resizeRef } = useResizeDetector();

    const [isBulk, setIsBulk] = useState(false);
    const [filesData, setFilesData] = useState([]);
    const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] =
      useState(false);
    const { isFileVersionUploading } = useSelector((state) => state);
    const [grid, setGrid] = useState(false);
    const [disableDeleteButton, setDisableDeleteButton] = useState(true);
    const [disableBulkUploadButton, setDisableBulkUploadButton] =
      useState(true);

    const reactGridRef = useRef(null);
    const { validateFormWithId } = useCustomFields();

    const navigate = useNavigate();

    const {
      handleInputChange,
      handleURLModalState,
      isFileUploading,
      handleFileUploadChange,
      removeUserFromList,
      saveEditedImage,
      handleRemoveUploadedFile,
      openAddUserModal,
    } = useForms({ navigate });

    useEffect(() => {
      if (grid && filesData) {
        const columnIndex = reactGridRef.current.slickGrid.columns.findIndex(
          (col) => col.id == "uploadAction",
        );
        const progressBarIndex =
          reactGridRef.current.slickGrid.columns.findIndex(
            (col) => col.id == "progressBar",
          );
        const progressIndex = reactGridRef.current.slickGrid.columns.findIndex(
          (col) => col.id == "progress",
        );

        filesData.forEach((file, index) => {
          reactGridRef.current.gridService._grid.updateCell(
            index,
            progressBarIndex,
          ); // Progress Bar is column 2
          reactGridRef.current.gridService._grid.updateCell(
            index,
            progressIndex,
          ); // Progress Value is column 3
          // reactGridRef.current.gridService._grid.updateCell(index, columnIndex); //
        });
      }
    }, [filesData, grid]);

    const options = {
      rowHeight: 80,
      multiColumnSort: true,
      autoFitColumnsOnFirstLoad: false,
      enableAutoSizeColumns: false,
      autosizeColumnsByCellContentOnFirstLoad: true,
      enableAutoResizeColumnsByCellContent: true,
      enableCellNavigation: true,
      enableFiltering: false,
      enableRowSelection: true,
      rowSelectionOptions: {
        // True (Single Selection), False (Multiple Selections)
        selectActiveRow: false,
      },
      enableCheckboxSelector: true,
      // checkboxSelector: {
      //   hideInFilterHeaderRow: false,
      //   hideInColumnTitleRow: true,
      //   applySelectOnAllPages: true,
      // },
      showTopHeaderPanel: false,
      showCustomFooter: true,
      enableHeaderMenu: false,
      enableSorting: true,
      enableColumnReorder: false,
      autoHeight: true,
    };

    const gridNameTemplate = (_row, _cell, _value, _col, args) => {
      let error = "";
      if (args.id && filesDataRef.current[args.id]) {
        error = filesDataRef.current[args.id].error;
      }
      const container = document.createElement("div");
      container.style.width = "100%";
      const root = createRoot(container);
      const component = args.id ? (
        <>
          {args.name ? (
            <div className="flex flex-col gap-1">
              <div className="flex gap-1 items-center justify-between">
                <div className="flex items-center text-ellipsis overflow-hidden whitespace-nowrap">
                  <img src={getFileImage(args)} className="w-6 h-6" />
                  <span className="">
                    &nbsp;
                    {args.name}
                  </span>
                </div>
                {filesDataRef.current[args.id] && !isFileVersionUploading ? (
                  !filesDataRef.current[args.id].isFileNomenclatureCorrect ? (
                    <ToolTipComponent
                      showTipPointer
                      position="BottomCenter"
                      content={`File name${isFolderUpload ? " inside this folder" : ""} does not comply with the nomenclature set for this folder. Please rename the file${
                        batchId ? "" : " or visit Escrow files section"
                      }.`}
                    >
                      <span>
                        <BsExclamationCircle
                          className="w-3 h-3 m-2"
                          color="red"
                        />
                      </span>
                    </ToolTipComponent>
                  ) : args.isDuplicate ? (
                    <ToolTipComponent
                      showTipPointer
                      position="BottomCenter"
                      content={
                        args.isFolder
                          ? "Folder with same name already exists in this space. Please rename the folder and try again."
                          : "File with same name already exists in this folder. Please upload it as a new file or a new version."
                      }
                    >
                      <span>
                        <BsExclamationCircle
                          className="w-3 h-3 m-2"
                          color="red"
                        />
                      </span>
                    </ToolTipComponent>
                  ) : null
                ) : null}
              </div>
              <div
                id={`error_text_${args.id}`}
                className="text-xs text-red-500 error_text text-wrap"
              >
                {error}
              </div>
            </div>
          ) : null}
        </>
      ) : null;
      root.render(component);
      return container;
    };

    const progressTemplate = (_row, _cell, _value, _col, args) => {
      const container = document.createElement("div");
      container.style.width = "100%";
      const root = createRoot(container);
      let progress = 0;
      if (args.id && filesDataRef.current[args.id]) {
        progress = filesDataRef.current[args.id].progress;
      }
      const component = args.id ? (
        <>
          <div className="flex items-center">
            <ProgressBarComponent
              id={`progress_bar_${args.id}`}
              value={progress}
              className="w-full mx-1 progress_bar"
            />
          </div>
        </>
      ) : null;
      root.render(component);
      return container;
    };

    const progressPercentageTemplate = (_row, _cell, _value, _col, args) => {
      const container = document.createElement("div");
      container.style.width = "100%";
      const root = createRoot(container);
      const progress =
        args.id && filesDataRef.current[args.id]
          ? filesDataRef.current[args.id].progress
          : 0;
      const component = args.id ? (
        <>
          <div className="flex items-center justify-center">
            <div className="progress_value">
              {progress ? `${progress}%` : ""}
            </div>
          </div>
        </>
      ) : null;
      root.render(component);
      return container;
    };

    const columnHeaderTeplate = (
      args,
      headerText,
    ) => `<div style="display: flex; align-items: center; gap: 4px;">
          <p>${headerText}</p>
          ${
            args.isMandatory
              ? `<span style="
            font-size: 24px;
            font-weight: 300;
            color: rgb(239 68 68);
          ">*</span>`
              : ""
          }
        </div>`;

    // return `${component}`;

    function gridNomenclatureTemplate(
      _row,
      _cell,
      _value,
      _col,
      args,
      nomenclatureData,
      handleNomenclatureChange,
    ) {
      const container = document.createElement("div");
      container.style.width = "100%";
      const root = createRoot(container);
      const component = !args.isFolder && (
        <div className="flex gap-2" key={`nomenclature_${args.id}`}>
          {!args.isFileNomenclatureCorrect &&
            args.id != 0 &&
            nomenclatureData &&
            nomenclatureData.fields.map((field, index) => {
              const value =
                (filesDataRef.current[args.id]?.nomenclature &&
                  filesDataRef.current[args.id].nomenclature[index]) ||
                "";
              return getNomenclatureField(
                args,
                field,
                index,
                value,
                handleNomenclatureChange,
              );
            })}
        </div>
      );
      root.render(component);
      return container;
    }

    const gridCustomFieldTemplate = (_row, _cell, _value, _col, args) => {
      const field = _col["data-field"];
      const placeholderText = `Enter ${field.questionValue}`;
      const data = filesDataRef.current[args.id] || {};
      const value = args.id != 0 ? data[field._id] : null;
      const container = document.createElement("div");
      container.style.width = "100%";
      const root = createRoot(container);
      const component = (
        <>
          <FormQuestionFields
            extraParams={args}
            eachField={{ ...field, answer: { value }, placeholderText }}
            formObject={formObject}
            width="100%"
            handleCustomFieldData={handleCustomFieldData}
            multiTextRows={1}
            handleInputChange={handleInputChange}
            handleURLModalState={handleURLModalState}
            isFileUploading={isFileUploading}
            handleFileUploadChange={handleFileUploadChange}
            removeUserFromList={removeUserFromList}
            saveEditedImage={saveEditedImage}
            handleRemoveUploadedFile={handleRemoveUploadedFile}
            openAddUserModal={openAddUserModal}
          />
        </>
      );
      root.render(component);
      return container;
    };

    const gridUploadActionTemplate = (_row, _cell, _value, _col, args) => {
      const file = args;
      const container = document.createElement("div");
      container.style.width = "100%";
      const root = createRoot(container);
      let component = <></>;
      if (!file) {
        component = <></>;
        root.render(component);
        return container;
      }
      if (file.duplicateFileStatus?.found) {
        file.isDuplicate = true;
      }

      const uploadButton = args.id ? (
        <div className="flex w-full justify-start">
          <ButtonComponent
            buttonType={BUTTON_TYPES.BACKGROUND_BUTTON}
            onClick={() => handleUpload(args)}
          >
            <span className="flex items-center">Upload</span>
          </ButtonComponent>
        </div>
      ) : null;
      const uploadAsNewFileOrVersion = args.id ? (
        <div className="flex w-full justify-start gap-2">
          <ButtonComponent
            buttonType={BUTTON_TYPES.BACKGROUND_BUTTON}
            onClick={() => handleUpload(args, uploadType.UPLOAD_AS_NEW_FILE)}
            className="flex-1 max-w-200"
            disabled={
              filesDataRef.current[args.id][uploadType.UPLOAD_AS_NEW_VERSION] ||
              filesDataRef.current[args.id][uploadType.UPLOAD_AS_NEW_FILE]
            }
          >
            <div className="flex gap-1 items-center">
              {filesDataRef.current[args.id][uploadType.UPLOAD_AS_NEW_FILE] && (
                <MdCheckCircleOutline className="w-4 h-4" color="green" />
              )}
              <span className="flex justify-center">Upload as New File</span>
            </div>
          </ButtonComponent>
          <ButtonComponent
            buttonType={BUTTON_TYPES.BACKGROUND_BUTTON}
            onClick={() => handleUpload(args, uploadType.UPLOAD_AS_NEW_VERSION)}
            className="flex-1 max-w-200"
            disabled={
              filesDataRef.current[args.id][uploadType.UPLOAD_AS_NEW_VERSION] ||
              filesDataRef.current[args.id][uploadType.UPLOAD_AS_NEW_FILE]
            }
          >
            <div className="flex gap-1 items-center">
              {filesDataRef.current[args.id][
                uploadType.UPLOAD_AS_NEW_VERSION
              ] && <MdCheckCircleOutline className="w-4 h-4" color="green" />}
              <span className="flex justify-center">Upload as New Version</span>
            </div>
          </ButtonComponent>
        </div>
      ) : null;

      if (file.isDuplicate) {
        if (batchId) {
          component = !file.isFileNomenclatureCorrect
            ? uploadButton
            : uploadAsNewFileOrVersion;
        } else {
          component = !file.isFileNomenclatureCorrect ? (
            <></>
          ) : (
            uploadAsNewFileOrVersion
          );
        }
      } else if (batchId) {
        component = uploadButton;
      }

      root.render(component);
      return container;
    };

    const uploadEscrowFile = async (id, data, showToast = true) => {
      filesDataRef.current[id].uploaded = true;
      const response = await axiosWrapper.post(
        `${process.env.REACT_APP_CUBE_BACKEND}/fileOperations/uploadEscrowFile`,
        data,
      );
      if (response.data.status) {
        if (batchId) {
          const files = [...reactGridRef.current.dataView.rows];
          setFilesData(files.filter((file) => file.id !== id));
          removeValidationRules(id);
        }
        showToast &&
          dispatch(
            showToastNotification({
              content: response.data.message,
              type: TOAST_TYPE_SUCCESS,
            }),
          );
      } else {
        showToast &&
          dispatch(
            showToastNotification({
              content: response.data.message,
              type: TOAST_TYPE_ERROR,
            }),
          );
      }
    };

    const handleUpload = async (args, type) => {
      let name = args.name;
      let extension = "";
      if (args.name.includes(".")) {
        const fileName = name.split(".");
        extension = fileName.pop();
      }

      if (filesDataRef.current[args.id].nomenclature) {
        name = `${filesDataRef.current[args.id].nomenclature.join(
          fileNomenclature.separator,
        )}${extension ? "." : ""}${extension}`;
      }

      const data = {
        id: filesDataRef.current[args.id].fileId,
        name,
      };
      if (validateFormWithId(args.id, template, formObject)) {
        if (type) {
          data[type] = true;
          filesDataRef.current[args.id][type] = true;
          const columnIndex = reactGridRef.current.slickGrid.columns.findIndex(
            (col) => col.id == "uploadAction",
          );
          reactGridRef.current.gridService._grid.updateCell(
            args.id - 1,
            columnIndex,
          );
        }
        if (filesDataRef.current[args.id].progressCompleted) {
          await uploadEscrowFile(args.id, data);
        }
      }
    };

    const saveNomenclature = (args, nomenclatureArr) => {
      let name = args.name;
      let extension;
      if (args.name.includes(".")) {
        const fileName = name.split(".");
        extension = fileName.pop();
      }
      name = `${filesDataRef.current[args.id].nomenclature.join(fileNomenclature.separator)}${
        extension ? "." : ""
      }${extension}`;
      if (
        nomenclatureArr.length &&
        filesDataRef.current[args.id].progressCompleted
      ) {
        if (isFileNomenclatureCorrect(name, fileNomenclature)) {
          !batchId &&
            updateNomenclature(
              filesDataRef.current[args.id].fileId,
              name,
              args,
            );
          filesDataRef.current[args.id].isFileNomenclatureCorrect = true;
        } else {
          filesDataRef.current[args.id].isFileNomenclatureCorrect = false;
        }
        // reactGridRef.current.gridService._grid.invalidate();
      }
    };

    const handleNomenclatureChange = (e, args, field, index) => {
      const value = e.value || e.target.value;
      let nomenclatureArr = [];
      if (filesDataRef.current[args.id].nomenclature) {
        nomenclatureArr = filesDataRef.current[args.id].nomenclature;
      }

      nomenclatureArr[index] = value;
      filesDataRef.current[args.id].nomenclature = nomenclatureArr;
      saveNomenclature(args, nomenclatureArr);
    };

    const [filesGridColumns] = useState(
      getFilesGridColumns({
        gridNameTemplate,
        batchId,
        isFileVersionUploading,
        isFolderUpload,
        gridNomenclatureTemplate,
        fileUploadList,
        template,
        gridCustomFieldTemplate,
        gridUploadActionTemplate,
        fileNomenclature,
        handleNomenclatureChange,
        progressTemplate,
        progressPercentageTemplate,
        columnHeaderTeplate,
      }),
    );
    const customFieldsColumns = [];

    const handleUploadComplete = (file) => {
      const id = file.id;
      const fileData = filesDataRef.current[id];
      if (fileData) {
        if (fileData.customFieldsUpdated && !fileData.customFieldsSaved) {
          const fields = [];
          template?.forEach((section) => {
            section.questionFields.forEach((field) => {
              if (fileData[field._id]) {
                fields.push({
                  _id: field._id,
                  value: fileData[field._id],
                });
              }
            });
          });
          saveFormTemplate(id, fields, true);
          filesDataRef.current[id].customFieldsSaved = true;
        }
        if (fileData[uploadType.UPLOAD_AS_NEW_FILE] && !fileData.uploaded) {
          handleUpload(file, uploadType.UPLOAD_AS_NEW_FILE);
        } else if (
          fileData[uploadType.UPLOAD_AS_NEW_VERSION] &&
          !fileData.uploaded
        ) {
          handleUpload(file, uploadType.UPLOAD_AS_NEW_VERSION);
        }
        if (fileData.nomenclature && !fileData.nomenclatureUpdated) {
          saveNomenclature(file, fileData.nomenclature);
        }
      }
    };

    useEffect(() => {
      let numFilesUploaded = 0;
      const data = fileUploadList.map((item, index) => {
        const id = index + 1;
        item.id = id;

        filesDataRef.current = {
          ...filesDataRef.current,
          [id]: { ...filesDataRef.current[id] },
        };

        if (
          item.fileId &&
          !(
            filesDataRef.current &&
            filesDataRef.current[id] &&
            filesDataRef.current[id].fileId
          )
        ) {
          filesDataRef.current[id].fileId = item.fileId;
        }
        if (item.formTemplate) {
          item.formTemplate.forEach((section) => {
            section.questionFields.forEach((field) => {
              let answerValue = _.get(field, "answer.value", null);
              if (
                filesDataRef.current[id] &&
                filesDataRef.current[id][field._id]
              ) {
                answerValue = filesDataRef.current[id][field._id];
              }
              filesDataRef.current[id][field._id] = answerValue;
            });
          });
        } else {
          customFieldsColumns?.forEach((column) => {
            let answerValue = "";
            if (filesDataRef.current[id]) {
              answerValue = filesDataRef.current[id][column.field];
            }
            filesDataRef.current[id][column.field] = answerValue;
          });
        }

        let progressVal = item.progress || 0;
        if (!progressVal && filesDataRef.current[id].progress) {
          progressVal = filesDataRef.current[id].progress;
        }
        if (item.progress == 100) {
          progressVal = item.progressCompleted ? 100 : 99;
        }
        filesDataRef.current[id].progress = progressVal;
        filesDataRef.current[id].controller = item.controller;
        filesDataRef.current[id].isFolder = item.isFolder;
        filesDataRef.current[id].folderId = item.folderId;
        filesDataRef.current[id].error = item.error;
        filesDataRef.current[id].name = item.name;
        if (
          !Object.keys(filesDataRef.current[id]).includes(
            "isFileNomenclatureCorrect",
          )
        ) {
          filesDataRef.current[id].isFileNomenclatureCorrect =
            item.isFileNomenclatureCorrect;
        }

        if (item.progress == 100 && item.progressCompleted && !item.isDeleted) {
          if (id != 0) {
            filesDataRef.current[id].progressCompleted = item.progressCompleted;
            handleUploadComplete(item);
          }
          numFilesUploaded = numFilesUploaded + 1;
        }
        const rowData = {
          id,
          _id: item.fileId,
          ...item,
        };
        return rowData;
      });
      const files = data.filter((file) => !file.isDeleted);
      if (isBulk) {
        setFilesData([filesData[0], ...files]);
      } else {
        setFilesData(files);
      }

      if (
        JSON.stringify(filesData) != JSON.stringify(files) &&
        !isFileVersionUploading
      ) {
        addValidationToInputFields({ template, formObject, rows: files });
      }

      if (setEnableDone) {
        setEnableDone(
          numFilesUploaded == data.filter((file) => !file.isDeleted).length,
        );
      }
    }, [fileUploadList]);

    useEffect(() => {
      newRecordValues.current = {};
      return () => {
        if (reactGridRef.current) {
          reactGridRef.current.slickGrid.destroy();
        }
      };
    }, []);

    const handleCustomFieldData = ({ args, eachField, answerValue }) => {
      const { _id } = eachField;
      if (args.id == 0) {
        newRecordValues.current[_id] = answerValue;
      } else if (filesDataRef.current[args.id]) {
        filesDataRef.current[args.id][_id] = answerValue;

        if (!filesDataRef.current[args.id].progressCompleted) {
          filesDataRef.current[args.id].customFieldsUpdated = true;
        }

        if (
          filesDataRef.current[args.id].fileId ||
          filesDataRef.current[args.id].folderId
        ) {
          saveCustomFields(
            args.id,
            eachField,
            answerValue,
            filesDataRef.current[args.id].progressCompleted,
          );
        }
      }
    };

    const saveFormTemplate = async (id, fieldsData = [], progressCompleted) => {
      const itemId = filesDataRef.current[id].isFolder
        ? filesDataRef.current[id].folderId
        : filesDataRef.current[id].fileId;
      let isUploadComplete = true;
      if (!progressCompleted) {
        isUploadComplete = filesDataRef.current[id].progressCompleted;
      }
      if (isUploadComplete && fieldsData.length) {
        saveCustomFieldsData({
          id: itemId,
          formTemplateId,
          fieldsData,
          checkResidual: isFolderUpload && fileNomenclature ? false : !batchId,
          isFolder: !!filesDataRef.current[id].isFolder,
        });
      }
    };

    const updateNomenclature = async (id, name, args) => {
      if (id) {
        filesDataRef.current[args.id].nomenclatureUpdated = true;
        await axiosWrapper.post(
          `${process.env.REACT_APP_CUBE_BACKEND}/fileOperations/updateFileNomenclature`,
          {
            id,
            name,
          },
        );
      }
    };

    const saveCustomFields = async (id, field, value, progressCompleted) => {
      const fieldsData = [
        {
          _id: field._id,
          value,
        },
      ];
      saveFormTemplate(
        id,
        fieldsData,
        filesDataRef.current[id].progressCompleted,
      );
    };

    const handleDeleteButtonClick = () => {
      if (reactGridRef.current.dataView.selectedRowIds.length) {
        setOpenDeleteConfirmationModal(true);
      }
    };

    const handleBulkUploadButtonClick = async () => {
      const selectedRows = reactGridRef.current.dataView.selectedRowIds;
      if (selectedRows.length) {
        console.log(selectedRows);
        let allValid = true;
        selectedRows.forEach((id) => {
          const isValid = validateFormWithId(id, template, formObject);
          if (!isValid || !filesDataRef.current[id].isFileNomenclatureCorrect) {
            allValid = false;
          }
        });
        if (allValid) {
          for (const id of selectedRows) {
            await handleUpload({
              id,
              name: filesDataRef.current[id].name,
            });
          }
        }
      }
    };

    const toggleBulkUpdateButton = () => {
      if (!isBulk) {
        newRecordValues.current = {};
        const newRecord = {
          id: 0,
          progress: 0,
          extension: null,
          name: "",
        };
        customFieldsColumns?.forEach((column) => {
          newRecord[column.field] = "";
        });
        const files = [newRecord, ...filesData];
        setFilesData(files);
      } else {
        const files = [...filesData];
        files.shift();
        for (let index = 0; index < files.length; index++) {
          const item = files[index];
          const fieldsData = [];
          if (item.id != 0) {
            filesDataRef.current[item.id].customFieldsUpdated = true;
            Object.keys(newRecordValues.current).forEach((key) => {
              const value = newRecordValues.current[key];

              filesDataRef.current[item.id][key] = value;
              files[index][key] = value;
              fieldsData.push({
                _id: key,
                value,
              });
            });
            saveFormTemplate(item.id, fieldsData);
          }
        }
        setFilesData(files);
      }
      setIsBulk(!isBulk);
    };

    const successDeleteToast = () => {
      dispatch(
        showToastNotification({
          content: "File(s) deleted successfully.",
          type: TOAST_TYPE_SUCCESS,
        }),
      );
    };

    const handleDeleteModalClose = () => {
      setOpenDeleteConfirmationModal(false);
    };

    const removeValidationRules = (id) => {
      const fields = [];
      template?.forEach((section) => {
        section.questionFields.forEach((field) => {
          fields.push(field._id);
        });
      });

      if (formObject.current && fields.length) {
        fields.forEach((item) =>
          formObject.current.removeRules(`${id}_${item}`),
        );
      }
    };

    function removeElementsAtIndexes(indexes) {
      const array = [...filesData];
      indexes.sort((a, b) => b - a);

      indexes.forEach((index) => {
        if (index >= 0 && index < array.length) {
          const fileIndex = array[index].id - 1;
          const fileId = array[index].id;
          removeValidationRules(array[index].id);
          if (!batchId) {
            dispatch(removeFileUploadData(fileIndex));
          }
          delete filesDataRef.current[fileId];
          array.splice(index, 1);
        }
      });
      setFilesData(array);
    }

    const handleDeleteSuccess = (indexes) => {
      reactGridRef.current.slickGrid.setSelectedRows([]);
      handleDeleteModalClose();
      removeElementsAtIndexes(indexes);
      successDeleteToast();
    };

    const onDeleteFiles = () => {
      if (!batchId) {
        filesData
          .filter(
            (data) =>
              data.id != 0 &&
              reactGridRef.current.dataView.selectedRowIds.includes(data.id),
          )
          .forEach((file) => {
            if (filesDataRef.current[file.id].controller) {
              filesDataRef.current[file.id].controller.forEach((item) =>
                item.abort(),
              );
            }
          });
      }
    };

    const handleRowSelection = () => {
      const selectedRows = reactGridRef.current.slickGrid.selectedRows;
      setDisableDeleteButton(selectedRows.length == 0);

      const selectedFiles = filesData.filter((file, index) =>
        selectedRows.includes(index),
      );
      const isDuplicateFile = selectedFiles.some((file) => file.isDuplicate);
      if (!isDuplicateFile) {
        setDisableBulkUploadButton(selectedRows.length == 0);
      } else {
        setDisableBulkUploadButton(true);
      }
    };

    const reactGridReady = (reactGrid) => {
      setGrid(reactGrid);
      reactGridRef.current.slickGrid.onSelectedRowsChanged.subscribe(() => {
        console.log(reactGridRef.current.slickGrid.getSelectedRows());
        handleRowSelection();
      });
      reactGridRef.current.slickGrid.onHeaderCellRendered.subscribe(
        (e, args) => {
          const { column } = args;
          if (typeof column.headerTemplate === "function") {
            const customHeaderContent = column.headerTemplate(
              column["data-field"],
              column.headerText,
            ); // Call the custom render function
            args.node.innerHTML = customHeaderContent; // Inject the content into the header
          }
        },
      );
    };

    const getFilesGrid = () => (
      <div className="overflow-auto h-full" ref={resizeRef}>
        <SlickGrid
          ref={reactGridRef}
          columnDefinitions={filesGridColumns}
          gridOptions={options}
          dataset={filesData === false ? [] : filesData}
          onReactGridCreated={reactGridReady}
          onClick={handleRowSelection}
        />
      </div>
    );

    return (
      <div className="h-full overflow-auto flex flex-col" id="formData">
        <div className="p-4 flex gap-4">
          {!isFileVersionUploading && (
            <ButtonComponent
              onClick={handleDeleteButtonClick}
              buttonType={BUTTON_TYPES.BACKGROUND_BUTTON}
              disabled={disableDeleteButton}
            >
              <span className="flex items-center gap-2">
                <MdDeleteOutline className="h-4" />
                Delete
              </span>
            </ButtonComponent>
          )}
          {!isFileVersionUploading && template && template.length ? (
            !isBulk ? (
              <ButtonComponent
                onClick={toggleBulkUpdateButton}
                buttonType={BUTTON_TYPES.BACKGROUND_BUTTON}
              >
                <span className="flex items-center gap-2">
                  <MdOutlineEdit className="h-4" />
                  Bulk Edit
                </span>
              </ButtonComponent>
            ) : (
              <ButtonComponent
                onClick={toggleBulkUpdateButton}
                buttonType={BUTTON_TYPES.BACKGROUND_BUTTON}
              >
                <span className="flex items-center gap-2">
                  <FaRegSave className="h-4" />
                  Update All
                </span>
              </ButtonComponent>
            )
          ) : null}
          {batchId ? (
            <ButtonComponent
              onClick={handleBulkUploadButtonClick}
              buttonType={BUTTON_TYPES.BACKGROUND_BUTTON}
              disabled={disableBulkUploadButton}
            >
              <span className="flex items-center gap-2">
                <img src={Upload} className="h-4" />
                Bulk Upload
              </span>
            </ButtonComponent>
          ) : null}
        </div>
        {getFilesGrid()}
        {openDeleteConfirmationModal && (
          <div style={{ marginLeft: "-40px" }}>
            <FileDelete
              workspaceId={workspaceId}
              selectedFiles={filesData
                .filter(
                  (data) =>
                    data.id != 0 &&
                    reactGridRef.current.dataView.selectedRowIds.includes(
                      data.id,
                    ),
                )
                .map((file) => {
                  if (fileUploadList[file.id - 1].isFolder) {
                    file.hierarchyId = fileUploadList[file.id - 1].folderId;
                  } else if (filesDataRef.current[file.id]) {
                    file.hierarchyId = filesDataRef.current[file.id].fileId;
                  }
                  return file;
                })}
              onCloseModal={handleDeleteModalClose}
              onDeleteSuccess={() =>
                handleDeleteSuccess(reactGridRef.current.slickGrid.selectedRows)
              }
              isFileUpload
              onDeleteFiles={onDeleteFiles}
            />
          </div>
        )}
      </div>
    );
  },
);

export default FilesGrid;
