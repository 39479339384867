import IconComponent from "../../../../../cubeComponent/icons";
import { fieldTypeToIconMap } from "./constant.helper";
import "./constants.css";

export const sectionFieldItems = [
  {
    text: "Single Line",
    id: "single-line",
    iconCss: "e-custom-fe-singleLineIcon",
  },
  {
    text: "Multi Line",
    id: "multi-line",
    iconCss: "e-custom-fe-multiLineIcon",
  },
  {
    text: "Single Select",
    id: "single-select",
    iconCss: "e-custom-fe-singleSelectIcon",
  },
  {
    text: "Multi Select",
    id: "multi-select",
    iconCss: "e-custom-fe-checkBoxIcon",
  },
  {
    text: "Number",
    id: "number",
    iconCss: "e-custom-fe-numberIcon",
  },
  {
    text: "Look Up",
    id: "look-up",
    iconCss: "e-custom-fe-lookUpIcon",
    items: [
      { text: "Location", id: "Location" },
      { text: "Teams", id: "Teams" },
    ],
    notInModules: [
      "PROJECTS",
      "CONTRACT",
      "FILES",
      "ISSUES",
      "MODEL",
      "TRANSMITTAL",
    ],
  },
  {
    text: "Date / Time",
    id: "date-time",
    iconCss: "e-custom-fe-dateTimeIcon",
  },
  {
    text: "User(s)",
    id: "users",
    iconCss: "e-custom-fe-userIcon",
    notInModules: [
      "PROJECTS",
      "CONTRACT",
      "FILES",
      "ISSUES",
      "MODEL",
      "TRANSMITTAL",
    ],
  },
  {
    text: "File Upload",
    id: "fileUpload",
    iconCss: "e-custom-fe-uploadIcon",
    notInModules: [
      "PROJECTS",
      "CONTRACT",
      "FILES",
      "ISSUES",
      "MODEL",
      "TRANSMITTAL",
    ],
  },
  {
    text: "Image",
    id: "image",
    iconCss: "e-custom-fe-imageIcon",
    notInModules: [
      "PROJECTS",
      "CONTRACT",
      "FILES",
      "ISSUES",
      "MODEL",
      "TRANSMITTAL",
    ],
  },
  {
    text: "Url",
    id: "url",
    iconCss: "e-custom-fe-LinkIcon",
    notInModules: ["FILES"],
  },
];

export const normalSectionPropBtns = [
  {
    text: "Add Section To Library",
    id: "add_to_section_library",
  },
  {
    text: "Edit Section Title",
    id: "edit_section_header",
  },
  {
    text: "Delete Section",
    id: "delete_section",
  },
];

export const presetSectionPropBtns = [
  {
    text: "Delete Section",
    id: "delete_section",
  },
];
export const tableSectionPropBtns = [
  {
    text: "Add Section To Library",
    id: "add_to_section_library",
  },
  {
    text: "Edit Section Title",
    id: "edit_section_header",
  },
  {
    text: "Configure",
    id: "configure",
  },
  {
    text: "Delete Section",
    id: "delete_section",
  },
];

export const sideBarMenuItems = [
  {
    text: "Close Side Bar",
    id: "closeSideBar",
  },
];

export const formSideBarDataTemplate = {
  "single-select": [
    { name: "Details" },
    { name: "Value" },
    { name: "Validation", values: [{ name: "Mandatory", id: "isMandatory" }] },
  ],
  "multi-select": [
    { name: "Details" },
    { name: "Value" },
    { name: "Validation", values: [{ name: "Mandatory", id: "isMandatory" }] },
  ],
  "single-line": [
    { name: "Details" },
    { name: "Value" },
    { name: "Validation", values: [{ name: "Mandatory", id: "isMandatory" }] },
  ],
  "multi-line": [
    { name: "Details" },
    { name: "Value" },
    { name: "Validation", values: [{ name: "Mandatory", id: "isMandatory" }] },
  ],
  "date-time": [
    { name: "Details" },
    { name: "Value" },
    { name: "Validation", values: [{ name: "Mandatory", id: "isMandatory" }] },
  ],
  fileUpload: [
    { name: "Details" },
    { name: "Value" },
    { name: "Validation", values: [{ name: "Mandatory", id: "isMandatory" }] },
  ],
  image: [
    { name: "Details" },
    { name: "Value" },
    { name: "Validation", values: [{ name: "Mandatory", id: "isMandatory" }] },
  ],
  url: [
    { name: "Details" },
    { name: "Validation", values: [{ name: "Mandatory", id: "isMandatory" }] },
  ],
  "look-up": [
    { name: "Details" },
    { name: "Validation", values: [{ name: "Mandatory", id: "isMandatory" }] },
  ],
  number: [
    { name: "Details" },
    { name: "Value" },
    { name: "Validation", values: [{ name: "Mandatory", id: "isMandatory" }] },
  ],
  users: [
    { name: "Details" },
    { name: "Value" },
    {
      name: "Validation",
      values: [
        { name: "Mandatory", id: "isMandatory" },
        { name: "Allow only current Logged in user", id: "onlyCurrentUser" },
      ],
    },
  ],
  actualStartDate: [
    { name: "Value" },
    { name: "Validation", values: [{ name: "Mandatory", id: "isMandatory" }] },
  ],
  actualEndDate: [
    { name: "Value" },
    { name: "Validation", values: [{ name: "Mandatory", id: "isMandatory" }] },
  ],
  progress: [
    { name: "Validation", values: [{ name: "Mandatory", id: "isMandatory" }] },
  ],
  remDuration: [
    { name: "Validation", values: [{ name: "Mandatory", id: "isMandatory" }] },
  ],
  tableSection: [
    {
      name: "Validation",
      values: [
        {
          name: "Allow adding new rows during form filling",
          id: "allowNewRowAddInForm",
        },
        { name: "Predefine row values", id: "allowPreDefineRowValues" },
        {
          name: "Allow editing of predefined row values during form filling",
          id: "allowEditPreDefineRowInForm",
        },
      ],
    },
  ],
};

export const templateEditCases = {
  EDIT_SECTION_HEADING: "editSectionHeading",
  DELETE_SECTION: "deleteSection",
  CHANGE_SECTION_SEQUENCE: "changeSectionSequence",
  CONFIGURE_SECTION: "configureSection",

  EDIT_QUESTION: "editQuestion",
  ADD_QUESTION: "addQuestion",
  DELETE_QUESTION: "deleteQuestion",
  CONFIGURE_QUESTION: "configureQuestion",
  CHANGE_QUESTION_SEQUENCE: "changeQuestionSequence",
};

export const lookUpType = ["Location", "Teams"];

export const fileTypesDataSource = [
  { name: "Pdf", value: "application/pdf" },
  { name: "Jpg", value: "image/jpg" },
  { name: "Png", value: "image/png" },
  { name: "Jpeg", value: "image/jpeg" },
];

export const imageTypesDataSource = [
  { name: "Jpg", value: "image/jpg" },
  { name: "Png", value: "image/png" },
  { name: "Jpeg", value: "image/jpeg" },
];

// export const formSideBarDataTemplate = [
//   {
//     heading: "Details",
//     fields:[{fieldName:"Field Label",fieldType:"textInputBox",placeholder:"label"},{fieldName:"Instructions",fieldType:"textInputBox",placeholder:"Instruction"},{fieldName:"placeHolder",fieldType:"textInputBox",placeholder:"placeholder"}]
//   },
//   {
//     heading: "Value" ,
//   },
//   {
//     heading: "Validation" ,
//   },
//   {
//     heading: "Visibility" ,
//   },
//   {
//     heading: "Privacy" ,
//   }
// ]

export const getIconFromFieldType = (fieldType) => {
  const iconName = fieldTypeToIconMap[fieldType];

  if (iconName) {
    return <IconComponent name={iconName} />;
  }
  return "";
};

export const fieldTypeToHeadingMap = {
  "single-line": "Single Line",
  "multi-line": "Multi Line",
  "single-select": "Single Select",
  "multi-select": "Multi Select",
  "date-time": "Date / Time",
  "look-up": "Look Up",
  "look-up-location": "Look Up - Location",
  "look-up-teams": "Look Up - Teams",
  number: "Number",
  users: "User(s)",
  fileUpload: "File Upload",
  image: "Image",
  url: "Url",
  actualStartDate: "Actual Start Date",
  actualEndDate: "Actual End Date",
  progress: "Progress %",
  remDuration: "Remaining Duration",
};
