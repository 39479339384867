import React, { FC, forwardRef, useRef } from "react";
import {
  DropdownButtonComponentProps,
} from "../../interface";
import {
  DropDownButtonComponent as SyncfusionDropdownButtonComponent,
  DropDownButtonModel,
  ItemModel,
} from "@syncfusion/ej2-react-splitbuttons";

import {
  COLORS,
  defaultDropdownButtonProps,
} from "../../constants/globalVariable";
import { createUseStyles } from "react-jss";
import {
  getDropDownBackgroundButtonStyle,
  getDropdownBtnCssClass,
  getDropDownCustomButtonStyle,
  getDropDownGradientButtonStyle,
} from "../../constants/globalFunctions";
import { MenuEventArgs } from "@syncfusion/ej2-react-navigations";

const DropdownButtonComponent: FC<DropdownButtonComponentProps & DropDownButtonModel> = forwardRef((props: DropdownButtonComponentProps & DropDownButtonModel, ref) => {
  const dropdownBtnRef = ref || useRef()

  const useStyles = createUseStyles({
    dropdownGradientButton: getDropDownGradientButtonStyle(),
    dropdownBackgroundButton: getDropDownBackgroundButtonStyle(props),
    dropdownBtnCustom: getDropDownCustomButtonStyle(props),
  });

  const DropdownButtonComponentStyle = {
    ...(!props.isDisabled && {
      padding: !props.allowButtonPadding ? "0px" : "",
      color: `${props.color ? props.color : COLORS.BLACK}`,
      alignItems: "center",
      borderRadius: "var(--size-5)",
      height: props.height,
      background:
        props.gradientColor1 && props.gradientColor2
          ? `linear-gradient(100.44deg, ${props.gradientColor1} 0%, ${props.gradientColor2} 110.4%)`
          : props.bgColor
            ? props.bgColor
            : `var(--surface-white)`,
    }),
    ...(props.isDisabled && {
      borderColor: "var(--border-gray)",
      color: "var(--border-gray)",
    }),
  };

  const classes = useStyles() as any;
  const addDisabled: any = (args: MenuEventArgs) => {
    if (props.isDisabled) args.element.classList.add("e-disabled");
  };
  /******************* 
@Purpose : Split Button  Component
@Parameter : SplitComponentProps
@Author : Techture
 *******************/

  return (
    <>
      {props.label && (
        <div style={{ marginBottom: "8px" }}>
          <span>{props.label}</span>
        </div>
      )}
      <SyncfusionDropdownButtonComponent
        {...props}
        ref={dropdownBtnRef}
        style={!props.buttonType ? DropdownButtonComponentStyle : {}}
        beforeItemRender={addDisabled}
        cssClass={props.buttonType?`${classes[`${props.buttonType}`]} ${props.cssClass}`:props.cssClass}
      />
    </>
  );
});
// DropdownButtonComponent.defaultProps = defaultDropdownButtonProps;
export default DropdownButtonComponent;