import { setLayout } from "../reducers/layout";
import axiosWrapper from "../services/axiosWrapper";

export function fetchLayout() {
  return async (dispatch) => {
    const response = await axiosWrapper.get(
      `${process.env.REACT_APP_CUBE_BACKEND}/portal/getLayoutData`,
    );
    if (response.data.status) {
      dispatch(setLayout(response.data.data));
    }
  };
}
