import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function PagerComponent({
  pageSize,
  currentPage,
  totalItemCount,
  setCurrentPage,
  setPageSize,
  setTotalPages,
  totalPages,
}) {
  const handlePageChange = (event, newPage = 1) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [totalPages]);

  useEffect(() => {
    setTotalPages(Math.max(1, Math.ceil(totalItemCount / pageSize)));
  }, [pageSize, totalItemCount]);

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (value) => {
    setAnchorEl(null);
    handlePageSizeChange(value);
  };

  return (
    <div className="flex flex-row w-full items-center mt-3 mb-[20px] text-sm ">
      <Pagination
        count={totalPages}
        showFirstButton
        page={currentPage}
        showLastButton
        onChange={(event, pageNumber) => handlePageChange(event, pageNumber)}
      />
      <Button
        sx={{
          border: "1px solid #767676",
          justifyContent: "space-between",
          color: "black",
          borderRadius: "0",
        }}
        className="ml-2"
        aria-controls={open ? "page-size-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <div className="flex items-center justify-between w-full">
          <span>{pageSize}</span>
          <ExpandMoreIcon style={{ color: "gray" }} />
        </div>
      </Button>
      <Menu
        id="page-size-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        MenuListProps={{
          style: {
            paddingTop: "10px",
            paddingBottom: "10px",
          },
        }}
      >
        {["All", 5, 10, 15, 20].map((size) => (
          <MenuItem
            key={size}
            onClick={() =>
              handleMenuItemClick(size === "All" ? totalItemCount : size)
            }
            selected={size === (pageSize === 0 ? "All" : pageSize)}
          >
            {size}
          </MenuItem>
        ))}
      </Menu>
      <span className="ml-2">items per page</span>
      <div className="flex-1" />
      <div className="contents">
        <div className="flex items-center ml-6">
          <>
            <span className="mr-1">{currentPage}</span>
            <span className="mx-1">of {totalPages} Pages</span>
            <span className="mx-1">({totalItemCount})</span> items
          </>
        </div>
      </div>
    </div>
  );
}

export default PagerComponent;
