import { formCategoryObject } from '../../components/PortalSetings/Configuration/FormsAndFields/constants';
import { validImageTypes } from '../../cubeComponent/constants/globalVariable';
import _ from 'lodash';

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const getProjectWorkspacesListPageUrl = (projectId: string) => `/project/${projectId}/Workspaces?projectId=${projectId}`

export const getCustomModulePageUrl = (moduleId: string,selectedTab:string) => `/portalSettings/formsAndFields?selectedCategory=${moduleId}&selectedTab=${selectedTab}`

export const getProjectWorkspacePageUrl = (
  projectId: string,
  spaceId: string,
  spaceType: string = "this",
  workflowType?: string,
  path: string[] = [],
) => {
  let url = `/workspace/${spaceId}/explorer?spaceType=${spaceType}&projectId=${projectId}`;

  if (workflowType) {
    url += `&type=${workflowType}`;
  }

  if(path.length) {

    const folderPath = encodeURIComponent( `/${path.join('/')}`);
    url += `#folderPath=${folderPath}`;
    
  }

  return url;
};


export const getProjectIssuesPageUrl = (projectId: string) => `/project/${projectId}/issues?projectId=${projectId}`
export const getProjectFilesPageUrl = (projectId: string) => `/project/${projectId}/listFiles?projectId=${projectId}`
export const getProjectTransmittalsPageUrl = (projectId: string) => `/project/${projectId}/listTransmittals?projectId=${projectId}`
export const getSingleTransmittalPageUrl = (projectId: string, transmittalId: string) => `/project/${projectId}/listTransmittals/${transmittalId}?projectId=${projectId}`
export const getProjectTasksPageUrl = (projectId: string, taskId?: string) => {
  if(taskId){
    return `/project/${projectId}/listTask/${taskId}?projectId=${projectId}`
  }
  return `/project/${projectId}/listTask?projectId=${projectId}`
}
export const getProjectEstimatesPageUrl = (projectId: string) => `/project/${projectId}/EstimateList?projectId=${projectId}`
export const getEstimatePageUrl = (projectId: string, estimateId: string) => `/project/${projectId}/estimate/${estimateId}?projectId=${projectId}`

export const getProjectSchedulesPageUrl = (projectId: string) => `/project/${projectId}/scheduleList?projectId=${projectId}`
export const getProjectFormsPageUrl = (projectId: string,moduleName:string) => `/project/${projectId}/formsList/${moduleName.toLowerCase()}?selectedTab=list&projectId=${projectId}`
export const getProjectDashboardPageUrl = (projectId: string) => `/project/${projectId}/projectdashboard?projectId=${projectId}`
export const getProjectMailboxPageUrl = (projectId: string) => `/project/${projectId}/mails?projectId=${projectId}`
export const getProjectReviewsPageUrl = (projectId: string) => `/reviews?projectId=${projectId}`
export const getProjectMailThreadsPageUrl = (projectId: string, mailId: string) => `/project/${projectId}/mails/${mailId}/mailThread?projectId=${projectId}`
export const getEscrowFilesPageUrl = (batchId: string) => `/escrowFiles/${batchId}`
export const getProjectContractPageUrl = (projectId: string) => `/project/${projectId}/contractsList?projectId=${projectId}`
export const getReviewUrl = (projectId: string, spaceId: string, reviewId: string) => `/review?reviewId=${reviewId}&spaceId=${spaceId}&projectId=${projectId}`
export const getPortalFilesUrl = () => `/Files?byPortal=true`
export const getProjectGeneralClashPageUrl = (projectId: string) => `/project/${projectId}/clashTests?projectId=${projectId}`
export const getGroupDetailsUrl = (groupId?: string) => {
  if (groupId) {
    return `/portalSettings/groups/${groupId}`;
  } else {
    return `/portalSettings/groups`;
  }
};
export const getProjectTemplatesUrl = (projectTemplateId?: string) => {
  if (projectTemplateId) {
    return `/portalSettings/projectTemplates/${projectTemplateId}`;
  } else {
    return `/portalSettings/projectTemplates`;
  }
};
export const getClashDetailsPageUrl = (projectId: string, clashId: string) => `/project/${projectId}/clashDetails/${clashId}?projectId=${projectId}`
export const getCreateTransmittalPageUrl = (projectId: string, selectedSpaceId?: string, reviewId?: string) => {
  const url = `/project/${projectId}/createTransmittals?projectId=${projectId}`;
  const searchParams = new URLSearchParams();

  if (selectedSpaceId) {
    searchParams.set('selectedSpaceId', selectedSpaceId);
  }
  if (reviewId) {
    searchParams.set('reviewId', reviewId);
  }
  return searchParams.toString() ? `${url}&${searchParams.toString()}` : url;
};

export const getTemplateURL = ({selectedCategory, selectedTab}) => {
  const url = `/portalSettings/formsAndFields`;
  const searchParams = new URLSearchParams();

  if (selectedCategory) {
    searchParams.set('selectedCategory', selectedCategory.toLowerCase());
  }
  if (selectedTab) {
    searchParams.set('selectedTab', selectedTab);
  }
  return `${url}?${searchParams.toString()}`;
};
export const getCreateTaskPageUrl = (projectId: string, extraSearchParams?: string) => {
  if(extraSearchParams){
    return `/project/${projectId}/createTasks?projectId=${projectId}&${extraSearchParams}`
  }
  return `/project/${projectId}/createTasks?projectId=${projectId}`
}
export const getEditTaskPageUrl = (projectId: string, taskId: string, extraSearchParams?: string) => {
  if(extraSearchParams){
    return `/project/${projectId}/editTask/${taskId}?projectId=${projectId}&${extraSearchParams}`
  }
  return `/project/${projectId}/editTask/${taskId}?projectId=${projectId}`
}

export const getEstimateUrl = ({ estimateList, projectId, estimateId, versionId }) => {
  const searchParams = new URLSearchParams('')
  let location = `/project/${projectId}`
  if (estimateList) {
    location += '/EstimateList'
  }
  if (estimateId) {
    location +=`/estimate/${estimateId}`

    if (versionId) {
    location +=`/version/${versionId}`
    }
  }

  searchParams.set('projectId', projectId)

  return `${location}?${searchParams.toString()}`
}

export const getScheduleUrl = ({ projectId, scheduleId, selectedTab }) => {
  const searchParams = new URLSearchParams('');
  const location = `/project/${projectId}/schedule/${scheduleId}/scheduleDetails`;

  searchParams.set('selectedTab', selectedTab);
  searchParams.set('projectId', projectId);

  return `${location}?${searchParams.toString()}`;
};

export const getContractListUrl = (url, {
  projectId,selectedTab
}) => {
  const searchParams = new URLSearchParams('');
 
  if (projectId) {
    searchParams.set('projectId', projectId);
  }
  if (selectedTab) {
    searchParams.set('selectedTab', selectedTab);
  }

  return `${url}?${searchParams.toString()}`;
};

export const getProjectSettingsPageUrl = ({
  projectId,
  tab,
  extraSearchParams = {}
}: any) => {
  const params = new URLSearchParams({
    projectId, 
    tab,
    ...extraSearchParams
  });
  return `/project/${projectId}/setting?${params.toString()}`;
};

export const getContractDetailUrl=({selectedTab,projectId,contractId,versionId})=>{

  const url = `/project/${projectId}/contract/${contractId}`
  const searchParams = new URLSearchParams('');
  
  if (projectId) {
    searchParams.set('projectId', projectId);
  }
  if (selectedTab) {
    searchParams.set('selectedTab', selectedTab);
  }
  if(versionId){
    searchParams.set('versionId', versionId);
  }

  return `${url}?${searchParams.toString()}`;
}

export const getFormsListUrl=({selectedTab,projectId,resourceType})=>{

  const url = `/project/${projectId}/formsList/${resourceType?.toLowerCase()}`
  const searchParams = new URLSearchParams('');
  
  if (projectId) {
    searchParams.set('projectId', projectId);
  }
  if (selectedTab) {
    searchParams.set('selectedTab', selectedTab);
  }

  return `${url}?${searchParams.toString()}`;
}
export const getFormsTemplateUrl=({templateId,resourceType})=>{
  return `/portalSettings/formsAndFields/${templateId}/${resourceType?.toLowerCase()}`
}

const chr = (codePt) => {
  if (codePt > 0xffff) {
    codePt -= 0x10000;
    return String.fromCharCode(0xd800 + (codePt >> 10), 0xdc00 + (codePt & 0x3ff));
  }
  return String.fromCharCode(codePt);
};

export const charToNum = (alpha) => {
  alpha = String(alpha).toUpperCase();
  let index = 0;
  for (let i = 0; ; i++) {
    if (alpha == numToChar(i)) {
      index = i;
     break;
    }
  }
  return index;
};

export const numToChar = (number) => {
  const numeric = (number - 1) % 26;
  const letter = chr(65 + numeric);
  const number2 = parseInt((number - 1) / 26);
  if (number2 > 0) {
    return numToChar(number2) + letter;
  }
  return letter;
};

export const toRoman = (number) => {
  let num = Math.floor(number);
  let val;
  let s = '';
  let i = 0;
  const v = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
  const r = ['M', 'CM', 'D', 'CD', 'C', 'XC', 'L', 'XL', 'X', 'IX', 'V', 'IV', 'I'];

  function toBigRoman(n) {
    let ret = '';
    let n1 = '';
    let rem = n;
    while (rem > 1000) {
      let prefix = '';
      let suffix = '';
      let n = rem;
      const s = `${rem}`;
      let magnitude = 1;
      while (n > 1000) {
        n /= 1000;
        magnitude *= 1000;
        prefix += '(';
        suffix += ')';
      }
      n1 = Math.floor(n);
      rem = s - n1 * magnitude;
      ret += prefix + n1.toRoman() + suffix;
    }
    return ret + rem.toRoman();
  }

  if (number - num || num < 1) num = 0;
  if (num > 3999) return toBigRoman(num);

  while (num) {
    val = v[i];
    while (num >= val) {
      num -= val;
      s += r[i];
    }
    ++i;
  }
  return s;
};

export const fromRoman = (roman, accept) => {
  const s = roman.toUpperCase().replace(/ +/g, '');
  const L = s.length;
  let sum = 0;
  let i = 0;
  let next;
  let val;
  const R = {
    M: 1000, D: 500, C: 100, L: 50, X: 10, V: 5, I: 1,
  };

  function fromBigRoman(rn) {
    let n = 0;
    let x;
    let n1;
    let S;
    const rx = /(\(*)([MDCLXVI]+)/g;

    while ((S = rx.exec(rn)) != null) {
      x = S[1].length;
      n1 = Number.fromRoman(S[2]);
      if (isNaN(n1)) return NaN;
      if (x) n1 *= Math.pow(1000, x);
      n += n1;
    }
    return n;
  }

  if (/^[MDCLXVI)(]+$/.test(s)) {
    if (s.indexOf('(') == 0) return fromBigRoman(s);

    while (i < L) {
      val = R[s.charAt(i++)];
      next = R[s.charAt(i)] || 0;
      if (next - val > 0) val *= -1;
      sum += val;
    }
    if (accept || toRoman(sum) === s) return sum;
  }
  return NaN;
};

export const prepareEDN = (projectCustomId, indexAtProjectLevel) => `${projectCustomId}-${indexAtProjectLevel}`;

export const validateAvatar = (e, stateData) => {
  const file = e?.target?.files;
  const fileType = file[0].type;
  const data = stateData;
  if (validImageTypes.includes(fileType) && file && file.length > 0) {
    data.logoObj = file[0];
    data.error.logo = '';
  } else {
    data.error.logo = 'Please select jpg, jpeg, png file only.';
  }
  return data;
};

export const getTargetValue = (e) => e.value;

export const getSelectedIndex = (list, compareTo) => {
  let indexReturn = -1;
  list?.map((s, index) => {
    if (s.value === compareTo) {
      indexReturn = index;
    }
  });
  return indexReturn;
};

export function getLocationCoordinates() {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition((position) => {
      const locationCoordinates = [position.coords.latitude, position.coords.longitude];
      resolve(locationCoordinates);
    }, (error) => {
      resolve([0]);
    });
  });
}

export function loadRecaptchaScripts() {
  const script = document.createElement('script');
  script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}`;
  script.async = false;
  document.body.appendChild(script);
}

export const getTreeViewOfDistinctFileProperties = (data, eachColumnMetadata = {}, selectedNodes = [], appendToLeafNodes = {}, appendToNonLeafNodes = {}, overrideField = false) => {
  const columnData = [];

  data.forEach((value) => {
    let name = value
    if(overrideField){
      name = value.label
    }
    const rootNode = name.split('.')[0];
    const nameArray = name.split('.').slice(1).join('.').split('/');

    let rootNodeIndex = columnData.findIndex((data) => data.field == rootNode);

    if (rootNodeIndex === -1) {
      let label = 'Metadata';
      if (rootNode == 'customProperties') {
        label = 'Custom Metadata';
      }
      else if (rootNode == 'typeProperties') {
        label = 'Type Metadata';
      }
      const index = columnData.push({
        field: rootNode,
        label,
        columns: [],
        ...appendToNonLeafNodes,
        ...eachColumnMetadata,
      });
      rootNodeIndex = index - 1;
    }

    const parentNodeIndex = columnData[rootNodeIndex].columns.findIndex((data) => data.label == nameArray[0]);

    if (parentNodeIndex !== -1) {
      columnData[rootNodeIndex].columns[parentNodeIndex].columns.push({
        field: name,
        label: nameArray.slice(1).join('/'),
        isChecked: !!selectedNodes.includes(name),
        ...appendToLeafNodes,
        ...eachColumnMetadata,
      });
    }
    else {
      let fieldName = `${rootNode}.${nameArray[0]}`;

      if(overrideField && rootNode == 'customProperties'){
        fieldName = value.field
      }

      let data = {
        field: fieldName,
        label: nameArray[0],
        isChecked: !!selectedNodes.includes(fieldName),
        ...appendToLeafNodes,
        ...eachColumnMetadata,
      };
      if (nameArray.length > 1) {
        data = {
          ...data,
          ...appendToNonLeafNodes,
          columns: [{
            field: name,
            label: nameArray.slice(1).join('/'),
            isChecked: !!selectedNodes.includes(name),
            ...appendToLeafNodes,
            ...eachColumnMetadata,
          }],
        };
      }
      columnData[rootNodeIndex].columns.push(data);
    }
  });

  return columnData;
};

export const fileToBase64 = async (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (e) => reject(e);
});

export function removingElementFromRcDock(child, nonActiveIds) {
  if (child.children?.length > 0) {
    child.children = child.children.filter((child) => removingElementFromRcDock(child, nonActiveIds));
  }
  if (child.tabs) {
    if (child.tabs.length > 1) {
      child.tabs = child.tabs.filter((tab) => !nonActiveIds.includes(tab.id));
      if (nonActiveIds.includes(child.activeId)) {
        if (!child.tabs[0]?.id) {
          return false;
        }
        child.activeId = child.tabs[0].id;
      }
      return true;
    }
    if (nonActiveIds.includes(child.activeId)) {
      return false;
    }
    return true;
  }
  return true;
}

export const getFileListPageUrl = (searchParams: string) => {
  const url = new URLSearchParams();
  if (searchParams) {
    url.set('search', searchParams)
  }
  return `/Files?byPortal=true&${url.toString()}`;
}

export const getProjectPageUrl = (searchParams : string, typeProject:string, viewProject: string) => {
  const url = new URLSearchParams();
  if (searchParams) {
    url.set('search', searchParams)
  }
  url.set('type', typeProject)
  url.set('view',viewProject)
  return `/projects?${url.toString()}`;
};

export const getWorkflowPageUrl = (typeWorkflow: string) => {
  const url = new URLSearchParams(window.location.search);
  url.set('type', typeWorkflow);
  
  const currentPath = window.location.pathname;
  return `${currentPath}?${url.toString()}`; 
};

const RESOURCE_TYPE_PROJECT = 'RESOURCE_TYPE_PROJECT';
const RESOURCE_TYPE_PORTAL = 'RESOURCE_TYPE_PORTAL';
const RESOURCE_TYPE_TRANSMITTAL = 'RESOURCE_TYPE_TRANSMITTAL';
const RESOURCE_TYPE_SPACE = 'RESOURCE_TYPE_SPACE';

export function getFileImage(file: Object) {
  let fileExtension;
  if(_.get(file, 'name'))
  {
     fileExtension = _.get(file, 'name').split('.').pop().toLowerCase();
  }

  try {
    if (file.isFolder) {
      return require('../../assets/icons/svg/folder.svg').default;
    } else if (file.resourceType == 'RESOURCE_TYPE_SHORTCUT_FILE') {
      return require(`../../assets/icons/svg/filesShortcutIcons/Sfile${fileExtension.toUpperCase()}.svg`);
    }
    else if (file.resourceType == 'RESOURCE_TYPE_MERGED_FILE') {
      return require('../../assets/icons/svg/FileMER.svg').default;
    }
    else {
      return require(`../../assets/icons/svg/File${fileExtension.toUpperCase()}.svg`);
    }
  } catch (e) {
    return require('../../assets/icons/svg/files.svg').default;
  }
}

export function isValidJSON(str: string) {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
}

export const getCustomFields = (formTemplate = []) => {
  const customFields = {};
  formTemplate.forEach((section: any) => {
    section.questionFields.forEach((field: any) => {
      if (field.answer && field.answer.value) {
        customFields[field._id] = field.answer.value;
      }
    });
  });
  return customFields
}

export const isFileNomenclatureCorrect = (name, fileNomenclature: any = {}) => {
  let fileName = name || ''
  if (!name || !fileNomenclature?.fields) {
    return true
  }
  if (name.includes('.')) {
    fileName = name.split('.')
    fileName.pop()
  } else {
    fileName = [fileName]
  }
  fileName = fileName.join('.').split(fileNomenclature.separator)

  if (fileNomenclature.fields.length) {
    return fileNomenclature.fields.every((rule, index) => {
      let output = false

      if ((rule.type == 'text' || rule.type == 'number') && !rule.allowedCharacters) {
        return true
      }

      const textCondition = rule.type == 'text' && fileName[index]?.length == rule.allowedCharacters
      const numberCondition = rule.type == 'number' && fileName[index]?.length == rule.allowedCharacters
      const selectCondition = rule.type == 'single select' && rule.options?.includes(fileName[index])

      if (textCondition || numberCondition || selectCondition) {
        output = true
      }
      return output
    })
  }
  return true
}

export type fileUpgradeArgs = {
  resourceType : string,
  sourceFileVersions ? : sourceFileArgs[]
  conversionStatus?: string,
  cachingStatus?: string,
}

export type sourceFileArgs = {
  conversionStatus : string,
  cachingStatus : string
}

export const isFileVersionUpgradePossible = ({resourceType, sourceFileVersions, conversionStatus, cachingStatus} : fileUpgradeArgs) : boolean=>{
  let isUpgardePossibe = true;
    if (resourceType == "RESOURCE_TYPE_MERGED_FILE" && sourceFileVersions) {
      sourceFileVersions.forEach((file : {conversionStatus? : string, cachingStatus? : string}) => {
        if ((file.cachingStatus && !["COMPLETED", "NOT_REQUIRED"].includes(file.cachingStatus)) || (file.conversionStatus && !["COMPLETED", "NOT_REQUIRED"].includes(file.conversionStatus))) {
          isUpgardePossibe = false;
        }
      });
    } else if ((cachingStatus && !["COMPLETED", "NOT_REQUIRED"].includes(cachingStatus)) || (conversionStatus && !["COMPLETED", "NOT_REQUIRED"].includes(conversionStatus))){
      isUpgardePossibe = false;
    }

    return isUpgardePossibe
}
