import React from "react";
import ChangeColor from "../../HoopsViewer/ToolbarViewer/ChangeColor";

function Sheets({ color, onClick }) {
  return (
    <svg
      onClick={onClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <linearGradient id="Sheets" gradientTransform="rotate(45)">
        <stop offset="38%" stopColor={color} />
        <stop offset="92%" stopColor={ChangeColor(color)} />
      </linearGradient>
      <path
        d="M12.8004 9.39995H16.4004M10.8004 19L10.4004 15.8L10.8004 19ZM20.0004 19V6.19995H12.4004L12.8004 9.39995L13.2004 12.6L13.6004 15.8L10.8004 19H20.0004ZM13.2004 12.6H16.4004H13.2004Z"
        stroke='url("#Sheets")'
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.4 12.6H10M4 3H12L12.4 6.2L12.8 9.4L13.2 12.6L13.6 15.8H4V3ZM6.4 6.2H9.2H6.4ZM6.4 9.4H9.6H6.4Z"
        stroke='url("#Sheets")'
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Sheets;
