import { useRef, useState, useCallback, useEffect, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ColumnsDirective,
  ColumnDirective,
  Page,
  Toolbar,
  Inject,
  Filter,
  Resize,
  VirtualScroll,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { convertDate } from "../../../../../services/luxonHelper";
import GridComponent from "../../../../../cubeComponent/html/grid";
import {
  BUTTON_TYPES,
  CONSTANTS,
} from "../../../../../cubeComponent/constants/globalVariable";
import { getNameInitials } from "../../../../../cubeComponent/constants/globalFunctions";
import ModalStateDialogComponent from "../../../../../cubeComponent/html/modal/ModalWithState";
import IconComponent from "../../../../../cubeComponent/icons";
import ButtonComponent from "../../../../../cubeComponent/form/ButtonComponent";
import TextBoxComponent from "@CubeComponents/form/TextBoxComponent";
import { showToastNotification } from "../../../../../actions/toast";
import {
  TOAST_TYPE_ERROR,
  TOAST_TYPE_SUCCESS,
} from "../../../../../services/constants";
import { Query } from "@syncfusion/ej2-data";
import ToolTipComponent from "@CubeComponents/html/tooltip";
import {
  FormService,
  FormTypeService,
} from "../../../../../services/dataManager";
import { formController } from "../../../../../actions/Forms";
import DropDownListComponent from "@CubeComponents/form/DropdownListComponent";
import { useNavigate } from "react-router-dom";
import {
  formCategories,
  formCategoryObject,
  formTemplateSource,
} from "../constants";
import DropdownButtonComponent from "@CubeComponents/form/DropdownButtonComponent";
import { v4 as uuid } from "uuid";
import { getFormsTemplateUrl } from "../../../../../services/utils/helpers";
import ProjectTemplateConfig from "./templateForConfiguration";
import { projectTemplateConfigHandler } from "../../../../../actions/projectSetting";
import UserNameTagComponent from "../../../../../cubeComponent/html/UserNameTagComponent";

const InspectionForm = forwardRef((props, ref) => {
  const [showDeleteConfirmationModal, setDeleteConfirmationModal] =
    useState(false);
  const [editMode, setEditMode] = useState(false);
  const [openAddEditModal, setOpenAddEditModal] = useState(false);
  const [showArchivedTemplates, setShowArchivedTemplates] = useState(false);
  const [showTemplatesGrid, setShowTemplatesGrid] = useState(false);
  const [excludeTemplates, setExcludeTemplates] = useState([]);
  const navigate = useNavigate();

  const initialFormValues = {
    name: "",
    description: "",
    type: "",
    template: [],
    lookUpUsed: [],
    resourceType: props.formCategory,
  };

  const query = new Query()
    .addParams("resourceType", props.formCategory)
    .addParams("isArchived", showArchivedTemplates);
  if (props.projectId) {
    query.where("projectId", "equal", props.projectId);
  }
  if (props.excludeTemplates) {
    query.where("_id", "notequal", props.excludeTemplates);
  }
  const [InspectionFormDataToSave, setInspectionFormDataToSave] =
    useState(initialFormValues);

  const dataGrid = ref || useRef();
  const dispatch = useDispatch();

  const handleFormStatusChange = () => {
    setShowArchivedTemplates((prev) => !prev);
  };

  const dropDownTemplate = () => (
    <DropdownButtonComponent
      select={handleFormStatusChange}
      cssClass="e-caret-hide"
      bgColor="transparent"
      items={
        showArchivedTemplates
          ? [{ text: "Show Active Template", id: "showArchive" }]
          : [{ text: "Show Archived Template", id: "showActive" }]
      }
    >
      <IconComponent name="threedots" cssClass="cursor-pointer" />
    </DropdownButtonComponent>
  );

  const toolbarOptions = [
    ...(!showArchivedTemplates
      ? [
          {
            text: "Create",
            prefixIcon: "e-add",
            id: "custom_create",
          },
          {
            text: "Edit",
            prefixIcon: "e-edit",
            id: "custom_edit",
          },
          {
            text: "Delete",
            prefixIcon: "e-delete",
            id: "custom_delete",
          },
          {
            text: "Duplicate",
            prefixIcon: "e-copy",
            id: "custom_duplicate",
          },
          {
            text: "Archive",
            prefixIcon: "e-transform-left",
            id: "custom_archive",
          },
        ]
      : []),
    ...(showArchivedTemplates
      ? [
          {
            text: "Un-Archive",
            prefixIcon: "e-transform-right",
            id: "custom_un-archive",
          },
        ]
      : []),
    {
      template: dropDownTemplate,
      align: "Right",
    },
  ];

  const toolBarForProjectSettings = [
    {
      text: "Add",
      prefixIcon: "e-add",
      id: "custom_add",
    },
    {
      text: "Remove",
      prefixIcon: "e-delete",
      id: "custom_remove",
    },
  ];

  const validationRules = { required: true };
  const pageSettings = { pageSizes: true, pageSize: 10 };
  const FilterOption = {
    type: "Menu",
  };

  const handleDeleteInspectionForms = async () => {
    const selectedData = dataGrid.current.getSelectedRecords();
    const InspectionFormIds = selectedData.map((formData) => formData._id);

    const data = {
      action: "delete",
      InspectionFormIds,
    };
    const res = await formController(data);
    if (res.data.status) {
      dispatch(
        showToastNotification({
          content: "Selected template(s)  have been deleted successfully",
          type: TOAST_TYPE_SUCCESS,
        }),
      );
      dataGrid.current.refresh();
    } else {
      dispatch(
        showToastNotification({
          content: res.data.errorMessage,
          type: TOAST_TYPE_ERROR,
        }),
      );
    }
    handleDeletModalClose();
  };

  const handleDeletModalClose = () => {
    setDeleteConfirmationModal(false);
  };

  const handleAddEditModalCLose = () => {
    setOpenAddEditModal(false);
    setEditMode(false);
    setInspectionFormDataToSave(initialFormValues);
  };

  const toolbarClickHandler = async (params) => {
    const selectedData = dataGrid.current.getSelectedRecords()[0];

    if (params.item.properties.id == "custom_add") {
      setShowTemplatesGrid(true);
    }
    if (params.item.properties.id == "custom_remove") {
      const selectedRecords = dataGrid.current.getSelectedRecords();
      const templateIds = selectedRecords.map((obj) => obj._id);
      const res = await projectTemplateConfigHandler({
        action: "remove",
        templateIds,
        projectId: props.projectId,
      });
      if (res.data.status) {
        dispatch(
          showToastNotification({
            content: "Template removed successfully",
            type: TOAST_TYPE_SUCCESS,
          }),
        );
        dataGrid.current.refresh();
      }
    }
    if (params.item.properties.id === "custom_create") {
      setOpenAddEditModal(true);
    }
    if (params.item.properties.id === "custom_delete") {
      setDeleteConfirmationModal(true);
    }
    if (params.item.properties.id === "custom_edit") {
      setInspectionFormDataToSave({
        name: selectedData.name,
        description: selectedData.description,
        type: selectedData.type,
      });
      setOpenAddEditModal(true);
      setEditMode(true);
    }
    if (params.item.properties.id === "custom_duplicate") {
      const count = selectedData.noOfDuplicatesOfthisForm;

      const tempTemplate = selectedData.template.map((section) => {
        const tempQuestionFields = section.questionFields?.map((question) => ({
          ...question,
          _id: uuid(),
        }));
        return {
          ...section,
          _id: uuid(),
          questionFields: tempQuestionFields,
        };
      });
      const newData = {
        ...initialFormValues,
        name: `${selectedData.name}(${count + 1})`,
        description: `${selectedData.description}(${count + 1})`,
        type: selectedData.type,
        template: tempTemplate,
        lookUpUsed: selectedData.lookUpUsed,
      };

      const res = await formController({
        action: "insert",
        data: newData,
        parentFormId: selectedData._id,
      });
      if (res.data.status) {
        dispatch(
          showToastNotification({
            content: "Duplicate Row Created successfully",
            type: TOAST_TYPE_SUCCESS,
          }),
        );
      }
      dataGrid.current.refresh();
    }
    if (
      params.item.properties.id === "custom_archive" ||
      params.item.properties.id === "custom_un-archive"
    ) {
      const selectedData = dataGrid.current.getSelectedRecords();
      const formIds = selectedData.map((form) => form._id);
      const res = await formController({
        action: "archive",
        formIds,
        isArchived: params.item.properties.id === "custom_archive",
      });
      if (res.data.status) {
        dispatch(
          showToastNotification({
            content: `Form ${
              params.item.properties.id === "custom_archive"
                ? "Archived"
                : "Un-Archived"
            } successfully`,
            type: TOAST_TYPE_SUCCESS,
          }),
        );
        dataGrid.current.refresh();
      }
    }
  };

  const selectionEvent = (args) => {
    if (!dataGrid || !dataGrid.current) return;
    const totalRowsSelected = dataGrid.current.getSelectedRowIndexes().length;
    if (props.formSelectBtnStateEnable) {
      props.formSelectBtnStateEnable(totalRowsSelected == 1);
    }
    if (!props.showOnlyGrid) {
      if (totalRowsSelected == 0) {
        dataGrid.current.toolbarModule.enableItems(["custom_delete"], false);
        dataGrid.current.toolbarModule.enableItems(["custom_edit"], false);
        dataGrid.current.toolbarModule.enableItems(["custom_duplicate"], false);
        dataGrid.current.toolbarModule.enableItems(["custom_archive"], false);
        dataGrid.current.toolbarModule.enableItems(
          ["custom_un-archive"],
          false,
        );
        dataGrid.current.toolbarModule.enableItems(["custom_remove"], false);
      }
      if (totalRowsSelected == 1) {
        dataGrid.current.toolbarModule.enableItems(["custom_edit"], true);
        dataGrid.current.toolbarModule.enableItems(["custom_delete"], true);
        dataGrid.current.toolbarModule.enableItems(["custom_duplicate"], true);
        dataGrid.current.toolbarModule.enableItems(["custom_archive"], true);
        dataGrid.current.toolbarModule.enableItems(["custom_un-archive"], true);
        dataGrid.current.toolbarModule.enableItems(["custom_remove"], true);
      }
      if (totalRowsSelected > 1) {
        dataGrid.current.toolbarModule.enableItems(["custom_edit"], false);
        dataGrid.current.toolbarModule.enableItems(["custom_delete"], true);
        dataGrid.current.toolbarModule.enableItems(["custom_duplicate"], false);
        dataGrid.current.toolbarModule.enableItems(["custom_archive"], true);
        dataGrid.current.toolbarModule.enableItems(["custom_un-archive"], true);
        dataGrid.current.toolbarModule.enableItems(["custom_remove"], true);
      }
    }
  };

  const formatCreatedDate = (data) => {
    const dateFormat = convertDate(Number(data.createdDate), "dateForTooltip");
    return (
      <div className="w-fit">
        <ToolTipComponent
          showTipPointer
          position="BottomCenter"
          content={dateFormat}
        >
          <span>{convertDate(Number(data.createdDate), "dateForText")}</span>
        </ToolTipComponent>
      </div>
    );
  };

  const recordClick = (args) => {
    const clickedRowId = args.rowData._id;
    if (
      args.cellIndex != 0 &&
      !props.showOnlyGrid &&
      !showArchivedTemplates &&
      props.source != formTemplateSource.projectSettings
    ) {
      const url = getFormsTemplateUrl({
        templateId: clickedRowId,
        resourceType: props.formCategory,
      });
      navigate(url);
    }
  };

  useEffect(() => {
    if (!props.showOnlyGrid && dataGrid.current) {
      dataGrid.current.toolbarModule.enableItems(["custom_edit"], false);
      dataGrid.current.toolbarModule.enableItems(["custom_delete"], false);
      dataGrid.current.toolbarModule.enableItems(["custom_duplicate"], false);
      dataGrid.current.toolbarModule.enableItems(["custom_archive"], false);
      dataGrid.current.toolbarModule.enableItems(["custom_un-archive"], false);
      dataGrid.current.toolbarModule.enableItems(["custom_remove"], false);
    }
  }, [showArchivedTemplates, props.formCategory]);

  const createdByTemplate = (params) => {
    const { _id } = params.createdBy;
    return <UserNameTagComponent id={_id} />;
  };

  const handleChange = (args) => {
    if (args?.target) {
      const data = args.value;
      setInspectionFormDataToSave({
        ...InspectionFormDataToSave,
        [args.target.name]: data,
      });
    }
  };

  const handleTypeChange = (args) => {
    const { _id, name, description } = args.target.itemData;
    const data = {
      _id,
      name,
      description,
    };
    setInspectionFormDataToSave({
      ...InspectionFormDataToSave,
      [args.target.name]: data,
    });
  };

  const handleCreateInspectionFormClick = async () => {
    const data = {
      data: { ...InspectionFormDataToSave, resourceType: props.formCategory },
      action: editMode ? "update" : "insert",
    };
    if (editMode) {
      data.updateName = true;
      data._id = dataGrid.current.getSelectedRecords()[0]._id;
      delete data.data.resourceType;
    }
    const res = await formController(data);
    if (res.data.status) {
      let content;
      if (editMode) {
        content = "Changes saved";
      } else {
        content = `New Template"${InspectionFormDataToSave.name}" has been created successfully`;
      }
      dispatch(
        showToastNotification({
          content,
          type: TOAST_TYPE_SUCCESS,
        }),
      );
      setInspectionFormDataToSave(initialFormValues);
      handleAddEditModalCLose();
      dataGrid.current.refresh();
    }
  };

  const refreshGrid = () => {
    dataGrid.current.refresh();
  };

  const headerContent = () => (
    <div className="  w-full flex justify-between rounded-md">
      <div className="ml-8 mt-6 mb-4">
        {editMode ? "Edit Form Template" : "Create New Form Template"}
      </div>
      <div className="flex pr-8 cursor-pointer">
        <IconComponent name="close" onClick={handleAddEditModalCLose} />
      </div>
    </div>
  );
  const modelContent = () => (
    <div className="p-8 flex flex-col gap-5">
      <div className="flex flex-col gap-2">
        <span>Template Name</span>
        <span>
          <TextBoxComponent
            name="name"
            type="text"
            placeholder="Add Template Name"
            showClearButton
            onChange={handleChange}
            value={InspectionFormDataToSave.name}
          />
        </span>
      </div>
      <div className="flex flex-col gap-2">
        <span>Template Description</span>
        <span>
          <TextBoxComponent
            name="description"
            type="text"
            placeholder="Add Template Description"
            showClearButton
            onChange={handleChange}
            value={InspectionFormDataToSave.description}
          />
        </span>
      </div>
      <div className="flex flex-col gap-2">
        <span>Template Type</span>
        <span>
          <DropDownListComponent
            name="type"
            placeholder="Add Template Type"
            value={InspectionFormDataToSave.type._id}
            dataSource={FormTypeService}
            onChange={handleTypeChange}
            fields={{ text: "name", value: "_id" }}
            query={new Query()
              .requiresCount()
              .addParams("resourceType", props.formCategory)}
            popupHeight="200px"
            buttonType={BUTTON_TYPES.DROPDOWN_BACKGROUND_BUTTON}
          />
        </span>
      </div>
    </div>
  );
  const footerContent = () => (
    <div className="m-8">
      <ButtonComponent
        buttonType={BUTTON_TYPES.GRADIENT_BUTTON}
        disabled={
          InspectionFormDataToSave.name.trim() == "" ||
          InspectionFormDataToSave.description.trim() == "" ||
          InspectionFormDataToSave.type == ""
        }
        onClick={handleCreateInspectionFormClick}
      >
        {editMode ? "Save" : "Create"}
      </ButtonComponent>
    </div>
  );

  const dataBound = (args) => {
    if (
      dataGrid.current &&
      props.selectedForm &&
      typeof props.selectedForm?.rowIndex == "number"
    ) {
      dataGrid.current.selectRow(props.selectedForm?.rowIndex);
    }
    const excludeTemplateIds = dataGrid.current?.currentViewData?.map(
      (obj) => obj._id,
    );
    setExcludeTemplates(excludeTemplateIds);
  };

  const getGridComponent = useCallback(
    () => (
      <GridComponent
        dataBound={dataBound}
        ref={dataGrid}
        dataSource={FormService}
        toolbar={
          props.showOnlyGrid
            ? null
            : props.source == formTemplateSource.portalSettings
              ? toolbarOptions
              : toolBarForProjectSettings
        }
        filterSettings={FilterOption}
        allowFiltering
        allowSorting
        allowPaging
        rowHeight={props.showSmallRowHeight ? 50 : 68}
        height="100%"
        pageSettings={pageSettings}
        toolbarClick={toolbarClickHandler}
        rowSelected={selectionEvent}
        rowDeselected={selectionEvent}
        allowResizing
        recordClick={recordClick}
        query={query}
      >
        <ColumnsDirective>
          <ColumnDirective
            type="checkbox"
            allowSorting={false}
            allowFiltering={false}
            width="50"
            isPrimaryKey
          />
          <ColumnDirective
            field="name"
            headerText="Name"
            clipMode="Ellipsis"
            textAlign="Left"
            width="150"
          />
          <ColumnDirective
            field="description"
            headerText="Description"
            width="150"
            textAlign="Left"
            clipMode="Ellipsis"
          />
          <ColumnDirective
            field="type.name"
            headerText="Type"
            clipMode="Ellipsis"
            textAlign="Left"
            width="120"
          />
          <ColumnDirective
            field="createdDate"
            headerText="Created On"
            width="120"
            type="date"
            clipMode="Ellipsis"
            template={formatCreatedDate}
          />
          <ColumnDirective
            field="createdBy.fullName"
            headerText="Created by"
            width="150"
            clipMode="Ellipsis"
            template={createdByTemplate}
          />
        </ColumnsDirective>
        <Inject
          services={[Page, Toolbar, Filter, Resize, Sort, VirtualScroll]}
        />
      </GridComponent>
    ),
    [props.formCategory, showArchivedTemplates],
  );

  const addEditModal = () => (
    <ModalStateDialogComponent
      allowModalPadding={false}
      footerContent={footerContent()}
      width="30%"
      headerContent={headerContent()}
      onCancel={handleAddEditModalCLose}
      isVisible={openAddEditModal}
      modelContent={modelContent()}
    />
  );

  const deletePopup = () => (
    <div>
      <div id="dialog-target" style={{ marginTop: 50 }}>
        <ModalStateDialogComponent
          allowModalPadding={false}
          footerContent={
            <div className="m-8 flex gap-4">
              <ButtonComponent
                buttonType={BUTTON_TYPES.BORDER_BUTTON}
                onClick={handleDeletModalClose}
              >
                Cancel
              </ButtonComponent>
              <ButtonComponent
                onClick={handleDeleteInspectionForms}
                buttonType="gradientButton"
              >
                Confirm
              </ButtonComponent>
            </div>
          }
          headerContent={
            <div className=" w-full flex justify-between rounded-md">
              <div className="ml-8 mt-6 mb-3">
                Delete selected Form Templates(s)
              </div>
              <div className="flex pr-8 cursor-pointer">
                <IconComponent name="close" onClick={handleDeletModalClose} />
              </div>
            </div>
          }
          isVisible={showDeleteConfirmationModal}
          modelContent={
            <div className="m-8">
              {" "}
              Are you sure you want to delete the selected Form template(s) ?
            </div>
          }
          width="30%"
        />
      </div>
    </div>
  );

  return (
    <>
      <div className="flex w-full h-full overflow-auto">
        {openAddEditModal && addEditModal()}
        {showDeleteConfirmationModal && deletePopup()}
        {showTemplatesGrid && (
          <ProjectTemplateConfig
            excludeTemplates={excludeTemplates}
            refreshGrid={refreshGrid}
            setShowTemplatesGrid={setShowTemplatesGrid}
            showOnlyGrid
            source={props.source}
            formCategory={props.formCategory}
          />
        )}
        <div className="w-full h-full overflow-auto">{getGridComponent()}</div>
      </div>
    </>
  );
});

export default InspectionForm;
