import { convertDate } from "./luxonHelper";
import { showToastNotification } from "../actions/toast";
import { TOAST_TYPE_WARNING, TOAST_TYPE_ERROR } from "./constants";
import axiosWrapper from "./axiosWrapper";
import { encode } from "@syncfusion/ej2-react-inplace-editor";
import ModalStateDialogComponent from "../cubeComponent/html/modal/ModalWithState";
import { setBrowserPopupBlocked } from "../actions/browserPopupActions";
import { store } from "../App";

export const downloadFile = (data, callback) => {
  let objCount = 1;
  let fileVersionIds;
  let folderIds;
  let issuesIds;
  let formId;
  let projectId;
  let paperSize;
  if (data.fileVersionIds || data.folderIds) {
    objCount = data.fileVersionIds.length + data.folderIds.length;
    fileVersionIds = data.fileVersionIds.join(",");
    folderIds = data.folderIds.join(",");
  } else if (data.issuesIds) {
    objCount = data.issuesIds.length;
    issuesIds = data.issuesIds.join(",");
  } else if (data.formData) {
    formId = data.formData.formId;
    projectId = data.formData.projectId;
  }
  let newWindow = true;

  function redirectDownload(url) {
    if (data.mobileFileManager) {
      const nameCondition =
        data.fileVersionIds.length == 1 && data.folderIds.length == 0;
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          action: "fileDownload",
          data: {
            url,
            fileName: nameCondition
              ? data.fileName
              : `CUBE_Download_${new Date()
                  .toLocaleDateString("en-IN", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  })
                  .replace(/\//g, "-")}.zip`,
          },
        }),
      );
      return true;
    }
    return window.open(url, "_blank");
  }
  if (callback) {
    let { fileName } = data;
    if (data.transmittalNo) {
      fileName = `TS-${data.transmittalNo} - ${convertDate(
        data.updatedDate,
        "dd-MM-yy",
      )} - ${fileName}`;
    }
    fileName = encodeURIComponent(fileName);
    axiosWrapper
      .get(
        `${process.env.REACT_APP_CUBE_BACKEND}/fileSystemServer/${data.type}?fileVersionIds=${fileVersionIds}&fileName=${fileName}`,
        {
          responseType: "blob",
        },
      )
      .then((response) => {
        let fileName = data.fileName || data.name;
        if (data.transmittalNo) {
          fileName = `TS-${data.transmittalNo} - ${convertDate(
            data.updatedDate,
            "dd-MM-yy",
          )} - ${data.fileName}`;
        }
        callback(response.data, fileName);
      });
  } else {
    // TODO: pass authentication cookie
    let url;
    if (objCount > 80) {
      axiosWrapper
        .post(
          `${process.env.REACT_APP_CUBE_BACKEND}/fileSystemServer/createTinyUrl`,
          { fileVersionIds, folderIds, issuesIds },
        )
        .then((res) => {
          url = `${process.env.REACT_APP_CUBE_BACKEND}/fileSystemServer/${data.type}?tinyUrl=${res.data.tinyUrl}&origin=${location.hostname}`;
          newWindow = redirectDownload(url);
        });
    } else {
      let params = "";
      if (fileVersionIds || folderIds) {
        params = `fileVersionIds=${encodeURIComponent(
          fileVersionIds,
        )}&folderIds=${encodeURIComponent(folderIds)}`;
      } else if (issuesIds) {
        params = `issuesIds=${encodeURIComponent(issuesIds)}`;
      } else if (formId) {
        params = `formId=${encodeURIComponent(
          formId,
        )}&projectId=${encodeURIComponent(projectId)}`;
        if (data.formData.paperSize) {
          params += `&paperSize=${encodeURIComponent(data.formData.paperSize)}`;
        }
        if (data.formData.tableSectionFormat) {
          params += `&tableSectionFormat=${encodeURIComponent(data.formData.tableSectionFormat)}`;
        }
      } else if (data.estimateId) {
        params = `estimateId=${encodeURIComponent(data.estimateId)}`;
        if (data.versionId) {
          params += `&versionId=${encodeURIComponent(data.versionId)}`;
        }
      } else if (data.reviewId) {
        params = `reviewId=${encodeURIComponent(data.reviewId)}`;
      }
      url = `${process.env.REACT_APP_CUBE_BACKEND}/fileSystemServer/${data.type}?${params}&origin=${location.hostname}`;
      newWindow = redirectDownload(url);
    }
    newWindow
      ? store.dispatch(setBrowserPopupBlocked(false))
      : store.dispatch(setBrowserPopupBlocked(true));
  }
};
