export function setEntityList(state = [], action) {
  switch (action.type) {
    case "SET_ENTITY_LIST":
      return action.data;
    default:
      return state;
  }
}

export function statusPortalData(state = [], action) {
  switch (action.type) {
    case "SET_PORTAL_STATUS_LIST":
      return action.data;
    default:
      return state;
  }
}

export function portalData(state = {}, action) {
  switch (action.type) {
    case "SET_PORTAL_DATA":
      if (action.data) {
        return action.data;
      }
      return {};
    case "MERGE_PORTAL_DATA":
      if (action.data) {
        return {
          ...state,
          ...action.data,
        };
      }
      return {};
    default:
      return state;
  }
}

export function profileData(state = [], action) {
  switch (action.type) {
    case "SET_PORTAL_PROFILES_LIST":
      return action.data;
    default:
      return state;
  }
}

export function portalEntityList(state = [], action) {
  switch (action.type) {
    case "SET_PORTAL_ENTITY_LIST":
      return action.data;
    default:
      return state;
  }
}
export function getPortalSetting(state = [], action) {
  switch (action.type) {
    case "PORTAL_DASHBOARD_PERMISSION":
      return action.data;
    default:
      return state;
  }
}

export function customModules(state = [], action) {
  switch (action.type) {
    case "CUSTOM_MODULES":
      return action.data;
    default:
      return state;
  }
}

export function setCategoryList(state = [], action) {
  switch (action.type) {
    case "SET_CATEGORY_LIST":
      return action.data;
    default:
      return state;
  }
}

export function setPortalCategoryList(state = [], action) {
  switch (action.type) {
    case "SET_PORTAL_CATEGORY_LIST":
      return action.data;
    default:
      return state;
  }
}

export function UserInPortal(state = [], action) {
  switch (action.type) {
    case "PORTAL_USERS":
      return action.data;
    default:
      return state;
  }
}

export function UserCountInPortal(state = "", action) {
  switch (action.type) {
    case "PORTAL_USERS_COUNT":
      return action.data;
    default:
      return state;
  }
}

export function currentUserPermissions(state = [], action) {
  switch (action.type) {
    case "SET_PORTAL_PERMISSION__LIST":
      return action.data;
    default:
      return state;
  }
}

export function getDsrData(state = [], action) {
  switch (action.type) {
    case "GET_DSR_DATA":
      return action.data;
    default:
      return state;
  }
}
export function getSymbolsData(state = [], action) {
  switch (action.type) {
    case "GET_SYMBOL_DATA":
      return action.data;
    default:
      return state;
  }
}
