import { useEffect, useRef, useState } from "react";
import { MdArrowDropDown, MdDelete } from "react-icons/md";
import { ImCross } from "react-icons/im";
import { BiEdit } from "react-icons/bi";
import { TiTick } from "react-icons/ti";
import { IoMdRefresh } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import axiosWrapper from "../../services/axiosWrapper";
import { setModalStatus, setViewerState } from "../../actions/HoopsViewer";
import DesignViewerLayoutSetting from "../DesignViewerLayoutSetting";
import SplitButtonComponent from "../../cubeComponent/form/SplitButtonComponent";
import {
  AccordionComponent,
  AccordionItemDirective,
  AccordionItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import { content } from "@syncfusion/ej2-react-grids";
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import { BsThreeDots } from "react-icons/bs";
import ButtonComponent from "@CubeComponents/form/ButtonComponent";
import { BUTTON_TYPES } from "@CubeComponents/constants/globalVariable";
import CreateIcon from "@Components/StickySidebarViewer/assets/CreateIcon";
import horizontalThreeDots from "../../assets/icons/svg/HorizontalThreeDots.svg";
import "./viewCss.css";
import TextBoxComponent from "../../cubeComponent/form/TextBoxComponent";
import cross from "../../assets/icons/svg/cross.svg";
import tick2 from "../../assets/icons/svg/tick2.svg";
import dragIcon from "../../assets/icons/svg/Drag.svg";
import { showToastNotification } from "../../actions/toast";
import {
  TOAST_TYPE_SUCCESS,
  TOAST_TYPE_WARNING,
} from "../../services/constants";
import { getNameInitials } from "../../cubeComponent/constants/globalFunctions";
import useModulesMapper from "@Components/StickySidebarViewer/ModulesMapper";
import { removingElementFromRcDock } from "../../services/utils/helpers";
import { CONSTANTS } from "@CubeComponents/constants/globalVariable";

import Accordion from "../../cubeComponent/html/accordion/materialUiAccordian/index";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconComponent from "@CubeComponents/icons";
import UserNameTagComponent from "../../cubeComponent/html/UserNameTagComponent";

function index({
  layoutData,
  currentDataId,
  onClickLayout,
  onEditCallback,
  onRenameCallback,
  onDuplicateCallback,
  onDeleteCallback,
  setLayoutData,
  headerName = "Layouts",
  enableUpdate = false,
  name = "Layout",
  createNewLayoutData,
  newData = {},
  newFlow = false,
}) {
  const [renameData, setRenameData] = useState({});
  const { userDetails } = useSelector((state) => state);
  const dispatch = useDispatch();
  const splitButtonRef = useRef(null);

  const [dropDownItems, setdropDownItems] = useState([
    {
      text: "Rename",
      id: "rename",
      iconCss: "e-custom-fe-renameIcon",
    },
    {
      text: "Duplicate",
      id: "duplicate",
      iconCss: "e-custom-fe-duplicateIcon",
    },
    {
      text: "Delete",
      id: "delete",
      iconCss: "e-custom-fe-deleteIcon",
    },
  ]);

  function handleSelectedOption({ args, layoutData, layoutDataIndex }) {
    if (args.element.id == "edit") {
      onEditCallback({ layoutData, layoutDataIndex });
    }
    if (args.element.id == "rename") {
      setRenameData(layoutData);
    }
    if (args.element.id == "delete") {
      onClickOptionDelete({ layoutData, layoutDataIndex });
    }
    if (args.element.id == "duplicate") {
      onClickOptionDuplicate({ layoutData, layoutDataIndex });
    }
  }

  async function onClickOptionDelete({ layoutData, layoutDataIndex }) {
    const response = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/layoutView/deleteView`,
      {
        id: layoutData._id,
      },
    );
    if (response.data.status) {
      setLayoutData((prevState) => {
        const newState = [...prevState];
        newState.splice(layoutDataIndex, 1);
        return newState;
      });
      onDeleteCallback &&
        (await onDeleteCallback({ deleteData: layoutData, layoutDataIndex }));
    }
  }

  async function onClickOptionDuplicate({ layoutData, layoutDataIndex }) {
    const response = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/layoutView/createView`,
      {
        createData: {
          ...layoutData,
        },
      },
    );
    if (response.data.status) {
      setLayoutData((prevState) => [...prevState, response.data.data]);
      onDuplicateCallback &&
        onDuplicateCallback({
          duplicatedData: layoutData,
          layoutDataIndex,
        });
    }
  }

  const updateDropdownItem = {
    text: "Update",
    id: "edit",
    iconCss: "e-custom-fe-updateIcon",
  };

  useEffect(() => {
    if (enableUpdate) {
      setdropDownItems([updateDropdownItem, ...dropDownItems]);
    }
  }, []);

  const createData = (type) => {
    const sharedName = type == "Shared Layout" ? "Shared " : "";
    return {
      resourceType: "VIEWER",
      data: createNewLayoutData(),
      name: `${sharedName}${name} ${
        type == "Shared Layout"
          ? layoutData.filter((item) => item.isShared).length + 1
          : layoutData.filter((item) => !item.isShared).length + 1
      }`,
      ...newData,
    };
    // if (data && !Array.isArray(data) && typeof data === "object") {
    //   const created = await axiosWrapper.post(
    //     `${process.env.REACT_APP_CUBE_BACKEND}/layoutView/createView`,
    //     {
    //       createData: { ...data, isShared: false },
    //     }
    //   );
    //   if (created.data.status) {
    //     setLayoutData((prevState) => [
    //       ...prevState,
    //       { ...created.data.data, createdByData: userDetails },
    //     ]);
    //     dispatch(
    //       showToastNotification({
    //         content: `${created.data.data.name} has been created`,
    //         type: TOAST_TYPE_SUCCESS,
    //       })
    //     );
    //   }
    // }
  };

  function layoutContent(showShared) {
    const layout = [];
    if (showShared) {
      layoutData.map((item, index) => {
        if (item.isShared) {
          layout.push({
            ...item,
            index,
          });
        }
      });
    } else {
      {
        layoutData.map((item, index) => {
          if (!item.isShared) {
            layout.push({
              ...item,
              index,
            });
          }
        });
      }
    }
    return (
      <div className="max-h-36 overflow-auto cursor-pointer font-normal">
        {layout.length > 0 ? (
          layout?.map((item, index) => (
            <>
              {renameData._id != item._id ? (
                <div className="group">
                  <div
                    className="flex-row flex items-center h-9 hover:bg-primary_blue"
                    onClick={() => onClickLayout(item)}
                  >
                    <div className="ml-2 text-sm w-32 overflow-hidden whitespace-nowrap text-ellipsis flex-none">
                      {item.name}
                    </div>
                    <div className="flex-1 w-36 mr-2">
                      {item.isShared && (
                        <UserNameTagComponent id={item.createdByData._id} />
                      )}
                    </div>
                    {currentDataId == item._id && (
                      <div className="flex justify-center items-center h-4 w-11 rounded-md text-xxs bg-sky-200  text-black mr-2 flex-none">
                        Current
                      </div>
                    )}
                    {item.isDefault && (
                      <div className="flex justify-center items-center h-4 w-11 rounded-md text-xxs bg-gray-500 text-white mr-2 flex-none">
                        Default
                      </div>
                    )}
                    <div className="flex-none w-8 items-center">
                      <div className="group-hover:block">
                        {!item.isDefault &&
                          userDetails._id == item.createdBy && (
                            <DropDownButtonComponent
                              style={{
                                height: "10px",
                                // background: 'var( --primary-blue)',
                              }}
                              select={(args) =>
                                handleSelectedOption({
                                  args,
                                  layoutData: item,
                                  layoutDataIndex: item.index,
                                })
                              }
                              items={dropDownItems}
                              cssClass="e-caret-hide p-0 bg-white border-none h-10 preventLayoutClose group-hover:bg-primary_blue"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            >
                              <img
                                src={horizontalThreeDots}
                                style={{
                                  top: "-7px",
                                  display: "block",
                                  position: "relative",
                                }}
                              />
                            </DropDownButtonComponent>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-row">
                  <TextBoxComponent
                    type="text"
                    value={renameData.name}
                    onChange={(args) => {
                      setRenameData({ ...renameData, name: args.value });
                    }}
                  />
                  <img
                    src={cross}
                    className="cursor-pointer mx-2"
                    onClick={() => setRenameData({})}
                  />
                  <img
                    src={tick2}
                    className="cursor-pointer"
                    onClick={async () => {
                      await axiosWrapper.post(
                        `${process.env.REACT_APP_CUBE_BACKEND}/layoutView/updateView`,
                        {
                          updateData: {
                            name: renameData.name,
                          },
                          id: renameData._id,
                        },
                      );
                      setRenameData({});
                      setLayoutData((prevState) => {
                        const tempData = prevState;
                        tempData[item.index].name = renameData.name;
                        return tempData;
                      });
                      onRenameCallback &&
                        onRenameCallback({
                          renameData,
                          renameDataIndex: item.index,
                        });
                    }}
                  />
                </div>
              )}
            </>
          ))
        ) : (
          <div className="flex flex-col w- items-center justify-center">
            <IconComponent cssClass="w-32" name="nodatasvg" />
            <div className="text-12">No Data</div>
          </div>
        )}
      </div>
    );
  }

  async function onSelectCreate(args) {
    let data = null;
    const isShared = args.item.properties.text == "Shared Layout";

    data = createData(args.item.properties.text);
    if (data && !Array.isArray(data) && typeof data === "object") {
      const created = await axiosWrapper.post(
        `${process.env.REACT_APP_CUBE_BACKEND}/layoutView/createView`,
        {
          createData: { ...data, isShared },
        },
      );
      if (created.data.status) {
        setLayoutData((prevState) => [
          ...prevState,
          { ...created.data.data, createdByData: userDetails },
        ]);
        dispatch(
          showToastNotification({
            content: `${created.data.data.name} layout has been created`,
            type: TOAST_TYPE_SUCCESS,
          }),
        );
      }
    }
  }

  async function onClickCreate(e) {
    await onSelectCreate({ item: splitButtonRef.current.properties.items[0] });
  }

  return (
    <div>
      <div
        className="flex justify-between px-2 py-3 mb-4 items-center"
        data-testid="addNewLayout"
      >
        <div className="font-bold text-base" data-testid="LayoutsHeading">
          {headerName}
        </div>
        <SplitButtonComponent
          ref={splitButtonRef}
          buttonType="splitBackgroundButton"
          content="Create"
          data-testid="createBtn"
          cssClass="preventLayoutClose"
          items={[
            {
              text: "My Layout",
            },
            {
              text: "Shared Layout",
            },
          ]}
          onClick={onClickCreate}
          select={onSelectCreate}
        />
      </div>
      <Accordion defaultExpanded data-testid="myLayoutsSection">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          data-testid="myLayoutsHeader"
        >
          <div
            className="flex justify-between items-center px-2 py-3 text-sm font-semibold"
            data-testid="myLayouts"
          >
            {`My ${headerName}`}
          </div>
        </AccordionSummary>
        <AccordionDetails data-testid="myLayoutsContent">
          {layoutContent(false)}
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded data-testid="sharedLayoutsSection">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          data-testid="sharedLayoutsHeader"
        >
          <div
            className="flex justify-between items-center px-2 py-3 text-sm font-semibold"
            data-testid="sharedLayouts"
          >
            {`Shared ${headerName}`}
          </div>
        </AccordionSummary>
        <AccordionDetails data-testid="sharedLayoutsContent">
          {layoutContent(true)}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default index;
