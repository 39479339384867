export const formCategories = {
  inspection: "INSPECTION",
  progressUpdates: "PROGRESS_UPDATES",
  generalForms: "GENERAL_FORMS",
  changeOrder: "CHANGE_ORDER",
  permitToWork: "PERMIT_TO_WORK",
  payments: "PAYMENTS",
  mailBox: "MAILBOX",
  bills: "BILLS",
  issues: "ISSUES",
  projects: "PROJECTS",
  files: "FILES",
  contract: "CONTRACT",
  model: "MODEL",
  reviews: "REVIEWS",
  transmittal: "TRANSMITTAL",
};
export const formCategoriesDataSource = [
  { name: "Inspections", value: formCategories.inspection },
  { name: "Progress Updates", value: formCategories.progressUpdates },
  { name: "General Forms", value: formCategories.generalForms },
  { name: "Change Orders", value: formCategories.changeOrder },
  { name: "Payments", value: formCategories.payments },
  { name: "Permit To Work", value: formCategories.permitToWork },
  { name: "Mail Box", value: formCategories.mailBox },
  { name: "Bills", value: formCategories.bills },
  { name: "Issues", value: formCategories.issues },
  { name: "Projects", value: formCategories.projects },
  { name: "Files", value: formCategories.files },
  { name: "Contract", value: formCategories.contract },
  { name: "Model", value: formCategories.model },
  { name: "Reviews", value: formCategories.reviews },
  { name: "Transmittals", value: formCategories.transmittal },
];

export const formCategoryObject = {
  [formCategories.inspection]: {
    formHeader: "Inspection",
    modalHeader: "Create Inspection",
    tabTitle: "Inspections",
    activeCell: "Inspections",
    selectedTab: "inspection",
    allowedSections: [
      "blankSection",
      "tableSection",
      "presetSection",
      "quantities",
      "WBSItems",
      "linkForm",
      "references",
      "sectionFromLibrary",
      "tasks",
      "milestones",
    ],
  },

  [formCategories.progressUpdates]: {
    formHeader: "Progress Update",
    modalHeader: "Create Progress Updates",
    tabTitle: "Progress and Updates",
    activeCell: "Progress Updates",
    selectedTab: "progressUpdates",
    allowedSections: [
      "blankSection",
      "tableSection",
      "presetSection",
      "quantities",
      "WBSItems",
      "linkForm",
      "references",
      "sectionFromLibrary",
      "tasks",
      "milestones",
    ],
  },

  [formCategories.generalForms]: {
    formHeader: "General Form",
    modalHeader: "Create General Forms",
    tabTitle: "General Forms",
    activeCell: "General Forms",
    allowedSections: [
      "blankSection",
      "tableSection",
      "presetSection",
      "linkForm",
      "references",
      "sectionFromLibrary",
    ],
  },
  [formCategories.mailBox]: {
    formHeader: "Mail Box",
    modalHeader: "Mail Box",
    tabTitle: "Mail Box",
    activeCell: "Mailbox",
    allowedSections: ["blankSection", "tableSection", "sectionFromLibrary"],
  },
  [formCategories.bills]: {
    formHeader: "Bill",
    modalHeader: "Create Bill",
    selectedTab: "bills",
    tabTitle: "Bills",
    activeCell: "Bills",
    allowedSections: [
      "blankSection",
      "tableSection",
      "presetSection",
      "quantities",
      "WBSItems",
      "linkForm",
      "references",
      "sectionFromLibrary",
      "tasks",
    ],
  },
  [formCategories.changeOrder]: {
    formHeader: "Change Order",
    modalHeader: "Create Change Order",
    selectedTab: "changeOrders",
    tabTitle: "Change Order",
    activeCell: "Change Orders",
    allowedSections: [
      "blankSection",
      "tableSection",
      "presetSection",
      "linkForm",
      "references",
      "summaryOfChanges",
      "sectionFromLibrary",
    ],
  },
  [formCategories.payments]: {
    formHeader: "Payments",
    modalHeader: "Create Payment",
    selectedTab: "payments",
    tabTitle: "Payments",
    activeCell: "Payments",
    allowedSections: [
      "blankSection",
      "presetSection",
      "tableSection",
      "linkForm",
      "references",
      "sectionFromLibrary",
    ],
  },
  [formCategories.permitToWork]: {
    formHeader: "Permit To Work",
    modalHeader: "Create Permit To Work",
    selectedTab: "PermitToWork",
    tabTitle: "Permit To Work",
    activeCell: "Permit To Work",
    allowedSections: [
      "blankSection",
      "presetSection",
      "tableSection",
      "linkForm",
      "references",
      "sectionFromLibrary",
    ],
  },
  [formCategories.issues]: {
    formHeader: "Issues",
    modalHeader: "Issues",
    tabTitle: "Issues",
    activeCell: "Issues",
    allowedSections: ["blankSection", "tableSection", "sectionFromLibrary"],
  },
  [formCategories.projects]: {
    formHeader: "Projects",
    modalHeader: "Projects",
    tabTitle: "Projects",
    activeCell: "Projects",
    formType: "projects",
    allowedSections: ["blankSection", "sectionFromLibrary"],
    singleTemplate: true,
    defaultSection: {
      questionFields: [
        {
          attachmentsData: [],
          fieldHeading: "Single Line",
          fieldType: "single-line",
          questionValue: "ID",
        },
        {
          attachmentsData: [],
          fieldHeading: "Single Line",
          fieldType: "single-line",
          questionValue: "Name",
        },
      ],
      sectionHeading: "ProjectDetails",
    },
  },
  [formCategories.files]: {
    formHeader: "Files",
    modalHeader: "Files",
    tabTitle: "Files",
    activeCell: "Files",
    formType: "files",
    allowedSections: ["blankSection", "sectionFromLibrary"],
  },
  [formCategories.contract]: {
    formHeader: "Contract",
    modalHeader: "Create Contract Custom Fields",
    selectedTab: "",
    tabTitle: "",
    activeCell: "",
    allowedSections: ["blankSection", "sectionFromLibrary"],
  },
  [formCategories.model]: {
    formHeader: "Model",
    modalHeader: "Create Custom Metadata Fields",
    selectedTab: "",
    tabTitle: "",
    activeCell: "",
    allowedSections: ["blankSection", "sectionFromLibrary"],
  },
  [formCategories.reviews]: {
    formHeader: "review",
    modalHeader: "Create reviews",
    selectedTab: "",
    tabTitle: "",
    activeCell: "",
    allowedSections: ["blankSection", "sectionFromLibrary"],
  },
  [formCategories.transmittal]: {
    formHeader: "transmittals",
    titleLable: "Title",
    descriptionLable: "Description",
    modalHeader: "Create Transmittals Custom Fields",
    urlKey: "forms",
    selectedTab: "",
    tabTitle: "",
    activeCell: "",
    listUrlKey: "",
    allowedSections: ["blankSection", "sectionFromLibrary"],
  },
};

export const presetSectionsObject = {
  quantity: "Quantities",
  references: "References",
  linkForm: "Forms",
  tasks: "Tasks",
  milestones: "Milestones",
  WBSItems: "WBS Items",
  summaryOfChanges: "Summary of Changes",
};

export const presetSections = [
  presetSectionsObject.quantity,
  presetSectionsObject.references,
  presetSectionsObject.linkForm,
  presetSectionsObject.tasks,
  presetSectionsObject.milestones,
  presetSectionsObject.WBSItems,
  presetSectionsObject.summaryOfChanges,
];

export const presetSectionsCodeObject = {
  quantity: 1,
  references: 2,
  linkForm: 3,
  tasks: 4,
  milestones: 5,
  tableSection: 6,
  WBSItems: 7,
  summaryOfChanges: 8,
};

export const formTemplateSource = {
  projectSettings: "project_settings",
  portalSettings: "portal_settings",
};
export const presetSectionsHeadingObject = {
  Quantities: "quantity",
  References: "references",
  Forms: "linkForm",
  Tasks: "tasks",
  Milestones: "milestones",
  "WBS Items": "WBSItems",
};
export const moduleIconMapper = {
  [formCategories.inspection]: "inspectionForm",
  [formCategories.progressUpdates]: "ProgressUpdates",
  [formCategories.generalForms]: "generalForms",
  [formCategories.permitToWork]: "PermitToWork",
  [formCategories.bills]: "bills",
  [formCategories.payments]: "Payments",
  [formCategories.contract]: "Contracts",
  [formCategories.files]: "files",
  [formCategories.issues]: "issues",
  [formCategories.reviews]: "Review",
  [formCategories.mailBox]: "Mailbox",
  [formCategories.model]: "Models&Drawings",
  [formCategories.changeOrder]: "changeOrder",
};
