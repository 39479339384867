import { forwardRef } from "react";
import {
  defaultSyncfusionButtonProps,
} from "../../constants/globalVariable";
import { BUTTON_TYPES } from "../../constants/globalVariable";
import { LoadingButton, LoadingButtonProps } from '@mui/lab';

interface ButtonComponentProps extends LoadingButtonProps {
  buttonType?: any;
}

const ButtonComponent = forwardRef<HTMLButtonElement, ButtonComponentProps>((props: any, ref) => {
  let variant = ''
  let style = {}
  const { className, loading, disabled, ...otherProps } = props;
  let buttonType = props.buttonType
  if(!props.buttonType){
    buttonType =  BUTTON_TYPES.BACKGROUND_BUTTON
  }

  if ([BUTTON_TYPES.BACKGROUND_BUTTON, BUTTON_TYPES.INFO_BACKGROUND_BUTTON].includes(buttonType)) {
    variant = 'contained'
    style = {
      ...style,
      background: `var(--button-light)`,
      borderRadius: buttonType == BUTTON_TYPES.BACKGROUND_BUTTON ? `var(--size-5)` : `var(--size-20)`,
      border: "none",
      color: `var(--primary-text)`,
      "&:hover": {
        background: '#D8DEEC'
      },
      "&:disabled": {
        background: `var(--button-light)`,
        color: `var(--disabled-text-gray)`,
        cursor: "not-allowed",
      },
      transition: "background 0.5s ease"
    };
  }
  else if ([BUTTON_TYPES.GRADIENT_BUTTON, BUTTON_TYPES.INFO_GRADIENT_BUTTON].includes(buttonType)) {
    variant = 'contained';
    style = {
      ...style,
      background: `linear-gradient(100.44deg, var(--primary-gradient-color1) 0%, var(--primary-gradient-color2) 110.4%)`,
      borderRadius: buttonType == BUTTON_TYPES.GRADIENT_BUTTON ? `var(--size-5)` : `var(--size-20)`,
      color: `var(--surface-white) `,
      marginLeft: "0 !important",
      transition: "background 0.3s ease",
      "&:hover": {
        background: `linear-gradient(100.44deg, #8BC7E5 0%, #67A9D6 110.4%)`,
      },
      "&:disabled": {
        background: `#ACD3E7 !important`,
        color: `var(--surface-white) !important`,
        cursor: "not-allowed",
      },
    };
  }
  else if (buttonType == BUTTON_TYPES.BORDER_BUTTON) {
    style = {
      overflow: "unset",
      position: "relative",
      background: "var(--surface-white)",
      zIndex: 1,
      color: "var(--primary-text)",
      border: "1px solid #4592B7",
      borderRadius: "var(--size-4)",
      marginLeft: "0 !important",
      "&:hover": {
        background: '#E8E9ED'
      },
      "&:disabled": {
        background: "var(--surface-white)",
        color: "var(--disabled-text-gray)",
        border: "1px solid #B2D9ED",
        cursor: "var(--not-allowed) !important",
        borderRadius: "var(--size-5) !important",
      },
      transition: "background 0.3s ease"
    };
    variant = 'outlined'
  }
  else if (buttonType == BUTTON_TYPES.TEXT_BUTTON) {
    variant = 'text'
    style = {
      border: "none",
      background: "var(--surface-white)",
      borderRadius: "var(--size-5)",
      color: "var(--primary-text)",
      "&:hover": {
        background: '#E8E9ED'
      },
      "&:disabled": {
        background: `var(--none)`,
        WebkitBackgroundClip: "var(--padding-box)",
        WebkitTextFillColor: `var(--disabled-text-gray)`,
        borderRadius: "var(--size-5)",
        cursor: "var(--not-allowed)",
      },
      transition: "background 0.3s ease"
    }
  }
  return (
    <LoadingButton
      loading={loading}
      {...otherProps}
      className={`${className} + min-w-fit`}
      ref={ref}
      variant={variant}
      sx={{
        whiteSpace: "nowrap",
        padding: "2px 20px",
        ...style,
        textTransform: "none",
        ...props.sx,
        "& .MuiCircularProgress-root": {
          color:
            props.buttonType === BUTTON_TYPES.GRADIENT_BUTTON ||
              props.buttonType === BUTTON_TYPES.INFO_GRADIENT_BUTTON
              ? "white"
              : "inherit",
        },
      }}
      disabled={disabled}
    >
      <span
        style={{
          visibility: props.loading ? "hidden" : "inherit",
        }}>{props.children}</span>
    </LoadingButton>
  );
});

ButtonComponent.defaultProps = defaultSyncfusionButtonProps;
export default ButtonComponent;
