import React from "react";
import ChangeColor from "../../HoopsViewer/ToolbarViewer/ChangeColor";

function ObjectTree({ color, onClick }) {
  return (
    <svg
      onClick={onClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <linearGradient id="ObjectTree" gradientTransform="rotate(45)">
        <stop offset="38%" stopColor={color} />
        <stop offset="92%" stopColor={ChangeColor(color)} />
      </linearGradient>
      <path
        d="M16.2609 8H20.174C20.5199 8 20.8517 7.8683 21.0963 7.63388C21.3409 7.39946 21.4783 7.08152 21.4783 6.75V4.25C21.4783 3.91848 21.3409 3.60054 21.0963 3.36612C20.8517 3.1317 20.5199 3 20.174 3H16.2609C15.915 3 15.5832 3.1317 15.3386 3.36612C15.094 3.60054 14.9566 3.91848 14.9566 4.25V4.875H8.43482V4.25C8.43482 3.91848 8.2974 3.60054 8.05279 3.36612C7.80818 3.1317 7.47641 3 7.13048 3H3.21743C2.8715 3 2.53973 3.1317 2.29512 3.36612C2.05051 3.60054 1.91309 3.91848 1.91309 4.25V6.75C1.91309 7.08152 2.05051 7.39946 2.29512 7.63388C2.53973 7.8683 2.8715 8 3.21743 8H7.13048C7.47641 8 7.80818 7.8683 8.05279 7.63388C8.2974 7.39946 8.43482 7.08152 8.43482 6.75V6.125H11.0435V18.625C11.0439 18.9564 11.1814 19.2742 11.4259 19.5085C11.6705 19.7429 12.002 19.8747 12.3479 19.875H14.9566V20.5C14.9566 20.8315 15.094 21.1495 15.3386 21.3839C15.5832 21.6183 15.915 21.75 16.2609 21.75H20.174C20.5199 21.75 20.8517 21.6183 21.0963 21.3839C21.3409 21.1495 21.4783 20.8315 21.4783 20.5V18C21.4783 17.6685 21.3409 17.3505 21.0963 17.1161C20.8517 16.8817 20.5199 16.75 20.174 16.75H16.2609C15.915 16.75 15.5832 16.8817 15.3386 17.1161C15.094 17.3505 14.9566 17.6685 14.9566 18V18.625H12.3479V13H14.9566V13.625C14.9566 13.9565 15.094 14.2745 15.3386 14.5089C15.5832 14.7433 15.915 14.875 16.2609 14.875H20.174C20.5199 14.875 20.8517 14.7433 21.0963 14.5089C21.3409 14.2745 21.4783 13.9565 21.4783 13.625V11.125C21.4783 10.7935 21.3409 10.4755 21.0963 10.2411C20.8517 10.0067 20.5199 9.875 20.174 9.875H16.2609C15.915 9.875 15.5832 10.0067 15.3386 10.2411C15.094 10.4755 14.9566 10.7935 14.9566 11.125V11.75H12.3479V6.125H14.9566V6.75C14.9566 7.08152 15.094 7.39946 15.3386 7.63388C15.5832 7.8683 15.915 8 16.2609 8ZM16.2609 4.25H20.174V6.75H16.2609V4.25ZM7.13048 6.75H3.21743V4.25H7.13048V6.75ZM16.2609 18H20.174V20.5H16.2609V18ZM16.2609 11.125H20.174V13.625H16.2609V11.125Z"
        fill='url("#ObjectTree")'
      />
    </svg>
  );
}

export default ObjectTree;
