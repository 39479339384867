import axiosWrapper from "../services/axiosWrapper";
import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from "../services/constants";
import { showToastNotification } from "./toast";

export function setProjectRolesList(data) {
  return {
    type: "SET_PROJECT_ROLE_LIST",
    data,
  };
}
export function setSpacesInProjectList(data) {
  return {
    type: "SET_SPACES_LIST",
    data,
  };
}
export function setEntityList(data) {
  return {
    type: "SET_ENTITY_LIST",
    data,
  };
}
export function setCategoryList(data) {
  return {
    type: "SET_CATEGORY_LIST",
    data,
  };
}
export function setStatusData(data) {
  return {
    type: "SET_STATUS_LIST",
    data,
  };
}
export function getProjectUserList(data) {
  return {
    type: "PROJECT_USERS",
    data,
  };
}
export function deletedSpaceStatus(data) {
  return {
    type: "DELETED_SPACE",
    data,
  };
}

export function deletedUserStatus(data) {
  return {
    type: "DELETED_USER",
    data,
  };
}

export function getProjectSettingObject(data) {
  return {
    type: "PROJECT_SETTING_OBJECT",
    data,
  };
}

// updateProjectRolePermission
export function updateProjectRolePermission(data) {
  return (dispatch) => {
    axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/projects/changeProjectRolePermission`,
      data,
    );
  };
}
export function updateSpaceRolePermission(data) {
  return (dispatch) => {
    axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/spaces/changeUserSpacePermission`,
      data,
    );
  };
}

export function getProjectRoles(data) {
  return (dispatch) => {
    let projectId = data?.projectId;
    if (!projectId) {
      const pathArray = location.pathname.split("/");
      projectId = pathArray[pathArray.length - 2];
    }
    axiosWrapper
      .post(`${process.env.REACT_APP_CUBE_BACKEND}/projects/getProjectRoles`, {
        id: projectId,
      })
      .then((response) => {
        if (response.data.status) {
          dispatch(setProjectRolesList(response.data.data));
        }
      });
  };
}

export function getUsersInSpaces(data) {
  return (dispatch) => {
    axiosWrapper
      .post(
        `${process.env.REACT_APP_CUBE_BACKEND}/spaces/getSpaceUsersList`,
        data,
      )
      .then((response) => {
        dispatch(setSpacesInProjectList(response.data.result));
      });
  };
}

export function saveProjectRole(data, toastdata) {
  return (dispatch) => {
    axiosWrapper
      .post(
        `${process.env.REACT_APP_CUBE_BACKEND}/projects/changeUserRoleFromProject/`,
        data,
      )
      .then((response) => {
        if (response.data.status) {
          dispatch(
            showToastNotification({
              content: `${toastdata.selectedUserfromRow}'s role has been changed to ${toastdata.roleChangedTo}`,
              type: TOAST_TYPE_SUCCESS,
            }),
          );
        } else {
          dispatch(
            showToastNotification({
              content: res.data.errorMessage,
              type: TOAST_TYPE_ERROR,
            }),
          );
        }
      });
  };
}
// updateStatusSpaces
export function updateStatusSpaces(data) {
  return (dispatch) => {
    axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/status/editVisibleData`,
      data,
    );
  };
}
// getAllEntity
export function getAllEntity() {
  return (dispatch) => {
    axiosWrapper
      .get(`${process.env.REACT_APP_CUBE_BACKEND}/status/getEntityList`)
      .then((response) => {
        if (response.data.status) {
          dispatch(setEntityList(response.data.data));
        }
      });
  };
}
// addStatusToProject
export function addStatusToProject(data) {
  return (dispatch) => {
    axiosWrapper
      .post(`${process.env.REACT_APP_CUBE_BACKEND}/status/saveStatusData`, data)
      .then((response) => {});
  };
}
// getStatusData
export function getStatusData(data) {
  return (dispatch) => {
    axiosWrapper
      .post(`${process.env.REACT_APP_CUBE_BACKEND}/status/getStatusData`, data)
      .then((response) => {
        if (response.data.status) {
          dispatch(setStatusData(response.data.data));
        }
      });
  };
}
// getAllCategories
export function getAllCategories() {
  return (dispatch) => {
    axiosWrapper
      .get(`${process.env.REACT_APP_CUBE_BACKEND}/status/getCategoryData`)
      .then((response) => {
        if (response.data.status) {
          dispatch(setCategoryList(response.data.data));
        }
      });
  };
}
// get list of user which are added into project
export function getProjectUserDetails(data) {
  return (dispatch) => {
    axiosWrapper
      .post(
        `${process.env.REACT_APP_CUBE_BACKEND}/projects/getProjectUsersList`,
        data,
      )
      .then((response) => {
        if (response && response.data.status) {
          dispatch(getProjectUserList(response.data.data));
        }
      });
  };
}

// export function deleteSpace(data) {
//   return async (dispatch) => {
//     const response = await axiosWrapper.post(
//       `${process.env.REACT_APP_CUBE_BACKEND}/spaces/deleteSpacesData`,
//       data
//     );

//     dispatch(deletedSpaceStatus(response.data.data));
//     return response.data.data.status;
//   };
// }

// delete space from project
export function deleteSpace(data) {
  return (dispatch) => {
    axiosWrapper
      .post(
        `${process.env.REACT_APP_CUBE_BACKEND}/spaces/deleteSpacesData`,
        data,
      )
      .then((response) => {
        dispatch(deletedSpaceStatus(response.data.data));
      });
  };
}

// Delete User from a project

export function RemoveUserFromProject(data) {
  return (dispatch) => {
    axiosWrapper
      .post(
        `${process.env.REACT_APP_CUBE_BACKEND}/projects/removeUserFromProject`,
        data,
      )
      .then((response) => {
        if (response.data.data) {
          dispatch(deletedUserStatus(response.data.data));
        }
      });
  };
}

export async function removeUsersFromSingleProject(data) {
  const response = await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/projects/removeUsersFromSingleProject/`,
    data,
  );
  return response.data;
}

export function getProjectObject(data) {
  return (dispatch) => {
    axiosWrapper
      .get(
        `${process.env.REACT_APP_CUBE_BACKEND}/projects/getProjectObject/${data.id}`,
      )
      .then((res) => {
        if (res.data.status) {
          dispatch(getProjectSettingObject(res.data.data));
        }
      })
      .catch((err) => console.log("Error in project setting object ", err));
  };
}
export const getIncludedProjectsList = async (data) => {
  const response = await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/projects/getIncludedProjectsController`,
    data,
  );
  return response;
};
export const teamsController = async (data) => {
  const response = await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/projects/teamsController`,
    data,
  );
  return response;
};
export const locationController = async (data) => {
  const response = await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/projects/locationController`,
    data,
  );
  return response;
};
export const projectTemplateConfigHandler = async (data) => {
  const response = await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/forms/projectTemplateConfigHandler`,
    data,
  );
  return response;
};
export const WBSController = async (data) => {
  const response = await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/projects/WBSController`,
    data,
  );
  return response;
};
export const saveDefaultSpace = async (data) => {
  return await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/spaces/saveDefaultSpace`,
    data,
  );
};
