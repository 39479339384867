import React from "react";
import ChangeColor from "../../HoopsViewer/ToolbarViewer/ChangeColor";

function SelectionSetIcon({ onClick, color }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          id="5kt35qv9pa"
          x1="2.2"
          y1="1.8"
          x2="18.6"
          y2="18.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".346" stopColor={color} />
          <stop offset="1" stopColor={ChangeColor(color)} />
        </linearGradient>
      </defs>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.08333 3.69444H15.9167V2.22222C15.9167 1.96437 16.0191 1.71708 16.2014 1.53476C16.3838 1.35243 16.631 1.25 16.8889 1.25H21.7778C22.0356 1.25 22.2829 1.35243 22.4652 1.53476C22.6476 1.71708 22.75 1.96437 22.75 2.22222V7.11111C22.75 7.36896 22.6476 7.61625 22.4652 7.79858C22.2829 7.9809 22.0356 8.08333 21.7778 8.08333H20.3056V15.9167H21.7778C22.0356 15.9167 22.2829 16.0191 22.4652 16.2014C22.6476 16.3838 22.75 16.631 22.75 16.8889V21.7778C22.75 22.0356 22.6476 22.2829 22.4652 22.4652C22.2829 22.6476 22.0356 22.75 21.7778 22.75H16.8889C16.631 22.75 16.3838 22.6476 16.2014 22.4652C16.0191 22.2829 15.9167 22.0356 15.9167 21.7778V20.3056H8.08333V21.7778C8.08333 22.0356 7.9809 22.2829 7.79858 22.4652C7.61625 22.6476 7.36896 22.75 7.11111 22.75H2.22222C1.96437 22.75 1.71708 22.6476 1.53476 22.4652C1.35243 22.2829 1.25 22.0356 1.25 21.7778V16.8889C1.25 16.631 1.35243 16.3838 1.53476 16.2014C1.71708 16.0191 1.96437 15.9167 2.22222 15.9167H3.69444V8.08333H2.22222C1.96437 8.08333 1.71708 7.9809 1.53476 7.79858C1.35243 7.61625 1.25 7.36896 1.25 7.11111V2.22222C1.25 1.96437 1.35243 1.71708 1.53476 1.53476C1.71708 1.35243 1.96437 1.25 2.22222 1.25H7.11111C7.36896 1.25 7.61625 1.35243 7.79858 1.53476C7.9809 1.71708 8.08333 1.96437 8.08333 2.22222V3.69444ZM20.8056 3.19444H17.8611V6.13889H20.8056V3.19444ZM6.13889 3.19444H3.19444V6.13889H6.13889V3.19444ZM3.19444 20.8056H6.13889V17.8611H3.19444V20.8056ZM17.8611 20.8056H20.8056V17.8611H17.8611V20.8056ZM16.8889 15.9167H18.3611V8.08333H16.8889C16.631 8.08333 16.3838 7.9809 16.2014 7.79858C16.0191 7.61625 15.9167 7.36896 15.9167 7.11111V5.63889H8.08333V7.11111C8.08333 7.36896 7.9809 7.61625 7.79858 7.79858C7.61625 7.9809 7.36896 8.08333 7.11111 8.08333H5.63889V15.9167H7.11111C7.36896 15.9167 7.61625 16.0191 7.79858 16.2014C7.9809 16.3838 8.08333 16.631 8.08333 16.8889V18.3611H15.9167V16.8889C15.9167 16.631 16.0191 16.3838 16.2014 16.2014C16.3838 16.0191 16.631 15.9167 16.8889 15.9167Z"
        fill="url(#5kt35qv9pa)"
      />
    </svg>
  );
}
export default SelectionSetIcon;
